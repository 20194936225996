import React, { FC } from 'react'
import { FormikContextType, useFormikContext } from 'formik'
import { makeStyles, MenuItem, TextField, OutlinedTextFieldProps } from '@material-ui/core'
import Schema from './Schema'
import { roleFormatter } from './User'
import { Roles } from '../../../graphql-types'

const useStyles = makeStyles(() => ({
  select: {
    width: '40%'
  }
}))

const TEXTFIELDPROPS: OutlinedTextFieldProps = {
  fullWidth: true,
  select: true,
  id: 'input.role',
  label: 'Role',
  name: 'input.role',
  SelectProps: {
    MenuProps: {
      getContentAnchorEl: null,
      anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
      transformOrigin: { vertical: 'top', horizontal: 'center' }
    }
  },
  variant: 'outlined'
}

const RoleSelect: FC<{ isAdminOnOwnPage: boolean }> = ({ isAdminOnOwnPage }) => {
  const { values, errors, touched, handleBlur, handleChange }: FormikContextType<Schema> = useFormikContext()
  const classes = useStyles()

  return (
    <TextField
      {...TEXTFIELDPROPS}
      error={touched?.input?.role && Boolean(errors?.input?.role)}
      helperText={touched?.input?.role && errors?.input?.role}
      className={classes.select}
      onBlur={handleBlur}
      onChange={handleChange}
      value={values.input.role || ''}
    >
      {Object.values(Roles)
        .reverse()
        .map((item) => (
          <MenuItem key={item} value={item} disabled={item !== 'ADMIN' && isAdminOnOwnPage}>
            {roleFormatter(item)}
          </MenuItem>
        ))}
    </TextField>
  )
}

export default RoleSelect
