/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type AddTopStoryQueryVariables = {
    query: string;
};
export type AddTopStoryQueryResponse = {
    readonly organization: {
        readonly media: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly title: string | null;
                    readonly public_at: unknown | null;
                    readonly thumbnails: {
                        readonly small: string | null;
                    } | null;
                } | null;
            } | null> | null;
        } | null;
    };
};
export type AddTopStoryQuery = {
    readonly response: AddTopStoryQueryResponse;
    readonly variables: AddTopStoryQueryVariables;
};



/*
query AddTopStoryQuery(
  $query: String!
) {
  organization {
    media(query: $query, max_age: 259200, sort: {attribute: "public_at", direction: DESC}) {
      edges {
        node {
          __typename
          id
          title
          public_at
          thumbnails(aspect_ratio: SQUARE) {
            small
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "max_age",
    "value": 259200
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "query"
  },
  {
    "kind": "Literal",
    "name": "sort",
    "value": {
      "attribute": "public_at",
      "direction": "DESC"
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "public_at",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "aspect_ratio",
      "value": "SQUARE"
    }
  ],
  "concreteType": "Thumbnail",
  "kind": "LinkedField",
  "name": "thumbnails",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "small",
      "storageKey": null
    }
  ],
  "storageKey": "thumbnails(aspect_ratio:\"SQUARE\")"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddTopStoryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SlugableInterfaceWithHitCountConnection",
            "kind": "LinkedField",
            "name": "media",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SlugableEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddTopStoryQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "SlugableInterfaceWithHitCountConnection",
            "kind": "LinkedField",
            "name": "media",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SlugableEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cbef4faa82cd149165ae5521e3142a46",
    "id": null,
    "metadata": {},
    "name": "AddTopStoryQuery",
    "operationKind": "query",
    "text": "query AddTopStoryQuery(\n  $query: String!\n) {\n  organization {\n    media(query: $query, max_age: 259200, sort: {attribute: \"public_at\", direction: DESC}) {\n      edges {\n        node {\n          __typename\n          id\n          title\n          public_at\n          thumbnails(aspect_ratio: SQUARE) {\n            small\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd96f461ae3b9dca65cbbfe0497108b10';
export default node;
