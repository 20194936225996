import React from 'react'
import styled, { css } from 'styled-components'

type Props = {
  open: boolean,
  onClick?: () => void
}

const StyledButton = styled.div<Props>`
  background-color: ${props => props.theme.colors.grays.solitude};
  position: absolute;
  right: 24px;
  top: 17px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  transition: background-color .10s ease-in-out;
  z-index: 1;

  ${props => !props.open && css`
    &:hover {
      background-color: #e2e3e6
    }
  `}

  ${props => props.open && css`
    background-color: ${props.theme.colors.secondary.lightCyan};
  `}

  i {
    transform: rotate(${props => props.open ? 180 : 0}deg);
    transition: color .10s ease-in-out, transform .15s ease-in-out;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.open ? 'white' : props.theme.colors.primary.blackRussian};
  }
`

const ToggleButton: React.FC<Props> = ({ onClick, ...props }) => (
  <StyledButton onClick={onClick} {...props}>
    <i className="material-icons">keyboard_arrow_down</i>
  </StyledButton>
)

export default ToggleButton
