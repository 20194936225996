import React, { useState, FC } from 'react'
import { createFragmentContainer } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import { Route, Redirect, Switch } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import { Layout_device as Device } from './__generated__/Layout_device.graphql'
import { Theme } from '../theme'
import NotFound from './NotFound'
import Livestream from './Settings/Livestream'
import Metatags from './Settings/Metatags'
import Navigation from './Navigation/Navigation'
import TopBar from './Navigation/TopBar/TopBar'
import ArticleIndex from './ArticleIndex'
import ArticleDetail from './ArticleDetail'
import CollectionIndex from './CollectionIndex'
import CollectionDetail from './CollectionDetail'
import DashboardRenderer from './DashboardRenderer'
import PageIndex from './PageIndex'
import PageDetail from './PageDetail'
import TemplateIndex from './TemplateIndex'
import TemplateDetail from './TemplateDetail'
import VideoIndex from './VideoIndex'
import AuthorIndex from './Settings/Authors/AuthorIndex'
import AuthorEdit from './Settings/Authors/AuthorEdit'
import UserEdit from './Settings/Users/UserEdit'
import UserIndex from './Settings/Users/UserIndex'
import TopStoriesIndex from './TopStories/TopStoriesIndex'
import TopStoryListEdit from './TopStories/TopStoryListEdit'
import TopStoryEdit from './TopStories/TopStoryEdit/TopStoryEdit'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 200
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}))

const Layout: FC<{ device: Device }> = ({ device, device: { user } }) => {
  const classes = useStyles()
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false)

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} device={device} />
      <Navigation device={device} onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            <Switch>
              <Redirect from='/' exact={true} to='/articles' />
              <Route path='/articles' exact={true} component={ArticleIndex} />
              <Route path='/articles/:id' component={ArticleDetail} />
              <Route path='/topstories' exact={true} component={TopStoriesIndex} />
              <Route path='/topstories/:id' exact={true} component={TopStoryEdit} />
              <Route path='/topstorylist/:id' exact={true} component={TopStoryListEdit} />
              <Route path='/collections' exact={true} component={CollectionIndex} />
              <Route path='/collections/:id' component={CollectionDetail} />
              <Route path='/dashboard' component={DashboardRenderer} />
              <Route path='/pages' exact={true} component={PageIndex} />
              <Route path='/pages/:id' component={PageDetail} />
              <Route path='/templates' exact={true} component={TemplateIndex} />
              <Route path='/templates/:id' component={TemplateDetail} />
              <Route path='/videos' exact={true} component={VideoIndex} />

              <Route path='/users/:id' exact={true}>
                <UserEdit id={user?.id!} role={user?.role!} />
              </Route>

              {user?.role === 'ADMIN' && (
                <Switch>
                  <Route path='/authors' exact={true} component={AuthorIndex} />
                  <Route path='/authors/:id' exact={true} component={AuthorEdit} />
                  <Route path='/settings' exact={true} component={Livestream} />
                  <Route path='/settings/metatags' exact={true} component={Metatags} />
                  <Route path='/users' exact={true} component={UserIndex} />
                  <Route component={NotFound} />
                </Switch>
              )}

              <Route component={NotFound} />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  )
}

Layout.displayName = 'Layout'

export default createFragmentContainer(Layout, {
  device: graphql`
    fragment Layout_device on DeviceToken {
      ...Navigation_device
      user {
        role
        id
        ...Account_user
      }
    },
  `
})
