import React, { useContext, useState, useEffect, useCallback } from 'react'
import { Context, ContextType } from '../Form'
import { fetchQuery } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import environment from '../../../relay/environment'
import styled, { css } from 'styled-components'
import Search from '../HeroPicker/Search'
import { Media_SlugableQueryResponse } from './__generated__/Media_SlugableQuery.graphql'
import MediaItem, { MediaItemPlaceholder } from './MediaItem'
import MediaListRenderer from './MediaListRenderer'

export const StyledMediaWrapper = styled.div`
  display: grid;
  width: 100% !important;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(3, auto);
  gap: 30px 20px;

  > div:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 6;
    grid-row: 1/3;
  }

  > div:nth-child(2) {
    grid-column-start: 6;
    grid-column-end: 9;
  }

  > div:nth-child(3) {
    grid-column-start: 6;
    grid-column-end: 9;
  }

  > div:nth-child(4) { grid-column: 1/3 }

  > div:nth-child(5) { grid-column: 3/5 }

  > div:nth-child(6) { grid-column: 5/7 }

  > div:nth-child(7) { grid-column: 7/9 }

  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > div:first-of-type {
    h2 {
      font-weight: 600;
      font-size: 26px;
      line-height: 29px;
    }
  }

  > div:not(:first-of-type) {
    h2 {
      font-weight: 500;
      font-size: 17px;
      line-height: 19px;
    }
  }
`

const StyledMediaItem = styled.div`
  display: flex;
  flex-direction: column;
`

const SearchWrapper = styled.div<{open: boolean}>`
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(32, 32, 32, 0.8);
  z-index: 3;
  ${({ open }) => open && css`
    display: flex;
    align-items: center;
    justify-content: center;
  `};
`

const SearchContainer = styled.div`
  width: 838px;
  height: 679px;
  background: #ffffff;
  position: relative;

  > div:nth-of-type(2) {
    align-items: flex-start;
    padding: 69px 77px;
    flex-wrap: wrap;
    input {
      width: 100%;
      border: 1px solid #8E8F91;
      padding: 24px 16px;
      margin-bottom: 46px;
    }
  }
`

const CloseSearch = styled.div`
  position: absolute;
  right: 35px;
  top: 29px;
  cursor: pointer;
  z-index: 1;
`

const slugableMediaQuery = graphql`
  query Media_SlugableQuery($id: ID!) {
    node(id: $id) {
      ...MediaItem_slugable
    }
  }
`

const getSlugableMedia = async(id: string, callback: (data: Media_SlugableQueryResponse) => void) => {
  const data = await fetchQuery(environment, slugableMediaQuery, { id }).toPromise() as Media_SlugableQueryResponse
  callback(data)
}

type MediaSearchProps = {
  open: boolean
  dismissSearch: () => void
  renderListComponent: (searchTerm: string) => JSX.Element
}

const MediaSearch: React.FC<MediaSearchProps> = ({ open, dismissSearch, renderListComponent }) => (
  <SearchWrapper open={open} data-testid='search-wrapper'>
    <SearchContainer>
      <CloseSearch onClick={dismissSearch} role='button'><i className="material-icons">close</i></CloseSearch>
      <Search onDismiss={dismissSearch} renderListComponent={renderListComponent}/>
    </SearchContainer>
  </SearchWrapper>
)

const Media: React.FC<{ name: string }> = (props) => {
  const [slugable, setSlugable] = useState<Media_SlugableQueryResponse>()
  const [open, setOpen] = useState(false)
  const { model, onChange, disabled } = useContext(Context) as ContextType
  const { name } = props
  const id = model[name] || null
  const dismissSearch = useCallback(() => setOpen(false), [])
  const showSearch = useCallback(() => setOpen(true), [])

  useEffect(() => {
    (id)
      ? getSlugableMedia(id, (data) => setSlugable(data))
      : setSlugable(undefined)
  }, [id])

  const renderListComponent = (searchTerm: string) => {
    return <MediaListRenderer dismiss={dismissSearch} searchTerm={searchTerm} name={name}/>
  }

  return (
    <StyledMediaItem>
      {slugable && slugable.node
        ? <MediaItem slugable={slugable.node} onSearch={showSearch} onDelete={() => onChange(name, '')} disabled={disabled}/>
        : <MediaItemPlaceholder onSearch={showSearch}/>
      }
      <MediaSearch open={open} dismissSearch={dismissSearch} renderListComponent={renderListComponent} />
    </StyledMediaItem>
  )
}

export default Media
