import React from 'react'
import styled from 'styled-components'
import ReactTagInput from '@pathofdev/react-tag-input'
import '@pathofdev/react-tag-input/build/index.css'
import { Name as Label, ErrorMessage } from './Input'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  width: 400px;

  .react-tag-input {
    font-size: ${props => props.theme.fontSizes[3]}px;
    font-weight: normal;
    line-height: ${props => props.theme.space[4]}px;
    padding: ${props => props.theme.space[2]}px;
    border: 1px solid #c4c4c4;
    border-radius: 2px;
    width: 100%;
    outline: none;
    max-height: 200px;

    ::placeholder {
      color: ${props => props.theme.colors.grays.aluminium};
    }
  }

  .react-tag-input__input {
    padding: 0;
  }

  .react-tag-input__tag {
    background: ${props => props.theme.colors.primary.blackRussian};
    color: white;
  }

  .react-tag-input__tag__remove {
    background: ${props => props.theme.colors.primary.blackRussian};
  }

  .react-tag-input__tag__remove:before, .react-tag-input__tag__remove:after {
    background-color: white
  }
`

type Props = {
  error?: string
  handleChange: ({ name, value }: { name: string, value: string | string [] }) => void
  values: string[] | []
}

const Chips: React.FC<Props> = ({ error, handleChange, values }) => (
  <Container>
    <Label>Values</Label>
    <ReactTagInput
      onChange={(tags) => handleChange({ name: 'fixedOptions', value: tags })}
      placeholder='Type in a value and hit enter'
      removeOnBackspace={true}
      tags={values}
    />
    {error && <ErrorMessage>{error}</ErrorMessage>}
  </Container>
)

export default Chips
