/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type ListDeleteMetatagMutationVariables = {
    id: string;
};
export type ListDeleteMetatagMutationResponse = {
    readonly metatagDelete: {
        readonly metatag: {
            readonly isDeleted: boolean;
            readonly id: string;
        };
    } | null;
};
export type ListDeleteMetatagMutation = {
    readonly response: ListDeleteMetatagMutationResponse;
    readonly variables: ListDeleteMetatagMutationVariables;
};



/*
mutation ListDeleteMetatagMutation(
  $id: ID!
) {
  metatagDelete(id: $id) {
    metatag {
      isDeleted
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "MetatagDeleteMutationPayload",
    "kind": "LinkedField",
    "name": "metatagDelete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Metatag",
        "kind": "LinkedField",
        "name": "metatag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDeleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ListDeleteMetatagMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ListDeleteMetatagMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1eebd1b6206117e70866a9f1ab049d9b",
    "id": null,
    "metadata": {},
    "name": "ListDeleteMetatagMutation",
    "operationKind": "mutation",
    "text": "mutation ListDeleteMetatagMutation(\n  $id: ID!\n) {\n  metatagDelete(id: $id) {\n    metatag {\n      isDeleted\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'bf3ea7d5ff14321a0144d6004579a6ae';
export default node;
