import React from 'react'
import { useHistory } from 'react-router-dom'
import { createPaginationContainer, RelayPaginationProp } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import { Draftable, PaginatedTable, PaginatedWrapper } from './Index'
import { PageIndexQuery as Query } from './__generated__/PageIndexQuery.graphql'
import { PageIndex_query as Response } from './__generated__/PageIndex_query.graphql'
import { PageIndexCreatePageMutation as Mutation } from './__generated__/PageIndexCreatePageMutation.graphql'
import { History } from 'history'

const PER_PAGE = 10

const query = graphql`
  query PageIndexQuery (
    $count: Int!,
    $cursor: String,
    $query: String
  ) {
    ...PageIndex_query @arguments(count: $count, cursor: $cursor, query: $query)
  }
`

const mutation = graphql`
  mutation PageIndexCreatePageMutation($organizationId: ID!) {
    pageCreate(organizationId: $organizationId) {
      draft {
        draftable {
          ...on Node {
            id
          }
        }
      }
    }
  }
`

const paginatedWrapperProps = (relay: RelayPaginationProp, organizationId: string, history: History) => {
  return {
    relay,
    variables: { organizationId },
    mutation,
    perPage: PER_PAGE,
    title: 'Pages',
    onCreate: (props: Mutation['response']) => history.push(`/pages/${props?.pageCreate?.draft?.draftable?.id}`)
  }
}

const Container = createPaginationContainer(
  (props: { query: Response, relay: RelayPaginationProp }) => {
    const { query: { organization: { pages } }, query, relay } = props
    const history = useHistory()
    const wrapperProps = paginatedWrapperProps(relay, query.organization.id, history)

    return (
      <PaginatedWrapper<Mutation> search={true} rowCount={pages?.edges?.length} {...wrapperProps}>
        <Draftable.Header />
        {pages?.edges?.map((e: any, i: number) => <Draftable.Cell key={i} draftable={e.node} index={i} />)}
      </PaginatedWrapper>
    )
  },
  {
    query: graphql`
      fragment PageIndex_query on Query
      @argumentDefinitions(
        count: { type: "Int" }
        cursor: { type: "String" }
        query: { type: "String" }
      ) {
        organization {
          id
          pages(
            first: $count,
            after: $cursor,
            query: $query
          ) @connection(key: "PageIndex_pages") {
            edges {
              node {
                id
                ...DraftableCell_draftable
              }
            }
          }
        }
      }
    `
  },
  {
    query,
    getVariables(props, { count, cursor }, { query }) {
      return {
        count,
        cursor,
        query,
      }
    }
  }
)

export default () => (
  <PaginatedTable<Query> Component={Container} query={query} variables={{ count: PER_PAGE }} />
)
