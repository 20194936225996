import React, { FC } from 'react'
import * as Yup from 'yup'
import { FormikContextType, useFormikContext } from 'formik'
import { Box, TextField, Divider, Dialog } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { graphql } from 'babel-plugin-relay/macro'
import { AuthorCreateMutationResponse } from './__generated__/AuthorCreateMutation.graphql'
import Form, { formatErrors, handleNonFieldErrors, OnCompletedOptions as FormProps } from '../Form'
import ModalHeader from '../../GenericFormComponents/ModalHeader'
import Buttons from '../../GenericFormComponents/ModalButtons'

export interface Props {
  id: string
  updateAuthorPage: () => void
  onClose: () => void
  open: boolean
}

interface ValidationSchema {
  organizationId: string
  input: {
    byline: string
    slug: string
  }
}

const initialValues = (id: string): ValidationSchema => ({
  organizationId: id,
  input: {
    byline: '',
    slug: ''
  }
})

const mutation = graphql`
  mutation AuthorCreateMutation($organizationId: ID!, $input: AuthorInput!) {
    authorCreate(organizationId: $organizationId, input: $input) {
      author {
        active
        byline
      }
      errors {
        field
        messages
      }
    }
  }
`

const validationSchema = Yup.object().shape({
  organizationId: Yup.string().max(255),
  input: Yup.object().shape({
    slug: Yup.string().max(5000),
    byline: Yup.string().max(255).required('Byline is required')
  })
})

const AuthorCreateForm: FC = () => {
  const { values, errors, touched, handleBlur, handleChange }: FormikContextType<ValidationSchema> = useFormikContext()

  return (
    <>
      <ModalHeader title={'Create Author'} />
      <Box p={3}>
        <Box height={70}>
          <TextField
            fullWidth
            id='input.byline'
            label='Byline'
            name='input.byline'
            error={touched?.input?.byline && Boolean(errors?.input?.byline)}
            helperText={touched?.input?.byline && errors?.input?.byline}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.input.byline}
            variant='outlined'
          />
        </Box>
        <Box mt={2} height={70}>
          <TextField
            fullWidth
            id='input.slug'
            label='Slug'
            name='input.slug'
            error={touched?.input?.slug && Boolean(errors?.input?.slug)}
            helperText={touched?.input?.slug && errors?.input?.slug}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.input.slug}
            variant='outlined'
          />
        </Box>
      </Box>
      <Divider />
    </>
  )
}

interface onCompleteProps extends FormProps {
  values: AuthorCreateMutationResponse
}

const AuthorCreate: FC<Props> = ({ onClose, open, id, updateAuthorPage }) => {
  const { enqueueSnackbar } = useSnackbar()

  const onComplete = ({ values: { authorCreate }, setErrors, resetForm, formValues }: onCompleteProps) => {
    if (!authorCreate?.errors.length) {
      onClose()
      updateAuthorPage()
      resetForm()
      enqueueSnackbar(`"${authorCreate?.author?.byline}" successfully created`, { variant: 'success' })
    } else {
      handleNonFieldErrors(authorCreate.errors, formValues!.input, enqueueSnackbar)
      setErrors({ input: formatErrors(authorCreate.errors) })
    }
  }

  return (
    <Dialog maxWidth='xs' fullWidth onClose={onClose} open={open}>
      <Form mutation={mutation} onComplete={onComplete} validationSchema={validationSchema} initialValues={initialValues(id)}>
        <AuthorCreateForm />
        <Buttons onClose={onClose} buttonText={'Create'} />
      </Form>
    </Dialog>
  )
}

export default AuthorCreate
