import React from 'react'
import styled from 'styled-components/macro';

const LoadingTakeover = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.5;
`

const Spinner = styled.div`
  position: absolute;
  background: url("https://d1g3c9sgfxg9xw.cloudfront.net/loop/loading.gif") no-repeat 50%;
  top: 42%;
  left: 50%;
  height: 120px;
  width: 120px;
`

const Loader = () => {
  return (
    <LoadingTakeover role='alert' aria-busy={true}>
      <Spinner />
    </LoadingTakeover>
  )
}

export default Loader
