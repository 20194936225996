import React from 'react'
import styled from 'styled-components'
import { components, InputProps, Props as SelectProps } from 'react-select'

const StyledPlaceholder = styled.div`
  font-size: ${props => props.theme.fontSizes[1]}px !important;
  color: ${props => props.theme.colors.grays.tuna} !important;
  font-weight: ${props => props.theme.fontWeights[1]};
  position: absolute;
  z-index: 1;
`

const StyledInputContainer = styled.div`
  width: 100%;
  padding-left: ${props => props.theme.space[3]}px;
  padding-right: ${props => props.theme.space[3]}px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(238, 238, 238, 0.32);
  border: solid 1px rgba(151, 151, 151, 0.1);
  height: 40px;
  display: flex;
  align-items: center;
  > div:last-of-type {
    margin: 0;
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 2;
    padding: 0;
  }
`

const StyledSearchIcon = styled.i`
  font-size: 18px;
  color: ${props => props.theme.colors.grays.stormGray};
`

const StyledOptionText = styled.div`
  padding-left: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 198px;
  white-space: nowrap;
`
StyledOptionText.displayName = "OptionText"

const Input = (props: InputProps & SelectProps) => (
  <StyledInputContainer>
    { !props.selectProps.inputValue && <StyledPlaceholder>{props.selectProps.placeholder}</StyledPlaceholder> }
    <components.Input {...props}/>
    <StyledSearchIcon className='material-icons'>search</StyledSearchIcon>
  </StyledInputContainer>
)

export default Input
