import React, { useEffect, useState } from 'react'
import Signin from './Signin/Signin'
import ErrorRenderer, { ErrorType } from './ErrorRenderer'
import Authenticator from '../authenticators/GoogleAuthenticator'
import { makeStyles, Typography, Box } from '@material-ui/core'
import clsx from 'clsx'
import { Theme } from '../theme'
import RequestPasswordReset from './Signin/ResetPassword/RequestPasswordReset'

declare global {
  interface Window {
    onSignIn: (googleUser: GoogleUser) => Promise<void>
    gapi: any
  }
}

export type GoogleUser = { getAuthResponse: (includeAuthorizationData: boolean) => { access_token: string } }

export const onSignIn = (setError?: (e: Error) => void) => {
  window.onSignIn = (googleUser: GoogleUser): Promise<void> => {
    return new Authenticator(googleUser.getAuthResponse(true).access_token).authenticateWithBackend().catch((e: Error) => setError!(e))
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.primary
  },
  container: {
    width: '608px',
    borderRadius: '4px',
    boxShadow: '0 2px 3px 0 rgba(244, 245, 247, 0.91)',
    padding: '52px 160px 93px 160px',
    boxSizing: 'border-box',
    backgroundColor: theme.palette.background.default
  },
  typography: {
    '&:hover': {
      cursor: 'pointer'
    },
    color: theme.palette.text.secondary
  }
}))

const ShowPasswordReset: React.FC<{ cb: () => void }> = ({ cb }) => {
  const classes = useStyles()

  return (
    <Box mt={2}>
      <Typography className={clsx(classes.typography)} onClick={cb}>
        Reset password
      </Typography>
    </Box>
  )
}

const LoggedOut: React.FC = () => {
  const [error, setError] = useState<ErrorType | null>(null)
  const [showPasswordReset, setShowPasswordReset] = useState(false)
  const errorMessage = error ? error.message : ''
  const classes = useStyles()

  useEffect(() => {
    onSignIn(setError)
  }, [])

  return (
    <div className={clsx(classes.wrapper)}>
      <Box className={clsx(classes.container)}>
        {!showPasswordReset && (
          <Signin>
            <ShowPasswordReset cb={() => setShowPasswordReset(true)} />
          </Signin>
        )}
        {showPasswordReset && <RequestPasswordReset onCancel={() => setShowPasswordReset(false)} />}
      </Box>
      <ErrorRenderer message={errorMessage} />
    </div>
  )
}

export default LoggedOut
