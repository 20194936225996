import React, { useEffect, FC } from 'react'
import { useLocation, matchPath, useHistory } from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Box, Divider, Drawer, Hidden, List, makeStyles } from '@material-ui/core'
import NotesIcon from '@material-ui/icons/Notes'
import ListIcon from '@material-ui/icons/List'
import DescriptionIcon from '@material-ui/icons/Description'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import SettingsIcon from '@material-ui/icons/Settings'
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import RecentActorsIcon from '@material-ui/icons/RecentActors'
import PeopleIcon from '@material-ui/icons/People'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import { createFragmentContainer } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import useFeatureFlag from '../../hooks/useFeatureFlag'
import NavItem from './NavItem'
import { Navigation_device, Roles } from './__generated__/Navigation_device.graphql'
import { Theme } from '../../theme'

interface NavigationProps {
  onMobileClose: () => void
  openMobile: boolean
  device: Navigation_device
}

interface Item {
  href: string
  icon: (props: any) => JSX.Element
  items?: Item[]
  title: string
  role?: Roles
  flag?: string
}

interface Section {
  items: Item[]
}

interface ReduceChildRoutesProps {
  acc: any
  pathname: string
  item: Item
  depth: number
  isAdmin: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  mobileDrawer: {
    width: 200,
    backgroundColor: theme.palette.background.dark
  },
  desktopDrawer: {
    width: 200,
    top: 64,
    height: 'calc(100% - 64px)',
    backgroundColor: theme.palette.background.dark
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}))

const LINKS: Item[] = [
  {
    title: 'Articles',
    icon: NotesIcon,
    href: '/articles'
  },
  {
    title: 'Top Stories',
    icon: SpeakerNotesIcon,
    href: '/topstories',
    flag: 'topStories'
  },
  {
    title: 'Pages',
    icon: ListIcon,
    href: '/pages'
  },
  {
    title: 'Templates',
    icon: DescriptionIcon,
    href: '/templates'
  },
  {
    title: 'Videos',
    icon: PlayArrowIcon,
    href: '/videos'
  },
  {
    title: 'Authors',
    href: '/authors',
    icon: RecentActorsIcon,
    role: 'ADMIN',
    flag: 'settings[authors]'
  },
  {
    title: 'Users',
    href: '/users',
    icon: PeopleIcon,
    role: 'ADMIN',
    flag: 'settings[users]'
  },
  {
    title: 'Settings',
    icon: SettingsIcon,
    role: 'ADMIN',
    href: '/settings',
    items: [
      {
        title: 'Livestreaming',
        href: '/settings',
        icon: SettingsInputAntennaIcon
      },
      {
        title: 'Tags',
        href: '/settings/metatags',
        icon: LocalOfferIcon,
        flag: 'settings[metatags]'
      }
    ]
  }
]

const reduceChildRoutes = ({ acc, pathname, item, depth, isAdmin }: ReduceChildRoutesProps) => {
  const sharedProps = {
    depth,
    IconComponent: item.icon,
    key: item.title + depth,
    title: item.title,
    isAdmin: isAdmin
  }

  if (item.items) {
    const open = matchPath(pathname, { path: item.href, exact: false })

    acc.push(
      <NavItem {...sharedProps} open={Boolean(open)}>
        <List disablePadding>
          {item.items.reduce((acc, item) => reduceChildRoutes({ acc, pathname, item, depth: depth + 1, isAdmin }), [])}
        </List>
      </NavItem>
    )
  } else {
    acc.push(<NavItem {...sharedProps} href={item.href} isAdmin={isAdmin} />)
  }
  return acc
}

const Content: FC<{ links: Section[]; pathname: string; isAdmin: boolean }> = ({ links, pathname, isAdmin }) => (
  <Box height='100%' display='flex' flexDirection='column'>
    <PerfectScrollbar options={{ suppressScrollX: true }}>
      <Divider />
      <Box p={2}>
        {links.map((link, i) => (
          <List key={i}>
            {link.items.reduce(
              (acc, item) =>
                reduceChildRoutes({
                  acc,
                  pathname,
                  item,
                  depth: 0,
                  isAdmin
                }),
              []
            )}
          </List>
        ))}
      </Box>
    </PerfectScrollbar>
  </Box>
)

const filterByRole = (device: NavigationProps['device']) =>
  LINKS.filter((i) => i.href !== '#' && (!i.role || device?.user?.role === i.role))

const filterByFlag = (links: Item[], enabledFlags: string[]) =>
  enabledFlags &&
  links
    .filter((i) => (i.flag ? enabledFlags.includes(i.flag) : i))
    .map((link) =>
      link.items
        ? {
            ...link,
            items: [link.items[0], ...link.items.filter((i) => i.flag && enabledFlags.includes(i.flag))]
          }
        : { ...link }
    )

const Navigation: FC<NavigationProps> = ({ onMobileClose, openMobile, device }) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => history.listen(() => onMobileClose()), [history, onMobileClose])
  useEffect(() => history.listen(() => onMobileClose()), [history, onMobileClose])

  const flagsList = useFeatureFlag(['settings[metatags]', 'settings[authors]', 'settings[users]', 'topStories'])
  const links = [{ items: filterByFlag(filterByRole(device), flagsList || []) }]

  return (
    <>
      <Hidden lgUp>
        <Drawer anchor='left' classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant='temporary'>
          <Content links={links} pathname={location.pathname} isAdmin={device?.user?.role! === 'ADMIN'} />
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor='left' classes={{ paper: classes.desktopDrawer }} open variant='persistent'>
          <Content links={links} pathname={location.pathname} isAdmin={device?.user?.role! === 'ADMIN'} />
        </Drawer>
      </Hidden>
    </>
  )
}

export default createFragmentContainer(Navigation, {
  device: graphql`
    fragment Navigation_device on DeviceToken {
      user {
        role
      }
    }
  `
})
