/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type Roles = "ADMIN" | "CMS" | "EDITOR" | "WRITER" | "%future added value";
export type UserInput = {
    author?: AuthorInput | null;
    email?: string | null;
    first_name?: string | null;
    last_name?: string | null;
    password?: string | null;
    role?: Roles | null;
};
export type AuthorInput = {
    active?: boolean | null;
    byline?: string | null;
    slug?: string | null;
    tags?: Array<TagInput> | null;
};
export type TagInput = {
    source: unknown;
    value: Array<string>;
};
export type CreatePasswordMutationVariables = {
    id: string;
    input: UserInput;
};
export type CreatePasswordMutationResponse = {
    readonly userUpdate: {
        readonly user: {
            readonly id: string;
        } | null;
        readonly errors: ReadonlyArray<{
            readonly field: string;
            readonly messages: ReadonlyArray<string>;
        }>;
    } | null;
};
export type CreatePasswordMutation = {
    readonly response: CreatePasswordMutationResponse;
    readonly variables: CreatePasswordMutationVariables;
};



/*
mutation CreatePasswordMutation(
  $id: ID!
  $input: UserInput!
) {
  userUpdate(id: $id, input: $input) {
    user {
      id
    }
    errors {
      field
      messages
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserUpdateMutationPayload",
    "kind": "LinkedField",
    "name": "userUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "messages",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreatePasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreatePasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "be82d5314cafb9946109f87e98cde089",
    "id": null,
    "metadata": {},
    "name": "CreatePasswordMutation",
    "operationKind": "mutation",
    "text": "mutation CreatePasswordMutation(\n  $id: ID!\n  $input: UserInput!\n) {\n  userUpdate(id: $id, input: $input) {\n    user {\n      id\n    }\n    errors {\n      field\n      messages\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fce34d4bac9ef2c84d78363354b9b21d';
export default node;
