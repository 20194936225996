/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MetatagType = "AVATAR" | "KEYWORDS" | "MULTI_SELECT" | "SINGLE_SELECT" | "TEXT" | "THUMBNAIL" | "%future added value";
export type AuthorEdit_node = {
    readonly id: string;
    readonly byline: string;
    readonly slug: string;
    readonly active: boolean;
    readonly tags: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly fixedOptions: ReadonlyArray<string> | null;
        readonly value: ReadonlyArray<string> | null;
        readonly type: MetatagType;
    }>;
    readonly __typename: "Author";
    readonly " $refType": "AuthorEdit_node";
};
export type AuthorEdit_node$data = AuthorEdit_node;
export type AuthorEdit_node$key = {
    readonly " $data"?: AuthorEdit_node$data;
    readonly " $fragmentRefs": FragmentRefs<"AuthorEdit_node">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuthorEdit_node",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "byline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "active",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tag",
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fixedOptions",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Author",
  "abstractKey": null
};
})();
(node as any).hash = '9211ca7f60b8168ae956f41cfa9e9ff9';
export default node;
