import tagboardLogo from '../../../assets/png/tagboard-logo.png'
import tagboardIcon from '../../../assets/png/tagboard-icon.png'
import { API, ToolConfig } from '@editorjs/editorjs'
import { createInputNode, createErrorNode } from './EditorElements'

export type TagboardData = {
  id: string
}

interface Constructor {
  data?: TagboardData
  api: API
  config?: ToolConfig
}

export default class Tagboard {
  private tagboardData: TagboardData
  private container: HTMLElement

  constructor(params: Constructor) {
    this.tagboardData = params.data!
    this.container = document.createElement('div')
    this.previewHandler = this.previewHandler.bind(this)
  }

  static get toolbox() {
    return {
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><image width="16" height="16" href='${tagboardIcon}'/></svg>`,
      title: 'Tagboard'
    }
  }

  static get enableLineBreaks() {
    return true
  }

  render(): HTMLElement {
    if(this.tagboardData && this.tagboardData.id) {
      this.createTagboardPreview()
    }
    else {
      createInputNode(this.container, 'tagboard-code-snippet', tagboardLogo, this.previewHandler)
    }
    return this.container
  }

  private previewHandler(previewButton: any, plugin: any) {
    previewButton.addEventListener('click', (e: Event) => {
      e.preventDefault()
      const codeSnippet: any = document.getElementById('tagboard-code-snippet')
      if (codeSnippet && codeSnippet.value) {
        const dom: HTMLElement = document.createElement('div')
        dom.innerHTML = codeSnippet.value
        const embedId = dom.children[0]?.getAttribute('tgb-embed-id')
        if (embedId) {
          this.tagboardData = { id: embedId }
          this.container.innerHTML = ''
          this.createTagboardPreview()
          return
        }
      }
      createErrorNode(this.container)
    })
  }

  private createTagboardPreview() {
    const iframe = document.createElement('iframe')
    iframe.width = '100%'
    iframe.height = '560px'
    iframe.style.border = 'none'
    iframe.style.margin = '20px 0'

    const tagboardContainer = document.createElement('div')
    tagboardContainer.id = 'tagboard-container'

    const tagboardScript = document.createElement('script')
    tagboardScript.setAttribute('src', 'https://static.tagboard.com/embed/assets/js/embed.js')

    const embedBlock = document.createElement('div')
    embedBlock.setAttribute('class', 'tagboard-embed')
    embedBlock.style.height = '540px'
    embedBlock.setAttribute('tgb-embed-id', this.tagboardData.id)

    tagboardContainer.appendChild(embedBlock)
    tagboardContainer.appendChild(tagboardScript)

    iframe.srcdoc = tagboardContainer.outerHTML
    this.container.appendChild(iframe)
  }

  save(): TagboardData {
    return this.tagboardData
  }
}
