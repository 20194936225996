/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ControlsEditDraftMutationVariables = {
    id: string;
};
export type ControlsEditDraftMutationResponse = {
    readonly draftEdit: {
        readonly editableDraft: {
            readonly " $fragmentRefs": FragmentRefs<"Controls_draft">;
        } | null;
    } | null;
};
export type ControlsEditDraftMutation = {
    readonly response: ControlsEditDraftMutationResponse;
    readonly variables: ControlsEditDraftMutationVariables;
};



/*
mutation ControlsEditDraftMutation(
  $id: ID!
) {
  draftEdit(id: $id) {
    editableDraft {
      ...Controls_draft
      id
    }
  }
}

fragment Controls_draft on Draft {
  id
  canDelete
  canEdit
  canPublish
  canUpdate
  updatedAt
  administrators {
    privilege
    user {
      id
      first_name
      last_name
      avatar_url
    }
  }
  draftable {
    ... on Article {
      id
      deletedAt
    }
    lastPublishedAt
    __typename
    ... on Node {
      __isNode: __typename
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ControlsEditDraftMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DraftEditMutationPayload",
        "kind": "LinkedField",
        "name": "draftEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Draft",
            "kind": "LinkedField",
            "name": "editableDraft",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Controls_draft"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ControlsEditDraftMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DraftEditMutationPayload",
        "kind": "LinkedField",
        "name": "draftEdit",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Draft",
            "kind": "LinkedField",
            "name": "editableDraft",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canDelete",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canEdit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canPublish",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canUpdate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DraftAdministrator",
                "kind": "LinkedField",
                "name": "administrators",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "privilege",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "first_name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "last_name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "avatar_url",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "draftable",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastPublishedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deletedAt",
                        "storageKey": null
                      }
                    ],
                    "type": "Article",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2f98b05dd06d63276468d91855128488",
    "id": null,
    "metadata": {},
    "name": "ControlsEditDraftMutation",
    "operationKind": "mutation",
    "text": "mutation ControlsEditDraftMutation(\n  $id: ID!\n) {\n  draftEdit(id: $id) {\n    editableDraft {\n      ...Controls_draft\n      id\n    }\n  }\n}\n\nfragment Controls_draft on Draft {\n  id\n  canDelete\n  canEdit\n  canPublish\n  canUpdate\n  updatedAt\n  administrators {\n    privilege\n    user {\n      id\n      first_name\n      last_name\n      avatar_url\n    }\n  }\n  draftable {\n    ... on Article {\n      id\n      deletedAt\n    }\n    lastPublishedAt\n    __typename\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '7e60bffd90ba1311d3026279638c178f';
export default node;
