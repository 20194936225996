import React from 'react'
import styled from 'styled-components'

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 72px;
  left: 0;
  right: 0;
  padding: 0 5rem;
  overflow-y: scroll;
`

const FieldScroll = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  & > * {
    margin-bottom: 2rem;
    &:first-child { margin-top: 5rem; }
  }
`

const MainWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 1024px;
`

export const MainFields: React.FC = ({ children }) => (
  <FieldContainer>
    <FieldScroll>
      {children}
    </FieldScroll>
  </FieldContainer>
)

const Main: React.FC = ({ children }) => (
  <MainWrapper>
    {children}
  </MainWrapper>
)

export default Main
