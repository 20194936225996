import React from 'react'
import styled from 'styled-components'
import { Icon } from '../Alert'

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.secondary.hollywoodCerise};
  position: absolute;
  top: -56px;
  z-index: 1;
  p {
    margin-left: 5px;
  }
`

const Error: React.FC<{ error: string }> = (props) => {
  return (
    <ErrorContainer>
      <Icon className='material-icons'>error</Icon>
      <p>{props.error}</p>
    </ErrorContainer>
  )
}

export default Error
