/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MediaListRendererQueryVariables = {
    searchTerm: string;
};
export type MediaListRendererQueryResponse = {
    readonly media: {
        readonly " $fragmentRefs": FragmentRefs<"MediaListRenderer_media">;
    } | null;
};
export type MediaListRendererQuery = {
    readonly response: MediaListRendererQueryResponse;
    readonly variables: MediaListRendererQueryVariables;
};



/*
query MediaListRendererQuery(
  $searchTerm: String!
) {
  media(query: $searchTerm, sort: {attribute: "public_at", direction: DESC}, first: 8) {
    ...MediaListRenderer_media
  }
}

fragment MediaListRenderer_media on SlugableConnection {
  edges {
    node {
      id
      public_at
      title
      thumbnails {
        small
      }
      __typename
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 8
  },
  {
    "kind": "Variable",
    "name": "query",
    "variableName": "searchTerm"
  },
  {
    "kind": "Literal",
    "name": "sort",
    "value": {
      "attribute": "public_at",
      "direction": "DESC"
    }
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MediaListRendererQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SlugableConnection",
        "kind": "LinkedField",
        "name": "media",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MediaListRenderer_media"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MediaListRendererQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SlugableConnection",
        "kind": "LinkedField",
        "name": "media",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SlugableEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "public_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Thumbnail",
                    "kind": "LinkedField",
                    "name": "thumbnails",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "small",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "92b1fe014ce438792e7164a5a5cb2746",
    "id": null,
    "metadata": {},
    "name": "MediaListRendererQuery",
    "operationKind": "query",
    "text": "query MediaListRendererQuery(\n  $searchTerm: String!\n) {\n  media(query: $searchTerm, sort: {attribute: \"public_at\", direction: DESC}, first: 8) {\n    ...MediaListRenderer_media\n  }\n}\n\nfragment MediaListRenderer_media on SlugableConnection {\n  edges {\n    node {\n      id\n      public_at\n      title\n      thumbnails {\n        small\n      }\n      __typename\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '7f6d1c1b903554b2f26b62aee06142f4';
export default node;
