/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type MetatagScopeType = "ARTICLE" | "AUTHOR" | "PAGE" | "TEMPLATE" | "TOP_STORY_LIST" | "%future added value";
export type MetatagType = "AVATAR" | "KEYWORDS" | "MULTI_SELECT" | "SINGLE_SELECT" | "TEXT" | "THUMBNAIL" | "%future added value";
export type MetatagsQueryVariables = {};
export type MetatagsQueryResponse = {
    readonly organization: {
        readonly id: string;
        readonly metatags: ReadonlyArray<{
            readonly fixedOptions: ReadonlyArray<string> | null;
            readonly id: string;
            readonly maxValues: number | null;
            readonly minValues: number | null;
            readonly name: string;
            readonly scopeType: ReadonlyArray<MetatagScopeType> | null;
            readonly type: MetatagType;
            readonly validator: string | null;
        }> | null;
    };
};
export type MetatagsQuery = {
    readonly response: MetatagsQueryResponse;
    readonly variables: MetatagsQueryVariables;
};



/*
query MetatagsQuery {
  organization {
    id
    metatags {
      fixedOptions
      id
      maxValues
      minValues
      name
      scopeType
      type
      validator
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Organization",
    "kind": "LinkedField",
    "name": "organization",
    "plural": false,
    "selections": [
      (v0/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Metatag",
        "kind": "LinkedField",
        "name": "metatags",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fixedOptions",
            "storageKey": null
          },
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maxValues",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minValues",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scopeType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "validator",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MetatagsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "MetatagsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "368034312af1b2e7c28cca3a12bc7162",
    "id": null,
    "metadata": {},
    "name": "MetatagsQuery",
    "operationKind": "query",
    "text": "query MetatagsQuery {\n  organization {\n    id\n    metatags {\n      fixedOptions\n      id\n      maxValues\n      minValues\n      name\n      scopeType\n      type\n      validator\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5e4ab8f2ef18961f415de8346b7e34a6';
export default node;
