import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { createFragmentContainer } from 'react-relay'
import { TagFieldRenderer_taggable as Fragment } from './__generated__/TagFieldRenderer_taggable.graphql'
import TagField from '../TagField'


const TagFieldRenderer = createFragmentContainer(
  (props: { taggable: Fragment }) => (
    <>
      {props.taggable.tags.map((tag: Fragment['tags'][number], i) => <TagField key={`tag-${i}`} tagName={tag.name} taggable={props.taggable}/>)}
    </>
  ),
  {
    taggable: graphql`
      fragment TagFieldRenderer_taggable on Article {
        tags {
          name
        }
        ...TagField_taggable
      }
    `
  }
)

TagFieldRenderer.displayName = 'TagFieldRenderer'
export default TagFieldRenderer
