import React from 'react'
import { createFragmentContainer } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import moment from 'moment'
import placeholder from '../../assets/png/image-placeholder.png'
import {
  VideoFileCell_videoFile as Response
} from './__generated__/VideoFileCell_videoFile.graphql'

import 'moment-timezone'

const Row = styled.div`
  display: table-row;
  background-color: white;
  text-decoration: none;
  color: black;

  & > * {
    display: table-cell;
    vertical-align: middle;
    padding: 0 8px;
  }
`

const Thumbnail = styled.img`
  width: 160px;
  height: 90px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: center no-repeat content-box #c4c4c4 url(${placeholder});
  border: 0;
`

const Cell: React.FC<{ videoFile: Response }> = ({ videoFile }) => {
  const { createdAt, duration, filename, thumbnails } = videoFile

  return (
    <Row>
      <Thumbnail src={thumbnails?.small || undefined} />
      <div role='cell'>{filename}</div>
      <div role='cell'>{moment.duration(duration || 0, 'seconds').format('hh:mm:ss', { trim: false })}</div>
      <div role='cell'>{moment(createdAt).format('MMM D, YYYY')}</div>
    </Row>
  )
}

const Container = createFragmentContainer(
  Cell,
  {
    videoFile: graphql`
      fragment VideoFileCell_videoFile on VideoFile {
        createdAt
        duration
        filename
        thumbnails(aspect_ratio: HD) {
          small
        }
      }
    `
  }
)

export { Container as Cell }
