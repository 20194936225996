import React from 'react'
import { useHistory } from 'react-router-dom'
import { createPaginationContainer, RelayPaginationProp } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import { Draftable, PaginatedTable, PaginatedWrapper } from './Index'
import { CollectionIndexQuery as Query } from './__generated__/CollectionIndexQuery.graphql'
import { CollectionIndex_query as Response } from './__generated__/CollectionIndex_query.graphql'
import { CollectionIndexCreateCollectionMutation as Mutation } from './__generated__/CollectionIndexCreateCollectionMutation.graphql'

const PER_PAGE = 10

const query = graphql`
  query CollectionIndexQuery (
    $count: Int!,
    $cursor: String,
    $query: String
  ) {
    ...CollectionIndex_query @arguments(count: $count, cursor: $cursor, query: $query)
  }
`

const mutation = graphql`
  mutation CollectionIndexCreateCollectionMutation($organizationId: ID!) {
    collectionCreate(organizationId: $organizationId) {
      draft {
        draftable {
          ...on Node {
            id
          }
        }
      }
    }
  }
`

const Container = createPaginationContainer(
  (props: { query: Response, relay: RelayPaginationProp }) => {
    const history = useHistory()
    const { query, relay } = props

    return (
      <PaginatedWrapper<Mutation>
        search={true}
        rowCount={query?.organization?.collections?.edges?.length}
        relay={relay}
        variables={{ organizationId: query?.organization.id }}
        mutation={mutation}
        perPage={PER_PAGE}
        title='Collections'
        onCreate={(props) => {
          history.push(`/collections/${props?.collectionCreate?.draft?.draftable?.id}`)
        }}>

        <Draftable.Header />
        {query?.organization.collections?.edges?.map((e: any, i: number) => (
          <Draftable.Cell key={i} draftable={e.node} index={i} />
        ))}
      </PaginatedWrapper>
    )
  },
  {
    query: graphql`
      fragment CollectionIndex_query on Query
      @argumentDefinitions(
        count: { type: "Int" }
        cursor: { type: "String" }
        query: { type: "String" }
      ) {
        organization {
          id
          collections(
            first: $count,
            after: $cursor,
            query: $query
          ) @connection(key: "CollectionIndex_collections") {
            edges {
              node {
                id
                ...DraftableCell_draftable
              }
            }
          }
        }
      }
    `
  },
  {
    query,
    getVariables(props, { count, cursor }, { query }) {
      return {
        count,
        cursor,
        query,
      }
    }
  }
)

export default () => (
  <PaginatedTable<Query> Component={Container} query={query} variables={{ count: PER_PAGE }} />
)
