import React, { useContext, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { createFragmentContainer } from 'react-relay'
import { PublicationDate_draftable as Fragment } from './__generated__/PublicationDate_draftable.graphql'
import styled from 'styled-components'
import moment from 'moment-timezone'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Section, { SectionProps } from './Metadata/Section'
import { Context, ContextType } from '../Form'
import CalendarContainer from './Calendar/CalendarContainer'
import CustomInput from './Calendar/CustomInput'
import CustomHeader from './Calendar/CustomHeader'
import RadioButton from './Calendar/RadioButton'
import { ReactDatePickerProps } from 'react-datepicker'

const ContainerRow = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
`

const CalendarWrapper = styled.div<{ open: boolean }>`
  height: ${props => (props.open) ? '337' : '0'}px;
  overflow-y: hidden;
  width: 100%;
  position: relative;
  left: -83px;
  transition: height 0.2s;
`

const datePickerProps = (handleChange: (date: Date) => void, publishedAt: string | null, currentSelection: Date | null, scheduledFor: string | null, disabled?: boolean) => {
  return {
    dateFormat: 'MM/dd/yyyy h:mm aa',
    popperPlacement: 'auto',
    timeInputLabel: '',
    shouldCloseOnSelect: false,
    showPopperArrow: false,
    fixedHeight: true,
    inline: true,
    showTimeInput: true,
    onChange: (date: Date) => { if (!disabled) handleChange(date) },
    selected: new Date(format(publishedAt) || format(scheduledFor) || currentSelection || moment().tz('America/New_York').add(1, 'hour').toDate()),
    minDate: moment().tz('America/New_York').toDate(),
    renderCustomHeader: ((props) => (<CustomHeader {...props} />)) as ReactDatePickerProps['renderCustomHeader']
  }
}

type Props = SectionProps & {
  draftable: Fragment,
  name: string,
  scheduledFor: string | null,
}

const format = (date: string | null | Date, typeString = false) => {
  if (date) {
    return (typeString) ? moment(date as string, 'YYYY-MM-DD HH:mm:ss Z').tz('America/New_York').format('MM/DD/YYYY h:mm a') : moment(date as string, 'YYYY-MM-DD HH:mm:ss Z').tz('America/New_York').toDate()
  }

  return null
}

const PublicationDate = createFragmentContainer(
  (props: Props) => {
    const { onChange, errors, disabled } = useContext(Context) as ContextType
    const { draftable: { publishedAt }, name, scheduledFor } = props
    const [selection, setSelection] = useState((publishedAt || scheduledFor) ? 'Custom' : 'Immediately')
    const [placeholder, setPlaceholder] = useState(format(publishedAt, true) || format(scheduledFor, true) || 'select date')
    const [currentSelection, setCurrentSelection] = useState<Date | null>(null)

    const handleChange = (date: Date) => {
      const payloadDateString = moment(date).format('YYYY-MM-DD HH:mm:ss ZZ')
      const placeholderDateString = format(date, true)
      setPlaceholder(placeholderDateString as string)
      setCurrentSelection(date)
      onChange('publishedAt', payloadDateString)
    }

    return (
      <Section {...props} preview={format(publishedAt, true) as string || format(scheduledFor, true) as string || selection} errors={errors.for(name)}>
        <ContainerRow>
          <RadioButton
            title='Immediately'
            checked={selection === 'Immediately'}
            onChange={() => {
              setSelection('Immediately')
              onChange('publishedAt', null)
            }}
            disabled={disabled}
          />
        </ContainerRow>
        <ContainerRow>
          <RadioButton
            title='Custom'
            checked={selection === 'Custom'}
            onChange={() => {
              setSelection('Custom');
              handleChange(moment().tz('America/New_York').add(1, 'hour').toDate())
            }}
            disabled={disabled}
          />
          <CalendarContainer data-testid="calendar-container" onKeyDown={(e : React.KeyboardEvent<HTMLElement>) => { if (e.keyCode === 13){ e.preventDefault() } }}>
            <CustomInput value={placeholder as string} />
            <CalendarWrapper open={(selection === 'Custom') ? true : false}>
              <DatePicker {...datePickerProps(handleChange, publishedAt, currentSelection, scheduledFor, disabled)} />
            </CalendarWrapper>
          </CalendarContainer>
        </ContainerRow>
      </Section>
    )
  }, {
  draftable: graphql`
      fragment PublicationDate_draftable on Article {
        publishedAt
      }
    `
  }
)

export default PublicationDate
