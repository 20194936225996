import React, { FC, useState } from 'react'
import { FormikContextType, useFormikContext } from 'formik'
import { useSnackbar } from 'notistack'
import { Avatar as MuiAvatar, Box, Button, makeStyles, TextField } from '@material-ui/core'
import { UserEdit_node as UserNode } from './__generated__/UserEdit_node.graphql'
import { TopStoryListEdit_node as TopStoriesNode } from '../../TopStories/__generated__/TopStoryListEdit_node.graphql'
import { Upload } from '../../Form/Thumbnail'
import { getSignedUrl } from '../../ComponentMap/Image'
import { onErrorHandler, onTouchHandler, setId } from './TagRenderer'
import Schema from './Schema'

import { AVATAR_MIN_DIMENSIONS } from '../../../util/meta'

const useStyles = makeStyles(() => ({
  avatar: {
    height: 100,
    width: 100
  },
  avatarButton: {
    textTransform: 'none'
  }
}))

export type Props = {
  tag: NonNullable<UserNode['author']>['tags'][0] | TopStoriesNode['tags'][0]
  scopeType: string
}

const Avatar: FC<Props> = ({ tag, scopeType }) => {
  const { setFieldValue, touched, errors }: FormikContextType<Schema> = useFormikContext()
  const [chooseFile, setChooseFile] = useState(true)
  const [src, setSrc] = useState((tag?.value && tag.value[0]) || null)
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const onFinish = (e: { imageUrl: string | null }) => {
    setSrc(e?.imageUrl)
    setFieldValue(setId(tag, scopeType), e?.imageUrl)
    setChooseFile(true)
  }

  const replaceAvatarHandler = () => {
    setSrc('')
    setChooseFile(false)
  }

  const errorHandler = (err?: string) => {
    if (err) {
      enqueueSnackbar(err, { variant: 'error' })
      setChooseFile(true)
    }
  }

  if (chooseFile) {
    return (
      <TextField
        variant='outlined'
        fullWidth
        multiline
        label='Avatar'
        id={setId(tag, scopeType)}
        name={setId(tag, scopeType)}
        error={onTouchHandler(tag, scopeType, touched) && Boolean(onErrorHandler(tag, scopeType, errors))}
        helperText={onTouchHandler(tag, scopeType, touched) && onErrorHandler(tag, scopeType, errors)}
        InputProps={{
          startAdornment: (
            <Box display='flex' alignItems='flex-end' flexDirection='row' height={112}>
              <MuiAvatar className={classes.avatar} src={src!} />
              <Button size='small' color='primary' className={classes.avatarButton} onClick={replaceAvatarHandler}>
                Replace avatar
              </Button>
            </Box>
          ),
          readOnly: true
        }}
      />
    )
  }
  return (
    <Upload
      minDimensions={AVATAR_MIN_DIMENSIONS}
      setIsUploadingOnThumbnail={() => {}}
      setError={errorHandler}
      getSignedUrl={getSignedUrl}
      onFinish={onFinish}
    />
  )
}

export default Avatar
