/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TopStoryEdit_node = {
    readonly stories: ReadonlyArray<{
        readonly order: number;
        readonly story: string;
    }>;
    readonly orderedStories: ReadonlyArray<{
        readonly id: string;
        readonly public_at: unknown | null;
        readonly title: string | null;
        readonly thumbnails: {
            readonly small: string | null;
        } | null;
    }> | null;
    readonly id: string;
    readonly name: string;
    readonly max: number;
    readonly min: number;
    readonly __typename: "TopStoryList";
    readonly " $refType": "TopStoryEdit_node";
};
export type TopStoryEdit_node$data = TopStoryEdit_node;
export type TopStoryEdit_node$key = {
    readonly " $data"?: TopStoryEdit_node$data;
    readonly " $fragmentRefs": FragmentRefs<"TopStoryEdit_node">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopStoryEdit_node",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TopStoryListStory",
      "kind": "LinkedField",
      "name": "stories",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "order",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "story",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "orderedStories",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "public_at",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "aspect_ratio",
              "value": "SQUARE"
            }
          ],
          "concreteType": "Thumbnail",
          "kind": "LinkedField",
          "name": "thumbnails",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "small",
              "storageKey": null
            }
          ],
          "storageKey": "thumbnails(aspect_ratio:\"SQUARE\")"
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "max",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "min",
      "storageKey": null
    }
  ],
  "type": "TopStoryList",
  "abstractKey": null
};
})();
(node as any).hash = 'ee71442bc926ef48363f57841e6f6341';
export default node;
