/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MetatagType = "AVATAR" | "KEYWORDS" | "MULTI_SELECT" | "SINGLE_SELECT" | "TEXT" | "THUMBNAIL" | "%future added value";
export type TopStoryListEdit_node = {
    readonly stories: ReadonlyArray<{
        readonly story: string;
    }>;
    readonly id: string;
    readonly name: string;
    readonly min: number;
    readonly max: number;
    readonly tags: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly fixedOptions: ReadonlyArray<string> | null;
        readonly value: ReadonlyArray<string> | null;
        readonly type: MetatagType;
    }>;
    readonly __typename: "TopStoryList";
    readonly " $refType": "TopStoryListEdit_node";
};
export type TopStoryListEdit_node$data = TopStoryListEdit_node;
export type TopStoryListEdit_node$key = {
    readonly " $data"?: TopStoryListEdit_node$data;
    readonly " $fragmentRefs": FragmentRefs<"TopStoryListEdit_node">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopStoryListEdit_node",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TopStoryListStory",
      "kind": "LinkedField",
      "name": "stories",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "story",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "min",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "max",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tag",
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fixedOptions",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TopStoryList",
  "abstractKey": null
};
})();
(node as any).hash = '4a6f957802e194470fbf38497ead1b9f';
export default node;
