import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'babel-plugin-relay/macro'
import { MetatagsQuery as Query, MetatagsQueryResponse as Response } from './__generated__/MetatagsQuery.graphql'
import { Wrapper, Container } from './Livestream'
import { AddButton as Button } from './Metatags/Controls'
import Renderer from '../Index/PaginatedTable'
import Header from './Header'
import List from './Metatags/List'
import CreateMetatag from './Metatags/Metatag'

export const StyledContainer = styled(Container)`
  > div {
    background-color: white;
    height: 100%;
  }
`

const ContainerEmpty = styled.div`
  padding: 110px 0;
  display: flex;
  justify-content: center;

  h2 {
    margin: 0 0 16px 0;
    padding: 0;
    font-weight: 500;
    font-size: ${props => props.theme.fontSizes[7]}px;
    line-height: 38px;
  }

  p {
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
  }
`

const query = graphql`
  query MetatagsQuery {
    organization {
      id
      metatags {
        fixedOptions
        id
        maxValues
        minValues
        name
        scopeType
        type
        validator
      }
    }
  }
`

const Empty = () => (
  <ContainerEmpty>
    <div>
      <h2>No tags have been created yet</h2>
      <p>Once you add a tag, it will show up here</p>
    </div>
  </ContainerEmpty>
)

export type MetatagType = NonNullable<Response['organization']['metatags']>[0]

const Metatags: React.FC<{ query: Response }> = ({ query }) => {
  const metatagsResponse = query.organization.metatags && query.organization.metatags.length > 0 ? [...query.organization.metatags].sort((a, b) =>  a.name.localeCompare(b.name, 'en')) : []
  const [ metatags, setMetatags ] = useState(metatagsResponse)
  const [ showModal, setShowModal ] = useState(false)

  const addMetatag = (metatag: MetatagType) => {
    if (metatags?.length) {
      setMetatags([...metatags, metatag])
    } else {
      setMetatags([ metatag ])
    }
  }

  const handleModify = (metatag: MetatagType) => {
    const newMetatags = metatags?.map((m: MetatagType) => (m.id === metatag.id) ? metatag : m)
    setMetatags(newMetatags || [])
  }

  const handleDelete = (id: string) => setMetatags(metatags ? [...metatags.filter(m => m.id !== id)] : [])

  return (
    <Wrapper>
      <Header title='Tags'>
        <Button handleClick={() => setShowModal(true)} text='Create'/>
      </Header>
      <StyledContainer>
        <div>
          {
            (metatags && metatags.length > 0)
            ? metatags.map((m, i) => <List
                                      key={`metatag-${i}`}
                                      metatag={{...m, fixedOptions: m?.fixedOptions && [...m.fixedOptions].sort((a, b) => a.localeCompare(b, 'en'))}}
                                      onDelete={handleDelete}
                                      onModify={handleModify}
                                      />)
            : <Empty/>
          }
        </div>
      </StyledContainer>
      <CreateMetatag
        handleClose={() => setShowModal(false)}
        id={query.organization.id}
        open={showModal}
        updateMetatags={addMetatag}
      />
    </Wrapper>
  )
}

export default () => <Renderer<Query> query={query} Component={Metatags} variables={{}}/>
