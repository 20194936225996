import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { graphql } from 'babel-plugin-relay/macro'
import { commitMutation } from 'react-relay'
import environment from '../../relay/environment'
import { SigninFormMutationVariables, SigninFormMutationResponse } from './__generated__/SigninFormMutation.graphql'
import { CircularProgress } from '@rmwc/circular-progress'

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
  position: relative;

  input:first-of-type {
    margin-bottom: 16px;
  }

  input:last-of-type {
    margin-bottom: 30px;
  }
`

const StyledInput = styled.input`
  font-size: 14px;
  font-weight: normal;
  color: ${props => props.theme.colors.grays.stormGray};
  padding: 12px 14px 11px;
  border-radius: 2px;
  border: solid 1px rgba(231, 232, 234, 0.7);
  background-color: rgba(244, 244, 244, 0.3);

  ::placeholder {
    text-transform: capitalize;
  }
`

const StyledSubmitButton = styled.button`
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 2px;
  padding: 12px 0;
  cursor: ${props => props.disabled ? 'initial' : 'pointer'};
  border: none;
  outline: none;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
  background-color: ${props => props.disabled ? '#a390f2' : props.theme.colors.primary.mediumSlateBlue};
  transition: 0.2s;
  user-select: none;
  position: relative;

  &:hover {
    background-color: ${props => !props.disabled ? props.theme.colors.primary.purpleHeart : '#a390f2'};
  }
`

const Loading = styled.div`
  position: absolute;
  right: 170px;
  bottom: 8px;

  .rmwc-circular-progress {
    color: #ffffff;
  }
`

const StyledErrorMessage = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #ff6969;
  padding: 0px 0px 8px 0;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 60px;
`

const mutation = graphql`
  mutation SigninFormMutation($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      id
      email
    }
  }
`

const login = (props: SigninFormMutationVariables, cb: (response: SigninFormMutationResponse) => void) => {
  commitMutation(environment, {
    mutation,
    variables: props,
    onCompleted: (response, errors) => {
      if (errors && errors[0]?.message === "An instance of User failed UserType's authorization check") {
        window.localStorage.setItem('loginError', 'Authorization error. Please try again')
        window.location.reload()
      } else {
        window.localStorage.removeItem('loginError')
        cb(response as SigninFormMutationResponse)
      }
    }
  })
}

type InputProps = {
  name: string
  type: string
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Input: React.FC<InputProps> = ({ name, type, value, onChange }) => (
  <StyledInput name={name} type={type} value={value} onChange={onChange} placeholder={name}/>
)

interface CallBackHandlerProps extends SigninFormMutationResponse {
  setFormData: React.Dispatch<React.SetStateAction<{ email: string; password: string;}>>
  setError: React.Dispatch<React.SetStateAction<boolean>>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
}

const callBackHandler = ({ login, setFormData, setIsLoading, setError }: CallBackHandlerProps) => {
  if (login) {
    window.location.reload()
  } else {
    setIsLoading(false)
    setError(true)
    setFormData({email: '', password: ''})
  }
}

const SigninForm: React.FC = () => {
  const [formData, setFormData] = useState({email: '', password: ''})
  const [disabled, setDisabled] = useState(true)
  const [error, setError] = useState(Boolean(window.localStorage.getItem('loginError')))
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    (Object.values(formData).includes('')) ? setDisabled(true) : setDisabled(false)
  }, [formData])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({...formData, [e.target.name]: e.target.value})
    setError(false)
  }

  const cb = ({ login }: SigninFormMutationResponse) => callBackHandler({ login, setFormData, setError, setIsLoading })

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsLoading(true)
    login(formData, cb)
  }

  return (
    <StyledForm onSubmit={handleSubmit} method="POST">
      <Input name='email' type='email' onChange={handleChange} value={formData.email}/>
      <Input name='password' type='password' onChange={handleChange} value={formData.password}/>
      { error && <StyledErrorMessage>{window.localStorage.getItem('loginError') || 'Incorrect username or password'}</StyledErrorMessage> }
      <StyledSubmitButton type='submit' role='button' disabled={disabled}>
        { isLoading && <Loading><CircularProgress size='xsmall'/></Loading> }
        Log in
      </StyledSubmitButton>
    </StyledForm>
  )
}

export default SigninForm
