import React from 'react'
import styled from 'styled-components'
import { Alert } from '../Form'
import { SectionProps } from './Metadata/Section'

type InternalFieldProps = SectionProps & {
  errors: readonly string[]
  warnings?: readonly string[]
}

const Wrapper = styled.div`
  position: relative;
  cursor: default;
`

const AlertContainer = styled.div`
  position: absolute;
  top: 14px;
  left: -48px;
`

const Field: React.FC<InternalFieldProps> = ({ name, errors, children, warnings = [] }) => (
  <Wrapper>
    {(errors.length > 0 || warnings.length > 0) &&
      (<AlertContainer>
        <Alert errors={errors} warnings={warnings}/>
      </AlertContainer>)}
    { children }
  </Wrapper>
)

export default Field
