import React, { ReactElement } from 'react'
import { Code } from './Form'
import Media from './Form/Media/Media'
import Title from './Form/Title'
import Generic from './ComponentMap/Generic'
import { Container, Section } from './ComponentMap/Structure'
import Image from './ComponentMap/Image'

export const MapChildren = (node: Element): ReactElement => (
  <>
    {Array.from(node.children).map((c: Element, i: number) => MapIt(c, i))}
  </>
)

const Single = (props: { name: string, placeholder?: string, node: Element }): ReactElement | null => {
  const { name, placeholder, node } = props

  if (node.getAttribute('value')) {
    return null
  } else {
    return <Title name={`content[${name}][value]`} placeholder={placeholder || name}/>
  }
}

const MapIt = (node: Element | null, i?: number): ReactElement => {
  if (node === null || node.nodeName === 'parsererror') { return <div key={Date.now().toString()}>Template cannot be parsed!</div> }
  const { nodeName } = node
  const name = node.getAttribute('name') || ''
  const key = `${nodeName}-${i || 0}`

  switch (nodeName) {
    case 'article':
      return <Single name={name} key={key} node={node}/>
    case 'banner':
      return <Generic key={key} node={node} label='banner' attrs={['tagLine', 'heading', 'url', 'start', 'end']}/>
    case 'container':
      return <Container key={key} node={node}/>
    case 'eventCalendar':
      return <Generic key={key} node={node} label='calendar' attrs={['id']}/>
    case 'heading':
      return <Generic key={key} node={node} label='heading' attrs={['value', 'url']}/>
    case 'image':
      return <Image key={key} name={name} node={node}/>
    case 'link':
      return <Single name={name} key={key} node={node} placeholder={`${name} URL`}/>
    case 'markdown':
      return <Code key={key} displayName={`markdown (${name})`} name={`content[${name}][value]`} mode='markdown' fill={true}/>
    case 'media':
      return <Media key={key} name={`content[${name}][value]`}/>
    case 'playlist':
      return <Generic key={key} node={node} label='playlist' attrs={['collection', 'title', 'style']}/>
    case 'section':
      return <Section key={key} node={node}/>
    case 'video':
      return <Single name={name} key={key} placeholder='Collection ID' node={node}/>
    case 'weatherCurrentConditions':
      return <Generic key={key} node={node} label='weather[current]' attrs={['zip', 'city']}/>
    case 'weatherHourlyConditions':
      return <Generic key={key} node={node} label='weather[hourly]' attrs={['zip']}/>
    case 'weatherRadarMap':
      return <Generic key={key} node={node} label='weather[radar]' attrs={['latitude', 'longitude', 'zoom']}/>
    case 'weatherWeeklyConditions':
      return <Generic key={key} node={node} label='weather[weekly]' attrs={['zip']}/>
    default:
      return <React.Fragment key={`${key}-children`}>{MapChildren(node)}</React.Fragment>
  }
}

const ComponentMap = (props: { template: string }) => {
  const { template } = props
  const parser = new DOMParser()
  const parsed = parser.parseFromString(template, 'text/xml')

  return MapIt(parsed.firstElementChild)
}

export default ComponentMap
