import React, { useContext } from 'react'
import styled from 'styled-components'
import { SectionLabel, SectionProps } from './Form/Metadata/Section'
import { Context, ContextType } from './Form'

export const Container = styled.div`
  width: 100%;
  color: ${props => props.theme.colors.grays.midGray};

  label {
    display: block;
    font-size: ${props => props.theme.fontSizes[3]}px;
    margin-bottom: 8px;

    span {
      margin-right: 8px;
      text-transform: capitalize;
    }
  }
`
Container.displayName = 'Container'

export const StyledTextInput = styled.input`
  height: 40px;
  width: 100%;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(238, 238, 238, 0.32);
  border: solid 0.5px rgba(151, 151, 151, 0.35);
  box-sizing: border-box;
  padding: 12px 16px;
  color: ${props => props.theme.colors.grays.aluminium};
  font-size: ${props => props.theme.fontSizes[2]}px;
  margin-bottom: 8px;

  ::placeholder {
    color: ${props => props.theme.colors.grays.grayChateau};
    font-style: italic;
  }

  &:focus {
    outline: none;
  }
`
StyledTextInput.displayName = 'StyledTextInput'

const TextInput: React.FC<SectionProps & { showLabel?: boolean }> = (props) => {
  const { model, onChange, errors, disabled } = useContext(Context) as ContextType
  const { placeholder, name, displayName, required, showLabel = true } = props
  const value = model[props.name] || ''

  return (
    <Container>
      { showLabel && <SectionLabel name={displayName || name} errors={errors.for(props.name)} required={required}/>}
      <StyledTextInput
        type='text'
        name={name}
        onChange={(e) => onChange(e)}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
      />
    </Container>
  )
}

export default TextInput
