import { useCallback, useEffect } from "react";

export default (ref: React.RefObject<HTMLElement>, onClickOutside: (() => void)): void => {
  const handleClickOutside = useCallback((event) => {
    if (!ref.current?.contains(event.target)) {
      onClickOutside()
    }
  }, [ref, onClickOutside])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return (() => document.removeEventListener('mousedown', handleClickOutside))
  }, [handleClickOutside])
}