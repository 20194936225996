import React from 'react'
import styled from 'styled-components'
import MaterialModal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'

const StyledModal = styled(MaterialModal)`
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiBackdrop-root {
    background-color: rgba(78, 81, 85, 0.3);
  }
`

const Wrapper = styled.div`
  background: white;
  border: 1px solid #F1F1F1;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px #C4C4C4;
  border-radius: 2px;
  outline: none;
`

const Container = styled.div`
  padding: 9px 60px 60px 60px;

  button {
    margin-right: 0;
  }

  label {
    padding-bottom: 24px;
  }
`

const ContainerButton = styled.div`
  display: flex;
  align-iterms: center;
  justify-content: flex-end;
  padding: 37px 37px 0 37px;

  button {
    border: none;
    cursor: pointer;
    outline: none;
    color: ${props => props.theme.colors.primary.nero};
    background: white;
    padding: 0;
    margin: 0;
  }
`

const CloseButton: React.FC<{ handleClose: () => void }> = ({ handleClose }) => (
  <ContainerButton>
    <button onClick={handleClose}>&#10005;</button>
  </ContainerButton>
)

type Props = {
  handleClose: () => void
  open: boolean
}

const Modal: React.FC<Props> = ({ children, handleClose, open }) => (
  <StyledModal
    open={open}
    closeAfterTransition
    disableBackdropClick
    onClose={handleClose}
    BackdropComponent={Backdrop}
    BackdropProps={{ timeout: 200 }}
  >
    <Fade in={open}>
      <Wrapper>
        <CloseButton handleClose={handleClose}/>
        <Container>
          {children}
        </Container>
      </Wrapper>
    </Fade>
  </StyledModal>
)

export default Modal
