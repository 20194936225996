import { commitMutation } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import environment from '../relay/environment'
import { PayloadError } from 'relay-runtime'
import { GoogleAuthenticatorOauthAccessTokenMutation, GoogleAuthenticatorOauthAccessTokenMutationResponse } from './__generated__/GoogleAuthenticatorOauthAccessTokenMutation.graphql'
export const VERSION = '0.1.0'

const mutation = graphql`
  mutation GoogleAuthenticatorOauthAccessTokenMutation($accessToken: String!, $version: String!) {
    oAuthToken(accessToken: $accessToken, platform: LOOP, version: $version) {
      device {
        token
        ...Layout_device
        ...Navigation_device
      }
    }
  }
`

export default class Authenticator {
  constructor(readonly accessToken: string) {}

  variables() {
    return { accessToken: this.accessToken, version: VERSION }
  }

  onCompleted(response?: GoogleAuthenticatorOauthAccessTokenMutationResponse, errors?: readonly PayloadError[] | null | undefined) {
    if (errors) {
      return
    }

    if (response && response.oAuthToken) {
      window.localStorage.setItem('deviceToken', response.oAuthToken.device.token)
    }
  }

  authenticateWithBackend(): Promise<void> {
    return new Promise((resolve, reject) => {
      commitMutation<GoogleAuthenticatorOauthAccessTokenMutation>(
        environment,
        {
          mutation,
          variables: this.variables(),
          onCompleted: (response?: GoogleAuthenticatorOauthAccessTokenMutationResponse, errors?: readonly PayloadError[] | null | undefined) => {
            this.onCompleted(response, errors)
            resolve()
          },
          onError: reject,
        },
      )
    })
  }
}
