/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type VideoFileCell_videoFile = {
    readonly createdAt: string;
    readonly duration: number | null;
    readonly filename: string | null;
    readonly thumbnails: {
        readonly small: string | null;
    } | null;
    readonly " $refType": "VideoFileCell_videoFile";
};
export type VideoFileCell_videoFile$data = VideoFileCell_videoFile;
export type VideoFileCell_videoFile$key = {
    readonly " $data"?: VideoFileCell_videoFile$data;
    readonly " $fragmentRefs": FragmentRefs<"VideoFileCell_videoFile">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VideoFileCell_videoFile",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "duration",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "filename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "aspect_ratio",
          "value": "HD"
        }
      ],
      "concreteType": "Thumbnail",
      "kind": "LinkedField",
      "name": "thumbnails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "small",
          "storageKey": null
        }
      ],
      "storageKey": "thumbnails(aspect_ratio:\"HD\")"
    }
  ],
  "type": "VideoFile",
  "abstractKey": null
};
(node as any).hash = 'e7f7b39d5920b1f02780ee5fe53dda9d';
export default node;
