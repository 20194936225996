import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import styled from 'styled-components'
import deepEqual from 'deep-equal'
import { graphql } from 'babel-plugin-relay/macro'
import NavigationPrompt from 'react-router-navigation-prompt'
import useBeforeUnload from '../../hooks/useBeforeUnload'
import { LivestreamQuery as Query, LivestreamQueryResponse as Response } from './__generated__/LivestreamQuery.graphql'
import { LivestreamMutation, LivestreamMutationResponse } from './__generated__/LivestreamMutation.graphql'
import { MutationButton as Submit, ButtonActions} from '../Button'
import Renderer from '../Index/PaginatedTable'
import Confirm from '../Confirm'
import Input from './Input'
import Switch from './Switch'
import SaveMessage from './SaveMessage'
import Header from './Header'

export const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.grays.aliceBlue};
  flex-grow: 1;
  overflow-y: scroll;
  height: 100%;
  padding-bottom: 15px;
`

export const Container = styled.div`
  padding-left: ${props => props.theme.space[9]}px;
  padding-right: ${props => props.theme.space[9]}px;
`

const Heading = styled.h2`
  font-size: ${props => props.theme.fontSizes[5]}px;
  color: ${props => props.theme.colors.primary.nero};
  font-weight: ${props => props.theme.fontWeights[2]};
  line-height: ${props => props.theme.space[6]}px;
  margin: 0;
  padding-bottom: ${props => props.theme.space[3]}px;
`

const mutation = graphql`
  mutation LivestreamMutation($id: ID!, $input: OrganizationInput!) {
    organizationUpdate(id: $id, input: $input) {
      organization {
        settings {
          synacor {
            key
            secret
            service
            pickerKey
            pickerId
          }
          live {
            free
          }
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

const query = graphql`
  query LivestreamQuery {
    organization {
      id
      settings {
        synacor {
          key
          pickerId
          pickerKey
          secret
          service
        }
        live {
          free
        }
      }
    }
  }
`

const MESSAGE = 'Your work has not been saved. Are you sure you want to exit?'
const FIELDS = [
  { name: 'key',  label: 'API key' },
  { name: 'pickerKey',  label: 'provider picker key' },
  { name: 'pickerId',  label: 'provider picker ID' },
  { name: 'secret',  label: 'API secret' },
  { name: 'service',  label: 'service name'}
]
const instructions = (checked: boolean, saved: boolean) => {
  switch(`${checked.toString()} ${saved.toString()}`) {
    case 'true true':
      return 'Paywall is currently on, the synacor authwall is enabled for livestream viewing'
    case 'true false':
      return 'For the paywall to be enabled, you must enter and save the information below'
    case 'false true':
      return 'To enable the paywall you must complete all other fields'
    default:
      return 'To enable the paywall you must complete all other fields'
  }
}

export type UpdateFormDataProps = {
  name: string
  value: string | boolean | number
  data: Pick<Response['organization'], 'settings'>
  cb: Dispatch<SetStateAction<{ settings: Response['organization']['settings'] }>>
}

export type UpdateFormErrorsProps = {
  name: string
  empty: boolean
  errors: string []
  cb: Dispatch<SetStateAction<string []>>
}

const updateFormData = ({ name, value, data, cb }: UpdateFormDataProps) => {
  const [settingType, option]: string [] = name.split('.')
  const newSetting = { [settingType]: { ...(data.settings as any)[settingType], [option]: value }}
  const newData: { settings: Response['organization']['settings']} = {
    settings: {
      ...data.settings, ...newSetting
    }
  }
  const isMissingFields = newData.settings.synacor && Object.values(newData.settings.synacor).findIndex(s => s === '' || s === null) > -1

  if (!newData.settings.synacor || isMissingFields) {
    newData.settings = { ...newData.settings, live: { free: true }}
  }

  cb(newData)
}

const Livestream: React.FC<{ query: Response }> = ({ query }) => {
  const { organization: { settings }} = query
  const [formData, setFormData] = useState({ settings })
  const [preventNavigation, setPreventNavigation] = useState(false)
  const [displaySaveMessage, setDisplaySaveMessage] = useState(false)
  const switchProps = {
    checked: !formData?.settings?.live?.free || false,
    saved: deepEqual(settings, formData.settings)
  }
  useBeforeUnload(preventNavigation)

  const onChange = ({ name, value }: Pick<UpdateFormDataProps, 'name' | 'value'>) => updateFormData({name, value, data: formData, cb: setFormData})
  const handleCompletedMutation = (response: LivestreamMutationResponse) => {
    setFormData({ settings: response.organizationUpdate?.organization?.settings! })
    setDisplaySaveMessage(true)
  }

  useEffect(() => {
    if (!deepEqual(settings, formData.settings, { strict: true })) {
      setPreventNavigation(true)
      setDisplaySaveMessage(false)
      return
    }
    setPreventNavigation(false)
  }, [settings, formData])

  return (
    <Wrapper>
      <Header title='Livestreaming'/>
      <Container>
        <form>
          <NavigationPrompt when={preventNavigation}>
            {({ onConfirm, onCancel }) =>
              <Confirm
                open={preventNavigation}
                close={onCancel}
                onClick={onConfirm}
                message={MESSAGE}/>}
          </NavigationPrompt>
          <Heading>Synacor</Heading>
          <Switch
            checked={switchProps.checked}
            saved={switchProps.saved}
            instructions={instructions(switchProps.checked, switchProps.saved)}
            onChange={onChange}
          />
          {FIELDS.map((e, index) =>
            <Input
              key={index}
              name={`synacor.${e.name}`}
              onChange={onChange}
              label={e.label}
              val={(settings.synacor && (settings.synacor as { [key: string]: string | null })[e.name]) || ''}/>
          )}
          <Submit<LivestreamMutation>
            mutation={mutation}
            variables={{ id: query.organization.id, input: formData }}
            buttonType={ButtonActions.Save}
            onCompleted={handleCompletedMutation}
          />
          { displaySaveMessage && <SaveMessage/>}
        </form>
      </Container>
    </Wrapper>
  )
}

export default () => <Renderer<Query> query={query} Component={Livestream} variables={{}}/>
