/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type GoogleAuthenticatorOauthAccessTokenMutationVariables = {
    accessToken: string;
    version: string;
};
export type GoogleAuthenticatorOauthAccessTokenMutationResponse = {
    readonly oAuthToken: {
        readonly device: {
            readonly token: string;
            readonly " $fragmentRefs": FragmentRefs<"Layout_device" | "Navigation_device">;
        };
    } | null;
};
export type GoogleAuthenticatorOauthAccessTokenMutation = {
    readonly response: GoogleAuthenticatorOauthAccessTokenMutationResponse;
    readonly variables: GoogleAuthenticatorOauthAccessTokenMutationVariables;
};



/*
mutation GoogleAuthenticatorOauthAccessTokenMutation(
  $accessToken: String!
  $version: String!
) {
  oAuthToken(accessToken: $accessToken, platform: LOOP, version: $version) {
    device {
      token
      ...Layout_device
      ...Navigation_device
      id
    }
  }
}

fragment Account_user on User {
  id
  avatar_url
  first_name
  last_name
}

fragment Layout_device on DeviceToken {
  ...Navigation_device
  user {
    role
    id
    ...Account_user
  }
}

fragment Navigation_device on DeviceToken {
  user {
    role
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accessToken"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "version"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accessToken",
    "variableName": "accessToken"
  },
  {
    "kind": "Literal",
    "name": "platform",
    "value": "LOOP"
  },
  {
    "kind": "Variable",
    "name": "version",
    "variableName": "version"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GoogleAuthenticatorOauthAccessTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AuthOauthAccessTokenMutationPayload",
        "kind": "LinkedField",
        "name": "oAuthToken",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceToken",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Layout_device"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Navigation_device"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GoogleAuthenticatorOauthAccessTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AuthOauthAccessTokenMutationPayload",
        "kind": "LinkedField",
        "name": "oAuthToken",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceToken",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "role",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "avatar_url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "first_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "last_name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cba0b983a741de556a3a2436f94cdf78",
    "id": null,
    "metadata": {},
    "name": "GoogleAuthenticatorOauthAccessTokenMutation",
    "operationKind": "mutation",
    "text": "mutation GoogleAuthenticatorOauthAccessTokenMutation(\n  $accessToken: String!\n  $version: String!\n) {\n  oAuthToken(accessToken: $accessToken, platform: LOOP, version: $version) {\n    device {\n      token\n      ...Layout_device\n      ...Navigation_device\n      id\n    }\n  }\n}\n\nfragment Account_user on User {\n  id\n  avatar_url\n  first_name\n  last_name\n}\n\nfragment Layout_device on DeviceToken {\n  ...Navigation_device\n  user {\n    role\n    id\n    ...Account_user\n  }\n}\n\nfragment Navigation_device on DeviceToken {\n  user {\n    role\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '9c05ab258d8856503a8a9b4211892e7a';
export default node;
