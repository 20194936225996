/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SlugableCell_slugable = {
    readonly updated_at: unknown;
    readonly title: string | null;
    readonly thumbnails: {
        readonly small: string | null;
    } | null;
    readonly " $refType": "SlugableCell_slugable";
};
export type SlugableCell_slugable$data = SlugableCell_slugable;
export type SlugableCell_slugable$key = {
    readonly " $data"?: SlugableCell_slugable$data;
    readonly " $fragmentRefs": FragmentRefs<"SlugableCell_slugable">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SlugableCell_slugable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updated_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "aspect_ratio",
          "value": "SQUARE"
        }
      ],
      "concreteType": "Thumbnail",
      "kind": "LinkedField",
      "name": "thumbnails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "small",
          "storageKey": null
        }
      ],
      "storageKey": "thumbnails(aspect_ratio:\"SQUARE\")"
    }
  ],
  "type": "Slugable",
  "abstractKey": "__isSlugable"
};
(node as any).hash = 'a41229d64490b2cfa614acf742692d03';
export default node;
