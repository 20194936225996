import React from 'react'
import styled from 'styled-components'
import { components, OptionProps, OptionTypeBase } from 'react-select'

const LetterIconContainer = styled.div`
  background: #c7c7c7;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LetterIcon = styled.span`
  font-size: ${props => props.theme.fontSizes[1]}px;
  color: #ffffff;
  text-transform: capitalize;
`

const StyledOptionContainer = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  padding-left: 16px;
  align-items: center;
`

const StyledOptionText = styled.div`
  padding-left: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 198px;
  white-space: nowrap;
`
StyledOptionText.displayName = "OptionText"

const Option = (props: OptionProps<OptionTypeBase, true>) => (
  <components.Option {...props}>
    <StyledOptionContainer>
      <LetterIconContainer><LetterIcon>{props.data.label[0]}</LetterIcon></LetterIconContainer>
      <StyledOptionText>
        {props.children}
      </StyledOptionText>
    </StyledOptionContainer>
  </components.Option>
)

export default Option
