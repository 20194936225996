/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type ThumbnailQueryVariables = {
    extension: string;
    mimeType: string;
};
export type ThumbnailQueryResponse = {
    readonly organization: {
        readonly mediaUploadUrl: string | null;
    };
};
export type ThumbnailQuery = {
    readonly response: ThumbnailQueryResponse;
    readonly variables: ThumbnailQueryVariables;
};



/*
query ThumbnailQuery(
  $extension: String!
  $mimeType: String!
) {
  organization {
    mediaUploadUrl(extension: $extension, mimeType: $mimeType, final: true)
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "extension"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mimeType"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "extension",
      "variableName": "extension"
    },
    {
      "kind": "Literal",
      "name": "final",
      "value": true
    },
    {
      "kind": "Variable",
      "name": "mimeType",
      "variableName": "mimeType"
    }
  ],
  "kind": "ScalarField",
  "name": "mediaUploadUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ThumbnailQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ThumbnailQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aefbd5f268cebb37932a616744ed343d",
    "id": null,
    "metadata": {},
    "name": "ThumbnailQuery",
    "operationKind": "query",
    "text": "query ThumbnailQuery(\n  $extension: String!\n  $mimeType: String!\n) {\n  organization {\n    mediaUploadUrl(extension: $extension, mimeType: $mimeType, final: true)\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e1cc75af05a44b46518b3d98606e478c';
export default node;
