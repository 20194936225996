import React, { useState } from 'react'
import styled from 'styled-components'
import Button from './Button'
import Input from '../Input'
import Row from './Row'

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`

type InputGroupProps = {
  handleChange: ( value: string | number ) => void
  handleCancel: () => void
  handleSave: () => void
  value: string
}

export const AddButton: React.FC<{ handleClick: () => void, text: string }> = ({ handleClick, text }) => (
  <Button onClick={handleClick}>
    <ButtonContent><i className="material-icons">add</i><span>{text}</span></ButtonContent>
  </Button>
)

const InputGroup: React.FC<InputGroupProps> = ({ handleChange, handleCancel, handleSave, value }) => (
  <>
    <Input onChange={(e) => handleChange(e.value)} name='option' val={value}/>
    <Button onClick={handleSave}>Save</Button>
    <Button onClick={handleCancel}>Cancel</Button>
  </>
)

const AddOption: React.FC<{ onAdd: (option: string) => void }> = ({ onAdd }) => {
  const [value, setValue] = useState('')
  const [showControls, setShowControls] = useState(false)

  const onCancel = () => {
    setValue('')
    setShowControls(false)
  }

  const onSave = () => {
    onAdd(value)
    setValue('')
    setShowControls(false)
  }

  return (
    <Row visibleButtons={true}>
      {!showControls
        ? <AddButton handleClick={() => setShowControls(true)} text='Add Value'/>
        : <InputGroup
            handleCancel={onCancel}
            handleChange={(e) => setValue(e as string)}
            handleSave={onSave}
            value={value}
          />
       }
    </Row>
  )
}

export default AddOption
