/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type BylineSelect_draftable = {
    readonly authors: ReadonlyArray<{
        readonly id: string;
        readonly byline: string;
    }>;
    readonly " $refType": "BylineSelect_draftable";
};
export type BylineSelect_draftable$data = BylineSelect_draftable;
export type BylineSelect_draftable$key = {
    readonly " $data"?: BylineSelect_draftable$data;
    readonly " $fragmentRefs": FragmentRefs<"BylineSelect_draftable">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BylineSelect_draftable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Author",
      "kind": "LinkedField",
      "name": "authors",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "byline",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
(node as any).hash = 'b633c59bbcbd95ecc10afb39be62d402';
export default node;
