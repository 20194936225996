import React from 'react'
import styled from 'styled-components'
import { MapChildren } from '../ComponentMap'

const StyledContainer = styled.div<{ direction: string | null }>`
  display: flex;
  flex-direction: ${props => props.direction === 'vertical' ? 'column' : 'row'};
  flex-wrap: wrap;
  flex-grow: 1;
`

export const StyledSection = styled.div<{ sizes: string | null, sizing: string | null }>`
  width: ${props => props.sizes?.split(',')?.shift()?.split(':')?.shift() || '100%'};
  flex-grow: ${props => props?.sizing === 'static' ? 0 : 1};
  flex-wrap: wrap;
  display: ${props => props?.sizes?.startsWith('0') ? 'none' : 'flex'};
  padding: 10px;
  box-sizing: border-box;
`

export const Container = (props: { node: Element }) => {
  const { node } = props

  return (
    <StyledContainer direction={node.getAttribute('direction')} data-testid='container'>
      {MapChildren(node)}
    </StyledContainer>
   )
}

export const Section = (props: { node: Element }) => {
  const { node } = props

  if (node.getAttribute('editable') === 'false') {
    return null
  } else {
    return (
      <StyledSection sizes={node.getAttribute('sizes')} sizing={node.getAttribute('sizing')} data-testid='section'>
        {MapChildren(node)}
      </StyledSection>
    )
  }
}
