import React from 'react'
import { createFragmentContainer } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import styled from 'styled-components'
import { formatDate, secondsToMinutes } from '../HeroPicker/ListRenderer'
import { ContextType } from '../Form'
import placeholder from '../../../images/imagePlaceholder.svg'

const StyledMediaItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`

const StyledImage = styled.div`
  svg, img {
    width: 100%;
    height: auto;
    vertical-align: top;
  }
`

const StyledControl = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  background-color: #f1f1f1;
  box-shadow: 0px 4px 4px rgba(155, 155, 155, 0.25);
  cursor: pointer;
  margin: 5px;
  > span {
    display: none;
  }
`

const ControlsContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;

  ${StyledMediaItem}:hover & {
    display: flex;
  }

  @media (min-width: 1550px) {
    ${StyledControl} {
      width: 70px;
      height: 70px;
      margin: 8px;
      > span {
        display: inline;
      }
    }
  }
`

const StyledDescription = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
  font-size: 16px;
  line-height: 23px;
  color: #2c2c2c;
`

const StyledDate = styled.div`
  font-style: italic;
  font-weight: normal;
  font-size: 15px;
  line-height: 14px;
  color: #505050;
  margin-top: 10px;
`

const StyledMetaContainer = styled.div`
  display: flex;
`

const ImageControlsWrapper = styled.div`
  position: relative;
`

const PlayButtonContainer = styled.a`
  background-color: #F0F200;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  padding: 4px 5px 4px 6px;
  vertical-align: text-bottom;
  margin-left: 10px;
`

const PlayButton = styled.div`
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 4px;
  border-color: transparent transparent transparent #000000;
  cursor: pointer;
  border-style: solid;
  border-width: 4px 0 4px 6px;
`

const Image: React.FC<{ thumbnailUrl?: string | null }> = ({ thumbnailUrl }) => (
  <StyledImage>
    <img src={(thumbnailUrl) ? thumbnailUrl : placeholder} alt=''/>
  </StyledImage>
)

type ButtonProps = {
  onClick?: () => void
  icon: string
  text: string
}

const Button: React.FC<ButtonProps> = ({ onClick, icon, text }) => (
  <StyledControl onClick={onClick}>
    <i className="material-icons">{icon}</i><span>{text}</span>
  </StyledControl>
)

const Controls: React.FC<Partial<MediaItemProps>> = ({ onSearch, onDelete }) => {
  return (
    <ControlsContainer>
      {(onDelete)
      ? (<div>
          <Button onClick={onSearch} icon="refresh" text="Replace"/>
          <Button onClick={onDelete} icon="delete" text="Delete"/>
        </div>)
      : <Button onClick={onSearch} icon="add_box" text="Add"/>}
    </ControlsContainer>
  )
}

export const MediaItemPlaceholder: React.FC<{ onSearch: MediaItemProps['onSearch'] }> = ({ onSearch }) => (
  <StyledMediaItem>
    <ImageControlsWrapper>
      <Image/>
      <Controls onSearch={onSearch}/>
    </ImageControlsWrapper>
  </StyledMediaItem>
)

type MediaItemProps = {
  slugable: any
  onSearch: () => void
  onDelete: () => void
  disabled: ContextType['disabled']
}

const MediaItem = createFragmentContainer(
  (props: MediaItemProps) => {
    const { slugable, onSearch, onDelete, disabled } = props

    return (
      <StyledMediaItem>
        <ImageControlsWrapper>
          <Image thumbnailUrl={slugable.thumbnails?.medium}/>
          {!disabled && <Controls onSearch={onSearch} onDelete={onDelete}/>}
        </ImageControlsWrapper>
        <h2 title={slugable.title}>{slugable.title}</h2>
        <StyledDescription title={slugable.description}>{slugable.description}</StyledDescription>
        <StyledMetaContainer>
          <StyledDate>
            {formatDate(slugable.public_at)}
            {(slugable.hero_video && slugable.hero_video.duration) && `, ${secondsToMinutes(slugable.hero_video.duration)}`}
            {(slugable.hero_video && slugable.hero_video.duration && slugable.public_url)
              && <PlayButtonContainer target='_blank' href={slugable.public_url}><PlayButton/></PlayButtonContainer>}
          </StyledDate>
        </StyledMetaContainer>
      </StyledMediaItem>
    )
  },
  {
    slugable: graphql`
      fragment MediaItem_slugable on Slugable {
        id
        description
        hero_video {
          duration
        }
        public_at
        public_url
        thumbnails(aspect_ratio: HD) {
          medium
        }
        title
        __typename
      }
    `
  }
)

export default MediaItem
