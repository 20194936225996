/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TopStoryListEditQueryVariables = {
    id: string;
};
export type TopStoryListEditQueryResponse = {
    readonly node: {
        readonly " $fragmentRefs": FragmentRefs<"TopStoryListEdit_node">;
    } | null;
};
export type TopStoryListEditQuery = {
    readonly response: TopStoryListEditQueryResponse;
    readonly variables: TopStoryListEditQueryVariables;
};



/*
query TopStoryListEditQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ...TopStoryListEdit_node
    id
  }
}

fragment TopStoryListEdit_node on TopStoryList {
  __typename
  stories {
    story
  }
  id
  name
  min
  max
  tags {
    id
    name
    fixedOptions
    value
    type
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TopStoryListEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TopStoryListEdit_node"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TopStoryListEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TopStoryListStory",
                "kind": "LinkedField",
                "name": "stories",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "story",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "min",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "max",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Tag",
                "kind": "LinkedField",
                "name": "tags",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fixedOptions",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "TopStoryList",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "04450b3b74a9a49d39439069639631ed",
    "id": null,
    "metadata": {},
    "name": "TopStoryListEditQuery",
    "operationKind": "query",
    "text": "query TopStoryListEditQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...TopStoryListEdit_node\n    id\n  }\n}\n\nfragment TopStoryListEdit_node on TopStoryList {\n  __typename\n  stories {\n    story\n  }\n  id\n  name\n  min\n  max\n  tags {\n    id\n    name\n    fixedOptions\n    value\n    type\n  }\n}\n"
  }
};
})();
(node as any).hash = '30e72bcfc3481b99ca5eaf6372d4599f';
export default node;
