import { commitMutation } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import environment from '../relay/environment'
import { 
  CheddarAuthenticatorCreateDeviceTokenMutation,  
  CheddarAuthenticatorCreateDeviceTokenMutationResponse, 
} from './__generated__/CheddarAuthenticatorCreateDeviceTokenMutation.graphql'
import { PayloadError } from 'relay-runtime'

export const VERSION = '0.1.0'

const mutation = graphql`
  mutation CheddarAuthenticatorCreateDeviceTokenMutation($platform: Platform!, $version: String!, $token: String) {
    associate(platform: $platform, version: $version, token: $token) {
      id
      token
      ...Layout_device
    }
  }
`

export default class CheddarAuthenticator {
  onCompleted(response:CheddarAuthenticatorCreateDeviceTokenMutationResponse, errors?: any) {
    if (!errors && response && response.associate) {
      window.localStorage.setItem('deviceToken', response.associate.token)
    }
  }

  createToken(): Promise<void> {
    return new Promise((resolve, reject) => {
      commitMutation<CheddarAuthenticatorCreateDeviceTokenMutation>(
        environment,
        {
          mutation: mutation,
          variables: { platform: 'LOOP', version: '0.1.0' },
          onCompleted: (response:CheddarAuthenticatorCreateDeviceTokenMutationResponse, errors?: readonly PayloadError[] | null | undefined) => {
            this.onCompleted(response, errors)
            resolve()
          },
          onError: reject, 
        }
      )
    })
  }
}
