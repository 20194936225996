import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import ToggleButton from './ToggleButton'
import ErrorIcon from '../Alert'

const Content = styled.div`
  transition: opacity .10s ease-out;
`
Content.displayName = 'MetadataSectionContent'

const Preview = styled.span`
  position: absolute;
  font-style: italic;
  font-size: ${props => props.theme.fontSizes[2]}px;
  color: ${props => props.theme.colors.grays.midGray};
  bottom: ${props => props.theme.space[3]}px;
  transition: opacity .1s ease-in-out;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 220px;
  opacity: 0;
`
Preview.displayName = 'MetadataSectionPreview'

const Container = styled.div<{ open: boolean }>`
  background-color: white;
  color: ${props => props.theme.colors.primary.blackRussian};
  width: 304px;
  min-width: 304px;
  padding: 20px 24px;
  box-sizing: border-box;
  position: relative;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(206, 207, 209, 0.1);
  margin-bottom: 8px;
  overflow: hidden;
  max-height: 80px;
  transition: max-height .15s ease-out;
  ${props => props.open && css`
    max-height: 600px;
    transition: max-height .25s ease-in;
  `}

  ${Content} {
    opacity: ${props => props.open ? 1 : 0};
    ${props => !props.open && css`
      display: none;
    `}
  }

  ${Preview} {
    ${props => !props.open && css`
      opacity: 1;
      transition: opacity .1s ease-in-out .1s;
    `}
  }

  label {
    display: flex;
    flex-wrap: wrap;
    font-size: 15px;
    font-weight: thin;
    margin: 0 0 20px 0;
    cursor: pointer;

    span {
      margin-right: 8px;
      text-transform: capitalize;
    }
  }
`
Container.displayName = 'MetadataSectionContainer'

const Completed = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  color: white;
  display: inline-block;
  background-color: ${props => props.theme.colors.grays.zircon};
  text-align: center;
  vertical-align: bottom;

  i {
    font-size: 13px;
    line-height: 16px;
    font-weight: bold;
  }
`
Completed.displayName = 'Completed'

export type SectionProps = {
  name: string,
  label?: string,
  required?: boolean,
  placeholder?: string,
  displayName?: string
}

type InternalSectionProps = SectionProps & {
  preview?: string,
  errors: readonly string[]
}

type SectionLabelProps = {
  onClick?: () => void,
  name: string,
  label?: string,
  errors: readonly string[],
  required?: boolean
}

export const SectionLabel: React.FC<SectionLabelProps> = (props) => {
  const { onClick, name, errors, required, label } = props

  return (
    <label onClick={onClick}>
      <span>{label || name}</span>
      {errors.length === 0 && required && <Completed><i className='material-icons'>done</i></Completed>}
      {errors.length > 0 && <ErrorIcon errors={errors}/>}
    </label>
  )
}

const MetadataSection: React.FC<InternalSectionProps> = ({ name, label, children, preview, required, errors }) => {
  const [open, setOpen] = useState(false)
  const clickHandler = () => setOpen(!open)

  return (
    <Container open={open}>
      <SectionLabel onClick={clickHandler} name={name} label={label} errors={errors} required={required}/>
      <ToggleButton onClick={clickHandler} open={open} />
      { (preview && preview.length > 0) && <Preview>{preview}</Preview> }
      <Content>
        {children}
      </Content>
    </Container>
  )
}

export default MetadataSection
export { Preview }
