import React from 'react'
import { Theme } from '../theme'
import { Link as RouterLink } from 'react-router-dom'
import { Box, Button, Container, Typography, useTheme, useMediaQuery, makeStyles } from '@material-ui/core'
import pageNotFound from '../images/pageNotFound.svg'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.primary,
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(3),
    paddingTop: 80,
    paddingBottom: 80
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto'
  }
}))

const NotFound = () => {
  const classes = useStyles()
  const theme = useTheme()
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <div className={classes.root} title='404: Not found'>
      <Container maxWidth='lg'>
        <Typography align='center' variant={mobileDevice ? 'h4' : 'h1'} color='textPrimary'>404: The page you are looking for isn’t here</Typography>
        <Box mt={6} display='flex' justifyContent='center'>
          <img alt='Under development' className={classes.image} src={pageNotFound} />
        </Box>
        <Box mt={6} display='flex' justifyContent='center'>
          <Button color='secondary' component={RouterLink} to='/' variant='outlined'>
            Back to articles
          </Button>
        </Box>
      </Container>
    </div>
  )
}

export default NotFound
