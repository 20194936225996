/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AccountMutationVariables = {};
export type AccountMutationResponse = {
    readonly deviceLogout: {
        readonly device: {
            readonly " $fragmentRefs": FragmentRefs<"Layout_device">;
        };
    } | null;
};
export type AccountMutation = {
    readonly response: AccountMutationResponse;
    readonly variables: AccountMutationVariables;
};



/*
mutation AccountMutation {
  deviceLogout {
    device {
      ...Layout_device
      id
    }
  }
}

fragment Account_user on User {
  id
  avatar_url
  first_name
  last_name
}

fragment Layout_device on DeviceToken {
  ...Navigation_device
  user {
    role
    id
    ...Account_user
  }
}

fragment Navigation_device on DeviceToken {
  user {
    role
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeviceLogoutMutationPayload",
        "kind": "LinkedField",
        "name": "deviceLogout",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceToken",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Layout_device"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeviceLogoutMutationPayload",
        "kind": "LinkedField",
        "name": "deviceLogout",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "DeviceToken",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "role",
                    "storageKey": null
                  },
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "avatar_url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "first_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "last_name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f490bfcceaa5f9633a6c0e85aaaa60fd",
    "id": null,
    "metadata": {},
    "name": "AccountMutation",
    "operationKind": "mutation",
    "text": "mutation AccountMutation {\n  deviceLogout {\n    device {\n      ...Layout_device\n      id\n    }\n  }\n}\n\nfragment Account_user on User {\n  id\n  avatar_url\n  first_name\n  last_name\n}\n\nfragment Layout_device on DeviceToken {\n  ...Navigation_device\n  user {\n    role\n    id\n    ...Account_user\n  }\n}\n\nfragment Navigation_device on DeviceToken {\n  user {\n    role\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '7880a555c24c264fd3506e0edfcb664a';
export default node;
