/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type UserDeleteMutationVariables = {
    id: string;
};
export type UserDeleteMutationResponse = {
    readonly userDelete: {
        readonly user: {
            readonly deletedAt: string | null;
            readonly first_name: string | null;
            readonly last_name: string | null;
        };
        readonly errors: ReadonlyArray<{
            readonly field: string;
            readonly messages: ReadonlyArray<string>;
        }>;
    } | null;
};
export type UserDeleteMutation = {
    readonly response: UserDeleteMutationResponse;
    readonly variables: UserDeleteMutationVariables;
};



/*
mutation UserDeleteMutation(
  $id: ID!
) {
  userDelete(id: $id) {
    user {
      deletedAt
      first_name
      last_name
      id
    }
    errors {
      field
      messages
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "first_name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last_name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Error",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "messages",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserDeleteMutationPayload",
        "kind": "LinkedField",
        "name": "userDelete",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserDeleteMutationPayload",
        "kind": "LinkedField",
        "name": "userDelete",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "29bbaac8e1cf4964d21378eb8d95856e",
    "id": null,
    "metadata": {},
    "name": "UserDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation UserDeleteMutation(\n  $id: ID!\n) {\n  userDelete(id: $id) {\n    user {\n      deletedAt\n      first_name\n      last_name\n      id\n    }\n    errors {\n      field\n      messages\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '17c0541cb08836e0ee459a5354f5cc5c';
export default node;
