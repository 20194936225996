import React, { useContext, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import styled, { css } from 'styled-components/macro'
import { graphql } from 'babel-plugin-relay/macro'
import { createFragmentContainer } from 'react-relay'
import { Subject } from 'rxjs'
import 'moment-timezone'
import { capitalize } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import moment from 'moment'
import Avatar from '../Avatar'
import Dropdown from './Dropdown'
import { MutationButton, ButtonActions } from '../Button'
import { Context, ContextType, FormEvent } from './Form'
import { ControlsEditDraftMutation } from './__generated__/ControlsEditDraftMutation.graphql'
import { ControlsDeleteArticleMutation } from './__generated__/ControlsDeleteArticleMutation.graphql'
import { ControlsPublishDraftMutation } from './__generated__/ControlsPublishDraftMutation.graphql'
import { Controls_draft as Fragment } from './__generated__/Controls_draft.graphql'
import { useNavigatorOnline } from '../../hooks/useNavigatorOnline'

const LastUpdateContainer = styled.div`
  font-style: italic;
  color: ${(props) => props.theme.colors.grays.stormGray};
  margin-right: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const LastUpdateTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => props.theme.space[2]}px;
`

const LastUpdateUser = styled.div`
  font-size: ${(props) => props.theme.fontSizes[1]}px;
  font-weight: ${(props) => props.theme.fontWeights[1]};
  line-height: ${(props) => props.theme.fontSizes[2]}px;
`

const LastUpdateDate = styled.div`
  font-size: ${(props) => props.theme.fontSizes[1]}px;
  font-weight: ${(props) => props.theme.fontWeights[3]};
`

const LastUpdate: React.FC<{ draft: Fragment }> = ({ draft }) => {
  const writer = draft.administrators.find((a) => a.privilege === 'writer')

  if (writer?.user) {
    return (
      <LastUpdateContainer>
        <Avatar user={writer.user} size={0} />
        <LastUpdateTextWrapper>
          <LastUpdateUser>{writer.user.first_name} last edited at</LastUpdateUser>
          <LastUpdateDate>
            {moment(draft.updatedAt as string)
              .tz('America/New_York')
              .format('MMM D, Y [at] h:mma z')}
          </LastUpdateDate>
        </LastUpdateTextWrapper>
      </LastUpdateContainer>
    )
  }

  return null
}

const StatusIcon = css`
  i {
    font-size: 19px;
  }

  .rmwc-circular-progress {
    color: black;
  }
`

const StyledStatus = styled.div`
  display: flex;
  margin-right: 40px;
  ${StatusIcon}
`
StyledStatus.displayName = 'StyledStatus'

const StatusText = styled.div<{ visible: boolean }>`
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  color: ${(props) => props.theme.colors.grays.aluminium};
  margin-left: 4px;
  transition: opacity 0.1s ease-in-out;
  opacity: ${(props) => (props.visible ? 1 : 0)};
`
StatusText.displayName = 'StatusText'

const Status: React.FC<{ events: Subject<FormEvent> }> = ({ events }) => {
  const [changesSaved, setChangesSaved] = useState(false)

  events.subscribe({
    next(e) {
      switch (e) {
        case FormEvent.saveStart:
          setChangesSaved(false)
          break
        case FormEvent.saveSuccess:
          setChangesSaved(true)
          break
      }
    }
  })

  return (
    <StyledStatus>
      <StatusText visible={changesSaved}>All changes saved</StatusText>
    </StyledStatus>
  )
}

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 72px;
  padding-right: 40px;
  justify-content: flex-end;
  align-items: center;
  margin-top: auto;
  background-color: ${(props) => props.theme.colors.primary.whiteSmoke};
  z-index: 2;
`
Container.displayName = 'Container'

export const ButtonContainer = styled.div`
  display: flex;

  & > button:not(:last-child) {
    margin-right: 16px;
  }
`
ButtonContainer.displayName = 'ButtonContainer'

const editMutation = graphql`
  mutation ControlsEditDraftMutation($id: ID!) {
    draftEdit(id: $id) {
      editableDraft {
        ...Controls_draft
      }
    }
  }
`

const deleteMutation = graphql`
  mutation ControlsDeleteArticleMutation($id: ID!) {
    articleDelete(id: $id) {
      article {
        latestDraft {
          ...Controls_draft
        }
        deletedAt
      }
      errors {
        field
        messages
      }
    }
  }
`

export const publishMutation = graphql`
  mutation ControlsPublishDraftMutation($id: ID!, $minorEdit: Boolean = false) {
    draftPublish(id: $id, minorEdit: $minorEdit) {
      draft {
        ...Controls_draft
      }
    }
  }
`

type Props = RouteComponentProps<{ id: string }> & {
  draft: Fragment
}

const inTheFuture = (dateTime: string | undefined | null) => {
  return dateTime !== undefined && dateTime !== null && moment(dateTime as string, 'YYYY-MM-DD HH:mm:ss Z').isAfter(moment())
}

export default createFragmentContainer(
  withRouter((props: Props) => {
    const {
      match,
      history,
      draft,
      draft: {
        id,
        canEdit,
        canUpdate,
        canPublish,
        canDelete,
        draftable: { __typename, lastPublishedAt, id: articleId }
      }
    } = props
    const { autoSave, events, publishable, saveMutation, updates } = useContext(Context) as ContextType
    const { isOnline } = useNavigatorOnline()
    const { enqueueSnackbar } = useSnackbar()

    return (
      <Container>
        <LastUpdate draft={draft} />
        <Status events={events} />
        <ButtonContainer>
          {!autoSave && canUpdate && canEdit && (
            <MutationButton
              buttonType={ButtonActions.Save}
              disabled={Object.values(updates).length === 0}
              mutation={saveMutation}
              onCompleted={() => {}}
              variables={{ id, input: updates }}
            />
          )}
          {lastPublishedAt && canEdit && canUpdate && <Dropdown draftId={id} />}
          {canEdit && !canUpdate && (
            <MutationButton<ControlsEditDraftMutation>
              buttonType={ButtonActions.Edit}
              mutation={editMutation}
              onCompleted={() => history.go(0)}
              variables={{ id }}
            />
          )}
          <MutationButton<ControlsDeleteArticleMutation>
            buttonType={ButtonActions.Delete}
            disabled={!canDelete || !isOnline}
            draftType={__typename}
            mutation={deleteMutation}
            onCompleted={({ articleDelete }) => {
              if (articleDelete?.article.deletedAt && !articleDelete?.errors.length) {
                history.replace({ pathname: match.path.replace(/\/:id$/, '') })
              } else {
                enqueueSnackbar(`Field '${capitalize(articleDelete?.errors[0].field || '')}' ${articleDelete?.errors[0].messages[0]}.`, {
                  variant: 'error'
                })
              }
            }}
            variables={{ id: articleId! }}
          />
          {!lastPublishedAt && (
            <MutationButton<ControlsPublishDraftMutation>
              buttonType={inTheFuture(updates?.publishedAt) ? ButtonActions.Schedule : ButtonActions.Publish}
              disabled={!canPublish || !isOnline || !publishable}
              draftType={__typename}
              mutation={publishMutation}
              variables={{ id }}
            />
          )}
        </ButtonContainer>
      </Container>
    )
  }),
  {
    draft: graphql`
      fragment Controls_draft on Draft {
        id
        canDelete
        canEdit
        canPublish
        canUpdate
        updatedAt
        administrators {
          privilege
          user {
            id
            first_name
            last_name
            avatar_url
          }
        }
        draftable {
          ...on Article {
            id
            deletedAt
          }
          lastPublishedAt
          __typename
        }
      }
    `
  }
)
