/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CollectionDetailQueryVariables = {
    id: string;
};
export type CollectionDetailQueryResponse = {
    readonly node: {
        readonly latestDraft?: {
            readonly " $fragmentRefs": FragmentRefs<"CollectionDetail_draft">;
        };
        readonly " $fragmentRefs": FragmentRefs<"CollectionDetail_draft">;
    } | null;
};
export type CollectionDetailQuery = {
    readonly response: CollectionDetailQueryResponse;
    readonly variables: CollectionDetailQueryVariables;
};



/*
query CollectionDetailQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ... on Collection {
      latestDraft {
        ...CollectionDetail_draft
        id
      }
    }
    ...CollectionDetail_draft
    id
  }
}

fragment CollectionDetail_draft on Draft {
  id
  ...Controls_draft
  canUpdate
  draftable {
    __typename
    ... on Collection {
      id
      name
      body
      description
      index
      collectables(first: 10) {
        edges {
          node {
            __typename
            id
            ...SlugableCell_slugable
          }
        }
      }
    }
    ... on Node {
      __isNode: __typename
      id
    }
  }
  errors {
    field
    messages
  }
  warnings {
    field
    messages
  }
}

fragment Controls_draft on Draft {
  id
  canDelete
  canEdit
  canPublish
  canUpdate
  updatedAt
  administrators {
    privilege
    user {
      id
      first_name
      last_name
      avatar_url
    }
  }
  draftable {
    ... on Article {
      id
      deletedAt
    }
    lastPublishedAt
    __typename
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment SlugableCell_slugable on Slugable {
  __isSlugable: __typename
  updated_at
  title
  thumbnails(aspect_ratio: SQUARE) {
    small
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "CollectionDetail_draft"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canDelete",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canEdit",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canPublish",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canUpdate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "DraftAdministrator",
  "kind": "LinkedField",
  "name": "administrators",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "privilege",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "first_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatar_url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "draftable",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastPublishedAt",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "kind": "InlineFragment",
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deletedAt",
          "storageKey": null
        }
      ],
      "type": "Article",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v4/*: any*/)
      ],
      "type": "Node",
      "abstractKey": "__isNode"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "index",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 10
            }
          ],
          "concreteType": "SlugableConnection",
          "kind": "LinkedField",
          "name": "collectables",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SlugableEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    (v4/*: any*/),
                    {
                      "kind": "TypeDiscriminator",
                      "abstractKey": "__isSlugable"
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "updated_at",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "title",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": [
                        {
                          "kind": "Literal",
                          "name": "aspect_ratio",
                          "value": "SQUARE"
                        }
                      ],
                      "concreteType": "Thumbnail",
                      "kind": "LinkedField",
                      "name": "thumbnails",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "small",
                          "storageKey": null
                        }
                      ],
                      "storageKey": "thumbnails(aspect_ratio:\"SQUARE\")"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "collectables(first:10)"
        }
      ],
      "type": "Collection",
      "abstractKey": null
    }
  ],
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "field",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "messages",
    "storageKey": null
  }
],
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Error",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Error",
  "kind": "LinkedField",
  "name": "warnings",
  "plural": true,
  "selections": (v12/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CollectionDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Draft",
                "kind": "LinkedField",
                "name": "latestDraft",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Collection",
            "abstractKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CollectionDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Draft",
                "kind": "LinkedField",
                "name": "latestDraft",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Collection",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "type": "Draft",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "90c5971072d8b5439392f17cced11fce",
    "id": null,
    "metadata": {},
    "name": "CollectionDetailQuery",
    "operationKind": "query",
    "text": "query CollectionDetailQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Collection {\n      latestDraft {\n        ...CollectionDetail_draft\n        id\n      }\n    }\n    ...CollectionDetail_draft\n    id\n  }\n}\n\nfragment CollectionDetail_draft on Draft {\n  id\n  ...Controls_draft\n  canUpdate\n  draftable {\n    __typename\n    ... on Collection {\n      id\n      name\n      body\n      description\n      index\n      collectables(first: 10) {\n        edges {\n          node {\n            __typename\n            id\n            ...SlugableCell_slugable\n          }\n        }\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  errors {\n    field\n    messages\n  }\n  warnings {\n    field\n    messages\n  }\n}\n\nfragment Controls_draft on Draft {\n  id\n  canDelete\n  canEdit\n  canPublish\n  canUpdate\n  updatedAt\n  administrators {\n    privilege\n    user {\n      id\n      first_name\n      last_name\n      avatar_url\n    }\n  }\n  draftable {\n    ... on Article {\n      id\n      deletedAt\n    }\n    lastPublishedAt\n    __typename\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment SlugableCell_slugable on Slugable {\n  __isSlugable: __typename\n  updated_at\n  title\n  thumbnails(aspect_ratio: SQUARE) {\n    small\n  }\n}\n"
  }
};
})();
(node as any).hash = '722519626f0ef60fb45fe2b7bc92c03c';
export default node;
