import React, { FC, useState } from 'react'
import * as Yup from 'yup'
import { graphql } from 'babel-plugin-relay/macro'
import { createFragmentContainer, QueryRenderer } from 'react-relay'
import { RouteComponentProps } from 'react-router-dom'
import { Box, Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import environment from '../../../relay/environment'
import { FormikContextType, FormikValues, useFormikContext } from 'formik'
import { AuthorEditQuery as Query } from './__generated__/AuthorEditQuery.graphql'
import { AuthorEditMutationResponse as MutationResponse } from './__generated__/AuthorEditMutation.graphql'
import { AuthorEdit_node as Node } from './__generated__/AuthorEdit_node.graphql'
import { Wrapper } from '../Livestream'
import { formatFields } from '../formatFields'
import TagRenderer, { Props } from '../Users/TagRenderer'
import Form, { handleNonFieldErrors, OnCompletedOptions as FormProps } from '../Form'
import Header from '../Header'
import Controls from '../Controls'
import Loader from '../../Loader'
import StaticFields from './StaticFields'
import ToggleButton from '../ToggleButton'

const mutation = graphql`
  mutation AuthorEditMutation($id: ID!, $input: AuthorInput!) {
    authorUpdate(id: $id, input: $input) {
      author {
        id
        byline
        slug
        active
        tags {
          id
          name
          fixedOptions
          value
          type
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

const query = graphql`
  query AuthorEditQuery($id: ID!) {
    node(id: $id) {
      ...AuthorEdit_node
    }
  }
`

interface OnCompleteProps extends FormProps {
  values: MutationResponse
}

const initialValues = (node: Node) => ({
  id: node.id,
  input: {
    author: {
      byline: node.byline,
      slug: node.slug,
      tags: node.tags.reduce((acc, tag) => ({ ...acc, [tag.name]: [tag.id, tag.value] }), {})
    }
  }
})

const formatAuthorEditErrors = (backendErrors: any) =>
  backendErrors.length &&
  backendErrors.reduce(
    (acc: any, val: any) => ({
      ...acc,
      [`author.${val.field}`]: val.messages.join(' ')
    }),
    {}
  )

const formatValues = (values: FormikValues) => {
  let tags = []
  for (let tag in values.input.author.tags) {
    tags.push({ source: values.input.author.tags[tag][0], value: values.input.author.tags[tag][1] })
  }
  const author = { ...values.input.author, tags: tags.filter((tag) => tag.value && tag?.value?.length > 0) }
  return { ...values, input: author }
}

const AuthorToggle = (props: { node: Node }) => {
  const [isActive, setIsActive] = useState(props.node.active)
  const { setFieldValue }: FormikContextType<FormikValues> = useFormikContext()

  return (
    <Box mb={4}>
      <ToggleButton
        active={isActive}
        handleChange={(e) => {
          setIsActive((s) => !s)
          setFieldValue(e.target.name, e.target.checked)
        }}
        name='input.author.active'
        titlePosition='start'
        title='Active'
      />
    </Box>
  )
}

const Fields: FC<{ node: Node; scopeType: string }> = ({ node, scopeType }) => (
  <>
    <Header title='Update Author Profile' />
    <Box m={8}>
      <Grid container direction='row' alignItems='flex-start' justify='space-between'>
        <Grid container item direction='column' md={4} xs={7}>
          <StaticFields />
          <AuthorToggle node={node} />
        </Grid>
        <Grid container item direction='column' md={4} xs={7}>
          {node.tags.map((tag: Props['tag']) => (
            <Box mt={1} mb={4} key={tag.id}>
              <TagRenderer tag={tag} scopeType={scopeType} />
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  </>
)

const AuthorEdit = createFragmentContainer(
  (props: { node: Node }) => {
    const { node } = props
    const { enqueueSnackbar } = useSnackbar()

    const onComplete = ({ values: { authorUpdate }, setErrors, formValues }: OnCompleteProps) => {
      const formattedBackendErrors =  formatAuthorEditErrors(authorUpdate?.errors)
      if (!authorUpdate?.errors.length) {
        enqueueSnackbar('Information successfully updated.', { variant: 'success' })
      } else {
        handleNonFieldErrors(formattedBackendErrors, formatFields(formValues!.input), enqueueSnackbar)
        setErrors({ input: formattedBackendErrors })
      }
    }

    return (
      <Form
        formatValues={formatValues}
        mutation={mutation}
        onComplete={onComplete}
        validationSchema={Yup.object().shape({})}
        initialValues={initialValues(node)}
      >
        <Wrapper>
          <Fields node={node} scopeType={node.__typename} />
          <Controls redirectTo='authors' isAdmin={true} />
        </Wrapper>
      </Form>
    )
  },
  {
    node: graphql`
      fragment AuthorEdit_node on Author {
        __typename
        id
        byline
        slug
        active
        tags {
          id
          name
          fixedOptions
          value
          type
        }
      },
    `
  }
)

const Renderer: FC<RouteComponentProps<{ id: string }>> = ({ match }) => (
  <QueryRenderer<Query>
    environment={environment}
    query={query}
    variables={match.params}
    render={({ props }) => {
      if (props && props.node) {
        return <AuthorEdit node={props.node} />
      } else {
        return <Loader />
      }
    }}
  />
)

export default Renderer
