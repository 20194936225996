import React, { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { Box, Card, makeStyles, Table, TableBody } from '@material-ui/core'
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd'
import remove from 'lodash/remove'
import { FormikContextType, useFormikContext, FormikHelpers } from 'formik'
import { useSnackbar } from 'notistack'
import { AddStoryButton } from './Buttons'
import Row from './Row'
import { Story, StoryList } from './TopStoryEdit'
import { StoryId } from './types'

export type Props = {
  max: number
  min: number
  replaceStory: ({ id }: StoryId) => void
  setShowModal: Dispatch<SetStateAction<boolean>>
  storyList: NonNullable<StoryList>
  updateStories: (stories: any) => void
}

export type DragEndProps = {
  result: DropResult
  stories: Props['storyList']
  updateFormValues: FormikHelpers<any>['setValues']
  updateStories: Props['updateStories']
  values: any
}

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: '60px'
  },
  root: {
    margin: 64,
    marginBottom: 10,
    marginTop: 0
  },
}))

const reorder = (stories: Props['storyList'], startIndex: number, endIndex: number) => {
  const result = Array.from(stories)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return [...result.map((s: any, i) => ({...s, order: i}))]
}

//Responsible for handling post drag event logic after user finished dragging a row
function onDragEnd({ result, stories, updateStories, values, updateFormValues }: DragEndProps) {
  if (!result?.destination || (result?.destination?.index === result?.source?.index)) {
    return
  }

  const newStories = reorder(
    stories,
    result.source.index,
    result.destination.index
  )

  //updates the form values to ensure form state matches the local state
  updateFormValues({...values, input: { stories: [...newStories.map((s) => ({ story: s.id, order: s.order }))] }})
  //update the local state values
  updateStories(newStories)
}

const List: FC<Props> = ({ min, max, replaceStory, storyList, setShowModal, updateStories }) => {
  /* library hooks */
  const classes = useStyles()
  const { enqueueSnackbar } = useSnackbar()
  const { setValues, values }: FormikContextType<any> = useFormikContext()

  /* constants */
  const storiesLength = storyList?.length || 0

  /* helper functions */
  function handleDelete({ id }: StoryId) {
    const minDeletionError = `Unable to delete story. The minimum number has been set to ${min}.`

    // "Top Stories" has a stipulation that there be a minimum number of stories attached to them. If deleting the selected
    //    story would drop the list under this minimum, stop the user from doing so.
    if (storiesLength <= min) {
      return void enqueueSnackbar(minDeletionError, { variant: 'error' })
    }

    return void removeStory({ id })
  }

  function handleReplace({ id }: StoryId) {
    return void replaceStory({ id })
  }

  function showAddStory(): boolean {
    return storiesLength < min || storiesLength < max
  }

  function removeStory({ id }: StoryId) {
    const result = remove(
      storyList,
      // Remove the selected story from the list of stories
      (story: Story) => story.id !== id
    )
    // Update the manual "order" property with the new order
    .map(({ ...rest }: Story, i) => ({ ...rest, order: i }))

    setValues({...values, input: { stories: [...result.map((s) => ({ story: s.id, order: s.order }))] }})
    return void updateStories(result)
  }

  /* render functions */
  const renderStories = useCallback<any>([...storyList]
    .sort((a: any, b: any) => a.order - b.order)
    .map(mapRows),
    [storyList]
  )

  function mapRows(story: Story, index: number) {
    return (
      <Row
        handleDelete={handleDelete}
        id={story.id}
        index={index}
        key={story.id}
        publicAt={story.public_at as string}
        story={story}
        thumbnail={story.thumbnails?.small || ''}
        title={story.title!}
        handleReplace={handleReplace}
      />
    )
  }

  return (
    <Box className={classes.container}>
      <DragDropContext onDragEnd={(result: any) => onDragEnd({result, stories: storyList, updateStories, values, updateFormValues: setValues})}>
        <Droppable droppableId='list'>
          {(provided: any) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <Card className={classes.root}>
                <Box minWidth={700}>
                  <Table component='div'>
                    <TableBody component='div'>
                      {renderStories}
                      {provided.placeholder}
                    </TableBody>
                  </Table>
                  {showAddStory() && <AddStoryButton clickHandler={() => setShowModal(true)} />}
                </Box>
              </Card>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Box>
  )
}

export default List
