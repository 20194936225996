/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type CollectionIndexCreateCollectionMutationVariables = {
    organizationId: string;
};
export type CollectionIndexCreateCollectionMutationResponse = {
    readonly collectionCreate: {
        readonly draft: {
            readonly draftable: {
                readonly id?: string;
            };
        };
    } | null;
};
export type CollectionIndexCreateCollectionMutation = {
    readonly response: CollectionIndexCreateCollectionMutationResponse;
    readonly variables: CollectionIndexCreateCollectionMutationVariables;
};



/*
mutation CollectionIndexCreateCollectionMutation(
  $organizationId: ID!
) {
  collectionCreate(organizationId: $organizationId) {
    draft {
      draftable {
        __typename
        ... on Node {
          __isNode: __typename
          id
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CollectionIndexCreateCollectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionCreateMutationPayload",
        "kind": "LinkedField",
        "name": "collectionCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Draft",
            "kind": "LinkedField",
            "name": "draft",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "draftable",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CollectionIndexCreateCollectionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CollectionCreateMutationPayload",
        "kind": "LinkedField",
        "name": "collectionCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Draft",
            "kind": "LinkedField",
            "name": "draft",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "draftable",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e9a3d1b293181eeeb42bfbce5fe952f2",
    "id": null,
    "metadata": {},
    "name": "CollectionIndexCreateCollectionMutation",
    "operationKind": "mutation",
    "text": "mutation CollectionIndexCreateCollectionMutation(\n  $organizationId: ID!\n) {\n  collectionCreate(organizationId: $organizationId) {\n    draft {\n      draftable {\n        __typename\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '253281d8f25e648521f4059083e29fed';
export default node;
