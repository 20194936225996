/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Preview_article = {
    readonly headline: string | null;
    readonly heroImageUrl: string | null;
    readonly video: {
        readonly video_urls: ReadonlyArray<{
            readonly url: string | null;
            readonly mime: string | null;
        } | null> | null;
    } | null;
    readonly videoFile: {
        readonly videoUrls: ReadonlyArray<{
            readonly url: string | null;
            readonly mime: string | null;
        }>;
    } | null;
    readonly html: string;
    readonly " $refType": "Preview_article";
};
export type Preview_article$data = Preview_article;
export type Preview_article$key = {
    readonly " $data"?: Preview_article$data;
    readonly " $fragmentRefs": FragmentRefs<"Preview_article">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "mime",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Preview_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "headline",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "heroImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Video",
      "kind": "LinkedField",
      "name": "video",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Url",
          "kind": "LinkedField",
          "name": "video_urls",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoFile",
      "kind": "LinkedField",
      "name": "videoFile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Url",
          "kind": "LinkedField",
          "name": "videoUrls",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "html",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();
(node as any).hash = 'f5fa16b800ef788a0eec1bde328da57a';
export default node;
