import React, { FC, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { useHistory } from 'react-router-dom'
import { commitMutation } from 'react-relay'
import environment from '../../../relay/environment'
import { useSnackbar } from 'notistack'
import { IconButton, TableCell } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { UserIndex_query as Response } from './__generated__/UserIndex_query.graphql'
import { UserDeleteMutation, UserDeleteMutationResponse } from './__generated__/UserDeleteMutation.graphql'
import { ClickableRow } from '../DataList'
import ConfirmModal from '../../Modal'

type User = NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<Response['organization']['users']>>['edges']>[0]>['node']>

export type UserProps = {
  user: User
  id: string
  updateUserPage: () => void
}

export const roleFormatter = (role: string) => (role && role === 'CMS' ? role : role[0].toUpperCase() + role.slice(1).toLowerCase())

const deleteMutation = graphql`
  mutation UserDeleteMutation($id: ID!) {
    userDelete(id: $id) {
      user {
        deletedAt
        first_name
        last_name
      }
      errors {
        field
        messages
      }
    }
  }
`

const User: FC<UserProps> = ({ id, updateUserPage, user }) => {
  const [showModal, setShowModal] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const deleteUser = () => {
    commitMutation<UserDeleteMutation>(environment, {
      mutation: deleteMutation,
      variables: { id },
      onCompleted: (resp: UserDeleteMutationResponse) => {
        if (!resp.userDelete?.errors?.length && resp.userDelete?.user.deletedAt!) {
          updateUserPage()
          enqueueSnackbar(`"${resp?.userDelete?.user?.first_name} ${resp?.userDelete?.user?.last_name}" deleted`, { variant: 'success' })
        } else {
          enqueueSnackbar(`${resp?.userDelete?.errors[0].messages[0]}`, { variant: 'error' })
        }
        setShowModal(false)
      },
      onError: () => {
        enqueueSnackbar(`Cannot delete user`, { variant: 'error' })
        setShowModal(false)
      }
    })
  }

  return (
    <>
      <ClickableRow clickHandler={() => history.push(`/users/${user.id}`)}>
        <TableCell>{`${user.first_name || ''} ${user.last_name || ''}`}</TableCell>
        <TableCell>{user.email}</TableCell>
        <TableCell>{user.role && roleFormatter(user.role!)}</TableCell>
        <TableCell align='right'>
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              setShowModal(true)
            }}
            title='Delete'
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>{' '}
      </ClickableRow>
      <ConfirmModal
        buttonText='Delete'
        title='Delete User'
        message={`Are you sure you want to delete "${user.first_name} ${user.last_name}"?`}
        onClick={deleteUser}
        open={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  )
}

export default User
