import React, { useState, useEffect } from 'react'
import { createPaginationContainer, RelayPaginationProp } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import { VideoFile, PaginatedTable, PaginatedWrapper } from './Index'
import { VideoIndexQuery as Query } from './__generated__/VideoIndexQuery.graphql'
import { VideoIndex_query as Response } from './__generated__/VideoIndex_query.graphql'
import IndexSearch, { NoResults } from './IndexSearch'

const PER_PAGE = 10

const query = graphql`
  query VideoIndexQuery (
    $count: Int!,
    $cursor: String,
    $query: String
  ) {
    ...VideoIndex_query @arguments(count: $count, cursor: $cursor, query: $query)
  }
`

const Container = createPaginationContainer(
  (props: { query: Response, relay: RelayPaginationProp }) => {

    const { query, relay } = props
    const [queryString, setQueryString] = useState<string | null>(null)
    const videoFiles = query?.organization?.videoFiles?.edges

    useEffect(() => {
      if (queryString !== null && (queryString.length > 1 || queryString === "")) {
        relay.refetchConnection(PER_PAGE, () => { }, { query: queryString })
      }
    }, [queryString, relay])

    return (
      <PaginatedWrapper relay={relay} variables={{}} perPage={PER_PAGE} title='Videos'>
        <IndexSearch setQueryString={setQueryString} />
        {videoFiles?.map((e: any, i: number) => (
          <VideoFile.Cell key={i} videoFile={e.node} />
        ))}
        {videoFiles?.length === 0 && queryString !== null && queryString !== "" && <NoResults queryString={queryString} />}
      </PaginatedWrapper>
    )
  },
  {
    query: graphql`
      fragment VideoIndex_query on Query
      @argumentDefinitions(
        count: { type: "Int" }
        cursor: { type: "String" }
        query: { type: "String" }
      ) {
        organization {
          videoFiles(
            first: $count,
            after: $cursor,
            query: $query
          ) @connection(key: "VideoFileIndex_videoFiles") {
            edges {
              node {
                id
                ...VideoFileCell_videoFile
              }
            }
          }
        }
      }
    `
  },
  {
    query,
    getVariables(props, { count, cursor }, { query }) {
      return {
        count,
        cursor,
        query
      }
    }
  }
)

export default () => (
  <PaginatedTable<Query> Component={Container} query={query} variables={{ count: PER_PAGE }} />
)
