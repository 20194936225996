import React, { FC } from 'react'
import { QueryRenderer } from 'react-relay'
import environment from '../../../relay/environment'
import { graphql } from 'babel-plugin-relay/macro'
import { TopBarQuery as Query } from './__generated__/TopBarQuery.graphql'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import { AppBar, Box, Hidden, IconButton, Toolbar, makeStyles, SvgIcon } from '@material-ui/core'
import { CircularProgress } from '@rmwc/circular-progress'
import '@rmwc/circular-progress/circular-progress.css'
import MenuIcon from '@material-ui/icons/Menu'
import { Theme } from '../../../theme'
import Account from './Account'
import alticeLogoWhite from '../../../assets/png/altice-logo-white.png'

export interface TopBarProps {
  className?: string
  onMobileNavOpen?: () => void
  device: any
}

const query = graphql`
  query TopBarQuery {
    organization {
      name
    }
  }
`

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    backgroundColor: theme.palette.background.dark
  },
  toolbar: {
    minHeight: 64
  }
}))

const logo: { [key: string]: any } = {
  Cheddar: {
    height: '42',
    url: 'https://img.chdrstatic.com/assets/CheddarNews_400.png'
  },
  News12: {
    height: '24',
    url: 'https://img.chdrstatic.com/assets/N12_400.png'
  }
}

const OrganizationLogo: FC<{}> = () => (
  <QueryRenderer<Query>
    environment={environment}
    query={query}
    variables={{}}
    render={({ props }) => {
      if (props?.organization) {
        const { name } = props.organization

        return (
          <img
            src={logo[name].url}
            alt={`${name} Logo`}
            height={logo[name].height}
            width='auto'
            style={{ verticalAlign: 'middle' }}
            data-testid='logo'
          />
        )
      } else {
        return <CircularProgress size='xsmall'/>
      }
    }}
  />
)

const TopBar: FC<TopBarProps> = ({ className, onMobileNavOpen = () => {}, device, ...rest }) => {
  const classes = useStyles()

  return (
    // Top navigation bar
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        {/* Navigation bar toggle button for mobile */}
        <Hidden lgUp>
          <IconButton color='inherit' onClick={onMobileNavOpen} data-testid='mobile-nav-button'>
            <SvgIcon fontSize='default'>
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        {/* Altice logo for desktop */}
        <Hidden mdDown>
          <RouterLink to='/'>
            <img src={alticeLogoWhite} alt='Altice' height='48' width='42' />
          </RouterLink>
          {/* Organization logo for desktop */}
          <Box ml={2}>
            <OrganizationLogo />
          </Box>
        </Hidden>
        {/* Spacer */}
        <Box flexGrow={1} display='flex' justifyContent='center'>
          {/* Organization logo for mobile */}
          <Hidden lgUp>
            {/* Column */}
            <Box>
              <OrganizationLogo />
            </Box>
          </Hidden>
        </Box>
        {/* Column */}
        <Box ml={2}>
          <Account user={device.user} />
        </Box>
      </Toolbar>
    </AppBar>
  )
}

export default TopBar
