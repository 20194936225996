import Section, { SectionProps } from "./Section";
import { useContext } from "react";
import { Context, ContextType } from "../Form";
import React from "react";
import TextInput from "../../TextInput";

const TextSection: React.FC<SectionProps> = (props) => {
  const { model, errors } = useContext(Context) as ContextType
  const value = model[props.name] || ''

  return (
    <Section {...props} preview={value} errors={errors.for(props.name)}>
      <TextInput {...props} showLabel={false} />
    </Section>
  )
}

export default TextSection
