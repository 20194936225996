import React, { useState } from 'react'
import { fetchQuery } from 'react-relay'
import environment from './relay/environment'
import { ThemeProvider } from 'styled-components'
import { ThemeProvider as DeviasThemeProvider } from '@material-ui/core'
import { RootQuery as Query } from './__generated__/RootQuery.graphql'
import { graphql } from 'babel-plugin-relay/macro'
import { theme, styledComponentsTheme } from './theme'
import Authenticate from './Authenticate'
import CheddarAuthenticator from './authenticators/CheddarAuthenticator'
import Loader from './components/Loader'
import { SnackbarProvider } from 'notistack'


declare global {
  interface Window {
    initgapi: () => void
    gapi: any
  }
}

const query = graphql`
  query RootQuery {
    device {
      id
    }
  }
`

const Root: React.FC = () => {
  const [isTokenValid, setIsTokenValid] = useState(false)

  fetchQuery<Query>(environment, query, {}, { fetchPolicy: 'network-only' }).toPromise().then((data) => {
    if (data?.device === null) {
      new CheddarAuthenticator().createToken()
      .then(() => setIsTokenValid(true))
    } else {
      setIsTokenValid(true)
    }
  })

  return !isTokenValid
          ? <Loader />
          : <DeviasThemeProvider theme={theme}>
              <ThemeProvider theme={styledComponentsTheme}>
                <SnackbarProvider dense anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={3000}>
                  <Authenticate/>
                </SnackbarProvider>
              </ThemeProvider>
            </DeviasThemeProvider>
}

export default Root
