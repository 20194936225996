import React, { FC } from 'react'
import { FormControl, FormGroup, FormControlLabel, makeStyles, FormControlLabelProps } from '@material-ui/core'
import Switch from '@material-ui/core/Switch'
import { Theme } from '../../theme'

type ToggleButtonProps = {
  active: boolean
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  name: string
  title?: string
  titlePosition: FormControlLabelProps['labelPlacement']
}

const useStyles = makeStyles((theme: Theme) => ({
  switchLabel: {
    marginLeft: 0
  },
  switchBase: {
    color: theme.palette.secondary.light,
    '&$checked': {
      color: theme.palette.secondary.light
    },
    '&$checked + $track': {
      backgroundColor: theme.palette.secondary.light
    }
  },
  checked: {},
  track: {}
}))

const ToggleButton: FC<ToggleButtonProps> = ({ active, handleChange, name, title, titlePosition }) => {
  const classes = useStyles()

  return (
    <FormControl component='fieldset'>
      <FormGroup aria-label='position' row>
        <FormControlLabel
          classes={{
            root: classes.switchLabel
          }}
          value={name}
          label={title}
          labelPlacement={titlePosition}
          control={
            <Switch
              role='switch'
              checked={active}
              onChange={handleChange}
              classes={{
                switchBase: classes.switchBase,
                track: classes.track,
                checked: classes.checked
              }}
              inputProps={{ id: name, name: name }}
            />
          }
        />
      </FormGroup>
    </FormControl>
  )
}

export default ToggleButton
