/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Roles = "ADMIN" | "CMS" | "EDITOR" | "WRITER" | "%future added value";
export type Navigation_device = {
    readonly user: {
        readonly role: Roles | null;
    } | null;
    readonly " $refType": "Navigation_device";
};
export type Navigation_device$data = Navigation_device;
export type Navigation_device$key = {
    readonly " $data"?: Navigation_device$data;
    readonly " $fragmentRefs": FragmentRefs<"Navigation_device">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Navigation_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DeviceToken",
  "abstractKey": null
};
(node as any).hash = 'e24ec7f9a29955a9bb98bd1a544e69ba';
export default node;
