import React, { useContext, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import styled from 'styled-components'
import AceEditor from 'react-ace'
import { Context, ContextType, InputProps, inputStyles, Field } from '../Form'
import Errors from '../../graphql/errors'

import 'brace/mode/xml'
import 'brace/mode/json'
import 'brace/mode/css'
import 'brace/mode/html'
import 'brace/mode/markdown'
import 'brace/theme/tomorrow'

const StyledCode = styled(AceEditor).attrs(props => ({
  tabSize: 2,
  theme: 'tomorrow',
  mode: props.mode || 'markdown',
  width: '100%',
  height: '100%',
  editorProps: {
    $blockScrolling: Infinity
  }
}))<InputProps>`${() => inputStyles}`

export type CodeProps = {
  mode?: string
}

const parseErrors = (errors: Errors, name: string) => {
  return errors.for(name).map(msg => {
    let matches = msg.match(/(\d+):(\d+): ERROR: (.+)/)

    let row: number
    let column: number
    let text: string

    if (matches) {
      row = +matches[1] - 1
      column = +matches[2]
      text = matches[3]
    } else {
      row = 0
      column = 0
      text = msg
    }

    return {
      type: 'error',
      row,
      column,
      text,
    }
  })
}

const Wrapper = styled.div<{ grow?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: ${props => props.grow ? 1 : 0};
  min-height: 200px;
  width: 100%;

  div:nth-of-type(2) {
    height: 100%;
  }
`

const Label = styled.div`
  text-transform: capitalize;
  margin-bottom: 0.5rem;
`

const Code: React.FC<InputProps & CodeProps> = (props) => {
  const { model, onChange, disabled, errors } = useContext(Context) as ContextType
  const [ annotations, setAnnotations ] = useState(([] as any))
  const [ editor, setEditor ] = useState()
  const nextAnnotations = [
    ...annotations.filter((annotation: { validation: boolean }) => !annotation.validation), // annotations from syntax errors
    ...parseErrors(errors, props.name).map(annotation => ({ ...annotation, validation: true})) // annotations from invalid compliance to xml schema
  ]

  useDeepCompareEffect(() => {
    if (editor) {
      editor.getSession().setAnnotations(nextAnnotations)
    }
  }, [editor, nextAnnotations])

  return (
    <Wrapper grow={props.fill}>
      <Label>{props.displayName || props.name}</Label>
      <Field name={props.name} errors={errors.for(props.name)}>
        <StyledCode {...props}
          readOnly={disabled}
          onLoad={setEditor}
          onValidate={setAnnotations}
          value={model[props.name] || ''}
          onChange={(value, event) => onChange(props.name, value)}
        />
      </Field>
    </Wrapper>
  )
}

export default Code
