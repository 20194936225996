import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
`

const Month = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.colors.grays.brightGray};
`

const Button = styled.button<{ disabled: boolean} >`
  position: relative;
  border: none;
  cursor: ${props => props.disabled ? 'normal' : 'pointer'};
  height: 12px;
  margin: 0 10px;
  visibility: ${props => props.disabled ? 'hidden' : 'visible'};
  outline: none;
`

const PrevButton = styled(Button)`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-right: 5px solid ${props => props.theme.colors.grays.tuna};
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }

  &:after {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    border-right: 3px solid #fff;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
  }
`

const NextButton = styled(Button)`
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-left: 5px solid ${props => props.theme.colors.grays.tuna};
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
  }

  &:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 2px;
    border-left: 3px solid #fff;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
  }
`

type CustomHeaderProps = {
  date: Date
  decreaseMonth(): void
  increaseMonth(): void
  prevMonthButtonDisabled: boolean
  nextMonthButtonDisabled: boolean
}

const CustomHeader: React.FC<CustomHeaderProps> = ({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
  <Wrapper>
    <PrevButton onClick={(e) => {e.preventDefault(); decreaseMonth()}} disabled={prevMonthButtonDisabled}></PrevButton>
    <Month data-testid='month'>{moment.utc(date).format('MMMM')}</Month>
    <NextButton onClick={(e) => {e.preventDefault(); increaseMonth()}} disabled={nextMonthButtonDisabled}></NextButton>
  </Wrapper>
)

export default CustomHeader
