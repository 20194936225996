/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PublicationStatus = "DRAFT" | "PUBLISHED" | "SCHEDULED" | "%future added value";
export type DraftableCell_draftable = {
    readonly __typename: string;
    readonly id?: string;
    readonly latestDraft: {
        readonly publishedAt: string | null;
        readonly scheduledFor: string | null;
        readonly updatedAt: string;
        readonly publicationStatus: PublicationStatus;
        readonly administrators: ReadonlyArray<{
            readonly user: {
                readonly id: string;
                readonly first_name: string | null;
                readonly last_name: string | null;
                readonly avatar_url: string;
            } | null;
        }>;
        readonly draftable: {
            readonly name: string;
            readonly thumbnails?: {
                readonly small: string | null;
            } | null;
        };
    };
    readonly " $refType": "DraftableCell_draftable";
};
export type DraftableCell_draftable$data = DraftableCell_draftable;
export type DraftableCell_draftable$key = {
    readonly " $data"?: DraftableCell_draftable$data;
    readonly " $fragmentRefs": FragmentRefs<"DraftableCell_draftable">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraftableCell_draftable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Draft",
      "kind": "LinkedField",
      "name": "latestDraft",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publishedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "scheduledFor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "publicationStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "DraftAdministrator",
          "kind": "LinkedField",
          "name": "administrators",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "first_name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "last_name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "avatar_url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "draftable",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Literal",
                      "name": "aspect_ratio",
                      "value": "SQUARE"
                    }
                  ],
                  "concreteType": "Thumbnail",
                  "kind": "LinkedField",
                  "name": "thumbnails",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "small",
                      "storageKey": null
                    }
                  ],
                  "storageKey": "thumbnails(aspect_ratio:\"SQUARE\")"
                }
              ],
              "type": "Slugable",
              "abstractKey": "__isSlugable"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/)
      ],
      "type": "Node",
      "abstractKey": "__isNode"
    }
  ],
  "type": "Draftable",
  "abstractKey": "__isDraftable"
};
})();
(node as any).hash = '29da2d99dd5d1bb19903021f35fcad8d';
export default node;
