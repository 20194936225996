/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Account_user = {
    readonly id: string;
    readonly avatar_url: string;
    readonly first_name: string | null;
    readonly last_name: string | null;
    readonly " $refType": "Account_user";
};
export type Account_user$data = Account_user;
export type Account_user$key = {
    readonly " $data"?: Account_user$data;
    readonly " $fragmentRefs": FragmentRefs<"Account_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Account_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "avatar_url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_name",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = '881ba99d2d8fd000c06fcff303d13758';
export default node;
