type Err = {
  readonly field: string,
  readonly messages: ReadonlyArray<string>
}

export default class Errors {
  private readonly errors: ReadonlyArray<Err>

  constructor(errors: ReadonlyArray<Err>) {
    this.errors = errors
  }

  for(key: string): readonly string[] {
    const err = this.errors.find(e => e.field === key)
    return err ? err.messages : []
  }

  get length(): number {
    return this.errors.reduce((tot, err) => tot + err.messages.length, 0)
  }
}
