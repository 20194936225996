/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MediaItem_slugable = {
    readonly id: string;
    readonly description: string | null;
    readonly hero_video: {
        readonly duration: number | null;
    } | null;
    readonly public_at: unknown | null;
    readonly public_url: string | null;
    readonly thumbnails: {
        readonly medium: string | null;
    } | null;
    readonly title: string | null;
    readonly __typename: string;
    readonly " $refType": "MediaItem_slugable";
};
export type MediaItem_slugable$data = MediaItem_slugable;
export type MediaItem_slugable$key = {
    readonly " $data"?: MediaItem_slugable$data;
    readonly " $fragmentRefs": FragmentRefs<"MediaItem_slugable">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MediaItem_slugable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "HeroVideo",
      "kind": "LinkedField",
      "name": "hero_video",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "duration",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "public_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "public_url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "aspect_ratio",
          "value": "HD"
        }
      ],
      "concreteType": "Thumbnail",
      "kind": "LinkedField",
      "name": "thumbnails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "medium",
          "storageKey": null
        }
      ],
      "storageKey": "thumbnails(aspect_ratio:\"HD\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "type": "Slugable",
  "abstractKey": "__isSlugable"
};
(node as any).hash = '049274dc1d1747a6d61f3b91a94820e3';
export default node;
