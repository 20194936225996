/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TemplateDetailQueryVariables = {
    id: string;
};
export type TemplateDetailQueryResponse = {
    readonly node: {
        readonly latestDraft?: {
            readonly " $fragmentRefs": FragmentRefs<"TemplateDetail_draft">;
        };
        readonly " $fragmentRefs": FragmentRefs<"TemplateDetail_draft">;
    } | null;
};
export type TemplateDetailQuery = {
    readonly response: TemplateDetailQueryResponse;
    readonly variables: TemplateDetailQueryVariables;
};



/*
query TemplateDetailQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ... on Draftable {
      __isDraftable: __typename
      latestDraft {
        ...TemplateDetail_draft
        id
      }
    }
    ...TemplateDetail_draft
    id
  }
}

fragment Controls_draft on Draft {
  id
  canDelete
  canEdit
  canPublish
  canUpdate
  updatedAt
  administrators {
    privilege
    user {
      id
      first_name
      last_name
      avatar_url
    }
  }
  draftable {
    ... on Article {
      id
      deletedAt
    }
    lastPublishedAt
    __typename
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment TemplateDetail_draft on Draft {
  id
  ...Controls_draft
  canUpdate
  draftable {
    __typename
    ...TemplateTypeSelect_template
    ... on Template {
      body
      css
      description
      name
    }
    ... on Node {
      __isNode: __typename
      id
    }
  }
  errors {
    field
    messages
  }
  warnings {
    field
    messages
  }
}

fragment TemplateTypeSelect_template on Draftable {
  __isDraftable: __typename
  ... on Template {
    templatableType
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "TemplateDetail_draft"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canDelete",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canEdit",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canPublish",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canUpdate",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "DraftAdministrator",
  "kind": "LinkedField",
  "name": "administrators",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "privilege",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "first_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatar_url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "draftable",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastPublishedAt",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "kind": "TypeDiscriminator",
      "abstractKey": "__isDraftable"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "deletedAt",
          "storageKey": null
        }
      ],
      "type": "Article",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v4/*: any*/)
      ],
      "type": "Node",
      "abstractKey": "__isNode"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "templatableType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "body",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "css",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "type": "Template",
      "abstractKey": null
    }
  ],
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "field",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "messages",
    "storageKey": null
  }
],
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "Error",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Error",
  "kind": "LinkedField",
  "name": "warnings",
  "plural": true,
  "selections": (v12/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TemplateDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Draft",
                "kind": "LinkedField",
                "name": "latestDraft",
                "plural": false,
                "selections": [
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Draftable",
            "abstractKey": "__isDraftable"
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TemplateDetailQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Draft",
                "kind": "LinkedField",
                "name": "latestDraft",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Draftable",
            "abstractKey": "__isDraftable"
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "type": "Draft",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b15e2487ebd852fa38b2ab0bffa8d4b4",
    "id": null,
    "metadata": {},
    "name": "TemplateDetailQuery",
    "operationKind": "query",
    "text": "query TemplateDetailQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Draftable {\n      __isDraftable: __typename\n      latestDraft {\n        ...TemplateDetail_draft\n        id\n      }\n    }\n    ...TemplateDetail_draft\n    id\n  }\n}\n\nfragment Controls_draft on Draft {\n  id\n  canDelete\n  canEdit\n  canPublish\n  canUpdate\n  updatedAt\n  administrators {\n    privilege\n    user {\n      id\n      first_name\n      last_name\n      avatar_url\n    }\n  }\n  draftable {\n    ... on Article {\n      id\n      deletedAt\n    }\n    lastPublishedAt\n    __typename\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment TemplateDetail_draft on Draft {\n  id\n  ...Controls_draft\n  canUpdate\n  draftable {\n    __typename\n    ...TemplateTypeSelect_template\n    ... on Template {\n      body\n      css\n      description\n      name\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  errors {\n    field\n    messages\n  }\n  warnings {\n    field\n    messages\n  }\n}\n\nfragment TemplateTypeSelect_template on Draftable {\n  __isDraftable: __typename\n  ... on Template {\n    templatableType\n  }\n}\n"
  }
};
})();
(node as any).hash = 'be61cb3befc7cb9bad5321c3be4255d0';
export default node;
