import React from 'react'
import '../../stylesheets/app.css'
import alticeLogo from '../../images/alticeLogo.png'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import SigninForm from './SigninForm'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
`

export const SigninPage = styled.div`
  background-color: #ffffff;
  width: 608px;
  height: 560px;
  border-radius: 4px;
  box-shadow: 0 2px 3px 0 rgba(244, 245, 247, 0.91);
  border: solid 1px rgba(110, 117, 130, 0.1);
  padding: 52px 160px 93px 160px;
  box-sizing: border-box;
`

export const Logo = styled.div`
  text-align: center;
  padding-bottom: 26px;
`

export const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: ${props => props.theme.colors.primary.nero};
  text-align: center;
  padding-bottom: 23px;
`

const Separator = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.colors.grays.aluminium};
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;

  &:before, &:after {
    content: '';
    width: calc(50% - 20px);
    border-top: 1px solid #c4c4c4;
    display: block;
    box-sizing: border-box;
  }

`

export const GoogleSignIn = styled.div`
  user-select: none;

  .abcRioButton {
    width: 100% !important;
    height: auto !important;
    border-radius: 2px !important;
    border: solid 1px rgba(231, 232, 234, 0.7) !important;;
    background-color: rgba(244, 244, 244, 0.3) !important;;
    box-shadow: none !important;;

    .abcRioButtonContentWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0 !important;

      .abcRioButtonIcon {
        padding: 0 !important;
        transform: scale(1.4);
      }

      .abcRioButtonContents {
        padding: 0 0 0 16px !important;
        margin: 0 !important;
        line-height: 19px !important;

        span {
          font-size: 16px !important;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: ${props => props.theme.colors.grays.stormGray};
          font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;

          &:after {
            content: ' with Google'
          }
        }
      }
    }
  }
`

const Signin: React.FC = ({ children }) => (
  <>
    <Helmet>
      <script src="https://apis.google.com/js/platform.js" async defer></script>
    </Helmet>
    <Logo><img alt={'Altice Logo'} src={alticeLogo}/></Logo>
    <Title>CMS Login</Title>
    <SigninForm/>
    <Separator>OR</Separator>
    <GoogleSignIn className="g-signin2" data-onsuccess="onSignIn"/>
    {children}
  </>
)

export default Signin
