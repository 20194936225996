import React from 'react'
import { Box, TextField } from '@material-ui/core'
import { FormikContextType, useFormikContext } from 'formik'
import { ValidationSchema } from './TopStoriesCreate'

const FIELDS = [
  { name: 'name', label: 'Title', type: 'text', fullWidth: true, mt: 0 },
  { name: 'min', label: 'Min', type: 'number', fullWidth: false, mt: 2 },
  { name: 'max', label: 'Max', type: 'number', fullWidth: false, mt: 2 }
]

const StaticFields = () => {
  const { values, errors, touched, handleBlur, handleChange }: FormikContextType<ValidationSchema> = useFormikContext()

  // the boolean value returned from Formik's context. If a validation error has occurred, it will be 'true', when there is no error - returns undefined;
  const determineHasError = (field: { name: string }): boolean | undefined =>
    touched?.input && (touched?.input as any)[field.name] && errors?.input && Boolean((errors?.input as any)[field.name])

  // a string value that is displayed under the form field if 'error' is true;
  const getHelperText = (field: { name: string }): string =>
    touched?.input && (touched?.input as any)[field.name] && errors?.input && (errors?.input as any)[field.name]

  return (
    <>
      {FIELDS.map((field) => (
        <Box height={70} mt={field.mt} mb={1} key={field.name}>
          <TextField
            fullWidth={field.fullWidth}
            id={`input.${field.name}`}
            label={field.label}
            type={field.type}
            name={`input.${field.name}`}
            error={determineHasError(field)}
            helperText={getHelperText(field)}
            onBlur={handleBlur}
            onChange={handleChange}
            value={(values.input as any)[field.name] ? (values.input as any)[field.name] : ''}
            variant='outlined'
          />
        </Box>
      ))}
    </>
  )
}

export default StaticFields
