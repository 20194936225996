/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AuthenticateQueryVariables = {};
export type AuthenticateQueryResponse = {
    readonly device: {
        readonly user: {
            readonly email: string;
            readonly deletedAt: string | null;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"Layout_device">;
    } | null;
};
export type AuthenticateQuery = {
    readonly response: AuthenticateQueryResponse;
    readonly variables: AuthenticateQueryVariables;
};



/*
query AuthenticateQuery {
  device {
    user {
      email
      deletedAt
      id
    }
    ...Layout_device
    id
  }
}

fragment Account_user on User {
  id
  avatar_url
  first_name
  last_name
}

fragment Layout_device on DeviceToken {
  ...Navigation_device
  user {
    role
    id
    ...Account_user
  }
}

fragment Navigation_device on DeviceToken {
  user {
    role
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedAt",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthenticateQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeviceToken",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Layout_device"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AuthenticateQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "DeviceToken",
        "kind": "LinkedField",
        "name": "device",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatar_url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "first_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last_name",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "494e5dcf19c47a7a3aed574135594d37",
    "id": null,
    "metadata": {},
    "name": "AuthenticateQuery",
    "operationKind": "query",
    "text": "query AuthenticateQuery {\n  device {\n    user {\n      email\n      deletedAt\n      id\n    }\n    ...Layout_device\n    id\n  }\n}\n\nfragment Account_user on User {\n  id\n  avatar_url\n  first_name\n  last_name\n}\n\nfragment Layout_device on DeviceToken {\n  ...Navigation_device\n  user {\n    role\n    id\n    ...Account_user\n  }\n}\n\nfragment Navigation_device on DeviceToken {\n  user {\n    role\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'deef440111b0c3841fbbaf46a5319929';
export default node;
