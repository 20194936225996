/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TemplatableType = "ARTICLE" | "PAGE" | "%future added value";
export type TemplateTypeSelect_template = {
    readonly templatableType?: TemplatableType;
    readonly " $refType": "TemplateTypeSelect_template";
};
export type TemplateTypeSelect_template$data = TemplateTypeSelect_template;
export type TemplateTypeSelect_template$key = {
    readonly " $data"?: TemplateTypeSelect_template$data;
    readonly " $fragmentRefs": FragmentRefs<"TemplateTypeSelect_template">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TemplateTypeSelect_template",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "templatableType",
          "storageKey": null
        }
      ],
      "type": "Template",
      "abstractKey": null
    }
  ],
  "type": "Draftable",
  "abstractKey": "__isDraftable"
};
(node as any).hash = 'f5e064a3af65ab3afd00d2b736c88de1';
export default node;
