/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type HeroPickerControlQueryVariables = {
    searchTerm: string;
};
export type HeroPickerControlQueryResponse = {
    readonly organization: {
        readonly videoFiles: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly createdAt: string;
                    readonly duration: number | null;
                    readonly name: string | null;
                    readonly videoUrls: ReadonlyArray<{
                        readonly mime: string | null;
                        readonly url: string | null;
                    }>;
                    readonly thumbnails: {
                        readonly small: string | null;
                    } | null;
                } | null;
            } | null> | null;
        } | null;
    };
};
export type HeroPickerControlQuery = {
    readonly response: HeroPickerControlQueryResponse;
    readonly variables: HeroPickerControlQueryVariables;
};



/*
query HeroPickerControlQuery(
  $searchTerm: String!
) {
  organization {
    videoFiles(query: $searchTerm, first: 15) {
      edges {
        node {
          id
          createdAt
          duration
          name
          videoUrls {
            mime
            url
          }
          thumbnails(aspect_ratio: HD) {
            small
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "first",
      "value": 15
    },
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "searchTerm"
    }
  ],
  "concreteType": "VideoFileConnection",
  "kind": "LinkedField",
  "name": "videoFiles",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoFileEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "VideoFile",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "duration",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Url",
              "kind": "LinkedField",
              "name": "videoUrls",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "mime",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": [
                {
                  "kind": "Literal",
                  "name": "aspect_ratio",
                  "value": "HD"
                }
              ],
              "concreteType": "Thumbnail",
              "kind": "LinkedField",
              "name": "thumbnails",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "small",
                  "storageKey": null
                }
              ],
              "storageKey": "thumbnails(aspect_ratio:\"HD\")"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HeroPickerControlQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HeroPickerControlQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a543c9f9900c11543e1950468ea34811",
    "id": null,
    "metadata": {},
    "name": "HeroPickerControlQuery",
    "operationKind": "query",
    "text": "query HeroPickerControlQuery(\n  $searchTerm: String!\n) {\n  organization {\n    videoFiles(query: $searchTerm, first: 15) {\n      edges {\n        node {\n          id\n          createdAt\n          duration\n          name\n          videoUrls {\n            mime\n            url\n          }\n          thumbnails(aspect_ratio: HD) {\n            small\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd852ead231d9c8ab284abe5843891b7f';
export default node;
