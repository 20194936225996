import React, { useContext, useState } from 'react'
import { Context, ContextType } from '../Form'
import environment from '../../../relay/environment'
import { QueryRenderer, GraphQLTaggedNode } from 'react-relay'
import {
  HeroPickerControlQueryResponse as Response,
  HeroPickerControlQuery as Query
} from './__generated__/HeroPickerControlQuery.graphql'
import styled from 'styled-components/macro'
import moment from 'moment'
import 'moment-duration-format'

const Container = styled.div<{ videoIsUpdating: boolean }>`
  position: absolute;
  top: 216px;
  height: 560px;
  width: calc(100% - 64px);
  box-shadow: 0 1px 2px 0 rgba(110, 117, 130, 0.68);
  background-color: #ffffff;
  z-index: 2;
  overflow: scroll;
  opacity: ${props => props.videoIsUpdating ? 0.5 : 1}
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 160px 1fr 48px 72px;
  grid-gap: 16px;
  padding: 0 ${props => props.theme.space[3]}px;
  box-sizing: border-box;
`

const HeaderWrapper = styled(Grid)`
  position: sticky;
  top: 0;
  width: 100%;
  height: 64px;
  box-shadow: 0 2px 1px 0 rgba(219, 220, 222, 0.53);
  background-color: ${props => props.theme.colors.grays.aliceBlue};
  cursor: pointer;
`

const ListWrapper = styled.div`
  padding: ${props => props.theme.space[2]}px 0;
  box-sizing: border-box;
  font-size: ${props => props.theme.fontSizes[1]}px;;
  color: ${props => props.theme.colors.grays.tuna};
`

const Result = styled(Grid)`
  grid-auto-rows: minmax(90px, auto);
  margin-bottom: ${props => props.theme.fontSizes[2]}px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.grays.aliceBlue};
  }
`

const ResultCell = styled.div`
  display: flex;
  align-items: center;
`

const Image = styled.img`
  width: 100%;
`

const HeaderCell = styled.div`
  display: flex;
  align-items: center;
  font-size: ${props => props.theme.fontSizes[0]}px;
  font-weight: bold;
  color: ${props => props.theme.colors.grays.grayChateau};
`

const VideoButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 80px;
  height: 32px;
  box-shadow: 0 1px 5px 0 rgba(219, 220, 222, 0.5);
  background-color: ${props => props.theme.colors.primary.mediumSlateBlue};
  font-size: ${props => props.theme.fontSizes[1]}px;
  font-weight: ${props => props.theme.fontWeights[3]};
  color: #ffffff;
`

const Icon = styled.i`
  font-size: ${props => props.theme.fontSizes[3]}px;
`

export const formatDate = (date?: string) => {
  return moment(date).format('MMM D, YYYY')
}

export const secondsToMinutes = (duration: number) => {
  return moment.duration(duration, 'seconds').format('mm:ss', { trim: false })
}

const Header = () => (
  <HeaderWrapper>
    <HeaderCell>
      <VideoButton>
        <Icon className='material-icons'>play_circle_filled</Icon>
        <span>Videos</span>
      </VideoButton>
    </HeaderCell>
    <HeaderCell>NAME</HeaderCell>
    <HeaderCell>DURATION</HeaderCell>
    <HeaderCell>DATE</HeaderCell>
  </HeaderWrapper>
)

interface ListQueryResponseProps {
  ListQueryResponse: Response | null
}

const Results: React.FC<ListQueryResponseProps & { handleClick: (node: { id?: string | null }) => void }> = (props) => {
  const edges = props.ListQueryResponse?.organization?.videoFiles?.edges

  return (
    <>
      {edges?.map(edge => (
        <Result key={edge?.node?.id} onClick={() => props.handleClick(edge?.node || {})}>
          <Image src={edge?.node?.thumbnails?.small || undefined}/>
          <ResultCell>{ edge?.node?.name }</ResultCell>
          <ResultCell>{ secondsToMinutes(edge?.node?.duration || 0) }</ResultCell>
          <ResultCell>{ formatDate(edge?.node?.createdAt) }</ResultCell>
        </Result>
      ))}
    </>
  )
}

const List = (props: ListQueryResponseProps) => {
  const { onChange } = useContext(Context) as ContextType
  const [ videoIsUpdating, setVideoIsUpdating ] = useState<boolean>(false)

  const handleClick = (node: { id?: string | null }) => {
    onChange('videoFileId', node.id)
    onChange('heroImageUrl', null)
    setVideoIsUpdating(true)
  }

  return (
    <Container videoIsUpdating={videoIsUpdating}>
      <Header/>
      <ListWrapper>
        {props && <Results {...props} handleClick={handleClick} />}
      </ListWrapper>
    </Container>
  )
}

export type ListProps = {
  query: GraphQLTaggedNode,
  searchTerm: string
}

const ListRenderer: React.FC<ListProps> = (parentProps) => (
  <QueryRenderer<Query>
    environment={environment}
    query={parentProps.query}
    variables={{searchTerm: parentProps.searchTerm}}
    render={({props}) => <List ListQueryResponse={props} />}
  />
)

export default ListRenderer
