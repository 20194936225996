/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type MetatagType = "AVATAR" | "KEYWORDS" | "MULTI_SELECT" | "SINGLE_SELECT" | "TEXT" | "THUMBNAIL" | "%future added value";
export type Roles = "ADMIN" | "CMS" | "EDITOR" | "WRITER" | "%future added value";
export type AuthorToggleMutationVariables = {
    id: string;
    isAuthor: boolean;
    slug?: string | null;
};
export type AuthorToggleMutationResponse = {
    readonly userAuthorToggle: {
        readonly user: {
            readonly id: string;
            readonly email: string;
            readonly first_name: string | null;
            readonly last_name: string | null;
            readonly role: Roles | null;
            readonly author: {
                readonly __typename: string;
                readonly active: boolean;
                readonly byline: string;
                readonly slug: string;
                readonly tags: ReadonlyArray<{
                    readonly id: string;
                    readonly name: string;
                    readonly fixedOptions: ReadonlyArray<string> | null;
                    readonly value: ReadonlyArray<string> | null;
                    readonly type: MetatagType;
                }>;
            } | null;
        } | null;
        readonly errors: ReadonlyArray<{
            readonly field: string;
            readonly messages: ReadonlyArray<string>;
        }>;
    } | null;
};
export type AuthorToggleMutation = {
    readonly response: AuthorToggleMutationResponse;
    readonly variables: AuthorToggleMutationVariables;
};



/*
mutation AuthorToggleMutation(
  $id: ID!
  $isAuthor: Boolean!
  $slug: String
) {
  userAuthorToggle(id: $id, isAuthor: $isAuthor, slug: $slug) {
    user {
      id
      email
      first_name
      last_name
      role
      author {
        __typename
        active
        byline
        slug
        tags {
          id
          name
          fixedOptions
          value
          type
        }
        id
      }
    }
    errors {
      field
      messages
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isAuthor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "slug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "isAuthor",
    "variableName": "isAuthor"
  },
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "slug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "first_name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "last_name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "byline",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Tag",
  "kind": "LinkedField",
  "name": "tags",
  "plural": true,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fixedOptions",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Error",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "messages",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthorToggleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserAuthorToggleMutationPayload",
        "kind": "LinkedField",
        "name": "userAuthorToggle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Author",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthorToggleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserAuthorToggleMutationPayload",
        "kind": "LinkedField",
        "name": "userAuthorToggle",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Author",
                "kind": "LinkedField",
                "name": "author",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v12/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6acefb688ec3527513760657847480c7",
    "id": null,
    "metadata": {},
    "name": "AuthorToggleMutation",
    "operationKind": "mutation",
    "text": "mutation AuthorToggleMutation(\n  $id: ID!\n  $isAuthor: Boolean!\n  $slug: String\n) {\n  userAuthorToggle(id: $id, isAuthor: $isAuthor, slug: $slug) {\n    user {\n      id\n      email\n      first_name\n      last_name\n      role\n      author {\n        __typename\n        active\n        byline\n        slug\n        tags {\n          id\n          name\n          fixedOptions\n          value\n          type\n        }\n        id\n      }\n    }\n    errors {\n      field\n      messages\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a8ce2655721c0aa27908d51151a34c0c';
export default node;
