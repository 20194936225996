const createErrorNode = (container: HTMLElement) => {
  if(!document.getElementById('error-wrapper')) {
    const errorNode = document.createElement('div')
    errorNode.id = 'error-wrapper'
    errorNode.textContent = 'Invalid Code Snippet'
    errorNode.style.backgroundColor = '#f52727'
    errorNode.style.color = '#f6f6f6'
    errorNode.style.height = '3vh'
    errorNode.style.lineHeight = '3vh'
    errorNode.style.textAlign = 'center'
    errorNode.style.padding = '10px'
    container.appendChild(errorNode)
  }
}

const createInputNode = (container: HTMLElement, id: string, logo: string, previewHandler: Function) => {
  const logoNode = document.createElement('img')
  logoNode.src = logo
  logoNode.height = 90
  logoNode.alt = 'Logo'
  logoNode.style.margin = '5px auto'
  logoNode.style.display = 'block'

  const codeSnippet = document.createElement('textarea')
  codeSnippet.id = id
  codeSnippet.placeholder = 'Paste code snippet here'
  codeSnippet.classList.add('cdx-input')
  codeSnippet.rows = 5

  const previewButton = document.createElement('button')
  previewButton.innerHTML = 'Preview'
  previewButton.classList.add('cdx-button')
  previewButton.style.margin = '20px auto'
  previewButton.style.display = 'block'

  container.appendChild(logoNode)
  container.appendChild(codeSnippet)
  container.appendChild(previewButton)
  previewHandler(previewButton)
}

export { createErrorNode, createInputNode }
