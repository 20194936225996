/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type VideoFileInput = {
    url?: string | null;
    filename?: string | null;
    source?: string | null;
};
export type VideoUploadVideoFileCreateMutationVariables = {
    organizationId?: string | null;
    input: VideoFileInput;
};
export type VideoUploadVideoFileCreateMutationResponse = {
    readonly videoFileCreate: {
        readonly videoFile: {
            readonly id: string;
        };
    } | null;
};
export type VideoUploadVideoFileCreateMutation = {
    readonly response: VideoUploadVideoFileCreateMutationResponse;
    readonly variables: VideoUploadVideoFileCreateMutationVariables;
};



/*
mutation VideoUploadVideoFileCreateMutation(
  $organizationId: ID
  $input: VideoFileInput!
) {
  videoFileCreate(organizationId: $organizationId, input: $input) {
    videoFile {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      }
    ],
    "concreteType": "VideoFileCreateMutationPayload",
    "kind": "LinkedField",
    "name": "videoFileCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "VideoFile",
        "kind": "LinkedField",
        "name": "videoFile",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoUploadVideoFileCreateMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "VideoUploadVideoFileCreateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "8319695904227c4768eb2af9d656dea2",
    "id": null,
    "metadata": {},
    "name": "VideoUploadVideoFileCreateMutation",
    "operationKind": "mutation",
    "text": "mutation VideoUploadVideoFileCreateMutation(\n  $organizationId: ID\n  $input: VideoFileInput!\n) {\n  videoFileCreate(organizationId: $organizationId, input: $input) {\n    videoFile {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'eecafa533d22a376a9a59e5ed0542ecf';
export default node;
