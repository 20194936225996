/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type HeroPickerQueryVariables = {
    extension: string;
    mimeType: string;
    final?: boolean | null;
};
export type HeroPickerQueryResponse = {
    readonly organization: {
        readonly mediaUploadUrl: string | null;
    };
};
export type HeroPickerQuery = {
    readonly response: HeroPickerQueryResponse;
    readonly variables: HeroPickerQueryVariables;
};



/*
query HeroPickerQuery(
  $extension: String!
  $mimeType: String!
  $final: Boolean
) {
  organization {
    mediaUploadUrl(extension: $extension, mimeType: $mimeType, final: $final)
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "extension"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "final"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "mimeType"
},
v3 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "extension",
      "variableName": "extension"
    },
    {
      "kind": "Variable",
      "name": "final",
      "variableName": "final"
    },
    {
      "kind": "Variable",
      "name": "mimeType",
      "variableName": "mimeType"
    }
  ],
  "kind": "ScalarField",
  "name": "mediaUploadUrl",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "HeroPickerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "HeroPickerQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d8162d73c65020286296a475a4ce0d8e",
    "id": null,
    "metadata": {},
    "name": "HeroPickerQuery",
    "operationKind": "query",
    "text": "query HeroPickerQuery(\n  $extension: String!\n  $mimeType: String!\n  $final: Boolean\n) {\n  organization {\n    mediaUploadUrl(extension: $extension, mimeType: $mimeType, final: $final)\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '2ef6afdd55097e81343658f2e542e6a1';
export default node;
