import React, { useState, useRef, SetStateAction } from 'react'
import styled, { StyledComponent, DefaultTheme } from 'styled-components'
import InputContainer from './KeywordsInputContainer'

type Props = {
  value: readonly string[] | null
  onChange: ((newValue: string[]) => void)
  disabled: boolean | undefined
}

type KeywordListProp = {
  value: readonly string[]
  disabled: boolean | undefined
  deleteTag: (idx: number) => void
}

type KeywordListItemProp = {
  keyword: string
  disabled: boolean | undefined
  deleteTagAction: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
}

const StyledKeywords = styled.div`
  width: 100%;
  min-height 48px;
  height: auto;
  box-shadow: 0 2px 4px 0 rgba(238, 238, 238, 0.32);
  border: solid 1px rgba(151, 151, 151, 0.1);
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 300;
  overflow: auto;
  padding: 17px 0 17px 8px;
  max-height: 500px;
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
`
StyledKeywords.displayName = "StyledKeywords"

const StyledKeywordListItem = styled.div<{ disabled: boolean | undefined }>`
  display: inline-block;
  padding: 9px 13px;
  margin: 0 3px 8px 0;
  list-style: none;
  background-color: black;
  color: white;
  min-height: 32px;
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(238, 238, 238, 0.32);
  border: solid 1px rgba(151, 151, 151, 0.06);
  box-sizing: border-box;
  position: relative;
  span{
    display: inline-block;
    width: 60%;
    min-width: 70px;
    max-width: 250px;
    height: auto;
    overflow-wrap: break-word;
    margin-right: 3px;
  }
  pointer-events: ${(props) => (props.disabled) ? 'none' : 'auto'}
  i {
    position: absolute;
    font-size: 12px !important;
    padding: 1px;
    font-weight: bold;
    right: 5%;
    top: 30%;
    :hover{
      cursor: pointer;
    }
  }
`

const removeTagFromArray = (tags: string[], idx: number) => {
  return tags.slice(0, idx).concat(tags.slice(idx + 1))
}

const updateValues = (onChange: (newValue: string[]) => void, setValue: React.Dispatch<SetStateAction<readonly string[] | null>>, arr: string[]) => {
  setValue(arr)
  onChange(arr)
}

const removeTag = (onChange: (newValue: string[]) => void, setValue: React.Dispatch<SetStateAction<readonly string[] | null>>, arr: string[], idx: number) => {
  updateValues(onChange, setValue, removeTagFromArray(arr, idx))
}

const focusInput = (ref: React.RefObject<null | StyledComponent<"input", DefaultTheme> | any>) => {
  return (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => ref.current?.focus()
}

const KeywordListItem: React.FC<KeywordListItemProp> = (props) => {
  const { deleteTagAction, disabled } = props
  return (
    <StyledKeywordListItem disabled={props.disabled}>
      <span>{props.keyword}</span>
      <i className="material-icons" data-testid="close-btn" onClick={e => disabled ? () => { } : deleteTagAction(e)}>close</i>
    </StyledKeywordListItem>
  )
}

const KeywordList: React.FC<KeywordListProp> = (props: KeywordListProp) => {
  const { value, deleteTag, disabled } = props

  return (
    <React.Fragment>
      {value.map((element, idx) => {
        return <KeywordListItem deleteTagAction={(e: React.MouseEvent<HTMLElement, MouseEvent>) => { e.stopPropagation(); deleteTag(idx); }} key={idx} keyword={element} disabled={disabled} />
      })}
    </React.Fragment>
  )
}

const updateHeight = (mainDiv: React.RefObject<HTMLDivElement>) => {
  if (mainDiv.current) {
    mainDiv.current.scrollTop = mainDiv.current.scrollHeight
  }
}

const Keywords: React.FC<Props> = (props: Props) => {
  const { onChange, disabled } = props
  const [value, setValue] = useState(props.value)
  const newInput = useRef<HTMLInputElement>(null)
  const mainDiv = useRef<HTMLDivElement>(null)
  const restArray = (value) ? value as string[] : []

  return (
    <StyledKeywords data-testid="main-input" onClick={focusInput(newInput)} ref={mainDiv}>
      {value && value.length > 0 &&
        <KeywordList
          data-testid="keyword-list"
          deleteTag={idx => {
            removeTag(onChange, setValue, restArray, idx)
          }}
          value={value}
          disabled={disabled}
        />}
      <InputContainer disabled={disabled} newInput={newInput}
        addWord={(word: string) => {
          if (mainDiv.current) { mainDiv.current.scrollLeft = 0 };
          updateValues(onChange, setValue, [...restArray, word])
        }}
        updateHeight={() => updateHeight(mainDiv)}
        removeWord={() => removeTag(onChange, setValue, restArray, restArray.length - 1)}
      />
    </StyledKeywords>
  )
}
Keywords.displayName = "Keywords"

export default Keywords
