import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { CircularProgress } from '@rmwc/circular-progress'
import '@rmwc/circular-progress/circular-progress.css'
import environment from '../relay/environment'
import { commitMutation } from 'react-relay'
import { MutationConfig, MutationParameters } from 'relay-runtime'
import Confirm from './Confirm'
import { ErrorBanner } from './NetworkErrors'
import { useNavigatorOnline } from '../hooks/useNavigatorOnline'

export enum ButtonActions {
  Create = 'create',
  Delete = 'delete',
  Edit = 'edit',
  Modify = 'modify',
  Publish = 'publish',
  Republish = 'republish',
  Schedule = 'schedule',
  Save = 'save',
}

export type ButtonProps = {
  buttonType?: ButtonActions,
  onClick?: () => void,
  disabled?: boolean,
  message?: string,
  draftType?: string,
  size?: number
}

const ButtonIcon = css`
  i {
    font-size: 13px;
    margin-right: 4px;
  }
`

const Disabled = css`
  background-color: ${props => props.theme.colors.primary.whiteSmoke};
  border-color: ${props => props.theme.colors.grays.ghost};
  color: ${props => props.theme.colors.grays.ghost};
  cursor: default;
`

const Purple = css`
  background-color: ${props => props.theme.colors.primary.purpleHeart};
  border-color: ${props => props.theme.colors.primary.purpleHeart};
  color: #ffffff;
`

const ButtonText = styled.span`
  text-transform: capitalize;
`
ButtonText.displayName = 'ButtonText'

const Wrapper = styled.button.attrs(props => ({
  disabled: props.disabled || false,
  type: 'button'
}))<{ size?: ButtonProps['size'] }>`
  display: flex;
  width: 7.3em;
  height: 2.8em;
  padding: 0 10px;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: solid 1px #4a4a4a;
  background-color: ${props => props.theme.colors.primary.whiteSmoke};
  color: #4a4a4a;
  cursor: pointer;
  font-size: ${props => props.theme.fontSizes[(props.size || 0) + 1]}px;
  user-select: none;
  ${ButtonIcon}
`
Wrapper.displayName = 'Wrapper'

const StyledButton = styled(Wrapper)`
  &:hover {
    ${Purple}
  }

  &:disabled {
    ${Disabled}
  }

  &:disabled:hover {
    ${Disabled}
  }
`
StyledButton.displayName = 'StyleButton'

const Loading = styled(Wrapper)`
  ${Purple}

  .rmwc-circular-progress {
    color: #ffffff;
  }
`
Loading.displayName = 'Loading'

const LoadingButton: React.FC = () => (
  <Loading disabled={true}>
    <CircularProgress size='xsmall'/>
  </Loading>
)

const Button: React.FC<ButtonProps> = (props) => {
  const { onClick, buttonType, disabled, children, size } = props

  return (
    <StyledButton onClick={onClick} disabled={disabled} size={size}>
      {buttonType &&
        <i className='material-icons'>{buttonType}</i>
      }
      <ButtonText>{buttonType || children}</ButtonText>
    </StyledButton>
  )
}

const ConfirmationButton: React.FC<ButtonProps> = (props) => {
  const [open, setOpen] = useState(false)
  const { onClick, disabled, buttonType, message } = props

  return (
    <React.Fragment>
      <StyledButton onClick={() => setOpen(true)} disabled={disabled}>
        <i className='material-icons'>{buttonType}</i>
        <ButtonText>{buttonType}</ButtonText>
      </StyledButton>
      {open && <Confirm open={open} close={() => setOpen(false)} onClick={onClick} message={message}/>}
    </React.Fragment>
  )
}

function ReturnButton(props: ButtonProps) {
  const { onClick, disabled, buttonType, draftType } = props

  const nameMap: {[property: string]: string} = { 'Article': 'story' }
  const confirmMessage = `Are you sure you want to ${buttonType} this ${nameMap[draftType!] || draftType?.toLowerCase()}?`

  if (buttonType === 'delete' || buttonType === 'publish' || buttonType === 'schedule') {
    return <ConfirmationButton onClick={onClick} disabled={disabled} buttonType={buttonType} message={confirmMessage}/>
  } else {
    return <Button onClick={onClick} disabled={disabled} buttonType={buttonType}/>
  }
}

export function MutationButton<T extends MutationParameters>(props: Omit<ButtonProps, 'onClick'> & MutationConfig<T>) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const { mutation, variables, disabled, buttonType, onCompleted, draftType, onError } = props
  const { isOnline } = useNavigatorOnline()

  const mutate = (variables: MutationConfig<T>['variables'], mutation: MutationConfig<T>['mutation']) => {
    setIsLoading(true)

    return commitMutation<T>(environment, {
      mutation,
      variables: variables || {},
      onCompleted: (props, errors) => {
        setIsLoading(false)
        onCompleted && onCompleted(props, errors)
        setError('')
      },
      onError: (error) => {
        onError && onError(error)
        setIsLoading(false)
        setError(`Unable to perform action: ${buttonType?.toUpperCase()}`)
      }
    })
  }

  return (
    <React.Fragment>
      {isLoading
      ? <LoadingButton/>
      : ReturnButton({onClick: () => mutate(variables, mutation), disabled: disabled || !isOnline, buttonType, draftType})}
      {(buttonType !== 'create') && <ErrorBanner message={error} onDismiss={() => setError('')}/>}
    </React.Fragment>
  )
}

export default Button
