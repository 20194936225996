import React, { CSSProperties } from 'react'
import Select, { components, ControlProps, ContainerProps, IndicatorProps, OptionProps, Props } from 'react-select'
import styled from 'styled-components'

const CustomStyles: any = {
  container: (styles: CSSProperties, { selectProps: { sharingSpace, lastOfType } }: ContainerProps<{}, true>) => ({
    display: sharingSpace ? 'inline-block' : 'block',
    marginRight: sharingSpace && !lastOfType ? '8px' : '0',
    verticalAlign: 'top',
    maxWidth: (() => {
      if (sharingSpace) {
        if (lastOfType) {
          return '64px'
        }
        return '184px'
      }
      return '100%'
    })(),
    width: sharingSpace ? '184px' : '100%'
  }),
  control: (styles: CSSProperties, { selectProps: { menuIsOpen, sharingSpace, lastOfType } }: ControlProps<{}, true>) => ({
    ...styles,
    height: 40,
    fontSize: 12,
    borderRadius: 2,
    borderColor: menuIsOpen ? '#5637db' : 'rgba(193, 194, 196, 0.8)',
    boxShadow: menuIsOpen ? 'none' : '0.2px 0.2px 1px 0.2px 0.2px 1px grey',
    backgroundColor: menuIsOpen ? '#5637db' : 'white',
    color: menuIsOpen ? 'white' : 'black',
    maxWidth: (() => {
      if (sharingSpace) {
        if (lastOfType) {
          return '100px'
        }
        return '184px'
      }
      return 'auto'
    })(),
    width: '100%',
    '&:hover': {
      backgroundColor: '#5637db',
      color: 'white',
    },
  }),
  input: (styles: CSSProperties) => ({
    color: 'white'
  }),
  dropdownIndicator: (styles: CSSProperties, { selectProps: { menuIsOpen } }: IndicatorProps<{}, true>) => ({
    padding: 3,
    transition: '.20s ease-out, color 0ms',
    transform: menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  menu: (styles: CSSProperties) => ({
    ...styles,
    position: 'relative',
    borderRadius: 2,
    margin: 0,
    animation: 'fade--in .10s ease-out',

    '@keyframes fade--in': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      }
    }
  }),
  menuList: (styles: CSSProperties) => ({
    ...styles,
    margin: 0,
    height: 'auto'
  }),
  option: (styles: CSSProperties, { isFocused }: OptionProps<{}, true>) => ({
    ...styles,
    fontSize: 12,
    backgroundColor: isFocused ? 'rgba(244, 245, 247, 0.88)' : '#fff',
    margin: 0,
    color: '#5c5d5f',
    paddingRight: '20px',
    position: 'relative',
    wordBreak: 'break-all',
    height: 'auto'
  }),
  placeholder: (styles: CSSProperties) => ({} as CSSProperties),
  singleValue: (styles: CSSProperties) => ({} as CSSProperties),
}

export const StyledCheck = styled.i`
  font-size: 16px;
  position: absolute;
  width: 20px;
  height: 100%;
  right: 0;
  top: 0;
  display: flex !important;
  align-items: center !important;
  color: ${(props) => props.theme.colors.primary.purpleHeart};
`

export const Option = (props: OptionProps<{}, false>) => {
  const { children, isSelected } = props
  return (
    <components.Option {...props}>
      {children}
      {isSelected && <StyledCheck className='material-icons'>check</StyledCheck>}
    </components.Option>
  )
}

const SingleSelect: React.FC<Props<{}>> = (props) => (
  <Select
    {...props}
    isSearchable
    components={{ Option }}
    styles={CustomStyles}
  />
)

export default SingleSelect
