/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MetatagType = "AVATAR" | "KEYWORDS" | "MULTI_SELECT" | "SINGLE_SELECT" | "TEXT" | "THUMBNAIL" | "%future added value";
export type TagField_taggable = {
    readonly tags: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
        readonly fixedOptions: ReadonlyArray<string> | null;
        readonly value: ReadonlyArray<string> | null;
        readonly type: MetatagType;
    }>;
    readonly " $refType": "TagField_taggable";
};
export type TagField_taggable$data = TagField_taggable;
export type TagField_taggable$key = {
    readonly " $data"?: TagField_taggable$data;
    readonly " $fragmentRefs": FragmentRefs<"TagField_taggable">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TagField_taggable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Tag",
      "kind": "LinkedField",
      "name": "tags",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fixedOptions",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
(node as any).hash = 'd273b179ed4a0e6d6aa9a298f0019bd8';
export default node;
