import React from 'react'
import styled from 'styled-components'
import Dialog from '@material-ui/core/Dialog'
import { ButtonProps } from './Button'

const StyledDialog = styled(Dialog)`
  text-align: center;

  .MuiPaper-root {
    box-shadow: none;
    border: 1px solid ${props => props.theme.colors.grays.solitude};
    border-radius: 2px;
    padding: 22px 21px;
    background: #ffffff;
  }
`

const StyledButton = styled.button`
  color: ${props => props.theme.colors.primary.nero};
  border: 1px solid #4E5155;
  border-radius: 3px;
  transition: 0.2s;
  outline: none;
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
  margin: 0 12px;
  padding: 8px 12px;

  &:hover {
    color: #ffffff;
    border-color: ${props => props.theme.colors.primary.purpleHeart};
    background-color: ${props => props.theme.colors.primary.purpleHeart};
  }
`

const StyledClose = styled.button`
  color: #000000;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.2s;
  margin: 0;
  padding: 0;
  font-size: 16px;

  &:hover {
    color: ${props => props.theme.colors.primary.purpleHeart};
  }
`

const ModalClose = styled.div`
  text-align: right;
`

const ModalBody = styled.div`
  padding: 47px 21px;
  background: #ffffff;
`

const ModalContent = styled.div`
  padding-bottom: 31px;
  color: ${props => props.theme.colors.primary.nero}
`

const Icon = styled.span`
  vertical-align: bottom;
`

type Props = {
  open: boolean
  close: () => void
  onClick: ButtonProps['onClick']
  message: ButtonProps['message']
}

const Confirm: React.FC<Props> = ({ open, close, onClick, message }) => (
  <StyledDialog open={open} onClose={close}>
    <ModalClose><StyledClose onClick={close}>&#10005;</StyledClose></ModalClose>
    <ModalBody>
      <ModalContent><Icon role="img" aria-label="up pointing index" aria-hidden="true">☝️</Icon> {message}</ModalContent>
      <div>
        <StyledButton onClick={onClick}>Yes</StyledButton>
        <StyledButton onClick={close} autoFocus>No</StyledButton>
      </div>
    </ModalBody>
  </StyledDialog>
)

export default Confirm
