import React, { useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { TopStoriesIndexQuery, TopStoriesIndexQueryResponse } from './__generated__/TopStoriesIndexQuery.graphql'
import { Wrapper } from '../Settings/Livestream'
import { AddButton as Button } from '../Settings/Metatags/Controls'
import Renderer from '../Index/PaginatedTable'
import Header from '../Settings/Header'
import TopStoriesCreate from './TopStoriesCreate'
import TopStoriesDelete from './TopStoriesDelete'
import TopStoriesList from './TopStoriesList'

const query = graphql`
  query TopStoriesIndexQuery {
    user {
      id
      role
    }
    organization {
      id
      topStoryLists {
        edges {
          node {
            user {
              firstName
              lastName
            }
            updatedAt
            id
            name
          }
        }
      }
    }
  }
`

export type Props = {
  query: TopStoriesIndexQueryResponse
  refreshCounter: number
  setRefreshCounter: React.Dispatch<React.SetStateAction<number>>
}

export type StoryToDelete = {
  id?: string // This ID is the ID needed for the server's graphql parameters
  name?: string
}

/**
 * Handles rendering the list of Top Stories, its styling, and its state functionality.
 */
export function TopStoriesIndex({ query: { organization, user }, refreshCounter = 0, setRefreshCounter }: Props) {
  /* state hooks */
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  // A message envelope for determining which Top Story in the list has been selected by the end user for deletion
  const [storyToDelete, setStoryToDelete] = useState<StoryToDelete>({})

  /* callbacks */
  const handleDelete = ({ id, name }: StoryToDelete) => {
    setStoryToDelete({ id: id!, name: name! })
    setShowDeleteModal(true)
  }

  const handleCloseDelete = () => {
    setStoryToDelete({})
    setShowDeleteModal(false)
  }

  return (
    <Wrapper>
      <Header title='Top Stories'>{user?.role === 'ADMIN' && <Button handleClick={() => setShowCreateModal(true)} text='Create' />}</Header>
      <TopStoriesList handleDelete={handleDelete} organization={organization} user={user} />
      <TopStoriesCreate open={showCreateModal} onClose={() => setShowCreateModal(false)} id={organization?.id} />
      <TopStoriesDelete
        handleClose={handleCloseDelete}
        id={storyToDelete.id!}
        name={storyToDelete.name!}
        open={showDeleteModal}
        triggerRefresh={() => void setRefreshCounter(refreshCounter + 1)}
      />
    </Wrapper>
  )
}

export default function StateWrapper() {
  /**
   * The purpose of this state wrapper is to handle refreshing the list when a Top Story is deleted. React-relay's QueryRenderer will
   *  always re-execute a query when its variables change or update; therefore, this wrapper injects a simple refresh counter to be
   *  incremented whenever a refresh needs to occur.
   *
   * Query Renderer: https://relay.dev/docs/v10.1.3/query-renderer/
   */
  const [refreshCounter, setRefreshCounter] = useState(0)
  const addProps = { refreshCounter, setRefreshCounter }

  return (
    <Renderer<TopStoriesIndexQuery>
      Component={(props) => <TopStoriesIndex {...props} {...addProps} />}
      query={query}
      variables={{ refreshCounter }}
    />
  )
}
