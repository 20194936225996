import React from 'react'
import SingleSelect from './SingleSelect'
import { TemplateSelect_draftable as Fragment } from './__generated__/TemplateSelect_draftable.graphql'
import { ChangeHandler } from '../../../hooks/useStuntman'


type Props = {
  draftable: Fragment
  setSelectedTemplate: React.Dispatch<React.SetStateAction<{
    versions: [{
      id: string;
      version: number;
    }];
    name: string;
    currentVersion?: number | undefined;
  } | null>>
  name: string
  selectedTemplate: {
    versions: [{ id: string, version: number }],
    name: string,
    currentVersion?: number
  } | null
  onChange: ChangeHandler
  disabled: boolean
}

const truncate = (str: string, length: number | null = null, ending: string | null = null) => {
  length = length ? length : 23
  ending = ending ? ending : '.....'
  return str.length > length ? str.substring(0, length - ending.length) + ending : str
}

const TemplateDropdown: React.FC<any> = (props: Props) => {
  const { draftable: { selectableTemplates, template }, setSelectedTemplate, name, selectedTemplate, onChange, disabled } = props
  const templateName = (template && template.name) || ''
  const options = selectableTemplates || []

  return (
    <SingleSelect {...props}
      placeholder={'Select A Template'}
      required={true}
      options={options}
      preview={templateName}
      value={options.filter((c: any) => c === selectedTemplate).map((word: any) => { return { ...word, name: truncate(word.name) } })}
      onChange={(e: any) => { setSelectedTemplate(e); onChange(name, e.latestDraft.id) }}
      getOptionLabel={(option: any) => option['name']}
      getOptionValue={(option: any) => option['latestDraft']['id']}
      isDisabled={disabled}
      sharingSpace={selectedTemplate ? true : false}
    />
  )
}

export default TemplateDropdown
