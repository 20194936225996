import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding-left: ${props => props.theme.space[9]}px;
  padding-right: ${props => props.theme.space[9]}px;
  padding-top: ${props => props.theme.space[6]}px;
  flex-grow: 1;
`

const Container = styled.div`
  padding-bottom: ${props => props.theme.space[4]}px;
  border-bottom: 1px solid ${props => props.theme.colors.grays.athensGray};
  margin-bottom: ${props => props.theme.space[6]}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Title = styled.h1`
  font-size: ${props => props.theme.fontSizes[5]}px;
  color: ${props => props.theme.colors.primary.nero};
  font-weight: ${props => props.theme.fontWeights[3]};
`

const Header: React.FC<{ title: string }> = ({ title, children }) => (
  <Wrapper>
    <Container>
      <Title>{title}</Title>
      {children}
    </Container>
  </Wrapper>
)

export default Header
