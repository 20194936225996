import React, { useState, FC } from 'react'
import { NavLink as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import { Button, Collapse, Divider, ListItem, makeStyles } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { Theme } from '../../theme'

export type NavItemProps = {
  depth: number
  href?: string
  IconComponent: (props: any) => JSX.Element
  open?: boolean
  title: string
  isAdmin: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.lightSecondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    '&:hover': {
      backgroundColor: theme.palette.background.hover
    }
  },
  buttonLeaf: {
    color: theme.palette.text.lightSecondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    '&:hover': {
      backgroundColor: theme.palette.background.hover
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.secondary.light,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.secondary.light
    }
  },
  divider: {
    backgroundColor: theme.palette.text.lightPrimary
  }
}))

const NavItem: FC<NavItemProps> = ({ children, depth, href, IconComponent, open = false, title, isAdmin }) => {
  const classes = useStyles()
  const [isOpen, setOpen] = useState<boolean>(open)
  const onClick = () => setOpen((prevOpen: boolean) => !prevOpen)

  const buttonProps = {
    className: children ? classes.button : clsx(classes.buttonLeaf, `depth-${depth}`),
    style: {
      paddingLeft: (depth > 0) ? 32 + 8 * depth : 8,
    }
  }

  const collapseButtonProps = {
    ...buttonProps,
    activeClassName: classes.active,
    component: RouterLink,
    exact: true,
    to: href
  }

  return (
    <>
      <ListItem
        className={clsx(children ? classes.item : classes.itemLeaf)}
        disableGutters
        key={title}
      >
        <Button {...(children ? {...buttonProps, onClick} : collapseButtonProps)}>
          <IconComponent className={classes.icon} size='20'/>
          <span className={classes.title}>{title}</span>
          {(children && isOpen) && <ExpandLessIcon data-testid='expand-less-icon'/>}
          {(children && !isOpen) && <ExpandMoreIcon/>}
        </Button>
        {children && <Collapse in={isOpen}>{children}</Collapse>}
      </ListItem>
      {title === 'Videos' && isAdmin && <Divider className={classes.divider}/>}
    </>
  )
}

export default NavItem
