import { useEffect } from 'react'

export default (when: boolean) => {
  useEffect(() => {
    const handleBeforeUnload = (event: Event) => {
      event.preventDefault()
      event.returnValue = true
    }

    if (when) {
      window.addEventListener('beforeunload', handleBeforeUnload)
    }

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [when])
}
