import React from 'react'
import environment from './relay/environment'
import LoggedOut from './components/LoggedOut'
import ErrorRenderer, { ErrorType } from './components/ErrorRenderer'
import { Helmet } from 'react-helmet'
import { QueryRenderer } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import { AuthenticateQuery, AuthenticateQueryResponse } from './__generated__/AuthenticateQuery.graphql'
import { BrowserRouter as Router } from 'react-router-dom'
import Loader from './components/Loader'
import Layout from './components/Layout'
import ValidateInvite from './components/Signin/CreatePassword/ValidateInvite'
import ResetPassword from './components/Signin/ResetPassword/ResetPassword'

const query = graphql`
  query AuthenticateQuery {
    device {
      user {
        email
        deletedAt
      }
      ...Layout_device
    }
  }
`

const renderApp = ({ props, error }: { props: AuthenticateQueryResponse | null; error: ErrorType | null }) => {
  const renderContents = () => {
    if (error) {
      return <ErrorRenderer message={(error && error.message) || 'Something went wrong!'} />
    }

    switch (new URL((window as any).location).pathname.split('/')[1]) {
      case 'invite':
        return <ValidateInvite />
      case 'resetpassword':
        return <ResetPassword passwordResetToken={new URL((window as any).location).pathname.split('/')[2]} />
    }

    if (!props) return <Loader />

    const { device } = props

    if (!device || !device.user || device?.user?.deletedAt) {
      return <LoggedOut />
    } else {
      return <Layout device={device} />
    }
  }

  return <Router>{renderContents()}</Router>
}

const Authenticate: React.FC = () => {
  return (
    <React.Fragment>
      <Helmet>
        <meta name='google-signin-response_type' content='code' />
        <meta name='google-signin-client_id' content={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}.apps.googleusercontent.com`} />
      </Helmet>
      <QueryRenderer<AuthenticateQuery> environment={environment} query={query} variables={{}} render={renderApp} />
    </React.Fragment>
  )
}

export default Authenticate
