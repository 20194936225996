import React, { useState } from 'react'
import styled from 'styled-components'
import { KeyObject } from './KeywordsInputContainer'

export type Dispatch<props> = React.Dispatch<React.SetStateAction<props>>

type FakeChangeEvent = {
  currentTarget: {
    value: string
    scrolling: boolean
  }
}

type InputProps = {
  getSuggestions: (query: string) => void
  newInput: React.RefObject<HTMLInputElement> | null
  addWord: (word: string) => void
  removeWord: () => void
  updateHeight: () => void
  setSuggestions: Dispatch<string[]>
}

const StyledInput = styled.input<{ inputWidth: string }>`
  width: ${props => props.inputWidth};
  max-width: 100%;
  border: none;
  word-wrap: wrap;
  :focus{
    outline: none;
  }
`
StyledInput.displayName = "StyledInput"

const calculateWidth = (e: React.ChangeEvent<HTMLInputElement> | FakeChangeEvent) => {
  let target = e.currentTarget.value
  let targetLength = target.length * 6

  return `${targetLength}px`
}

const handleOnChange = (e: React.ChangeEvent<HTMLInputElement> | FakeChangeEvent, setInputWidth: Dispatch<string>, setCurrrentValue: Dispatch<string>, getSuggestions: any, updateHeight: any) => {
  let target = e.currentTarget
  setCurrrentValue(target.value)
  if (!target.hasOwnProperty('scrolling')) { getSuggestions(target.value) }
  setInputWidth(calculateWidth(e))
  updateHeight()
}

const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, currentValue: string, setCurrrentValue: Dispatch<string>, addWord: any, removeWord: any, setSuggestions: Dispatch<string[]>) => {
  const { ENTER, BACKSPACE } = KeyObject

  switch (e.keyCode) {
    case ENTER:
      e.preventDefault()
      if (currentValue.trim() !== "") { addWord(currentValue.trim()) }
      setCurrrentValue(" ")
      setSuggestions([])
      break
    case BACKSPACE:
      if (currentValue === " " || currentValue === "") {
        e.preventDefault()
        setSuggestions([])
        removeWord()
      }
      break
  }
}

const Input: React.FC<InputProps> = ({ getSuggestions, newInput, addWord, removeWord, updateHeight, setSuggestions }) => {
  const [inputWidth, setInputWidth] = useState<string>("12px")
  const [currentValue, setCurrentValue] = useState<string>(" ")

  return (
    <StyledInput inputWidth={inputWidth}
      data-testid="new-input"
      onChange={(e) => handleOnChange(e, setInputWidth, setCurrentValue, getSuggestions, updateHeight)} value={currentValue}
      onKeyDown={(e) => handleKeyDown(e, currentValue, setCurrentValue, addWord, removeWord, setSuggestions)}
      ref={newInput}
    />
  )
}

export default Input
