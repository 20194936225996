import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'

type Props = {
  value: readonly string[] | null
  options: readonly string[]
  onChange: ((newValue: string[]) => void)
  disabled: boolean | undefined
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Item = styled.div<{ disabled: boolean | undefined }>`
  ${props => !props.disabled && css`
    cursor: pointer;
  `}
  flex-basis: 50%;
  max-width: 50%;
  > label {
    text-transform: capitalize;
    font-size: 12px;
    display: inline-block;
    ${props => props.disabled && css`
      cursor: auto;
    `}
  }
`

const Styled = styled.div`
  height: 16px;
  width: 16px;
  display: inline-block;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(238, 238, 238, 0.32);
  border: solid 2px rgba(151, 151, 151, 0.1);
  vertical-align: middle;
  margin-right: 8px;
  transition: all 0.2s ease-out;

  &.checked {
    background-color: ${props => props.theme.colors.primary.mediumSlateBlue};
    > i {
      font-size: 16px;
      color: white;
    }
    transition: all 0.2s ease-out;
  }
`

export function presentOption(option: string) {
  return option.split('-').join(' ')
}

export default function Checkbox(props: Props) {
  const { disabled, options, onChange } = props
  const [value, setValue] = useState(props.value)

  return (
    <Container>
      {options.map((option) => {
        const optionChecked = value?.includes(option)

        return (
          <Item key={option} disabled={disabled}
            onClick={(_mouseEvent) => {
              if (!disabled) {
                const currentValue = value || []
                const nextValue = optionChecked ? currentValue.filter(v => v !== option) : [...currentValue, option]

                onChange(nextValue)
                setValue(nextValue)
              }
            }}
          >
            <Styled key={`option-${option}`} className={optionChecked ? 'checked' : ''}>
              {optionChecked && <i className='material-icons'>check</i>}
            </Styled>
            <label key={`label-${option}`}>{presentOption(option)}</label>
          </Item>
        )
      })}
    </Container>
  )
}

