import React from 'react'
import styled from 'styled-components'

const PaddingWrapper = styled.div`
  padding: 41px 0;
  height: 100%;
  max-height: 883px;
  box-sizing: border-box;
`

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.grays.athensGray};
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px #f0f0f0;
  padding: 20px;
  border-radius: 30px 30px 50px 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

const Header = styled.div`
  height: 124px;
  background-color: #000000;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

const HeaderItems = styled.div`
  background-color: ${props => props.theme.colors.grays.athensGray};
  height: 30px;
  width: 50%;
  position: relative;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  display: flex;
  justify-content: center;
`

const Speaker = styled.div`
  background-color: #f5f5f5;
  width: 50px;
  height: 6px;
  border-radius: 10px;
  margin-left: 15px;
  margin-top: 6px;
`

const Camera = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${props => props.theme.colors.grays.whiteSmoke};
  border-radius: 50%;
  position: relative;
  margin-left: 12px;
  margin-top: 3px;  

  &:after {
    content: '';
    background-color: #fffafa;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    left: 3px;
    top: 3px;
  }
`

const Content = styled.div`
  width: 375px;
  height: 100%;
  background: #ffffff
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow-y: scroll;
  padding: 20px;
  box-sizing: border-box;
`

type Props = {
  html: string
}

const PhonePreview: React.FC<Props> = ({html}) => {
  return (
    <PaddingWrapper>
      <Wrapper>
        <Header>
          <HeaderItems>
            <Speaker/>
            <Camera/>
          </HeaderItems>
        </Header>
        <Content dangerouslySetInnerHTML={{__html: html}}/>
      </Wrapper>
    </PaddingWrapper>
  )
}

export default PhonePreview
