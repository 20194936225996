/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type TemplateIndexCreateTemplateMutationVariables = {
    organizationId: string;
};
export type TemplateIndexCreateTemplateMutationResponse = {
    readonly templateCreate: {
        readonly draft: {
            readonly draftable: {
                readonly id?: string;
            };
        };
    } | null;
};
export type TemplateIndexCreateTemplateMutation = {
    readonly response: TemplateIndexCreateTemplateMutationResponse;
    readonly variables: TemplateIndexCreateTemplateMutationVariables;
};



/*
mutation TemplateIndexCreateTemplateMutation(
  $organizationId: ID!
) {
  templateCreate(organizationId: $organizationId) {
    draft {
      draftable {
        __typename
        ... on Node {
          __isNode: __typename
          id
        }
      }
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    (v2/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TemplateIndexCreateTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TemplateCreateMutationPayload",
        "kind": "LinkedField",
        "name": "templateCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Draft",
            "kind": "LinkedField",
            "name": "draft",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "draftable",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TemplateIndexCreateTemplateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TemplateCreateMutationPayload",
        "kind": "LinkedField",
        "name": "templateCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Draft",
            "kind": "LinkedField",
            "name": "draft",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "draftable",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f17eef37c079eda6f2a834c9f2a0620a",
    "id": null,
    "metadata": {},
    "name": "TemplateIndexCreateTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation TemplateIndexCreateTemplateMutation(\n  $organizationId: ID!\n) {\n  templateCreate(organizationId: $organizationId) {\n    draft {\n      draftable {\n        __typename\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c9b86a79b850a0f6b749f7389a1e36d9';
export default node;
