/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ArticleIndexQueryVariables = {
    count: number;
    cursor?: string | null;
    query?: string | null;
};
export type ArticleIndexQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ArticleIndex_query">;
};
export type ArticleIndexQuery = {
    readonly response: ArticleIndexQueryResponse;
    readonly variables: ArticleIndexQueryVariables;
};



/*
query ArticleIndexQuery(
  $count: Int!
  $cursor: String
  $query: String
) {
  ...ArticleIndex_query_1jWD3d
}

fragment ArticleIndex_query_1jWD3d on Query {
  organization {
    id
    articles(first: $count, after: $cursor, query: $query) {
      edges {
        node {
          id
          ...DraftableCell_draftable
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
}

fragment DraftableCell_draftable on Draftable {
  __isDraftable: __typename
  __typename
  ... on Node {
    __isNode: __typename
    id
  }
  latestDraft {
    publishedAt
    scheduledFor
    updatedAt
    publicationStatus
    administrators {
      user {
        id
        first_name
        last_name
        avatar_url
      }
    }
    draftable {
      __typename
      name
      ... on Slugable {
        __isSlugable: __typename
        thumbnails(aspect_ratio: SQUARE) {
          small
        }
      }
      ... on Node {
        __isNode: __typename
        id
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v1/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ArticleIndexQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "ArticleIndex_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ArticleIndexQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "ArticleConnection",
            "kind": "LinkedField",
            "name": "articles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ArticleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Article",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v4/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Draft",
                            "kind": "LinkedField",
                            "name": "latestDraft",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "publishedAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "scheduledFor",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "updatedAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "publicationStatus",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "DraftAdministrator",
                                "kind": "LinkedField",
                                "name": "administrators",
                                "plural": true,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                      (v2/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "first_name",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "last_name",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "avatar_url",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "draftable",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": [
                                          {
                                            "kind": "Literal",
                                            "name": "aspect_ratio",
                                            "value": "SQUARE"
                                          }
                                        ],
                                        "concreteType": "Thumbnail",
                                        "kind": "LinkedField",
                                        "name": "thumbnails",
                                        "plural": false,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "small",
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": "thumbnails(aspect_ratio:\"SQUARE\")"
                                      }
                                    ],
                                    "type": "Slugable",
                                    "abstractKey": "__isSlugable"
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v2/*: any*/)
                                    ],
                                    "type": "Node",
                                    "abstractKey": "__isNode"
                                  }
                                ],
                                "storageKey": null
                              },
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isNode"
                          }
                        ],
                        "type": "Draftable",
                        "abstractKey": "__isDraftable"
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "filters": [
              "query"
            ],
            "handle": "connection",
            "key": "ArticleIndex_articles",
            "kind": "LinkedHandle",
            "name": "articles"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bdc86220ecb4cd577edec674872b7598",
    "id": null,
    "metadata": {},
    "name": "ArticleIndexQuery",
    "operationKind": "query",
    "text": "query ArticleIndexQuery(\n  $count: Int!\n  $cursor: String\n  $query: String\n) {\n  ...ArticleIndex_query_1jWD3d\n}\n\nfragment ArticleIndex_query_1jWD3d on Query {\n  organization {\n    id\n    articles(first: $count, after: $cursor, query: $query) {\n      edges {\n        node {\n          id\n          ...DraftableCell_draftable\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n  }\n}\n\nfragment DraftableCell_draftable on Draftable {\n  __isDraftable: __typename\n  __typename\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n  latestDraft {\n    publishedAt\n    scheduledFor\n    updatedAt\n    publicationStatus\n    administrators {\n      user {\n        id\n        first_name\n        last_name\n        avatar_url\n      }\n    }\n    draftable {\n      __typename\n      name\n      ... on Slugable {\n        __isSlugable: __typename\n        thumbnails(aspect_ratio: SQUARE) {\n          small\n        }\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '83eae18751ef19e6eae78de039d0ad93';
export default node;
