import React, { useState, ReactElement, Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'
import PreviewButton from './PreviewButton'
import PreviewMenu from './PreviewMenu'
import DesktopPreview from './DesktopPreview'
import TabletPreview from './TabletPreview'
import MobilePreview from './PhonePreview'
import { sanitize } from 'dompurify'
import { createFragmentContainer } from 'react-relay'
import { Preview_article as Fragment } from './__generated__/Preview_article.graphql'
import { graphql } from 'babel-plugin-relay/macro'

const PreviewWrapper = styled.div<{ currentPreview: CurrentPreviewType }>`
  width: 36px;
  right: 34px;
  top: 15px;
  height: ${props => (props.currentPreview !== null) ? 'calc(100% - 116px);' : 'auto'};
  grid-area: 1 / 1 / 1 / 1;
  position: absolute;
  min-height: auto;
  z-index: 2;
  ${({ currentPreview  }) => currentPreview  && `
    width: 100%;
    right: 0;
    top: 0;
    height: calc(100% - 72px);
  `}
`

const ButtonsContainer = styled.div<{ currentPreview: CurrentPreviewType }>`
  ${({ currentPreview  }) => currentPreview  && `
    width: 36px;
    position: absolute;
    right: 34px;
    top: 15px;
  `}
  background-color: ${props => props.theme.colors.grays.solitude};
  border-radius: 2px;
  box-shadow: 0 2px 1px 0 #e7e8ea;
`

const PreviewContainer = styled.div<{ currentPreview: CurrentPreviewType }>`
  background-color: #ffffff;
  display: ${props => (props.currentPreview !== null) ? 'flex' : 'none'};
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow: auto;

  img {
    width: 100%;
    margin-bottom: 1em;
  }

  em:after {
    display: block;
	  white-space: pre;
    content: " ";
  }

  video {
    width: 100%;
    margin-bottom: 1em;
  }

  .block {
    margin-bottom: 1em;
  }
`

const PreviewButtonsContainer = styled.div<{ isOpen: boolean }>`
  overflow: ${props => (props.isOpen) ? 'visible' : 'hidden'};
  transition: height 0.15s ease-out;
  position: relative;
  &:hover {
    div {
      &:hover {
        i {
          color: ${props => props.theme.colors.grays.brightGray};
        }
      }
    }
  }
  &::before {
    content: '';
    transition: height 0.15s ease-out;
    background: #ffffff;
    width: 38px;
    height: ${props => (props.isOpen) ? '0' : '104'}px;
    position: absolute;
    z-index: 2;
    bottom: -3px;
    left: -1px;
  }
`

type CurrentPreviewType = string | null

const devices = [
  {
    type: 'Phone',
    icon: 'smartphone'
  }, {
    type: 'Tablet',
    icon: 'tablet_mac'
  }, {
    type: 'Desktop',
    icon: 'desktop_mac'
  }
]

type PreviewButtonRenderer = (
  devices: {type: string, icon: string}[],
  setCurrentPreview: Dispatch<SetStateAction<string | null>>,
  currentPreview: CurrentPreviewType
) => JSX.Element[]

const renderPreviewButton: PreviewButtonRenderer = (devices, setCurrentPreview, currentPreview) => {
  return (
    devices.map((device, index) => {
      return (
        <PreviewButton
          key={index}
          setCurrentPreview={setCurrentPreview}
          deviceType={device.type}
          deviceIcon={device.icon}
          isActive={device.type === currentPreview}
        />
      )
    })
  )
}

const generateHTML = (article: Fragment): string => {
  let html: Array<string> = new Array(3)

  const getImageElement = (source: string | null) => {
    if (source !== null)  {
      html[1] = `<img src=${source} />`
    }
  }

  const getVideoElement = (source: Partial<Fragment>) => {
    const urls = source?.video?.video_urls|| source?.videoFile?.videoUrls
    if (urls) {
      html[1] = `<video src=${urls.find(urlType => urlType!.mime === 'video/mp4')?.url} controls=true></video>`
    }
  }

  Object.keys(article).forEach((key) => {
    switch (key) {
      case 'headline':
        html[0] = `<h1>${article[key]}</h1>`
        break
      case 'heroImageUrl':
        getImageElement(article[key])
        break
      case 'video':
        getVideoElement(article)
        break
      case 'videoFile':
        getVideoElement(article)
        break
      case 'html':
        html[2] = sanitize(article[key])
        break
    }
  })

  return html.join('')
}

const renderPreview = (device: CurrentPreviewType, article: Fragment) => {
  type PreviewDevicesTypes = {
    [key: string]: ReactElement
  }

  const previewDevices : PreviewDevicesTypes = {
    "Phone": <MobilePreview html={generateHTML(article)}/>,
    "Tablet": <TabletPreview html={generateHTML(article)}/>,
    "Desktop": <DesktopPreview html={generateHTML(article)}/>
  }

  if (device !== null) {
    return previewDevices[device]
  }
}

type Props = {
  article: Fragment
}

const Preview = createFragmentContainer(
  (props: Props) => {
    const [isButtonContainerOpen, setIsButtonContainerOpen] = useState<boolean>(false)
    const [currentPreview, setCurrentPreview] = useState<CurrentPreviewType>(null)

    const toggleButtonContainer = () => {
      setIsButtonContainerOpen(!isButtonContainerOpen)
    }

    return (
      <PreviewWrapper currentPreview={currentPreview}>
        <ButtonsContainer currentPreview={currentPreview}>
          <PreviewMenu
            isButtonContainerOpen={isButtonContainerOpen}
            toggleButtonContainer={toggleButtonContainer}
            setCurrentPreview={setCurrentPreview}
          />
          <PreviewButtonsContainer isOpen={isButtonContainerOpen}>
            {isButtonContainerOpen && renderPreviewButton(devices, setCurrentPreview, currentPreview)}
          </PreviewButtonsContainer>
        </ButtonsContainer>
        <PreviewContainer currentPreview={currentPreview}>
          {renderPreview(currentPreview, props.article)}
        </PreviewContainer>
      </PreviewWrapper>
    )
  },
  {
    article: graphql`
      fragment Preview_article on Article {
        headline
        heroImageUrl
        video {
          video_urls {
            url
            mime
          }
        }
        videoFile {
          videoUrls {
            url
            mime
          }
        }
        html
      }
    `
  }
)
Preview.displayName = 'Preview'
export default Preview
