export type Image = 'image'
export type Video = 'video'
export type UploadResource = Image | Video

export enum UploadResources {
  IMAGE = 'image',
  VIDEO = 'video'
}

export default UploadResources

export enum ErrorUploadReasons {
  // Error reasons
  RESOLUTION = 'resolution',
  HEIGHT = 'height',
  WIDTH = 'width',
  INVALID = 'invalid'
}
