import React, { useContext } from 'react'
import styled from 'styled-components'
import AutosizeTextarea from 'react-autosize-textarea'
import { Context, ContextType, InputProps, Field } from '../Form'

const StyledTextarea = styled(AutosizeTextarea)`
  border: 0;
  resize: none;
  width: 100%;
  font-size: 32px;
  outline: none;

  ::placeholder {
    color: ${props => props.theme.colors.grays.aluminium};
  }
`

export default function Title(props: InputProps) {
  const { model, onChange, disabled, errors, warnings } = useContext(Context) as ContextType
  const { name } = props

  return (
    <Field name={name} errors={errors.for(name)} warnings={warnings.for(name)}>
      <StyledTextarea
        {...props}
        disabled={disabled}
        value={model[props.name] || ''}
        onChange={onChange}
      />
    </Field>
  )
}
