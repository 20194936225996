import React from 'react'
import { createFragmentContainer } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import * as cell from './Cell'
import moment from 'moment'
import { SlugableCell_slugable as Response } from './__generated__/SlugableCell_slugable.graphql'
import 'moment-timezone'

const Header: React.FC = () => (
  <cell.HeaderRow>
    <div role='columnheader' />
    <div role='columnheader' />
    <div role='columnheader'>Title</div>
    <div role='columnheader'>Last Updated</div>
  </cell.HeaderRow>
)

type Props = {
  slugable: Response,
  index: number
}

const Cell: React.FC<Props> = ({ slugable, index }) => {
  const {
    title,
    thumbnails,
    updated_at,
  } = slugable
  const dateFormat = 'MMM D, YYYY @ h:mma z'

  return (
    <cell.Row index={index}>
      <cell.Status role='cell' color={cell.StatusColor.PUBLISHED} aria-hidden={true} />
      <cell.Thumbnail src={thumbnails?.small || undefined} />
      <div role='cell'>
        {title || <em>Unnamed</em>}
      </div>
      <div role='cell'>{moment(updated_at as string).format(dateFormat)}</div>
    </cell.Row>
  )
}

const Container = createFragmentContainer(
  Cell,
  {
    slugable: graphql`
      fragment SlugableCell_slugable on Slugable {
        updated_at
        title
        thumbnails(aspect_ratio: SQUARE) {
          small
        }
      }
    `
  }
)

export { Header, Container as Cell }
