/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type OrganizationInput = {
    name?: string | null;
    parentId?: unknown | null;
    settings?: OrganizationSettingsInput | null;
    slug?: string | null;
};
export type OrganizationSettingsInput = {
    synacor?: SynacorSettingsInput | null;
    live?: LiveSettingsInput | null;
};
export type SynacorSettingsInput = {
    key?: string | null;
    secret?: string | null;
    service?: string | null;
    pickerKey?: string | null;
    pickerId?: string | null;
};
export type LiveSettingsInput = {
    free: boolean;
};
export type LivestreamMutationVariables = {
    id: string;
    input: OrganizationInput;
};
export type LivestreamMutationResponse = {
    readonly organizationUpdate: {
        readonly organization: {
            readonly settings: {
                readonly synacor: {
                    readonly key: string | null;
                    readonly secret: string | null;
                    readonly service: string | null;
                    readonly pickerKey: string | null;
                    readonly pickerId: string | null;
                } | null;
                readonly live: {
                    readonly free: boolean;
                } | null;
            };
        };
        readonly errors: ReadonlyArray<{
            readonly field: string;
            readonly messages: ReadonlyArray<string>;
        }>;
    } | null;
};
export type LivestreamMutation = {
    readonly response: LivestreamMutationResponse;
    readonly variables: LivestreamMutationVariables;
};



/*
mutation LivestreamMutation(
  $id: ID!
  $input: OrganizationInput!
) {
  organizationUpdate(id: $id, input: $input) {
    organization {
      settings {
        synacor {
          key
          secret
          service
          pickerKey
          pickerId
        }
        live {
          free
        }
      }
      id
    }
    errors {
      field
      messages
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "OrganizationSettings",
  "kind": "LinkedField",
  "name": "settings",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SynacorSettings",
      "kind": "LinkedField",
      "name": "synacor",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secret",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "service",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pickerKey",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "pickerId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LiveSettings",
      "kind": "LinkedField",
      "name": "live",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "free",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Error",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "messages",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LivestreamMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganizationUpdateMutationPayload",
        "kind": "LinkedField",
        "name": "organizationUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LivestreamMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "OrganizationUpdateMutationPayload",
        "kind": "LinkedField",
        "name": "organizationUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3040ad962e6441cf4587a8414de763ee",
    "id": null,
    "metadata": {},
    "name": "LivestreamMutation",
    "operationKind": "mutation",
    "text": "mutation LivestreamMutation(\n  $id: ID!\n  $input: OrganizationInput!\n) {\n  organizationUpdate(id: $id, input: $input) {\n    organization {\n      settings {\n        synacor {\n          key\n          secret\n          service\n          pickerKey\n          pickerId\n        }\n        live {\n          free\n        }\n      }\n      id\n    }\n    errors {\n      field\n      messages\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fe0bdac0a0a3bca645d0cf934b368fc6';
export default node;
