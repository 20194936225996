import React from 'react'
import Dashboard from './Dashboard'
import environment from '../relay/environment'
import Loader from './Loader'
import { graphql } from 'babel-plugin-relay/macro'
import { QueryRenderer } from 'react-relay'
import { DashboardRendererQuery } from './__generated__/DashboardRendererQuery.graphql'

const DashboardRenderer: React.FC = () => {
  return (
    <QueryRenderer<DashboardRendererQuery> environment={environment} query={graphql`
        query DashboardRendererQuery {
          user {
            dashboardToken
          }
        }`}
      variables={{}}
      render={({ props }) => {
        return props && props.user?.dashboardToken ? <Dashboard dashboardToken={props.user.dashboardToken} /> : <Loader />
      }}
    />
  )
}

export default DashboardRenderer
