/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type TopStoryListInput = {
    max?: number | null;
    min?: number | null;
    name?: string | null;
    stories?: Array<TopStoryListStoryInput> | null;
    tags?: Array<TagInput> | null;
};
export type TopStoryListStoryInput = {
    story: string;
    order: number;
};
export type TagInput = {
    source: unknown;
    value: Array<string>;
};
export type TopStoriesCreateMutationVariables = {
    organizationId: string;
    input: TopStoryListInput;
};
export type TopStoriesCreateMutationResponse = {
    readonly topStoryListCreate: {
        readonly topStoryList: {
            readonly id: string;
            readonly name: string;
        } | null;
        readonly errors: ReadonlyArray<{
            readonly field: string;
            readonly messages: ReadonlyArray<string>;
        }>;
    } | null;
};
export type TopStoriesCreateMutation = {
    readonly response: TopStoriesCreateMutationResponse;
    readonly variables: TopStoriesCreateMutationVariables;
};



/*
mutation TopStoriesCreateMutation(
  $organizationId: ID!
  $input: TopStoryListInput!
) {
  topStoryListCreate(organizationId: $organizationId, input: $input) {
    topStoryList {
      id
      name
    }
    errors {
      field
      messages
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      },
      {
        "kind": "Variable",
        "name": "organizationId",
        "variableName": "organizationId"
      }
    ],
    "concreteType": "TopStoryListCreateMutationPayload",
    "kind": "LinkedField",
    "name": "topStoryListCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TopStoryList",
        "kind": "LinkedField",
        "name": "topStoryList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "messages",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TopStoriesCreateMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TopStoriesCreateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e32dbc306bbf685ffdd6a88a5702dd8f",
    "id": null,
    "metadata": {},
    "name": "TopStoriesCreateMutation",
    "operationKind": "mutation",
    "text": "mutation TopStoriesCreateMutation(\n  $organizationId: ID!\n  $input: TopStoryListInput!\n) {\n  topStoryListCreate(organizationId: $organizationId, input: $input) {\n    topStoryList {\n      id\n      name\n    }\n    errors {\n      field\n      messages\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '71c11dc5d308a3195fb222f9baf98fe5';
export default node;
