import React, { FC } from 'react'
import { QueryRenderer } from 'react-relay'
import environment from '../../../relay/environment'
import { graphql } from 'babel-plugin-relay/macro'
import { ValidateInviteQuery as Query } from './__generated__/ValidateInviteQuery.graphql'
import Loader from '../../Loader'
import CreatePassword from './CreatePassword'
import ErrorRenderer from '../../ErrorRenderer'

const query = graphql`
  query ValidateInviteQuery($confirmationToken: String!){
    userValidateInvite(confirmationToken: $confirmationToken) {
      user {
        id
      }
      error
    }
  }
 `

const ValidateInvite: FC = () => (
  <QueryRenderer<Query>
    environment={environment}
    query={query}
    variables={{ confirmationToken: window.location.href.split('token=')[1] }}
    render={({ props }) => {
      if (props && props.userValidateInvite?.user?.id) {
        return <CreatePassword id={props.userValidateInvite.user.id}/>
      }
      if (props && props.userValidateInvite?.error) {
        return <ErrorRenderer message={(props.userValidateInvite.error) || 'Something went wrong!'}/>
      }
        return <Loader/>
    }}
  />
)

export default ValidateInvite