import { Network } from 'relay-runtime'
import config from '../util/config'

export function headers() {
  let headers: { [k: string]: string } = {
    'Content-Type': 'application/json'
  }

  const deviceToken = window.localStorage && window.localStorage.getItem('deviceToken')
  if (deviceToken) {
    headers['Authorization'] = "Token " + deviceToken
  }

  return headers
}

const serverMap: { [environment: string]: string } = {
  'development': 'http://localhost:3000',
  'staging': 'https://api-staging.cheddar.com',
  'production': 'https://api.cheddar.com',
}
export const server: string = serverMap[config('REACT_APP_GRAPHQL_ENV') || 'development']

function fetchQuery(
  operation: any,
  variables: any,
  cacheConfig: any,
  uploadables: any,
) {
  return fetch(`${server}/graphql`, {
    method: 'POST',
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
    headers: headers()
  }).then(response => {
    if (response.status === 401 && window.localStorage.getItem('deviceToken')) {
      window.localStorage.removeItem('deviceToken')
    }
    return response.json()
  })
  .catch(error => {
    return {}
  })
}

export default Network.create(fetchQuery)
