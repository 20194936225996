import React, { ReactElement } from 'react'
import styled from 'styled-components/macro'

const StyledSection = styled.div`
  padding: 10px 0;
  background-color: ${(props) => props.theme.colors.grays.aliceBlue};

  h3 {
    margin-top: 0;
    text-transform: capitalize;
    text-align: center;
  }
`

const SectionBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  > div {
    width: 40%;
    min-width: 140px;
  }

  label {
    display: flex;
  }
`

type Props = {
  children?: (false | ReactElement)[] | ReactElement
  name: string
}

const Section: React.FC<Props> = ({ name, children }): ReactElement | null => {
  if (![children].flat().some((c) => c)) return null

  return (
    <StyledSection>
      <h3>{name}</h3>
      <SectionBody>{children}</SectionBody>
    </StyledSection>
  )
}

export default Section
