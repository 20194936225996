/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type LivestreamQueryVariables = {};
export type LivestreamQueryResponse = {
    readonly organization: {
        readonly id: string;
        readonly settings: {
            readonly synacor: {
                readonly key: string | null;
                readonly pickerId: string | null;
                readonly pickerKey: string | null;
                readonly secret: string | null;
                readonly service: string | null;
            } | null;
            readonly live: {
                readonly free: boolean;
            } | null;
        };
    };
};
export type LivestreamQuery = {
    readonly response: LivestreamQueryResponse;
    readonly variables: LivestreamQueryVariables;
};



/*
query LivestreamQuery {
  organization {
    id
    settings {
      synacor {
        key
        pickerId
        pickerKey
        secret
        service
      }
      live {
        free
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Organization",
    "kind": "LinkedField",
    "name": "organization",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationSettings",
        "kind": "LinkedField",
        "name": "settings",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SynacorSettings",
            "kind": "LinkedField",
            "name": "synacor",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "key",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pickerId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pickerKey",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "secret",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "service",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "LiveSettings",
            "kind": "LinkedField",
            "name": "live",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "free",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LivestreamQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LivestreamQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "586dcaa836a6845104ed2d4958b0612e",
    "id": null,
    "metadata": {},
    "name": "LivestreamQuery",
    "operationKind": "query",
    "text": "query LivestreamQuery {\n  organization {\n    id\n    settings {\n      synacor {\n        key\n        pickerId\n        pickerKey\n        secret\n        service\n      }\n      live {\n        free\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a818dc9f11c8c8699014fe47797d529c';
export default node;
