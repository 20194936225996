import React, { FC, useState } from 'react'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { graphql } from 'babel-plugin-relay/macro'
import environment from '../../../relay/environment'
import { Box, Grid, TextField } from '@material-ui/core'
import { createFragmentContainer, QueryRenderer } from 'react-relay'
import { FormikContextType, FormikValues, useFormikContext } from 'formik'
import { useRouteMatch } from 'react-router-dom'
import { UserEditQuery as Query } from './__generated__/UserEditQuery.graphql'
import { UserEdit_node as NodeResponse } from './__generated__/UserEdit_node.graphql'
import { UserEdit_user as UserResponse } from './__generated__/UserEdit_user.graphql'
import { UserEditMutationResponse as MutationResponse } from './__generated__/UserEditMutation.graphql'
import { Wrapper } from '../Livestream'
import TagRenderer, { Props as TagRendererProps } from './TagRenderer'
import { formatFields } from '../formatFields'
import Form, { formatErrors, handleNonFieldErrors, OnCompletedOptions as FormProps } from '../Form'
import Schema from './Schema'
import Controls from '../Controls'
import Header from '../Header'
import Loader from '../../Loader'
import NotFound from '../../NotFound'
import RoleSelect from './RoleSelect'
import StaticAuthorFields from '../Authors/StaticFields'
import StaticFields from './StaticFields'
import UserAuthorToggle from './AuthorToggle'

const userEditMutation = graphql`
  mutation UserEditMutation($id: ID!, $input: UserInput!) {
    userUpdate(id: $id, input: $input) {
      user {
        ...UserEdit_node
      }
      errors {
        field
        messages
      }
    }
  }
`

const query = graphql`
  query UserEditQuery($id: ID!) {
    node(id: $id) {
      ...UserEdit_node
    },
    user {
      ...UserEdit_user
    }
  }
`

interface OnCompleteProps extends FormProps {
  values: MutationResponse
  formValues?: FormikValues
}

export interface FieldsProps {
  isAdmin: boolean
  isAdminOnOwnPage: boolean
  node: NodeResponse
  refetch: (node: NodeResponse) => void
  showPasswordField: boolean
  scopeType: string
}

const initialValues = (user: NodeResponse) => ({
  id: user.id,
  input: {
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    password: '',
    role: user.role,
    author:
      user.author === null
        ? null
        : {
            byline: user.author?.byline,
            slug: user.author?.slug,
            tags: user.author?.tags.reduce((acc, tag) => ({ ...acc, [tag.name]: [tag.id, tag.value] }), {})
          }
  }
})

const validationSchema = Yup.object().shape({
  id: Yup.string().max(255).required(),
  input: Yup.object().shape({
    password: Yup.string().min(8, 'Password must be at least 8 characters'),
    email: Yup.string().email().required('Email is required'),
    first_name: Yup.string().max(255).required('First name is required').nullable(),
    last_name: Yup.string().max(255).required('Last name is required').nullable()
  })
})

const formatValues = (values: FormikValues, isAdmin: boolean) => {
  let tags = []
  for (let tag in values?.input?.author?.tags) {
    tags.push({ source: values?.input?.author?.tags[tag][0], value: values?.input?.author?.tags[tag][1] })
  }

  const author = { author: { ...values?.input?.author, tags: tags.filter((tag) => tag?.value && tag?.value?.length > 0) } }
  const payload = { ...values, input: { ...values.input, ...author } }
  if (!isAdmin) {
    delete payload?.input?.role
  }

  if (!Boolean(values?.input?.password)) {
    delete payload?.input?.password
  }

  if (!values.input.author) {
    delete payload?.input?.author
  }

  return payload
}

const Fields: FC<FieldsProps> = ({ node, showPasswordField, isAdmin, isAdminOnOwnPage, refetch, scopeType }) => {
  const { values, errors, touched, handleBlur, handleChange }: FormikContextType<Schema> = useFormikContext()

  return (
    <Wrapper>
      <Header title='Update User Profile' />
      <Box m={8}>
        <Grid container direction='row' alignItems='flex-start' justify='space-between'>
          <Grid container item direction='column' md={4} xs={7}>
            <StaticFields />
            {showPasswordField && (
              <Box mt={1} mb={4} height={55}>
                <TextField
                  fullWidth
                  id='input.password'
                  label='Password'
                  name='input.password'
                  error={touched?.input?.password && Boolean(errors?.input?.password)}
                  helperText={touched?.input?.password && errors?.input?.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.input.password}
                  variant='outlined'
                  type='password'
                />
              </Box>
            )}
            <Box mt={1} mb={3}>
              {isAdmin && <RoleSelect isAdminOnOwnPage={isAdminOnOwnPage} />}
            </Box>
            <Box mt={1} mb={2}>
              {isAdmin && <UserAuthorToggle node={node} refetch={refetch} />}
            </Box>
          </Grid>
          <Grid container item direction='column' md={4} xs={7}>
            {Boolean(node?.author?.active) && (
              <>
                <StaticAuthorFields />
                {node?.author?.tags.map((tag: TagRendererProps['tag']) => (
                  <Box mt={1} mb={4} key={tag.id}>
                    <TagRenderer tag={tag} scopeType={scopeType} />
                  </Box>
                ))}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
      <Controls redirectTo='users' isAdmin={isAdmin} />
    </Wrapper>
  )
}

const UserEdit = createFragmentContainer(
  (props: { node: NodeResponse; user: UserResponse }) => {
    const [node, setNode] = useState(props.node)
    const { enqueueSnackbar } = useSnackbar()
    const isLoggedInUser = node.id === props.user.id
    const isLoggedInUserAdmin = props.user.role! && props.user.role === 'ADMIN'

    const onComplete = ({ values: { userUpdate }, setErrors, formValues }: OnCompleteProps) => {
      if (!userUpdate?.errors.length) {
        enqueueSnackbar('Information successfully updated.', { variant: 'success' })
      } else {
        handleNonFieldErrors(userUpdate.errors, formatFields(formValues!.input), enqueueSnackbar)
        setErrors({ input: formatErrors(userUpdate.errors) })
      }
    }

    return (
      <Form
        formatValues={(values) => formatValues(values, isLoggedInUserAdmin)}
        mutation={userEditMutation}
        onComplete={onComplete}
        validationSchema={validationSchema}
        initialValues={initialValues(node)}
      >
        <Fields
          refetch={(userNode) => setNode(userNode)}
          node={node}
          showPasswordField={isLoggedInUser}
          isAdmin={isLoggedInUserAdmin}
          isAdminOnOwnPage={isLoggedInUserAdmin && isLoggedInUser}
          scopeType={node?.author?.__typename!}
        />
      </Form>
    )
  },
  {
    node: graphql`
      fragment UserEdit_node on User {
        id
        email
        first_name
        last_name
        role
        author {
          __typename
          active
          byline
          slug
          tags {
            id
            name
            fixedOptions
            value
            type
          }
        }
      },
    `,
    user: graphql`
      fragment UserEdit_user on User {
        id
        role
      }
    `
  }
)

const Renderer: FC<{ id: string; role: UserResponse['role'] }> = ({ id, role }) => {
  const match = useRouteMatch('/users/:id')

  if (match.params.id === id || role === 'ADMIN') {
    return (
      <QueryRenderer<Query>
        environment={environment}
        query={query}
        variables={match.params}
        render={({ props }) => {
          if (props && props.node && props.user) {
            return <UserEdit node={props.node} user={props.user} />
          } else {
            return <Loader />
          }
        }}
      />
    )
  } else {
    return <NotFound />
  }
}

export default Renderer
