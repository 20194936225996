import React, { FC, MouseEvent } from 'react'
import { IconButton, TableCell, TableRow } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import FindReplaceIcon from '@material-ui/icons/FindReplace'
import AddBoxIcon from '@material-ui/icons/AddBox'
import { StoryId } from './types'

export type ActionButtonsProps = StoryId & {
  onDelete: (event: MouseEvent) => void
  onReplace: (event: MouseEvent) => void
}

export const ActionButtons: FC<ActionButtonsProps> = ({ id, onDelete, onReplace }) => (
  <TableCell align='right' component='div'>
    <IconButton id={id} onClick={onDelete} title='Delete'>
      <DeleteIcon />
    </IconButton>
    <IconButton id={id} onClick={onReplace} title='Replace'>
      <FindReplaceIcon />
    </IconButton>
  </TableCell>
)

export type AddStoryButtonProps = {
  clickHandler: () => void
}

export const AddStoryButton: FC<AddStoryButtonProps> = ({ clickHandler }) => (
  <TableRow hover component='div' style={{ display: 'table', width: '100%'}}>
    <TableCell style={{ width: '5%' }} component='div'>
      <IconButton title='Add story' onClick={clickHandler}>
        <AddBoxIcon />
      </IconButton>
    </TableCell>
    <TableCell colSpan={3} component='div'>Add a story</TableCell>
  </TableRow>
)
