import React, { useState, useRef, useCallback } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import useClickOutside from '../../../hooks/useClickOutside'

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  cursor: text;
`

const Input = styled.input`
  height: 10%;
  width: 90%;
  border: none;
  border-bottom: solid 1px rgba(110, 117, 130, 0.75);
  outline: none;
  font-size: 15px;
  line-height: 1.2;
  background-color: transparent;
  color: ${props => props.theme.colors.primary.blackRussian};

  &::placeholder {
    font-size: ${props => props.theme.fontSizes[2]}px;
    font-style: italic;
    line-height: 1.29;
    color: ${props => props.theme.colors.grays.grayChateau};
  }
`

type SearchProps = {
  onDismiss: () => void
  renderListComponent: any
}

const Search: React.FC<SearchProps> = (props) => {
  const { onDismiss, renderListComponent } = props
  const [search, setSearch] = useState('')
  const clickOutsideRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  useClickOutside(clickOutsideRef, onDismiss)

  const debounced = _.debounce((value) => setSearch(value), 500)

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    debounced(event.currentTarget.value)
  }

  const focusSearch = useCallback((() => inputRef.current?.focus()), [inputRef])

  return (
    <Container ref={clickOutsideRef} onClick={focusSearch}>
      <Input
        ref={inputRef}
        autoFocus={true}
        name='search'
        type='search'
        autoComplete='off'
        placeholder='search for content'
        onChange={onChange}
      />
      {search && renderListComponent(search)}
    </Container>
  )
}

export default Search
