import React, { FC } from 'react'
import { Box, Typography } from '@material-ui/core'

const ModalHeader: FC<{ title: string }> = ({ title }) => (
  <Box p={3} pb={0}>
    <Typography align='left' gutterBottom variant='h3'>
      {title}
    </Typography>
  </Box>
)

export default ModalHeader
