/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type VideoIndexQueryVariables = {
    count: number;
    cursor?: string | null;
    query?: string | null;
};
export type VideoIndexQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"VideoIndex_query">;
};
export type VideoIndexQuery = {
    readonly response: VideoIndexQueryResponse;
    readonly variables: VideoIndexQueryVariables;
};



/*
query VideoIndexQuery(
  $count: Int!
  $cursor: String
  $query: String
) {
  ...VideoIndex_query_1jWD3d
}

fragment VideoFileCell_videoFile on VideoFile {
  createdAt
  duration
  filename
  thumbnails(aspect_ratio: HD) {
    small
  }
}

fragment VideoIndex_query_1jWD3d on Query {
  organization {
    videoFiles(first: $count, after: $cursor, query: $query) {
      edges {
        node {
          id
          ...VideoFileCell_videoFile
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = {
  "kind": "Variable",
  "name": "query",
  "variableName": "query"
},
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VideoIndexQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "VideoIndex_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VideoIndexQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "VideoFileConnection",
            "kind": "LinkedField",
            "name": "videoFiles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "VideoFileEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "VideoFile",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "duration",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "filename",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "aspect_ratio",
                            "value": "HD"
                          }
                        ],
                        "concreteType": "Thumbnail",
                        "kind": "LinkedField",
                        "name": "thumbnails",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "small",
                            "storageKey": null
                          }
                        ],
                        "storageKey": "thumbnails(aspect_ratio:\"HD\")"
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v2/*: any*/),
            "filters": [
              "query"
            ],
            "handle": "connection",
            "key": "VideoFileIndex_videoFiles",
            "kind": "LinkedHandle",
            "name": "videoFiles"
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9e8f304da31e21d2ed53c97edb1084df",
    "id": null,
    "metadata": {},
    "name": "VideoIndexQuery",
    "operationKind": "query",
    "text": "query VideoIndexQuery(\n  $count: Int!\n  $cursor: String\n  $query: String\n) {\n  ...VideoIndex_query_1jWD3d\n}\n\nfragment VideoFileCell_videoFile on VideoFile {\n  createdAt\n  duration\n  filename\n  thumbnails(aspect_ratio: HD) {\n    small\n  }\n}\n\nfragment VideoIndex_query_1jWD3d on Query {\n  organization {\n    videoFiles(first: $count, after: $cursor, query: $query) {\n      edges {\n        node {\n          id\n          ...VideoFileCell_videoFile\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f7d633b8e5dff39fee919c6fb83069fb';
export default node;
