import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { createFragmentContainer } from 'react-relay'
import Form, { Title, Code } from './Form'
import { Main, MainFields, Aside } from './Form/Containers'
import Controls from './Form/Controls'
import { Textarea } from './Form/Metadata'
import DetailRenderer from './DetailRenderer'
import { Table, Slugable } from './Index'
import { CollectionDetailQuery as Query } from './__generated__/CollectionDetailQuery.graphql'
import { CollectionDetail_draft as Fragment } from './__generated__/CollectionDetail_draft.graphql'

const mutation = graphql`
  mutation CollectionDetailMutation($id: ID!, $input: CollectionInput!) {
    collectionUpdate(draftId: $id, input: $input) {
      draft {
        ...CollectionDetail_draft
      }
    }
  }
`

const query = graphql`
  query CollectionDetailQuery($id: ID!) {
    node(id: $id) {
      ...on Collection {
        latestDraft {
          ...CollectionDetail_draft
        }
      }
      ...CollectionDetail_draft
    }
  }
`

const CollectionDetail = createFragmentContainer(
  (props: { draft: Fragment }) => {
    const { draft, draft: { id } } = props

    return (
      <Form<Fragment["draftable"]> id={id} mutation={mutation} node={draft}>
        <Main>
          <MainFields>
            <h1>{draft.draftable.id}</h1>
            <Title name='name' placeholder='Collection Name'/>
            <Title name='index' placeholder='Indexes (e.g. articles,videos)'/>
            <Code name='body' mode='json' fill={true} />
            <Table>
              <Slugable.Header/>
              {draft.draftable.collectables?.edges?.map((e: any, i: number) => <Slugable.Cell key={i} slugable={e.node} index={i} />)}
            </Table>
          </MainFields>
          <Controls draft={draft}/>
        </Main>
        <Aside>
          <Textarea name='description'/>
        </Aside>
      </Form>
    )
  },
  {
    draft: graphql`
      fragment CollectionDetail_draft on Draft {
        id
        ...Controls_draft
        canUpdate
        draftable {
          ...on Collection {
            id
            name
            body
            description
            index
            collectables(first: 10) {
              edges {
                node {
                  id
                  ...SlugableCell_slugable
                }
              }
            }
          }
        }
        errors {
          field
          messages
        }
        warnings {
          field
          messages
        }
      }
    `
  }
)

export default DetailRenderer<Query>(CollectionDetail, query)
