import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { createFragmentContainer } from 'react-relay'
import Form, {  Title, Code } from './Form'
import { Main, MainFields, Aside } from './Form/Containers'
import Controls from './Form/Controls'
import { Textarea, TemplateTypeSelect } from './Form/Metadata'
import { TemplateDetailQuery as Query } from "./__generated__/TemplateDetailQuery.graphql"
import { TemplateDetail_draft as Fragment } from "./__generated__/TemplateDetail_draft.graphql"
import DetailRenderer from './DetailRenderer'

const mutation = graphql`
  mutation TemplateDetailMutation($id: ID!, $input: TemplateInput!) {
    templateUpdate(draftId: $id, input: $input) {
      draft {
        ...TemplateDetail_draft
      }
    }
  }
`

const query = graphql`
  query TemplateDetailQuery($id: ID!) {
    node(id: $id) {
      ...on Draftable {
        latestDraft {
          ...TemplateDetail_draft
        }
      }
      ...TemplateDetail_draft
    }
  }
`

const TemplateDetail = createFragmentContainer(
  (props: { draft: Fragment }) => {
    const { draft, draft: { id } } = props

    return (
      <Form<Fragment["draftable"]> id={id} mutation={mutation} node={draft}>
        <Main>
          <MainFields>
            <Title name='name' placeholder='Template Name' />
            <Code name='body' mode='xml' fill={true} />
            <Code name='css' mode='css' fill={false} />
          </MainFields>
          <Controls draft={draft}/>
        </Main>
        <Aside>
          <TemplateTypeSelect name='templatableType' template={draft.draftable} required={true} />
          <Textarea name='description' placeholder='Add a description' required={true} />
        </Aside>
      </Form>
    )
  },
  {
    draft: graphql`
      fragment TemplateDetail_draft on Draft {
        id
        ...Controls_draft
        canUpdate
        draftable {
          ...TemplateTypeSelect_template
          ...on Template {
            body
            css
            description
            name
          }
        }
        errors {
          field
          messages
        }
        warnings {
          field
          messages
        }
      }
    `
  }
)

export default DetailRenderer<Query>(TemplateDetail, query)
