import { useState, useRef, FormEvent, useEffect } from 'react'
import Stuntman, { Stunts } from '../graphql/stuntman'

export type ChangeHandler = (name: string | FormEvent, value?: string | Array<string> | null | boolean) => void

function isFormEvent(event: string | FormEvent): event is FormEvent {
  return (event as FormEvent).target !== undefined
}

export default <T>(node: T, onIdle?: () => void): [Stuntman<T>, Stunts, ChangeHandler] => {
  const stuntman = useRef(new Stuntman<T>(node, onIdle)).current
  const [stateValue, setStateValue] = useState(stuntman.staged)

  useEffect(() => {
    stuntman.reset(node)
    setStateValue(stuntman.staged)
  }, [stuntman, node])

  return [
    stuntman,
    stateValue,
    (name: string | FormEvent, value?: string | Array<string> | null | boolean) => {
      if (isFormEvent(name)) {
        const target = name.target as HTMLInputElement
        stuntman.set(target.name, target.value)
      } else {
        stuntman.set(name, value)
      }
      setStateValue(stuntman.staged)
    }
  ]
}
