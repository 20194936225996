import React from 'react'
import { Box, TextField } from '@material-ui/core'
import { FormikContextType, useFormikContext } from 'formik'

type Schema = {
  input: {
    author: {
      byline: string
      slug: string
      [key: string]: any
    }
  }
}

const FIELDS = [
  { name: 'byline', label: 'Byline', type: 'text' },
  { name: 'slug', label: 'Slug', type: 'text' }
]

const StaticFields = () => {
  const { values, errors, touched, handleBlur, handleChange }: FormikContextType<Schema> = useFormikContext()

  return (
    <>
      {FIELDS.map((field, i) => (
        <Box mt={1} mb={4} height={70} key={i}>
          <TextField
            fullWidth
            variant='outlined'
            type={field.type}
            id={`input.author.${field.name}`}
            label={field.label}
            name={`input.author.${field.name}`}
            onBlur={handleBlur}
            onChange={handleChange}
            value={(values?.input?.author && values?.input?.author[field.name]) ? values?.input?.author[field.name] : ''}
            error={
              touched?.input?.author &&
              touched?.input?.author[field.name] &&
              errors?.input &&
              Boolean((errors?.input as any)[`author.${field.name}`])
            }
            helperText={errors?.input && (errors?.input as any)[`author.${field.name}`]}
          />
        </Box>
      ))}
    </>
  )
}

export default StaticFields
