import React, { FC } from 'react'
import { FormikContextType, FormikTouched, FormikValues, useFormikContext } from 'formik'
import { capitalize, TextField } from '@material-ui/core'
import { FormikErrors } from 'formik/dist/types'
import Avatar, { Props as AvatarProps } from './Avatar'
import Schema from './Schema'
import Autocomplete from '@material-ui/lab/Autocomplete'

export interface Props extends AvatarProps {
  keywords: boolean
  scopeType: string
}

/**
 * Returns 'true' if field was touched
 */
export const onTouchHandler = (tag: Props['tag'], scopeType: string, touched: any): FormikTouched<FormikValues> | undefined => {
  switch (scopeType) {
    case 'Author':
      return (touched?.input?.author as any)?.tags && (touched?.input?.author as any).tags[tag.name]
    case 'TopStoryList':
      return (touched?.input as any)?.tags && (touched?.input as any).tags[tag.name]
  }
}

/**
 * Returns error string value for certain field if there is an error occurred
 */
export const onErrorHandler = (tag: Props['tag'], scopeType: string, errors: FormikErrors<any>): FormikErrors<FormikValues> | undefined => {
  switch (scopeType) {
    case 'Author':
      return errors?.input && (errors?.input as any)[`author.tags[${tag.name}]`]
    case 'TopStoryList':
      return errors?.input && (errors?.input as any)[`tags[${tag.name}]`]
  }
}

/**
 * Returns a value for certain field from the Formik context according to Tag scope_type
 */
export const getValues = (tag: Props['tag'], scopeType: string, values: FormikValues) => {
  switch (scopeType) {
    case 'Author':
      return values?.input?.author?.tags[tag.name][1] || []
    case 'TopStoryList':
      return values?.input?.tags[tag.name][1] || []
    default:
      return []
  }
}

/**
 * Sets the name/id of the form field according to Tag scope_type
 */
export const setId = (tag: Props['tag'], scopeType: string) => {
  switch (scopeType) {
    case 'Author':
      return `input.author.tags[${tag.name}][1]` || ''
    case 'TopStoryList':
      return `input.tags[${tag.name}][1]` || ''
    default:
      return ''
  }
}

const TagSelect: FC<Props> = ({ tag, keywords, scopeType }) => {
  const { values, handleBlur, setFieldValue, touched, errors }: FormikContextType<Schema> = useFormikContext()

  return (
    <Autocomplete
      onChange={(e, newValue) => {
        setFieldValue(setId(tag, scopeType) || '', newValue)
      }}
      size='medium'
      multiple
      freeSolo={keywords}
      limitTags={5}
      fullWidth
      onOpen={handleBlur}
      id={setId(tag, scopeType)}
      value={getValues(tag, scopeType, values)}
      options={tag.fixedOptions as []}
      renderInput={(params) => (
        <TextField
          {...params}
          variant='outlined'
          name={setId(tag, scopeType)}
          label={capitalize(tag.name)}
          onBlur={handleBlur}
          error={onTouchHandler(tag, scopeType, touched) && Boolean(onErrorHandler(tag, scopeType, errors))}
          helperText={onTouchHandler(tag, scopeType, touched) && onErrorHandler(tag, scopeType, errors)}
        />
      )}
    />
  )
}

export const TagRenderer: FC<{ tag: Props['tag']; scopeType: string }> = ({ tag, scopeType }) => {
  const { values, handleChange, handleBlur, touched, errors }: FormikContextType<Schema> = useFormikContext()

  switch (tag.type) {
    case 'AVATAR':
      return <Avatar tag={tag} scopeType={scopeType} />
    case 'KEYWORDS':
      return <TagSelect keywords={true} tag={tag} scopeType={scopeType} />
    case 'MULTI_SELECT':
      return <TagSelect keywords={false} tag={tag} scopeType={scopeType} />
    case 'TEXT':
      return (
        <TextField
          multiline={tag.name === 'bio'}
          rows={3}
          rowsMax={6}
          fullWidth
          value={getValues(tag, scopeType, values)}
          variant='outlined'
          label={capitalize(tag.name)}
          id={setId(tag, scopeType)}
          name={setId(tag, scopeType)}
          error={onTouchHandler(tag, scopeType, touched) && Boolean(onErrorHandler(tag, scopeType, errors))}
          helperText={onTouchHandler(tag, scopeType, touched) && onErrorHandler(tag, scopeType, errors)}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      )
    default:
      return <div>No component for metatag with type {tag.type}!</div>
  }
}

export default TagRenderer
