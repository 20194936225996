import React, { FC, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Avatar, Box, ButtonBase, Hidden, Menu, MenuItem, Typography, makeStyles } from '@material-ui/core'
import { createFragmentContainer } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import { commitMutation } from 'react-relay'
import { useHistory, RouteComponentProps } from 'react-router-dom'
import environment from '../../../relay/environment'
import { Account_user as User } from './__generated__/Account_user.graphql'
import ErrorRenderer, { ErrorType } from '../../ErrorRenderer'
import { Theme } from '../../../theme'

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1)
  },
  popover: {
    width: 200,
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.text.light
  },
  menuItem: {
  '&:hover': {
    backgroundColor: theme.palette.background.hover
  }
  }
}))

const mutation = graphql`
  mutation AccountMutation {
    deviceLogout {
      device {
        ...Layout_device
      }
    }
  }
`

const handleClick = (event: React.MouseEvent, history: RouteComponentProps['history']) => {
  event.preventDefault()
  window.gapi.load('auth2', () => {
    window.gapi.auth2.init().then(() => {
      const auth2 = window.gapi.auth2.getAuthInstance()
      auth2.signOut().then(() => {
        commitMutation(
          environment,
          {
            mutation,
            variables: {},
            onError: ((error: ErrorType) => <ErrorRenderer message={error.message} /> ),
            onCompleted: () => history.push('/')
          },
        )
      })
    })
  })
}

const Account: FC<{ user: User }> = ({ user : { first_name, last_name, avatar_url, id }}) => {
  const classes = useStyles()
  const anchorRef = useRef<any>(null)
  const [isOpen, setOpen] = useState<boolean>(false)
  const history = useHistory()

  return (
    <div ref={anchorRef}>
      <Helmet>
        <script src="https://apis.google.com/js/platform.js" async defer></script>
      </Helmet>
      <Box display='flex' alignItems='center' component={ButtonBase} onClick={() => setOpen(true)}>
        <Avatar alt='User' className={classes.avatar} src={avatar_url}/>
        <Hidden smDown>
          <Typography variant='h6' color='inherit'>
            {first_name} {last_name}
          </Typography>
        </Hidden>
      </Box>
      <Menu
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        keepMounted
        PaperProps={{ className: classes.popover }}
        getContentAnchorEl={null}
        anchorEl={anchorRef.current}
        open={isOpen}
        data-testid='account-menu'
      >
        <MenuItem className={classes.menuItem} onClick={(e) => history.push(`/users/${id}`)}>Profile</MenuItem>
        <MenuItem className={classes.menuItem} onClick={(e) => handleClick(e, history)}>Logout</MenuItem>
      </Menu>
    </div>
  )
}

export default createFragmentContainer(Account, {
  user: graphql`
    fragment Account_user on User {
      id
      avatar_url
      first_name
      last_name
    }
  `
})
