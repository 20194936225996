import React from 'react'
import { createFragmentContainer } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import * as cell from './Cell'
import moment from 'moment'
import Avatar from '../Avatar'
import { DraftableCell_draftable as Response } from './__generated__/DraftableCell_draftable.graphql'
import 'moment-timezone'

const Header: React.FC = () => (
  <cell.HeaderRow>
    <div role='columnheader' />
    <div role='columnheader' />
    <div role='columnheader'>Name</div>
    <div role='columnheader'>Last Edited By</div>
    <div role='columnheader'>Last Modified</div>
  </cell.HeaderRow>
)

type Props = {
  draftable: Response,
  index: number
}

const Cell: React.FC<Props> = ({ draftable, index }) => {
  const {
    __typename,
    id,
    latestDraft: {
      administrators,
      publishedAt,
      scheduledFor,
      updatedAt,
      publicationStatus,
      draftable: {
        name,
        thumbnails,
      },
    },
  } = draftable
  const user = administrators[0]?.user
  const dateFormat = 'MMM D, YYYY @ h:mma z'

  return (
    <cell.LinkRow index={index} to={`/${__typename.toLowerCase()}s/${id}`}>
      <cell.Status role='cell' color={cell.StatusColor[publicationStatus]} aria-hidden={true} />
      <cell.Thumbnail src={thumbnails?.small || undefined} />
      <div role='cell'>
        {name.length > 0 ? name : <em>Unnamed Article</em>}
        <cell.Subhead>
          {publicationStatus === 'PUBLISHED' && publishedAt && 'Published: ' + moment(publishedAt).format(dateFormat)}
          {publicationStatus === 'SCHEDULED' && scheduledFor && 'Scheduled: ' + moment(scheduledFor).format(dateFormat)}
          {publicationStatus === 'DRAFT' && 'Draft'}
        </cell.Subhead>
      </div>
      {user &&
        <cell.User role='cell'>
          <Avatar user={user} size={0} float='left' />
          {user.first_name} {user.last_name}
        </cell.User>
      }
      <div role='cell'>{updatedAt && moment(updatedAt).format(dateFormat)}</div>
    </cell.LinkRow>
  )
}

const Container = createFragmentContainer(
  Cell,
  {
    draftable: graphql`
      fragment DraftableCell_draftable on Draftable {
        __typename
        ...on Node {
          id
        }
        latestDraft {
          publishedAt
          scheduledFor
          updatedAt
          publicationStatus
          administrators {
            user {
              id
              first_name
              last_name
              avatar_url
            }
          }
          draftable {
            name
            ...on Slugable {
              thumbnails(aspect_ratio: SQUARE) {
                small
              }
            }
          }
        }
      }
    `
  }
)

export { Header, Container as Cell }
