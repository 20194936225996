import React, { useState, Dispatch, SetStateAction, useEffect } from 'react'
import styled from 'styled-components'
import { graphql } from 'babel-plugin-relay/macro'
import { MetatagInput, MetatagCreateMutation } from './__generated__/MetatagCreateMutation.graphql'
import { MetatagUpdateMutation } from './__generated__/MetatagUpdateMutation.graphql'
import { MutationButton as Submit, ButtonActions} from '../../Button'
import { MetatagType } from '../Metatags'
import CancelButton from './Button'
import Modal from './Modal'
import Input from '../Input'
import Chips from '../Chips'
import Select from '../Select'

const ContainerFieldGroup = styled.div<{ childWidth: string }>`
  display: flex;
  padding-bottom: 24px;
  width: 400px;

  > div {
    width: ${props => props.childWidth};
  }

  > div:first-of-type {
    padding-right: 12px;
  }

  > div:last-of-type {
    padding-left: 12px;
  }

  label {
    padding-bottom: 0;
  }
`

const ContainerButtonGroup = styled.div`
  padding-top: 24px;
  display: flex;
  justify-content: flex-end;

  button:first-of-type {
    margin-right: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    background: white;

    &:hover {
      background-color: ${props => props.theme.colors.primary.mediumSlateBlue};
      border-color: ${props => props.theme.colors.primary.mediumSlateBlue};
      color: white;
      transition: 0.2s ease-in-out;
    }
  }

  i {
    display: none;
  }
`

const createMutation = graphql`
  mutation MetatagCreateMutation($id: ID!, $input: MetatagInput!) {
    metatagCreate(organizationId: $id, input: $input) {
      metatag {
        fixedOptions
        id
        maxValues
        minValues
        name
        scopeType
        type
        validator
      }
      errors {
        messages
        field
      }
    }
  }
`

const updateMutation = graphql`
  mutation MetatagUpdateMutation($id: ID!, $input: MetatagInput!) {
    metatagUpdate(id: $id, input: $input) {
      metatag {
        fixedOptions
        id
        maxValues
        minValues
        name
        scopeType
        type
        validator
      }
      errors {
        messages
        field
      }
    }
  }
`

const INITIAL_STATE = {
  name: null,
  fixedOptions: [],
  maxValues: null,
  minValues: null,
  scopeType: null,
  type: null,
  validator: null
}

const SCOPE_TYPES = ['Article', 'Author', 'Page', 'Video', 'Top Story List']
const TYPES = [
  ['Avatar', 'AVATAR'],
  ['Fixed - Multi', 'MULTI_SELECT'],
  ['Fixed - Single', 'SINGLE_SELECT'],
  ['Keywords', 'KEYWORDS'],
  ['Thumbnail', 'THUMBNAIL'],
  ['Text', 'TEXT']
]

const OPTIONS = {
  scopeType: SCOPE_TYPES.map((scopeType) => ({ label: scopeType, value: scopeType.replace(/ /g, '_').toUpperCase() })),
  type: TYPES.map((t) => ({ label: t[0], value: t[1] }))
}

export type ErrorType = { field: string, messages: string [] } [] | []
type ChangeHandlerProps = ({ name, value } : { name: string, value: string | string [] | number }) => void

type SelectGroupProps = {
  errors: {
    scopeType: string | undefined
    type: string | undefined
  }
  formData: MetatagInput
  onChange: ChangeHandlerProps
}

const SelectGroup: React.FC<SelectGroupProps> = ({ errors, formData, onChange }) => (
  <ContainerFieldGroup childWidth='50%'>
    <Select
      error={errors.scopeType}
      handleChange={onChange}
      label='Applies to'
      name='scopeType'
      isMulti={true}
      options={OPTIONS.scopeType}
      placeholder='Applies to'
      value={formData.scopeType?.map(s => ({ label: s, value: s }))}
    />
    <Select
      error={errors.type}
      handleChange={onChange}
      label='Type'
      name='type'
      isMulti={false}
      options={OPTIONS.type}
      placeholder='Type'
      value={formData.type && { label: OPTIONS.type[OPTIONS.type.findIndex(o => o.value === formData.type)].label, value: formData.type }}
    />
  </ContainerFieldGroup>
)

const MinMaxValues: React.FC<{ handleChange: ChangeHandlerProps, formData: MetatagInput}> = ({ handleChange, formData }) => (
  <ContainerFieldGroup childWidth='33.3%'>
    <div>
      <Input
        label='Min'
        name='minValues'
        onChange={handleChange}
        placeholder='Min value'
        type='number'
        val={formData?.minValues!}
      />
    </div>
    <div>
      <Input
        label='Max'
        placeholder='Max value'
        onChange={handleChange}
        name='maxValues'
        type='number'
        val={formData?.maxValues!}
      />
    </div>
  </ContainerFieldGroup>
)

export type Props = {
  metatag?: MetatagInput
  handleClose: () => void
  open: boolean
  id: string
  updateMetatags: (metatag: MetatagType) => void
  edit?: boolean
}

interface ButtonProps extends Omit<Props, 'open'> {
  edit: boolean
  metatag: MetatagInput
  setErrors: Dispatch<SetStateAction<ErrorType>>
  setFormData: Dispatch<SetStateAction<MetatagInput>>
}

const Buttons: React.FC<ButtonProps> = ({ edit, metatag, id, setErrors, handleClose, updateMetatags, setFormData }) => (
  <ContainerButtonGroup>
    {
      (!edit)
      ? (
        <Submit<MetatagCreateMutation>
          mutation={createMutation}
          variables={{ id: id, input: metatag }}
          buttonType={ButtonActions.Save}
          onCompleted={({ metatagCreate }) => {
            if (metatagCreate?.errors.length) {
              setErrors(metatagCreate.errors as ErrorType)
            } else {
              handleClose()
              setErrors([])
              updateMetatags(metatagCreate?.metatag!)
              setFormData(INITIAL_STATE)
            }
          }}
        />
      )
      : (
        <Submit<MetatagUpdateMutation>
          mutation={updateMutation}
          variables={{ id: id, input: metatag }}
          buttonType={ButtonActions.Save}
          onCompleted={({ metatagUpdate }) => {
            if (metatagUpdate?.errors.length) {
              setErrors(metatagUpdate.errors as ErrorType)
            } else {
              handleClose()
              setErrors([])
              updateMetatags(metatagUpdate?.metatag!)
            }
          }}
        />
      )
    }
    <CancelButton onClick={handleClose}>Cancel</CancelButton>
  </ContainerButtonGroup>
)

const Metatag: React.FC<Props> = ({ metatag = INITIAL_STATE, edit = false, handleClose, open, id, updateMetatags }) => {
  const [formData, setFormData] = useState<MetatagInput>(metatag)
  const [errors, setErrors] = useState<ErrorType>([])

  const changeHandler: ChangeHandlerProps = ({ name, value}) => {
    setFormData({ ...formData, [name]: value })
    setErrors(errors.filter((e) => e.field !== name))
  }

  const errorsFor = (fieldName: string) => errors.find((e) => e.field === fieldName)?.messages.join('')

  useEffect(() => setFormData(metatag), [metatag])


  return (
    <Modal handleClose={handleClose} open={open}>
      <Input label='Name' onChange={changeHandler} name='name' val={formData.name || ''} error={errorsFor('name')}/>
      <SelectGroup formData={formData} onChange={changeHandler} errors={{scopeType: errorsFor('scopeType'), type: errorsFor('type')}}/>
      {(formData.type === 'KEYWORDS' || formData.type ===  'TEXT') &&
        <Input label='Validator' onChange={changeHandler} name='validator' val={formData?.validator || ''}/>
      }
      {formData.type === 'MULTI_SELECT' && <MinMaxValues handleChange={changeHandler} formData={formData}/>}
      {(formData.type === 'SINGLE_SELECT' || formData.type === 'MULTI_SELECT') &&
        <Chips
          error={errorsFor('fixedOptions')}
          handleChange={changeHandler}
          values={(formData.fixedOptions as string [])}
        />
      }
      <Buttons
        edit={edit}
        metatag={formData}
        id={id}
        setErrors={setErrors}
        handleClose={handleClose}
        setFormData={setFormData}
        updateMetatags={updateMetatags}
      />
  </Modal>
  )
}

export default Metatag
