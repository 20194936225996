import React from 'react'
import { TemplateSelect_draftable as Fragment } from './__generated__/TemplateSelect_draftable.graphql'
import SingleSelect from './SingleSelect'
import { ChangeHandler } from '../../../hooks/useStuntman'

type Props = {
  name: string
  selectedTemplate: {
    versions: [{ id: string, version: number }],
    name: string,
    currentVersion?: number
  } | null
  draftable: Fragment
  onChange: ChangeHandler
  disabled: boolean
}

const VersionSelectDropdown: React.FC<any> = (props: Props) => {
  const { draftable: { template, templateVersionId }, name, selectedTemplate, onChange, disabled } = props
  const templateName = (template && template.name) || ''
  const versions = selectedTemplate?.versions

  return (
    <SingleSelect {...props}
      placeholder=''
      required={false}
      options={versions}
      preview={templateName}
      value={versions?.filter((c: any) => c.id === templateVersionId)}
      onChange={(e: any) => onChange(name, e.id)}
      getOptionLabel={(option: any) => `v${option.version}`}
      getOptionValue={(option: any) => option.id}
      isDisabled={disabled}
      sharingSpace={selectedTemplate ? true : false}
      lastOfType={true}
    />
  )
}

export default VersionSelectDropdown
