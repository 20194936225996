import * as _ from 'lodash'

export type Stunts = { [key: string]: any }

export default class Stuntman<N> {
  private originalNode: Stunts
  private nodeState: Stunts
  private readonly onIdle?: () => void
  private idleTimer?: number

  constructor(node: N, onIdle?: () => void) {
    this.originalNode = this.unnestKeys(node)
    this.nodeState = {}
    this.onIdle = onIdle
  }

  get staged(): Stunts {
    return { ...this.originalNode, ...this.nodeState }
  }

  get updates(): Stunts {
    return Object.keys(this.nodeState).reduce((res: Stunts, key: string): Stunts => (
      this.attributeChanged(key) ? { ...res, ...this.nestKeys(key, this.nodeState, res) } : res
    ), {})
  }

  get hasChanged(): boolean {
    for (const key in this.nodeState) {
      if (this.attributeChanged(key)) {
        return true
      }
    }
    return false
  }

  set(key: string, value: any): Stuntman<N> {
    this.nodeState[key] = value
    if (this.idleTimer) {
      clearTimeout(this.idleTimer)
    }
    this.idleTimer = setTimeout(() => {
      this.idleTimer = undefined
      this.onIdle && this.onIdle()
    }, 500)
    return this
  }

  reset(node: N): Stuntman<N> {
    this.originalNode = this.unnestKeys(node)
    Object.keys(this.nodeState).forEach((k) => {
      if (!this.attributeChanged(k)) {
        delete this.nodeState[k]
      }
    })
    return this
  }

  private nestKeys(key: string, values: Stunts, updates?: Stunts): Stunts {
    const match = this.nestedMatcher(key)
    if (match.length > 1) {
      const existing = (updates && updates[match[0]]) || []
      const restKey = match[1] + match.slice(2).map(r => `[${r}]`)
      return { [match[0]]: existing.concat([{ source: restKey, value: values[key] }]) }
    } else {
      return { [key]: values[key] }
    }
  }

  private unnestKeys(values: Stunts): Stunts {
    return this.sortedKeys(values).reduce((res: Stunts, key: string): Stunts => {
      return { ...this.extractValue(key, values[key]), ...res }
    }, {})
  }

  private sortedKeys(values: Stunts): string[] {
    return Object.keys(values).sort((a, b) => {
      const aType = this.valueType(values[a])
      const bType = this.valueType(values[b])

      if (aType === 'scalar') {
        return -1
      } else if (bType === 'scalar') {
        return 1
      } else {
        return 0
      }
    })
  }

  private extractValue(key: string, value: Stunts): Stunts {
    switch(this.valueType(value)) {
      case 'array':
        return this.convertArrayValues(key, value as Stunts[])
      case 'object':
        return this.convertObjectValues(key, value)
      case 'scalar':
        return { [key]: value }
    }
  }

  private valueType(value: Stunts): 'array' | 'object' | 'scalar' {
    if (Array.isArray(value)) {
      return 'array'
    } else if (typeof value === 'object' && value !== null) {
      return 'object'
    } else {
      return 'scalar'
    }
  }

  private convertObjectValues(key: string, values: Stunts): Stunts {
    const unnested = Object.keys(values).reduce((accum, subKey) => (
      { ...accum, ...this.extractValue(`${key}${_.upperFirst(subKey)}`, values[subKey]) }
    ), {})
    return { ...unnested, [key]: values }
  }

  private convertArrayValues(key: string, values: Array<Stunts>): Stunts {
    return values.reduce((res: Stunts, sub: Stunts): Stunts => {
      const match = this.nestedMatcher(sub.source)
      const bracketKey = match.reduce((m, source) => m + `[${source}]`, '')
      return { ...res, [`${key}${bracketKey}`]: sub.value }
    }, {})
  }

  private attributeChanged(key: string): boolean {
    let currentValue = this.nodeState[key]
    if (currentValue === "") {
      currentValue = null
    }
    return currentValue !== this.originalNode[key]
  }

  private nestedMatcher(key: string | null): Array<string> {
    // splits a nested key, like:
    //   content[breakingNews][banner]
    // into array: ['content', 'breakingNews', 'banner']
    return key?.match(/\w+=*/g) || []
  }
}
