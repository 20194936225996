import { init } from '@sentry/react'
import React from 'react'
import ReactDOM from 'react-dom'
import Root from './Root'

if (process.env.NODE_ENV === 'production') {
  init({
    dsn: 'https://cf03d4e040ac4511a22c1ffcf02b756e@o76991.ingest.sentry.io/5350913',
    maxBreadcrumbs: 50,
  })
}

ReactDOM.render(<Root/>, document.getElementById('root'))
