/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type ValidateInviteQueryVariables = {
    confirmationToken: string;
};
export type ValidateInviteQueryResponse = {
    readonly userValidateInvite: {
        readonly user: {
            readonly id: string;
        } | null;
        readonly error: string | null;
    };
};
export type ValidateInviteQuery = {
    readonly response: ValidateInviteQueryResponse;
    readonly variables: ValidateInviteQueryVariables;
};



/*
query ValidateInviteQuery(
  $confirmationToken: String!
) {
  userValidateInvite(confirmationToken: $confirmationToken) {
    user {
      id
    }
    error
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "confirmationToken"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "confirmationToken",
        "variableName": "confirmationToken"
      }
    ],
    "concreteType": "UserValidateInvite",
    "kind": "LinkedField",
    "name": "userValidateInvite",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ValidateInviteQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ValidateInviteQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "97a8b928618f891694682891fc2f82bc",
    "id": null,
    "metadata": {},
    "name": "ValidateInviteQuery",
    "operationKind": "query",
    "text": "query ValidateInviteQuery(\n  $confirmationToken: String!\n) {\n  userValidateInvite(confirmationToken: $confirmationToken) {\n    user {\n      id\n    }\n    error\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e02c6eb3f32af0570d31b5c1ccf360b2';
export default node;
