/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PublicationDate_draftable = {
    readonly publishedAt: string | null;
    readonly " $refType": "PublicationDate_draftable";
};
export type PublicationDate_draftable$data = PublicationDate_draftable;
export type PublicationDate_draftable$key = {
    readonly " $data"?: PublicationDate_draftable$data;
    readonly " $fragmentRefs": FragmentRefs<"PublicationDate_draftable">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PublicationDate_draftable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "publishedAt",
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
(node as any).hash = '966b6922c2207adebbf669e7aceeb799';
export default node;
