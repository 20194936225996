import { Buffer } from 'buffer'
import { imageSize } from 'image-size'
import Upload, { Params } from './Upload'
import { UploadResources } from './UploadResources'

import { Dimensions, ResourceDimensions } from '../../../util/types/Dimensions'

export default class ImageUpload extends Upload {
  protected maxFileSize = 10485760
  protected fileTooBigMsg = 'Image size exceeds 10MB'

  public static validateImage(file: File, setError: (error?: string) => void, minDimensions: Dimensions = {}) {
    return new ImageUpload({ file, setError, minDimensions } as Params).validateResource(UploadResources.IMAGE)
  }

  protected async getResourceDimensions(): Promise<ResourceDimensions> {
    const src = (await this.toArrayBuffer()) as ArrayBuffer
    const buffer = Buffer.from(src)
    const { width, height, ...rest } = imageSize(buffer)

    // Returns metadata on the buffer source's height, width, and type
    return {
      width: width || 0,
      height: height || 0,
      type: '',
      ...rest
    } as ResourceDimensions
  }

  protected handleUpload() {
    this.uppy.on('upload', () => {
      this.setUploadPct(0.0)
      const reader = new FileReader()
      reader.readAsDataURL(this.file)
      this.setIsUploading(true)
    })
    this.uppy.on('upload-success', (file, response) => {
      this.setIsUploading(false)
      this.onChange('videoId', null)
      this.onChange('videoFileId', null)
      this.onChange('heroImageUrl', response.body.url)
      this.uppy.close()
    })
    this.uppy.addFile({ name: this.file.name, type: this.file.type, data: this.file })
  }

  private toArrayBuffer(): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsArrayBuffer(this.file)
      reader.onload = () => resolve(reader.result)
    })
  }
}
