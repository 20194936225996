/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PageInput = {
    name?: string | null;
    description?: string | null;
    templateVersionId?: unknown | null;
    thumbnailUrl?: string | null;
    slug?: string | null;
    content?: Array<PageContentInput> | null;
};
export type PageContentInput = {
    source: string;
    value: string;
};
export type PageDetailMutationVariables = {
    id: string;
    input: PageInput;
};
export type PageDetailMutationResponse = {
    readonly pageUpdate: {
        readonly draft: {
            readonly " $fragmentRefs": FragmentRefs<"PageDetail_draft">;
        };
    } | null;
};
export type PageDetailMutation = {
    readonly response: PageDetailMutationResponse;
    readonly variables: PageDetailMutationVariables;
};



/*
mutation PageDetailMutation(
  $id: ID!
  $input: PageInput!
) {
  pageUpdate(draftId: $id, input: $input) {
    draft {
      ...PageDetail_draft
      id
    }
  }
}

fragment Controls_draft on Draft {
  id
  canDelete
  canEdit
  canPublish
  canUpdate
  updatedAt
  administrators {
    privilege
    user {
      id
      first_name
      last_name
      avatar_url
    }
  }
  draftable {
    ... on Article {
      id
      deletedAt
    }
    lastPublishedAt
    __typename
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment PageDetail_draft on Draft {
  id
  ...Controls_draft
  canUpdate
  draftable {
    __typename
    ...TemplateSelect_draftable
    template {
      name
      body
      id
    }
    ... on Page {
      name
      description
      ...Thumbnail_page
      slug
      content {
        source
        value
      }
    }
    ... on Node {
      __isNode: __typename
      id
    }
  }
  errors {
    field
    messages
  }
  warnings {
    field
    messages
  }
}

fragment TemplateSelect_draftable on Draftable {
  __isDraftable: __typename
  selectableTemplates {
    name
    versions {
      id
      version
    }
    latestDraft {
      id
    }
    id
  }
  template {
    name
    id
  }
  templateVersionId
}

fragment Thumbnail_page on Page {
  thumbnailUrl
  thumbnails {
    small
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "draftId",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
],
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "field",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "messages",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PageDetailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PageUpdateMutationPayload",
        "kind": "LinkedField",
        "name": "pageUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Draft",
            "kind": "LinkedField",
            "name": "draft",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PageDetail_draft"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PageDetailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PageUpdateMutationPayload",
        "kind": "LinkedField",
        "name": "pageUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Draft",
            "kind": "LinkedField",
            "name": "draft",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canDelete",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canEdit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canPublish",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canUpdate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DraftAdministrator",
                "kind": "LinkedField",
                "name": "administrators",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "privilege",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "first_name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "last_name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "avatar_url",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "draftable",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastPublishedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isDraftable"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Template",
                    "kind": "LinkedField",
                    "name": "selectableTemplates",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Draft",
                        "kind": "LinkedField",
                        "name": "versions",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "version",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Draft",
                        "kind": "LinkedField",
                        "name": "latestDraft",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Template",
                    "kind": "LinkedField",
                    "name": "template",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "body",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "templateVersionId",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deletedAt",
                        "storageKey": null
                      }
                    ],
                    "type": "Article",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "Node",
                    "abstractKey": "__isNode"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "thumbnailUrl",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Thumbnail",
                        "kind": "LinkedField",
                        "name": "thumbnails",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "small",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "slug",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageContent",
                        "kind": "LinkedField",
                        "name": "content",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "source",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "value",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "Page",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Error",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Error",
                "kind": "LinkedField",
                "name": "warnings",
                "plural": true,
                "selections": (v5/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ee452e30935f2d260f7fece667905248",
    "id": null,
    "metadata": {},
    "name": "PageDetailMutation",
    "operationKind": "mutation",
    "text": "mutation PageDetailMutation(\n  $id: ID!\n  $input: PageInput!\n) {\n  pageUpdate(draftId: $id, input: $input) {\n    draft {\n      ...PageDetail_draft\n      id\n    }\n  }\n}\n\nfragment Controls_draft on Draft {\n  id\n  canDelete\n  canEdit\n  canPublish\n  canUpdate\n  updatedAt\n  administrators {\n    privilege\n    user {\n      id\n      first_name\n      last_name\n      avatar_url\n    }\n  }\n  draftable {\n    ... on Article {\n      id\n      deletedAt\n    }\n    lastPublishedAt\n    __typename\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment PageDetail_draft on Draft {\n  id\n  ...Controls_draft\n  canUpdate\n  draftable {\n    __typename\n    ...TemplateSelect_draftable\n    template {\n      name\n      body\n      id\n    }\n    ... on Page {\n      name\n      description\n      ...Thumbnail_page\n      slug\n      content {\n        source\n        value\n      }\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  errors {\n    field\n    messages\n  }\n  warnings {\n    field\n    messages\n  }\n}\n\nfragment TemplateSelect_draftable on Draftable {\n  __isDraftable: __typename\n  selectableTemplates {\n    name\n    versions {\n      id\n      version\n    }\n    latestDraft {\n      id\n    }\n    id\n  }\n  template {\n    name\n    id\n  }\n  templateVersionId\n}\n\nfragment Thumbnail_page on Page {\n  thumbnailUrl\n  thumbnails {\n    small\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd0ede643f343ec86cb2c760c092e4ff6';
export default node;
