import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'

const SearchInput = styled.input`
  border: none;
  height: 24px;
  width: 100%;
  font-size: 20px;

  ::placeholder{
    font-style: italic;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
  }

  :focus{
    outline: none;
  }
`

const SearchIcon = styled.i`
  position: absolute;
  font-size: 18px;
  top: 26px;
  right: 32px;
`

const SearchInputContainer = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(206, 207, 209, 0.1);
  border-radius: 2px;
  padding: 22px 60px 18px 21px;
  display: block;
  width: 100%;
  box-sizing: border-box;
  position: relative;
`

const SearchContainer = styled.div<{ isLegacyStyle?: boolean }>`
  background-color: #fafbfc;
  height: 83px;
  width: 100%;
  display: block;
  padding: ${props => (props.isLegacyStyle) ? '0 0 32px 0' : '0 64px 32px 64px'};
  box-sizing: ${props => (props.isLegacyStyle) ? 'content-box' : 'border-box'};
`

const NoResultsRow = styled.div`
  text-align: center;
  color: #25272b;
  font-size: 26px;
  line-height: 172px;
  display: block;
  caption-side: bottom;
`

type Props = {
  setQueryString: React.Dispatch<React.SetStateAction<string | null>>
  isLegacyStyle?: boolean
}

export const NoResults: React.FC<{ queryString: string }> = ({ queryString }) => (
  <NoResultsRow>{`No results for ${queryString}`}</NoResultsRow>
)

const IndexSearch: React.FC<Props> = ({ setQueryString, isLegacyStyle }) => {
  const debounced = _.debounce((value) => setQueryString(value), 500)

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist()
    debounced(event.currentTarget.value)
  }

  return (
    <SearchContainer isLegacyStyle={isLegacyStyle}>
      <SearchInputContainer>
        <SearchInput onChange={changeHandler} placeholder="Search" />
        <SearchIcon className="material-icons"> search </SearchIcon>
      </SearchInputContainer>
    </SearchContainer>
  )
}

export default IndexSearch
