import React, { ComponentType, ReactNode, useState, useEffect } from 'react'
import { QueryRenderer, GraphQLTaggedNode, RelayPaginationProp } from 'react-relay'
import { MutationConfig, MutationParameters } from 'relay-runtime'
import styled from 'styled-components'
import Button, { MutationButton, ButtonActions } from '../Button'
import environment from '../../relay/environment'
import Loader from '../Loader'
import IndexSearch, { NoResults } from '../IndexSearch'

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
`

const ScrollArea = styled.div`
  background-color: ${props => props.theme.colors.grays.aliceBlue};
  height: 100%;
  overflow: auto;
`

const Header = styled(FlexDiv)`
  background-color: white;
  padding: ${props => props.theme.space[5]}px;
  padding-left: ${props => props.theme.space[9]}px;
`

const HeaderTitle = styled.div`
  font-size: ${props => props.theme.fontSizes[6]}px;
`

const HeaderControls = styled(FlexDiv)`
  height: 33px;
`

const Table = styled.div`
  display: block;
  width: 100%;
  max-width: 1184px;
  box-sizing: border-box;
  padding: 32px;
  margin: 0 auto;
`

const Footer = styled(FlexDiv)`
  justify-content: space-around;
  margin: ${props => props.theme.space[2]}px 0 ${props => props.theme.space[6]}px 0;
`

type HeaderProps<M extends MutationParameters> = Partial<MutationConfig<M>> & {
  title: string,
  onCreate?: (response: M['response']) => void
}

type WrapperProps<M extends MutationParameters> = HeaderProps<M> & Partial<MutationConfig<M>> & {
  updateList?: boolean,
  isActive?: boolean,
  children?: ReactNode,
  HeaderComponent?: () => JSX.Element,
  perPage: number,
  relay: RelayPaginationProp,
  rowCount?: number,
  search?: boolean,
  isLegacyStyle?: boolean
}

function WrapperHeader<M extends MutationParameters>(props: HeaderProps<M>) {
  const { title, mutation, variables, onCreate } = props

  return (
    <Header>
      <HeaderTitle>{title}</HeaderTitle>
      {mutation &&
        <HeaderControls>
          <MutationButton<M>
            mutation={mutation}
            variables={variables || {}}
            buttonType={ButtonActions.Create}
            onCompleted={(props, errors) => {
              if (!errors || errors.length === 0) {
                onCreate && onCreate(props)
              }
            }}
          />
        </HeaderControls>
      }
    </Header>
  )
}

function Wrapper<M extends MutationParameters>(props: WrapperProps<M>) {
  const { children, isLegacyStyle=true, perPage, relay: { loadMore, hasMore, refetchConnection }, relay, rowCount, search, HeaderComponent, updateList, isActive, ...headerProps} = props
  const [ queryString, setQueryString ] = useState<string | null>(null)

  useEffect(() => {
    const vars = isLegacyStyle ? { query: queryString } : { query: queryString, active: isActive }

    if (!isLegacyStyle) {
      refetchConnection(perPage, () => {}, vars)
    } else if(search && queryString !== null && (queryString.length > 1 || queryString === '')) {
      refetchConnection(perPage, () => {}, vars)
    }
  }, [ perPage, queryString, refetchConnection, search, isActive, isLegacyStyle, updateList ])

  return (
    <ScrollArea>
      {HeaderComponent ? HeaderComponent() : <WrapperHeader<M> {...headerProps} />}
      <Table>
        {search && <IndexSearch isLegacyStyle={isLegacyStyle} setQueryString={setQueryString} />}
        {children}
        {rowCount === 0 && queryString !== null && queryString !== '' && <NoResults queryString={queryString} />}
      </Table>
      <Footer>
        <Button disabled={!hasMore()} onClick={() => loadMore(perPage)} size={2}>Load More</Button>
      </Footer>
    </ScrollArea>
  )
}

type Props<Q extends MutationParameters> = {
  Component: ComponentType<{ query: Q['response'] }>,
  query: GraphQLTaggedNode,
  variables: Q['variables']
}

function Renderer<Q extends MutationParameters>(props: Props<Q>) {
  const { Component, query, variables } = props

  return (
    <QueryRenderer<Q>
      environment={environment}
      query={query}
      variables={variables}
      render={({ props, error }) => {
        if (error) {
          return <div>{error.message}</div>
        } else if (props) {
          return <Component query={props}/>
        }
        return <Loader />
      }}
    />
  )
}

export { Table, Wrapper, Renderer as default }
