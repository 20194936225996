import React, { useContext } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { createFragmentContainer } from 'react-relay'
import styled from 'styled-components'
import { TagField_taggable as Fragment } from './__generated__/TagField_taggable.graphql'
import { Context, ContextType } from './Form'
import Section, { Preview } from './Metadata/Section'
import Checkbox, { presentOption } from '../Form/Checkbox'
import Keywords from './Keywords/Keywords'
import MultiSelect from './TagField/MultiSelect'

const SectionWrapper = styled.div`
  ${Preview} {
    text-transform: capitalize;
  }
`

const TagSwitch = (props: { tag: Fragment['tags'][number] }) => {
  const { onChange, disabled } = useContext(Context) as ContextType
  const { tag } = props

  const handleChange = (value: string[] | null) => {
    onChange(`tags[${tag.id}]`, value)
  }

  switch (tag.type) {
    case 'KEYWORDS':
      return <Keywords value={tag.value} onChange={handleChange} disabled={disabled} />
    case 'MULTI_SELECT':
      if (tag.fixedOptions && tag.fixedOptions.length > 9) {
        return <MultiSelect key={tag.id} tag={tag}/>
      } else {
        return (
          <Checkbox
            options={tag.fixedOptions || []}
            value={tag.value}
            onChange={handleChange}
            disabled={disabled}
          />
        )
      }
    default:
      return <div>No component for metatag with type {tag.type}!</div>
  }
}

type Props = {
  taggable: Fragment
  tagName: string
}

export const TagField = (props: Props) => {
  const { errors } = useContext(Context) as ContextType
  const { taggable, tagName } = props

  const tag = taggable.tags.find((t) => t.name === tagName)
  const preview = [...(tag?.value || [])].sort().map((v: string) => presentOption(v)).join(', ')

  return (
    <SectionWrapper>
      <Section name={tagName} preview={preview} errors={errors.for(`tags[${tagName}]`)} required={true}>
        {tag && <TagSwitch tag={tag} />}
      </Section>
    </SectionWrapper>
  )
}

TagField.displayName = 'TagField'


export default createFragmentContainer(TagField,
  {
    taggable: graphql`
      fragment TagField_taggable on Article {
        tags {
          id
          name
          fixedOptions
          value
          type
        }
      }
    `
  }
)
