import React from 'react'
import styled from 'styled-components'

const PaddingWrapper = styled.div`
  padding: 20px 70px;
  height: calc(100vw * 0.5);
  width: 100vw;
  max-height: 800px;
  box-sizing: border-box;
  max-width: 1421px;
`

const Wrapper = styled.div`
  border: 1px solid #cbd2d4;
  background-color: ${props => props.theme.colors.grays.athensGray};
  padding: 30px 60px 60px 60px;
  border-radius: 60px 60px 30px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
  box-shadow: -51px 51px 49px 0 rgba(0,0,0,0.06);
`

const Camera = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${props => props.theme.colors.grays.whiteSmoke};
  border-radius: 50%;
  position: relative;
  margin-bottom: 28px;

  &:after {
    content: '';
    background-color: #fffafa;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    position: absolute;
    left: 3px;
    top: 3px;
  }
`

const Screen = styled.div`
  width: 100%;
  height: 100%;
`

const ContentHeader = styled.div`
  width: 100%;
  background-color: #000000;
  height: 64px;
`

const Content = styled.div`
  padding: 30px 98px;
  background-color: #ffffff;
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
`

type Props = {
  html: string
}

const DesktopPreview: React.FC<Props> = ({html}) => {
  return (
    <PaddingWrapper>
      <Wrapper>
        <Camera/>
        <ContentHeader/>
        <Screen>
          <Content dangerouslySetInnerHTML={{__html: html}}/>
        </Screen>
      </Wrapper>
    </PaddingWrapper>
  )
}

export default DesktopPreview
