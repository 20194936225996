import SpotimLogo from '../../../assets/png/openweb-logo.png'
import { API, ToolConfig } from '@editorjs/editorjs'
import { createInputNode, createErrorNode } from './EditorElements'

export type SpotImData = {
  id: string
  org_id: string
}

interface Constructor {
  data?: SpotImData
  api: API
  config?: ToolConfig
}

export default class SpotIm {
  private spotImData: SpotImData
  private container: HTMLElement

  constructor(params: Constructor) {
    this.spotImData = params.data!
    this.container = document.createElement('div')
    this.previewHandler = this.previewHandler.bind(this)
  }

  static get toolbox() {
    return {
      icon: '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><image width="16" height="16" href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJN AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN RQfkCh0TOxZ/xCOXAAABUklEQVQoz03QO2uTcQCF8V/ev9o0qbUpVkijWYpVqKSoIMFbHZQu+gXE yc1v4YcRwVknXdVJIi2o1IYUNJjaNIlJtDRvc3FIvTzreYZzTjAmMmNZ1z23fVQw0jOEcCjMW3XJ MVec892qUwa2/whJOcGKnssibctastYljfQCch47b0LahG8+W9I2K+eBimpw1MhJZzRlPPfGhq8W xabteCVOKLhhwkXPvNMUI5i14JFNHR8iWWlJNSc0xGCgLqWOlNORn/bMSSs78I+KvpzYj8gnNQkb vvifhjWxjvfBXQ91dU3Z0j+Mg6JFk1Zkgl0lQ9eNHKiN33NBUVHZE2+DX+ZcVZGXV3bWvLRbrimZ saN0BPuq8lp67svqmLap6rhdTVFAy8ialJvaBvYNLNn2VFtJHDBSU1ew54UFQ681pbxU1ifxd1hG 36Q7BtZtmdIYF/4N2wN2wuG2FyoAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMTAtMjlUMTk6NTk6 MjIrMDA6MDAi3rnvAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTEwLTI5VDE5OjU5OjIyKzAwOjAw U4MBUwAAAABJRU5ErkJggg=="/></svg>',
      title: 'SpotIm'
    }
  }

  static get enableLineBreaks() {
    return true
  }

  render(): HTMLElement {
    if(this.spotImData && this.spotImData.id && this.spotImData.org_id) {
      this.createSpotImPreview()
    }
    else {
      createInputNode(this.container, 'spotim-code-snippet', SpotimLogo, this.previewHandler)
    }
    return this.container
  }

  private previewHandler(previewButton: HTMLElement) {
    previewButton.addEventListener('click', (e: Event) => {
      e.preventDefault()
      const codeSnippet: any = document.getElementById('spotim-code-snippet')
      if (codeSnippet && codeSnippet.value) {
        const dom: HTMLElement = document.createElement('div')
        dom.innerHTML = codeSnippet.value
        const organizationId = dom.children[0]?.getAttribute('src')?.split('/')?.pop()
        const id = dom.children[0]?.getAttribute('data-live-event-code')
        if (organizationId && id) {
          this.spotImData = {
            id: id,
            org_id: organizationId
          }
          this.container.innerHTML = ''
          this.createSpotImPreview()
          return
        }
      }
      createErrorNode(this.container)
    })
  }

  private createSpotImPreview() {
    const iframe = document.createElement('iframe')
    iframe.width = '100%'
    iframe.height = '560px'
    iframe.style.border = 'none'
    iframe.style.margin = '20px 0'
    const spotImContainer = document.createElement('div')
    spotImContainer.id = 'spotim-container'
    const spotImScript = document.createElement('script')
    spotImScript.setAttribute('src', 'https://launcher.spot.im/spot/' + this.spotImData.org_id)
    spotImScript.setAttribute('data-spotim-module', 'spotim-launcher')
    spotImScript.setAttribute('data-live-blog', 'true')
    spotImScript.setAttribute('data-live-event-code', this.spotImData.id)
    spotImContainer.appendChild(spotImScript)
    iframe.srcdoc = spotImContainer.outerHTML
    this.container.appendChild(iframe)
  }

  save(): SpotImData {
    return this.spotImData
  }
}
