import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import environment from '../../../relay/environment'
import { fetchQuery } from 'react-relay'
import { UppyFile } from '@uppy/core'
import { Uppy as UppyType } from '@uppy/core/types'
import { capitalize } from '@material-ui/core'
import { InternalContextType } from '../Form'
import { GraphQLTaggedNode } from 'relay-runtime/index'
import { HeroPickerQueryResponse as Response } from './__generated__/HeroPickerQuery.graphql'
import { ErrorUploadReasons, UploadResource } from './UploadResources'

import { Dimensions } from '../../../util/types/Dimensions'

export interface Params {
  setIsUploading: (value: boolean) => void
  setUploadPct: (value: number) => void
  setPreview: (value: string | null) => void
  onChange: InternalContextType['onChange']
  setError: (value: string) => void
  signedUrlQuery: GraphQLTaggedNode
  minDimensions: Dimensions
  file: File
  organizationId?: string
}

export default abstract class Upload {
  public setIsUploading: Params['setIsUploading']
  public setUploadPct: Params['setUploadPct']
  public setPreview: Params['setPreview']
  public onChange: Params['onChange']
  public setError: Params['setError']
  public signedUrlQuery: Params['signedUrlQuery']
  public minDimensions: Params['minDimensions']
  public organizationId: Params['organizationId']
  public file: Params['file']
  public uppy: UppyType

  protected abstract maxFileSize: number
  protected abstract fileTooBigMsg: string
  protected abstract getResourceDimensions(file: File): Promise<Dimensions>
  protected abstract handleUpload(): void

  public static dataUrlToFile(fileName: string, dataUrl: string) {
    var arr = dataUrl.split(','),
      mime = arr[0].match(/:(.*?);/)![1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], fileName, { type: mime })
  }

  constructor(params: Params) {
    this.setIsUploading = params.setIsUploading
    this.setUploadPct = params.setUploadPct
    this.setPreview = params.setPreview
    this.onChange = params.onChange
    this.setError = params.setError
    this.signedUrlQuery = params.signedUrlQuery
    this.minDimensions = params.minDimensions || {}
    this.organizationId = params.organizationId
    this.file = params.file

    this.uppy = Uppy({
      restrictions: { maxNumberOfFiles: 1 },
      allowMultipleUploads: false,
      autoProceed: true
    })
    this.configureUppy()
  }

  public async upload(resourceType: UploadResource) {
    if (await this.validateResource(resourceType)) {
      this.handleUpload()
    }
  }

  public async getSignedImageUrl(file: UppyFile): Promise<{}> {
    const vars = {
      extension: file.name.split('.').pop(),
      mimeType: file.type,
      final: !file.type?.startsWith('video/')
    }
    const data = (await fetchQuery(environment, this.signedUrlQuery as GraphQLTaggedNode, vars).toPromise()) as Response
    return {
      method: 'PUT',
      url: data.organization.mediaUploadUrl,
      fields: {},
      headers: {
        'Content-Type': file.type
      }
    }
  }

  protected async validateResource(resourceType: UploadResource): Promise<boolean> {
    // Do nothing and exit early if the file size is too big
    if (this.file.size > this.maxFileSize) {
      this.setError(this.fileTooBigMsg)
      return false
    }

    // If the file is an mxf file, skip remaining validation checks
    if(this.file.type.includes('mxf')) {
      return true
    }

    // Currently, the following operations replaces the error string instead of composing them together.
    let error = ''

    // Retrieve the necessary comparison data
    const { height: resourceHeight, width: resourceWidth } = await this.getResourceDimensions(this.file)
    const { height: minHeight, width: minWidth, resolution: minResolution, square: minSquare }: Dimensions = this.minDimensions

    // Check for a minimum height
    if (this.compareSizes(resourceHeight, minHeight)) {
      error = this.generateUploadErrorMessage(resourceType, ErrorUploadReasons.HEIGHT, minHeight)
    }

    // Check for a minimum width
    if (this.compareSizes(resourceWidth, minWidth)) {
      error = this.generateUploadErrorMessage(resourceType, ErrorUploadReasons.WIDTH, minWidth)
    }

    // Check for a minimum resolution (width + height)
    if (this.compareSizes(this.convertToResolution({ width: resourceWidth, height: resourceHeight }), minResolution)) {
      error = this.generateUploadErrorMessage(resourceType, ErrorUploadReasons.RESOLUTION, minResolution)
    }

    // Check if the resource must be a square
    if (typeof minSquare === 'number' && resourceHeight !== resourceWidth) {
      error = `${capitalize(resourceType)} must be a square`
    }

    this.setError(error)

    // If there is an error, fail the validation check; otherwise, approve the check.
    return !error
  }

  /**
   * Checks if the uploaded resource's dimension is below the set minimum requirement.
   */
  private compareSizes(resource: number | undefined = 0, minimum: number | undefined = 0): boolean {
    return resource < minimum
  }

  private generateUploadErrorMessage(resourceType: UploadResource, errorType: ErrorUploadReasons, value: number | undefined = 0) {
    return `${capitalize(resourceType)} ${errorType} must be at least ${value}px`
  }

  private configureUppy() {
    this.uppy.use(AwsS3, {
      getUploadParameters: async (file) => this.getSignedImageUrl(file),
      getResponseData: (responseText: XMLHttpRequest['responseText'], response: XMLHttpRequest) => ({
        url: response.responseURL.replace(/\?.*$/, '')
      }),
      limit: 1
    })
    this.uppy.on('upload-progress', (file, progress) => {
      this.setUploadPct(progress.bytesUploaded / progress.bytesTotal)
    })
    this.uppy.on('upload-error', (file, error, response) => {
      this.setIsUploading(false)
      this.setError(`There was an error uploading the ${this.file['type'].split('/')[0]}`)
    })
  }

  private convertToResolution({ width = 0, height = 0 }: Dimensions) {
    return width + height
  }
}
