import React from 'react'
import styled from 'styled-components'
import { CircularProgress } from '@rmwc/circular-progress'
import { UploadContainer, StyledImagePreview } from '../Thumbnail'

const StyledUploadContainer = styled(UploadContainer)`
  position: absolute;
  height: 100%;
`

const StyledCircularProgress = styled(CircularProgress)`
  position: absolute !important;
  z-index: 1;
  font-size: 2em !important;
  width: 2em !important;
  height: 2em !important;
  color: white !important;
`

const ImagePreview = styled(StyledImagePreview)`
  position: relative;
  width: 100%;
  height: 100%;
  opacity: 0.5;
`

type ProgressProps = {
  pct: number,
  preview: string | null,
  isUploading: boolean
}

const UploadProgress: React.FC<ProgressProps> = ({ pct, preview, isUploading }) => {
  return (
    <React.Fragment>
      {isUploading && <StyledUploadContainer>
        <StyledCircularProgress size='xlarge' progress={pct}/>
      {preview && <ImagePreview alt='upload preview' src={preview}/>}
      </StyledUploadContainer>}
    </React.Fragment>
  )
}

export default UploadProgress
