import React, { FC } from 'react'
import { commitMutation } from 'react-relay'
import { useSnackbar } from 'notistack'
import { graphql } from 'babel-plugin-relay/macro'
import environment from '../../relay/environment'
import { TopStoriesDeleteMutation, TopStoriesDeleteMutationResponse } from './__generated__/TopStoriesDeleteMutation.graphql'
import ConfirmModal from '../Modal'

export type Props = {
  id: string
  name: string
  handleClose: () => void
  open: boolean
  triggerRefresh: () => void
}

const deleteMutation = graphql`
  mutation TopStoriesDeleteMutation($id: ID!) {
    topStoryListDelete(id: $id) {
      topStoryList {
        id
        name
      }
      errors {
        field
        messages
      }
    }
  }
`

const TopStoriesDelete: FC<Props> = ({ id, handleClose, name, open, triggerRefresh }) => {
  /* library hooks */
  const { enqueueSnackbar } = useSnackbar()

  /* fetch callbacks */
  const deleteTopStory = () => {
    commitMutation<TopStoriesDeleteMutation>(environment, {
      mutation: deleteMutation,
      variables: { id },
      onCompleted: (resp: TopStoriesDeleteMutationResponse) => {
        enqueueSnackbar(`"${resp?.topStoryListDelete?.topStoryList?.name}" deleted`, { variant: 'success' })
        // Necessary for refreshing the entire list for the end user. Note: This refresh action will automatically
        //  close the modal on its own.
        triggerRefresh() 
      },
      onError: () => {
        enqueueSnackbar(`Cannot delete Top Story`, { variant: 'error' })
        handleClose()
      }
    })
  }

  return (
    <ConfirmModal
      buttonText='Delete'
      message={`Are you sure you want to delete "${name}"?`}
      onClick={deleteTopStory}
      onClose={handleClose}
      open={open}
      title='Delete Top Story'
    />
  )
}

export default TopStoriesDelete
