/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Media_SlugableQueryVariables = {
    id: string;
};
export type Media_SlugableQueryResponse = {
    readonly node: {
        readonly " $fragmentRefs": FragmentRefs<"MediaItem_slugable">;
    } | null;
};
export type Media_SlugableQuery = {
    readonly response: Media_SlugableQueryResponse;
    readonly variables: Media_SlugableQueryVariables;
};



/*
query Media_SlugableQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ...MediaItem_slugable
    id
  }
}

fragment MediaItem_slugable on Slugable {
  __isSlugable: __typename
  id
  description
  hero_video {
    duration
  }
  public_at
  public_url
  thumbnails(aspect_ratio: HD) {
    medium
  }
  title
  __typename
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Media_SlugableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "MediaItem_slugable"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Media_SlugableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "HeroVideo",
                "kind": "LinkedField",
                "name": "hero_video",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "duration",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "public_at",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "public_url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "aspect_ratio",
                    "value": "HD"
                  }
                ],
                "concreteType": "Thumbnail",
                "kind": "LinkedField",
                "name": "thumbnails",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "medium",
                    "storageKey": null
                  }
                ],
                "storageKey": "thumbnails(aspect_ratio:\"HD\")"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              }
            ],
            "type": "Slugable",
            "abstractKey": "__isSlugable"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3ac3c03e0ba99accfed5953ffa5b6cbb",
    "id": null,
    "metadata": {},
    "name": "Media_SlugableQuery",
    "operationKind": "query",
    "text": "query Media_SlugableQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...MediaItem_slugable\n    id\n  }\n}\n\nfragment MediaItem_slugable on Slugable {\n  __isSlugable: __typename\n  id\n  description\n  hero_video {\n    duration\n  }\n  public_at\n  public_url\n  thumbnails(aspect_ratio: HD) {\n    medium\n  }\n  title\n  __typename\n}\n"
  }
};
})();
(node as any).hash = 'eb8138df1732d8792278beb32317823e';
export default node;
