/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type Roles = "ADMIN" | "CMS" | "EDITOR" | "WRITER" | "%future added value";
export type TopStoriesIndexQueryVariables = {};
export type TopStoriesIndexQueryResponse = {
    readonly user: {
        readonly id: string;
        readonly role: Roles | null;
    } | null;
    readonly organization: {
        readonly id: string;
        readonly topStoryLists: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly user: {
                        readonly firstName: string | null;
                        readonly lastName: string | null;
                    };
                    readonly updatedAt: string;
                    readonly id: string;
                    readonly name: string;
                } | null;
            } | null> | null;
        } | null;
    };
};
export type TopStoriesIndexQuery = {
    readonly response: TopStoriesIndexQueryResponse;
    readonly variables: TopStoriesIndexQueryVariables;
};



/*
query TopStoriesIndexQuery {
  user {
    id
    role
  }
  organization {
    id
    topStoryLists {
      edges {
        node {
          user {
            firstName
            lastName
            id
          }
          updatedAt
          id
          name
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TopStoriesIndexQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TopStoryListConnection",
            "kind": "LinkedField",
            "name": "topStoryLists",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TopStoryListEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TopStoryList",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v0/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TopStoriesIndexQuery",
    "selections": [
      (v1/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "TopStoryListConnection",
            "kind": "LinkedField",
            "name": "topStoryLists",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TopStoryListEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TopStoryList",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v0/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "58ec1720a6340ad2ca618300f937915e",
    "id": null,
    "metadata": {},
    "name": "TopStoriesIndexQuery",
    "operationKind": "query",
    "text": "query TopStoriesIndexQuery {\n  user {\n    id\n    role\n  }\n  organization {\n    id\n    topStoryLists {\n      edges {\n        node {\n          user {\n            firstName\n            lastName\n            id\n          }\n          updatedAt\n          id\n          name\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '7ed85449bfd173f1a2a270aa3acb234b';
export default node;
