import React, { FC, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { createPaginationContainer, RelayPaginationProp } from 'react-relay'
import { TableCell } from '@material-ui/core'
import { UserIndexQuery as Query } from './__generated__/UserIndexQuery.graphql'
import { UserIndex_query as Response } from './__generated__/UserIndex_query.graphql'
import { Wrapper } from '../Livestream'
import { PaginatedTable, PaginatedWrapper } from '../../Index'
import Header from '../Header'
import { AddButton as Button } from '../Metatags/Controls'
import ListItem from './User'
import { PER_PAGE } from '../Authors/AuthorIndex'
import { List } from '../DataList'
import UserCreateModal from '../Users/UserInvite'

const query = graphql`
  query UserIndexQuery (
    $count: Int!,
    $cursor: String,
    $query: String
  ) {
    ...UserIndex_query @arguments(count: $count, cursor: $cursor, query: $query)
  }
`

const UsersTableHeader: FC = () => (
  <>
    <TableCell>Name</TableCell>
    <TableCell>Email</TableCell>
    <TableCell>Role</TableCell>
    <TableCell align='right'>Actions</TableCell>
  </>
)

const Users = createPaginationContainer(
  (props: { query: Response, relay: RelayPaginationProp }) => {
    const {query: { organization: { id, users }}, relay } = props
    const [updateList, setUpdateList] = useState(false)
    const [showModal, setShowModal] = useState(false)

    const updateUserPage = () => setUpdateList((s) => !s)

    return (
      <Wrapper>
        <PaginatedWrapper
          updateList={updateList}
          search={true}
          rowCount={users?.edges?.length}
          relay={relay}
          perPage={PER_PAGE}
          title='Users'
          isLegacyStyle={false}
          HeaderComponent={() => <Header title='Users'><Button handleClick={() => setShowModal(true)} text='Invite'/></Header>}>
          <List header={<UsersTableHeader/>}>
            {users?.edges?.map(user => <ListItem key={user?.node?.id!} user={user?.node!} id={user?.node?.id!} updateUserPage={updateUserPage}/>)}
          </List>
        </PaginatedWrapper>
        <UserCreateModal open={showModal} onClose={() => setShowModal(false)} id={id} updateAuthorPage={updateUserPage}/>
      </Wrapper>
    )
  },
  {
    query: graphql`
      fragment UserIndex_query on Query
      @argumentDefinitions(
        count: { type: "Int" }
        cursor: { type: "String" }
        query: { type: "String" }
      ) {
        organization {
          id
          users(
            first: $count,
            after: $cursor,
            query: $query
          ) @connection(key: "Users_users") {
            edges {
              node {
                ...on User {
                  id
                  email
                  first_name
                  last_name
                  role
                }
              }
            }
          }
        }
      }
    `
  },
  {
    query,
    getVariables(props, { count, cursor }, { query }) {
      return {
        count,
        cursor,
        query
      }
    }
  }
)

export default () => <PaginatedTable<Query> query={query} Component={Users} variables={{ count: PER_PAGE }}/>
