import React, { FC } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { createFragmentContainer, QueryRenderer } from 'react-relay'
import { RouteComponentProps } from 'react-router-dom'
import { Box, Grid } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import environment from '../../relay/environment'
import { TopStoryListEditQuery as Query } from './__generated__/TopStoryListEditQuery.graphql'
import { TopStoryListEdit_node as Node } from './__generated__/TopStoryListEdit_node.graphql'
import { TopStoryListEditMutationResponse } from './__generated__/TopStoryListEditMutation.graphql'
import { Wrapper } from '../Settings/Livestream'
import { validationSchema } from './TopStoriesCreate'
import { Props } from '../Settings/Users/Avatar'
import { formatFields } from '../Settings/formatFields'
import Form, { OnCompletedOptions as FormProps, handleNonFieldErrors, formatErrors } from '../Settings/Form'
import Loader from '../Loader'
import StaticFields from './StaticFields'
import Header from '../Settings/Header'
import Controls from '../Settings/Controls'
import TagRenderer from '../Settings/Users/TagRenderer'

const mutation = graphql`
  mutation TopStoryListEditMutation($id: ID!, $input: TopStoryListInput!) {
    topStoryListUpdate(id: $id, input: $input) {
      topStoryList {
        id
        name
        isDeleted
        tags {
          id
          name
          fixedOptions
          value
          type
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

const query = graphql`
  query TopStoryListEditQuery($id: ID!) {
    node(id: $id) {
      ...TopStoryListEdit_node
    }
  }
`

export interface OnCompleteProps extends FormProps {
  values: TopStoryListEditMutationResponse
}

const initialValues = ({ id, max, tags, min, name }: Node) => ({
  id,
  input: {
    name,
    min,
    max,
    tags: tags.reduce((acc, tag) => ({ ...acc, [tag.name]: [tag.id, tag.value] }), {})
  }
})

/**
 * Format Form values before sending them to the server. Backend expects tag values to be in this format ({ source:  tag_id, value: tag_name })
 */
const formatValues = (values: any) => {
  let tags = []
  for (let tag in values.input.tags) {
    tags.push({ source: values.input.tags[tag][0], value: values.input.tags[tag][1] })
  }
  return { ...values, input: { ...values.input, tags: tags.filter((tag) => tag.value && tag?.value?.length > 0) } }
}

const Fields: FC<{ node: Node; scopeType: string }> = ({ node, scopeType }) => (
  <>
    <Header title='Edit Top Story List' />
    <Box m={8}>
      <Grid container direction='row' alignItems='flex-start' justify='space-between'>
        <Grid container item direction='column' md={4} xs={7}>
          <StaticFields />
        </Grid>
        <Grid container item direction='column' md={4} xs={7}>
          {node.tags.map((tag: Props['tag']) => (
            <Box mt={1} mb={4} key={tag.id}>
              <TagRenderer tag={tag} scopeType={scopeType} />
            </Box>
          ))}
        </Grid>
      </Grid>
    </Box>
  </>
)

const TopStoryListEdit = createFragmentContainer(
  (props: { node: Node }) => {
    const { node } = props
    const { enqueueSnackbar } = useSnackbar()

    const onComplete = ({ values: { topStoryListUpdate }, setErrors, formValues }: OnCompleteProps) => {
      if (!topStoryListUpdate?.errors.length) {
        enqueueSnackbar('Information successfully updated.', { variant: 'success' })
      } else {
        handleNonFieldErrors(topStoryListUpdate.errors, formatFields(formValues!.input), enqueueSnackbar)
        setErrors({ input: formatErrors(topStoryListUpdate?.errors) })
      }
    }

    return (
      <Form
        formatValues={formatValues}
        mutation={mutation}
        onComplete={onComplete}
        validationSchema={validationSchema}
        initialValues={initialValues(node)}
      >
        <Wrapper>
          <Fields node={node} scopeType={node.__typename} />
          <Controls redirectTo='topstories' isAdmin={true} numberOfStories={node.stories.length} />
        </Wrapper>
      </Form>
    )
  },
  {
    node: graphql`
      fragment TopStoryListEdit_node on TopStoryList {
        __typename
        stories {
          story
        }
        id
        name
        min
        max
        tags {
          id
          name
          fixedOptions
          value
          type
        }
      }
    `
  }
)

const Renderer: FC<RouteComponentProps<{ id: string }>> = ({ match }) => (
  <QueryRenderer<Query>
    environment={environment}
    query={query}
    variables={match.params}
    render={({ props }) => {
      if (props && props.node) {
        return <TopStoryListEdit node={props.node} />
      } else {
        return <Loader />
      }
    }}
  />
)

export default Renderer
