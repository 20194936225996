import styled from 'styled-components'

const CalendarContainer = styled.div`
  .react-datepicker {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
    border-radius: 4px;
    box-shadow: 0 2px 2px 0 #eff0f2;
    border: solid 1px #f4f5f7;
    margin-top: 5px;
  }
  .react-datepicker__header {
    background-color: #ffffff;
    border: none;
  }
  .react-datepicker__month-container {
    float: none;
  }
  .react-datepicker__month {
    margin: 4px 10.5px;
  }
  .react-datepicker__day-names {
    text-transform: uppercase;
  }
  .react-datepicker__day-name { 
    width: 28px;
    user-select: none;
    color: #999a9c;
    font-weight: 500;
  }
  .react-datepicker__day {
    font-size: 12px;
    user-select: none;
    width: 31px;
    margin: 1px;
    color: #c8c9cb;
    font-weight: 500;
    &:hover {
      border-radius: 0;
      color: #ffffff;
      font-weight: bold;
      background-color: #ab96fb;
    }
    outline: none;
  }
  .react-datepicker__day--disabled, .react-datepicker__day--outside-month {
    background-color: ${props => props.theme.colors.grays.solitude};
    color: #bac2c7;
    font-weight: 200;
    &:hover {
      background-color: ${props => props.theme.colors.grays.solitude};
      color: #bac2c7;
      font-weight: 200;
    }
    outline: none;
  }
  .react-datepicker__day--selected {
    background-color: #896aff; 
    border-radius: 0; 
    color: #ffffff; 
    font-weight: bold;
    &:hover {
      background-color: #896aff; 
      border-radius: 0; 
      color: #ffffff; 
      font-weight: bold; 
    }
  }
  .react-datepicker__day--keyboard-selected {
    background-color: #896aff; 
    border-radius: 0; 
    color: #ffffff;
    font-weight: bold;
    &:hover {
      background-color: #896aff; 
      border-radius: 0; 
      color: #ffffff; 
      font-weight: bold; 
    }
  }
  .react-datepicker__input-time-container {
    float: none;
    margin: 0;
    width: 100%;
    padding-top: 16px;
    padding-bottom: 20px;
    &:before {
      content: '';
      width: 72%;
      height: 1px;
      background-color: #f4f5f7;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
    }
  }
  div.react-datepicker-time__input { 
    display: block !important;
    margin: 0 !important;
    padding-top: 14px;
    width: 100%;
    text-align: center;
  }
  input.react-datepicker-time__input { 
    border-radius: 2px;
    box-shadow: 0 2px 4px 0 rgba(238, 238, 238, 0.32);
    border: solid 2px rgba(151, 151, 151, 0.1);
    text-align: center;
    color: #b4b5b7;
    font-size: 12px;
    margin: 0 !important;
    padding: 0;
    width: 160px !important;
    font-weight: normal;
    line-height: 18px;
    padding: 2px 0 2px 10px;
    outline: none;
    cursor: text;
  }
  .react-datepicker-time__input-container {
    display: block !important;
  }
  .react-datepicker-time__caption {
    display: none !important;
  }
`

export default CalendarContainer
