import React, { FC, useState } from 'react'
import { useSnackbar } from 'notistack'
import { commitMutation } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import environment from '../../../relay/environment'
import { Box, capitalize, TextField } from '@material-ui/core'
import { FormikContextType, useFormikContext } from 'formik'
import { UserEdit_node as NodeResponse } from './__generated__/UserEdit_node.graphql'
import { AuthorToggleMutation } from './__generated__/AuthorToggleMutation.graphql'
import Schema from './Schema'
import ToggleButton from '../ToggleButton'

const mutation = graphql`
  mutation AuthorToggleMutation($id: ID!, $isAuthor: Boolean!, $slug: String)  {
    userAuthorToggle(id: $id, isAuthor: $isAuthor, slug: $slug) {
      user {
        ...UserEdit_node @relay(mask: false)
      }
      errors {
        field
        messages
      }
    }
  }
`

type Props = {
  node: NodeResponse
  refetch: (node: NodeResponse) => void
}

const camelToUnderscore = (key: string) =>
  key
    .replace(/([A-Z])/g, ' $1')
    .split(' ')
    .join('_')
    .toLowerCase()

type SlugFieldProps = {
  error: string
  handleChange: (value: string) => void
  handleError: (value: string) => void
  value: string
}

const SlugField: FC<SlugFieldProps> = ({ value, error, handleChange, handleError }) => {
  return (
    <Box mb={4} height={55}>
      <TextField
        fullWidth
        variant='outlined'
        type='text'
        id='slug'
        label='Slug'
        name='slug'
        onChange={(e) => {
          handleChange(e.target.value)
          handleError('')
        }}
        value={value}
        error={Boolean(error)}
        helperText={error}
      />
    </Box>
  )
}

const AuthorToggle: FC<Props> = ({ refetch, node }) => {
  const [showSlugField, setShowSlugField] = useState(false)
  const [slugValue, setSlugValue] = useState('')
  const [slugError, setSlugError] = useState('')
  const { values, setErrors, setTouched }: FormikContextType<Schema> = useFormikContext()
  const { enqueueSnackbar } = useSnackbar()

  return (
    <>
      <Box mt={1} mb={2}>
        <ToggleButton
          active={node?.author?.active ? node.author.active : false}
          handleChange={(e) => {
            commitMutation<AuthorToggleMutation>(environment, {
              mutation,
              variables: {
                id: node!.id,
                isAuthor: e.target.checked,
                slug: slugValue
              },
              onCompleted: ({ userAuthorToggle }) => {
                if (!userAuthorToggle?.errors?.length) {
                  setShowSlugField(false)
                  enqueueSnackbar(`Author ${userAuthorToggle?.user?.author?.active ? 'activated' : 'deactivated'}.`, { variant: 'success' })
                  refetch!(userAuthorToggle?.user as NodeResponse)
                } else {
                  if (userAuthorToggle.errors[0].field === 'firstName' || userAuthorToggle.errors[0].field === 'lastName') {
                    if (values.input.first_name && values.input.last_name) {
                      enqueueSnackbar('Please save form before enabling author.', { variant: 'error' })
                    } else {
                      setErrors({
                        input: userAuthorToggle.errors.reduce(
                          (acc, val) => ({ ...acc, [camelToUnderscore(val.field)]: val.messages.join('; ') }),
                          {}
                        )
                      })
                      setTouched({
                        input: {
                          first_name: true,
                          last_name: true
                        }
                      })
                    }
                    return
                  }
                  if (userAuthorToggle.errors[0].field === 'author.slug') {
                    !slugValue && setSlugValue(`${values.input.first_name}-${values.input.last_name}`)
                    setSlugError(capitalize(userAuthorToggle.errors[0].messages.join('; ')))
                    setShowSlugField(true)
                  }
                }
              },
              onError: () => {
                enqueueSnackbar('Unable to activate Author, please check all fields.', { variant: 'error' })
              }
            })
          }}
          name='input.author.active'
          title='Author'
          titlePosition='start'
        />
      </Box>
      {showSlugField && (
        <Box mt={1} mb={2}>
          <SlugField
            value={slugValue}
            error={slugError}
            handleChange={(value) => setSlugValue(value)}
            handleError={(error) => setSlugError(error)}
          />
        </Box>
      )}
    </>
  )
}

export default AuthorToggle
