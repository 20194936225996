/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type MetatagType = "AVATAR" | "KEYWORDS" | "MULTI_SELECT" | "SINGLE_SELECT" | "TEXT" | "THUMBNAIL" | "%future added value";
export type AuthorInput = {
    active?: boolean | null;
    byline?: string | null;
    slug?: string | null;
    tags?: Array<TagInput> | null;
};
export type TagInput = {
    source: unknown;
    value: Array<string>;
};
export type AuthorEditMutationVariables = {
    id: string;
    input: AuthorInput;
};
export type AuthorEditMutationResponse = {
    readonly authorUpdate: {
        readonly author: {
            readonly id: string;
            readonly byline: string;
            readonly slug: string;
            readonly active: boolean;
            readonly tags: ReadonlyArray<{
                readonly id: string;
                readonly name: string;
                readonly fixedOptions: ReadonlyArray<string> | null;
                readonly value: ReadonlyArray<string> | null;
                readonly type: MetatagType;
            }>;
        } | null;
        readonly errors: ReadonlyArray<{
            readonly field: string;
            readonly messages: ReadonlyArray<string>;
        }>;
    } | null;
};
export type AuthorEditMutation = {
    readonly response: AuthorEditMutationResponse;
    readonly variables: AuthorEditMutationVariables;
};



/*
mutation AuthorEditMutation(
  $id: ID!
  $input: AuthorInput!
) {
  authorUpdate(id: $id, input: $input) {
    author {
      id
      byline
      slug
      active
      tags {
        id
        name
        fixedOptions
        value
        type
      }
    }
    errors {
      field
      messages
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "AuthorUpdateMutationPayload",
    "kind": "LinkedField",
    "name": "authorUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Author",
        "kind": "LinkedField",
        "name": "author",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "byline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fixedOptions",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "messages",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthorEditMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthorEditMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "6122d0da13bc7613bb09bc1bdc9098da",
    "id": null,
    "metadata": {},
    "name": "AuthorEditMutation",
    "operationKind": "mutation",
    "text": "mutation AuthorEditMutation(\n  $id: ID!\n  $input: AuthorInput!\n) {\n  authorUpdate(id: $id, input: $input) {\n    author {\n      id\n      byline\n      slug\n      active\n      tags {\n        id\n        name\n        fixedOptions\n        value\n        type\n      }\n    }\n    errors {\n      field\n      messages\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '5c929e13e12bbbd5ff2952e0bd8b8d98';
export default node;
