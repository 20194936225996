import React, { FC, useState } from 'react'
import { createFragmentContainer, QueryRenderer } from 'react-relay'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { graphql } from 'babel-plugin-relay/macro'
import moment from 'moment'
import { useSnackbar } from 'notistack'
import * as Yup from 'yup'

import environment from '../../../relay/environment'
import { TopStoryEditQuery as Query } from './__generated__/TopStoryEditQuery.graphql'
import { TopStoryEdit_node as Node } from './__generated__/TopStoryEdit_node.graphql'
import { TopStoryEdit_user as UserResponse } from './__generated__/TopStoryEdit_user.graphql'

import Button, { ButtonActions } from '../../Button'
import Loader from '../../Loader'
import Controls from '../../Settings/Controls'
import Header from '../../Settings/Header'
import Form from '../../Settings/Form'
import { Wrapper } from '../../Settings/Livestream'
import { OnCompleteProps } from '../TopStoryListEdit'
import AddTopStory from './AddTopStory'
import List from './List'
import { StoryId } from './types'

const query = graphql`
  query TopStoryEditQuery($id: ID!) {
    node(id: $id) {
      ...TopStoryEdit_node
    },
    user {
      ...TopStoryEdit_user
    }
  }
`

const mutation = graphql`
  mutation TopStoryEditMutation($id: ID!, $input: TopStoryListInput!) {
    topStoryListUpdate(id: $id, input: $input) {
      topStoryList {
        id
        name
        stories {
          story
          order
        }
      }
      errors {
        field
        messages
      }
    }
  }
`

export type StoryList = Node['orderedStories']
export type Story = NonNullable<Node['orderedStories']>[0]
export type ModifiedStoryList = Array<{
  order: number
  id: string
  public_at: unknown
  title: string | null
  thumbnails: {
    readonly small: string | null
  } | null
}>

export const publicationDate = (date: string) => moment.tz(date, 'America/New_York').format('MMM D, YYYY h:mma z')

/**
 * Append the 'order' value to each story in the list
 */
const formatList = (stories: Node['stories'], orderedStories: StoryList) => {
  return orderedStories!.map((story, i) => ({
    ...story,
    order: stories[i]?.order
  }))
}

/**
 * Format the list for the Formik form
 */
const formatValues = ({ id, storyList }: StoryId & any) => {
  return {
    id,
    input: { stories: storyList.map(({ id, order }: any) => ({ order, story: id })) }
  }
}

const TopStoryEdit = createFragmentContainer(
  (props: { node: Node; user: UserResponse }) => {
    const {
      node: { id, name, stories, orderedStories, min, max },
      user
    } = props
    const history = useHistory()
    const { enqueueSnackbar } = useSnackbar()
    const [showModal, setShowModal] = useState(false)
    const [storyList, setStoryList] = useState<ModifiedStoryList>(formatList(stories, orderedStories))
    const [storyToReplace, setStoryToReplace] = useState<StoryId | undefined>(undefined)

    const addStoryHandler = (newStoryList: ModifiedStoryList) => void setStoryList(newStoryList)

    const onComplete = ({ values: { topStoryListUpdate } }: OnCompleteProps) => {
      if (!topStoryListUpdate?.errors.length) {
        enqueueSnackbar('List successfully updated.', { variant: 'success' })
      } else {
        if (topStoryListUpdate?.errors[0].field === 'stories') {
          enqueueSnackbar(topStoryListUpdate?.errors[0].messages, { variant: 'error' })
        } else {
          enqueueSnackbar('Unable to save your changes. Please backup your work and try again later.', { variant: 'error' })
        }
      }
    }

    const updateStories = (stories: any) => setStoryList(stories)

    const replaceStory = ({ id }: StoryId) => {
      setStoryToReplace({ id })
      setShowModal(true)
    }

    return (
      <Form
        mutation={mutation}
        onComplete={onComplete}
        validationSchema={Yup.object().shape({})}
        initialValues={formatValues({ id, storyList })}
      >
        <Wrapper>
          <Header title={`Top Stories: ${name}`}>
            {user?.role === 'ADMIN' && <Button onClick={() => history.push(`/topstorylist/${id}`)} buttonType={ButtonActions.Edit} />}
          </Header>
          <List
            max={max}
            min={min}
            replaceStory={replaceStory}
            setShowModal={setShowModal}
            storyList={storyList}
            updateStories={updateStories}
          />
          <AddTopStory
            showModal={showModal}
            onClose={() => {
              setStoryToReplace(undefined)
              setShowModal(false)
            }}
            storyToReplace={storyToReplace}
            addStoryHandler={addStoryHandler}
            storyList={storyList}
          />
          <Controls redirectTo='topstories' isAdmin={true} />
        </Wrapper>
      </Form>
    )
  },
  {
    node: graphql`
      fragment TopStoryEdit_node on TopStoryList {
        __typename
        stories {
          order
          story
        }
        orderedStories {
          id
          public_at
          title
          thumbnails(aspect_ratio: SQUARE) {
            small
          }
        }
        id
        name
        max
        min
      }
    `,
    user: graphql`
      fragment TopStoryEdit_user on User {
        role
      }
    `
  }
)

const Renderer: FC<RouteComponentProps<StoryId>> = ({ match }) => (
  <QueryRenderer<Query>
    environment={environment}
    query={query}
    variables={match.params}
    render={({ props }) => {
      if (props && props.node) {
        return <TopStoryEdit node={props.node} user={props.user!} />
      } else {
        return <Loader />
      }
    }}
  />
)

export default Renderer
