import React from 'react'
import styled from 'styled-components'

const ErrorContainer = styled.div`
  background-color: ${props => props.theme.colors.primary.mediumSlateBlue};
  height: 5vh;
  line-height: 5vh;
  width: 100%;
  position: fixed;
  text-align: center;
  padding: 10px;
`
ErrorContainer.displayName = 'ErrorContainer'

export type ErrorType = { message: string | null }

const ErrorRenderer: React.FC<ErrorType> = (ErrorType) => {
  const {message} = ErrorType
  return (
    <React.Fragment>
      {message && message.length > 0 && <ErrorContainer>Error: {message}</ErrorContainer>}
    </React.Fragment>
  )
}

export default ErrorRenderer
