import React, { FC } from 'react'
import { capitalize, TableCell } from '@material-ui/core'
import { TopStoriesIndexQueryResponse } from './__generated__/TopStoriesIndexQuery.graphql'
import { List } from '../Settings/DataList'
import { Roles } from '../__generated__/Layout_device.graphql'
import TopStory from './TopStory'
import { StoryToDelete } from './TopStoriesIndex'

export type TopStoriesListProps = {
  handleDelete: ({ id, name }: StoryToDelete) => void
  organization?: TopStoriesIndexQueryResponse['organization']
  user?: TopStoriesIndexQueryResponse['user']
}

const TopStoriesList = ({ handleDelete, organization, user }: TopStoriesListProps) => {
  return (
    <List header={<TableHeader role={user?.role!} />}>
      {organization?.topStoryLists?.edges?.map(
        (story) =>
          story?.node?.id && (
            <TopStory
              key={story?.node?.id}
              id={story?.node?.id}
              name={story?.node?.name!}
              editedBy={`${capitalize(story?.node?.user.firstName! || '')} ${capitalize(story?.node?.user.lastName![0] || '')}`}
              updatedAt={story?.node?.updatedAt!}
              role={user?.role!}
              handleDelete={handleDelete}
            />
          )
      )}
    </List>
  )
}

const TableHeader: FC<{ role: Roles }> = ({ role }) => (
  <>
    <TableCell style={{ width: 300 }} />
    <TableCell>Last edited by</TableCell>
    <TableCell>Last Modified</TableCell>
    {role === 'ADMIN' ? <TableCell align='right'>Actions</TableCell> : <TableCell />}
  </>
)

export default TopStoriesList
