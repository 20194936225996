/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PageDetail_draft = {
    readonly id: string;
    readonly canUpdate: boolean;
    readonly draftable: {
        readonly template: {
            readonly name: string;
            readonly body: string | null;
        } | null;
        readonly name?: string;
        readonly description?: string | null;
        readonly slug?: string;
        readonly content?: ReadonlyArray<{
            readonly source: string;
            readonly value: string;
        }>;
        readonly " $fragmentRefs": FragmentRefs<"TemplateSelect_draftable" | "Thumbnail_page">;
    };
    readonly errors: ReadonlyArray<{
        readonly field: string;
        readonly messages: ReadonlyArray<string>;
    }>;
    readonly warnings: ReadonlyArray<{
        readonly field: string;
        readonly messages: ReadonlyArray<string>;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"Controls_draft">;
    readonly " $refType": "PageDetail_draft";
};
export type PageDetail_draft$data = PageDetail_draft;
export type PageDetail_draft$key = {
    readonly " $data"?: PageDetail_draft$data;
    readonly " $fragmentRefs": FragmentRefs<"PageDetail_draft">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "field",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "messages",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PageDetail_draft",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "draftable",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Template",
          "kind": "LinkedField",
          "name": "template",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "body",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TemplateSelect_draftable"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageContent",
              "kind": "LinkedField",
              "name": "content",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "source",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Thumbnail_page"
            }
          ],
          "type": "Page",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Error",
      "kind": "LinkedField",
      "name": "errors",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Error",
      "kind": "LinkedField",
      "name": "warnings",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Controls_draft"
    }
  ],
  "type": "Draft",
  "abstractKey": null
};
})();
(node as any).hash = '0d824fabf64ee780ff1c12de7a3d2a4a';
export default node;
