import Upload from './Upload'
import environment from '../../../relay/environment'
import { commitMutation } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import {
  VideoUploadVideoFileCreateMutation,
  VideoUploadVideoFileCreateMutationResponse
} from './__generated__/VideoUploadVideoFileCreateMutation.graphql'
import { PayloadError } from 'relay-runtime'
import ImageUpload from './ImageUpload'
import { UploadResources } from './UploadResources'

import { Dimensions, ResourceDimensions } from '../../../util/types/Dimensions'

export default class VideoUpload extends Upload {
  protected maxFileSize = 5368709120
  protected fileTooBigMsg = 'Video size exceeds 5GB'

  protected async getResourceDimensions(file: File): Promise<Dimensions> {
    return new Promise((resolve) => {
      const video = document.createElement('video')
      video.muted = true
      video.style.display = 'none'
      video.onloadedmetadata = (event) => {
        const { videoHeight, videoWidth } = event.target as HTMLVideoElement
        resolve({
          height: videoHeight,
          width: videoWidth,
          type: ''
        } as ResourceDimensions)
      }
      video.setAttribute('src', URL.createObjectURL(file))
    })
  }

  protected handleUpload() {
    this.uppy.on('upload', () => {
      this.setUploadPct(0.0)
      this.setIsUploading(true)
      this.generateImageFromVideo().then((imageData) => {
        this.setPreview(imageData.imageDataUrl)
        const file = Upload.dataUrlToFile(imageData.fileName, imageData.imageDataUrl)
        new ImageUpload({
          ...this,
          setIsUploading: () => {},
          setUploadPct: () => {},
          minDimensions: this.minDimensions,
          file,
          onChange: (prop: string, value: any) => {
            if (prop !== 'videoFileId') {
              this.onChange(prop, value)
            }
          }
        }).upload(UploadResources.VIDEO)
      })
    })
    this.uppy.on('upload-success', (file, response) => {
      this.saveVideo({ fileName: file.name, url: response.body.url }).then((response: VideoUploadVideoFileCreateMutationResponse) => {
        const id = response.videoFileCreate?.videoFile.id
        this.onChange('videoFileId', id)
        this.setIsUploading(false)
        this.uppy.close()
      })
    })
    this.uppy.addFile({ name: this.file.name, type: this.file.type, data: this.file })
  }

  private generateImage(video: HTMLVideoElement): Promise<string> {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas')
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      canvas?.getContext('2d')?.drawImage(video, 0, 0, canvas.width, canvas.height)
      resolve(canvas.toDataURL())
    })
  }

  private generateImageFromVideo(): Promise<{ fileName: string; imageDataUrl: string }> {
    return new Promise((resolve) => {
      const fileReader = new FileReader()
      fileReader.onload = (e) => {
        const blob = new Blob([e?.target?.result as ArrayBuffer], { type: this.file.type })
        const video = document.createElement('video')
        video.ontimeupdate = (event) => {
          video.pause()
        }
        video.onloadeddata = (event) => {
          this.generateImage(event.target as HTMLVideoElement).then((imageDataUrl: string) =>
            resolve({ fileName: this.file.name.split('.')[0], imageDataUrl })
          )
        }
        video.style.display = 'none'
        video.setAttribute('src', URL.createObjectURL(blob))
        video.muted = true
        video.play()
      }
      fileReader.readAsArrayBuffer(this.file)
    })
  }

  private saveVideo({ url, fileName }: { url: string; fileName: string }): Promise<VideoUploadVideoFileCreateMutationResponse> {
    const mutation = graphql`
      mutation VideoUploadVideoFileCreateMutation($organizationId: ID, $input: VideoFileInput!) {
        videoFileCreate(organizationId: $organizationId, input: $input) {
          videoFile {
            id
          }
        }
      }
    `

    return new Promise((resolve, reject) => {
      const { organizationId } = this
      const source = new URL(url).pathname.replace(/^\/|\.\w+$/g, '')

      commitMutation<VideoUploadVideoFileCreateMutation>(environment, {
        mutation: mutation,
        variables: { organizationId, input: { source, url, filename: fileName } },
        onCompleted: (response: VideoUploadVideoFileCreateMutationResponse, errors?: readonly PayloadError[] | null | undefined) => {
          resolve(response)
        },
        onError: reject
      })
    })
  }
}
