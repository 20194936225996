import React from 'react'
import { useHistory } from 'react-router-dom'
import { createPaginationContainer, RelayPaginationProp } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import { Draftable, PaginatedTable, PaginatedWrapper } from './Index'
import { TemplateIndexQuery as Query } from './__generated__/TemplateIndexQuery.graphql'
import { TemplateIndex_query as Response } from './__generated__/TemplateIndex_query.graphql'
import { TemplateIndexCreateTemplateMutation as Mutation } from './__generated__/TemplateIndexCreateTemplateMutation.graphql'

const PER_PAGE = 10

const query = graphql`
  query TemplateIndexQuery (
    $count: Int!,
    $cursor: String
  ) {
    ...TemplateIndex_query @arguments(count: $count, cursor: $cursor)
  }
`

const mutation = graphql`
  mutation TemplateIndexCreateTemplateMutation($organizationId: ID!) {
    templateCreate(organizationId: $organizationId) {
      draft {
        draftable {
          ...on Node {
            id
          }
        }
      }
    }
  }
`

const Container = createPaginationContainer(
  (props: { query: Response, relay: RelayPaginationProp }) => {
    const history = useHistory()
    const { query, relay } = props

    return (
      <PaginatedWrapper<Mutation>
        relay={relay}
        variables={{ organizationId: query?.organization.id }}
        mutation={mutation}
        perPage={PER_PAGE}
        title='Templates'
        onCreate={(props) => {
          history.push(`/templates/${props?.templateCreate?.draft?.draftable?.id}`)
        }}>

        <Draftable.Header />
        {query?.organization.templates?.edges?.map((e: any, i: number) => (
          <Draftable.Cell key={i} draftable={e.node} index={i} />
        ))}
      </PaginatedWrapper>
    )
  },
  {
    query: graphql`
      fragment TemplateIndex_query on Query
      @argumentDefinitions(
        count: { type: "Int" }
        cursor: { type: "String" }
      ) {
        organization {
          id
          templates(
            first: $count,
            after: $cursor
          ) @connection(key: "TemplateIndex_templates") {
            edges {
              node {
                id
                ...DraftableCell_draftable
              }
            }
          }
        }
      }
    `
  },
  {
    query,
    getVariables(props, { count, cursor }) {
      return {
        count,
        cursor
      }
    }
  }
)

export default () => (
  <PaginatedTable<Query> Component={Container} query={query} variables={{ count: PER_PAGE }} />
)
