/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type AuthorDeleteMutationVariables = {
    id: string;
};
export type AuthorDeleteMutationResponse = {
    readonly authorDelete: {
        readonly author: {
            readonly byline: string;
            readonly isDeleted: boolean;
            readonly id: string;
        };
        readonly errors: ReadonlyArray<{
            readonly field: string;
            readonly messages: ReadonlyArray<string>;
        }>;
    } | null;
};
export type AuthorDeleteMutation = {
    readonly response: AuthorDeleteMutationResponse;
    readonly variables: AuthorDeleteMutationVariables;
};



/*
mutation AuthorDeleteMutation(
  $id: ID!
) {
  authorDelete(id: $id) {
    author {
      byline
      isDeleted
      id
    }
    errors {
      field
      messages
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "AuthorDeleteMutationPayload",
    "kind": "LinkedField",
    "name": "authorDelete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Author",
        "kind": "LinkedField",
        "name": "author",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "byline",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDeleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "messages",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthorDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthorDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ea717c6f87a730bacf0060459522d825",
    "id": null,
    "metadata": {},
    "name": "AuthorDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation AuthorDeleteMutation(\n  $id: ID!\n) {\n  authorDelete(id: $id) {\n    author {\n      byline\n      isDeleted\n      id\n    }\n    errors {\n      field\n      messages\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'd2663118148d66bbfd5f73c67256ae43';
export default node;
