import React, { Dispatch, SetStateAction, Fragment } from 'react'
import styled, { css } from 'styled-components'
import { Tooltip, ButtonContainer } from './PreviewButton'

const SharedButtonStyles = css`
  text-align: center;
  display: block;
  font-size: ${props => props.theme.fontSizes[3]}px;
  cursor: pointer;
  padding: 6px 0;
`

const OpenButton = styled.i`
  ${SharedButtonStyles}
  &:hover{
    color: ${props => props.theme.colors.primary.mediumSlateBlue};
  }
`
OpenButton.displayName = 'OpenButton'

const CloseButton = styled.i`
  ${SharedButtonStyles}
  border-bottom: solid 1px #e7e8ea;
  color: ${props => props.theme.colors.primary.mediumSlateBlue} !important;
`
CloseButton.displayName = 'CloseButton'

type TogglePreviewButtonProps = {
  toggleButtonContainer: () => void,
  isButtonContainerOpen: boolean,
  setCurrentPreview: Dispatch<SetStateAction<string | null>>
}

const renderButton = (clickHandler: () => void, isOpen: boolean) => {
  return (
    <ButtonContainer onClick={clickHandler} isOpen={isOpen}>
      {(isOpen)
        ? <Fragment>
            <CloseButton className='material-icons'>close</CloseButton>
            <Tooltip text='Close'/>
          </Fragment>
        : <Fragment>
            <OpenButton className='material-icons'>remove_red_eye</OpenButton>
            <Tooltip text='Preview'/>
          </Fragment>
      }
    </ButtonContainer>
  )
}

const PreviewMenu: React.FC<TogglePreviewButtonProps> = ({ isButtonContainerOpen, toggleButtonContainer, setCurrentPreview }) => {
  
  const hidePreview = () => {
    toggleButtonContainer()
    setCurrentPreview(null)
  } 
  
  return (
    (isButtonContainerOpen) 
      ? renderButton(hidePreview, isButtonContainerOpen) 
      : renderButton(toggleButtonContainer, isButtonContainerOpen)
  )
}

export default PreviewMenu