/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type TopBarQueryVariables = {};
export type TopBarQueryResponse = {
    readonly organization: {
        readonly name: string;
    };
};
export type TopBarQuery = {
    readonly response: TopBarQueryResponse;
    readonly variables: TopBarQueryVariables;
};



/*
query TopBarQuery {
  organization {
    name
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "TopBarQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "TopBarQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "787591a8e48d99467e936e78b34ed4c8",
    "id": null,
    "metadata": {},
    "name": "TopBarQuery",
    "operationKind": "query",
    "text": "query TopBarQuery {\n  organization {\n    name\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '3a39d2faa97b700dfb3165bee8b69989';
export default node;
