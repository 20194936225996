import React from 'react'
import styled from 'styled-components'

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.space[6]}px;

  label {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
  }

  h2 {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    margin: 0 28px 0 0;
  }
`

const StyledInput = styled.input<{ idle: boolean}>`
  opacity: 0;
  width: 0;
  height: 0;

  &&:checked + span {
    background-color: ${props => (props.idle) ? '#A390F2' : props.theme.colors.primary.lightSlateBlue};
  }

  &&:focus + span {
    box-shadow: 0 0 1px #2196F3;
  }

  &&:checked + span:before {
    transform: translateX(20px);
  }
`

const Track = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .2s;
  transition: .2s;
  border-radius: 34px;

  &&:before {
    position: absolute;
    content: '';
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 4px;
    background-color: #ffffff;
    transition: .2s;
    border-radius: 50%;
  }
`

const InstructionsContainer = styled.div`
  p {
    color: ${props => props.theme.colors.grays.abbey};
    font-weight: ${props => props.theme.fontWeights[2]};
    margin: 0;
    font-size: ${props => props.theme.fontSizes[3]}px;
    line-height: ${props => props.theme.space[4]}px;
    padding-bottom: ${props => props.theme.space[5]}px;
  }
`

export type Props = {
  checked: boolean
  instructions: string
  onChange: (e: { name: string, value: boolean }) => void
  saved: boolean
}

const Switch: React.FC<Props> = ({ checked, instructions, onChange, saved}) => (
  <>
    <InstructionsContainer><p>{instructions}</p></InstructionsContainer>
    <SwitchContainer>
      <h2>Paywall</h2>
      <label>
        <StyledInput
          checked={checked}
          data-testid='switch'
          idle={checked && !saved}
          name='live.free'
          onChange={(e) => onChange({ name: e.target.name, value: !e.target.checked })}
          type='checkbox'/>
        <Track/>
      </label>
    </SwitchContainer>
  </>
)

export default Switch
