/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TemplatableType = "ARTICLE" | "PAGE" | "%future added value";
export type TemplateInput = {
    body?: string | null;
    css?: string | null;
    description?: string | null;
    name?: string | null;
    templatableType?: TemplatableType | null;
};
export type TemplateDetailMutationVariables = {
    id: string;
    input: TemplateInput;
};
export type TemplateDetailMutationResponse = {
    readonly templateUpdate: {
        readonly draft: {
            readonly " $fragmentRefs": FragmentRefs<"TemplateDetail_draft">;
        };
    } | null;
};
export type TemplateDetailMutation = {
    readonly response: TemplateDetailMutationResponse;
    readonly variables: TemplateDetailMutationVariables;
};



/*
mutation TemplateDetailMutation(
  $id: ID!
  $input: TemplateInput!
) {
  templateUpdate(draftId: $id, input: $input) {
    draft {
      ...TemplateDetail_draft
      id
    }
  }
}

fragment Controls_draft on Draft {
  id
  canDelete
  canEdit
  canPublish
  canUpdate
  updatedAt
  administrators {
    privilege
    user {
      id
      first_name
      last_name
      avatar_url
    }
  }
  draftable {
    ... on Article {
      id
      deletedAt
    }
    lastPublishedAt
    __typename
    ... on Node {
      __isNode: __typename
      id
    }
  }
}

fragment TemplateDetail_draft on Draft {
  id
  ...Controls_draft
  canUpdate
  draftable {
    __typename
    ...TemplateTypeSelect_template
    ... on Template {
      body
      css
      description
      name
    }
    ... on Node {
      __isNode: __typename
      id
    }
  }
  errors {
    field
    messages
  }
  warnings {
    field
    messages
  }
}

fragment TemplateTypeSelect_template on Draftable {
  __isDraftable: __typename
  ... on Template {
    templatableType
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "draftId",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "field",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "messages",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TemplateDetailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TemplateUpdateMutationPayload",
        "kind": "LinkedField",
        "name": "templateUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Draft",
            "kind": "LinkedField",
            "name": "draft",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "TemplateDetail_draft"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TemplateDetailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "TemplateUpdateMutationPayload",
        "kind": "LinkedField",
        "name": "templateUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Draft",
            "kind": "LinkedField",
            "name": "draft",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canDelete",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canEdit",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canPublish",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "canUpdate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "updatedAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "DraftAdministrator",
                "kind": "LinkedField",
                "name": "administrators",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "privilege",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "first_name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "last_name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "avatar_url",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "draftable",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastPublishedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isDraftable"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deletedAt",
                        "storageKey": null
                      }
                    ],
                    "type": "Article",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "templatableType",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "body",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "css",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "type": "Template",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Error",
                "kind": "LinkedField",
                "name": "errors",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Error",
                "kind": "LinkedField",
                "name": "warnings",
                "plural": true,
                "selections": (v3/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3b12cff3e4d7778e695c4fd0cb586143",
    "id": null,
    "metadata": {},
    "name": "TemplateDetailMutation",
    "operationKind": "mutation",
    "text": "mutation TemplateDetailMutation(\n  $id: ID!\n  $input: TemplateInput!\n) {\n  templateUpdate(draftId: $id, input: $input) {\n    draft {\n      ...TemplateDetail_draft\n      id\n    }\n  }\n}\n\nfragment Controls_draft on Draft {\n  id\n  canDelete\n  canEdit\n  canPublish\n  canUpdate\n  updatedAt\n  administrators {\n    privilege\n    user {\n      id\n      first_name\n      last_name\n      avatar_url\n    }\n  }\n  draftable {\n    ... on Article {\n      id\n      deletedAt\n    }\n    lastPublishedAt\n    __typename\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n}\n\nfragment TemplateDetail_draft on Draft {\n  id\n  ...Controls_draft\n  canUpdate\n  draftable {\n    __typename\n    ...TemplateTypeSelect_template\n    ... on Template {\n      body\n      css\n      description\n      name\n    }\n    ... on Node {\n      __isNode: __typename\n      id\n    }\n  }\n  errors {\n    field\n    messages\n  }\n  warnings {\n    field\n    messages\n  }\n}\n\nfragment TemplateTypeSelect_template on Draftable {\n  __isDraftable: __typename\n  ... on Template {\n    templatableType\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f96b5894c305b6a6526bc1263464e79d';
export default node;
