/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type SigninFormMutationVariables = {
    email: string;
    password: string;
};
export type SigninFormMutationResponse = {
    readonly login: {
        readonly id: string;
        readonly email: string;
    } | null;
};
export type SigninFormMutation = {
    readonly response: SigninFormMutationResponse;
    readonly variables: SigninFormMutationVariables;
};



/*
mutation SigninFormMutation(
  $email: String!
  $password: String!
) {
  login(email: $email, password: $password) {
    id
    email
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "password"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "email",
        "variableName": "email"
      },
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      }
    ],
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "login",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SigninFormMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SigninFormMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1a453bdf7ebc64ab9328f6dc259f44ae",
    "id": null,
    "metadata": {},
    "name": "SigninFormMutation",
    "operationKind": "mutation",
    "text": "mutation SigninFormMutation(\n  $email: String!\n  $password: String!\n) {\n  login(email: $email, password: $password) {\n    id\n    email\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c593d2aac33db7196c947638e60a8c87';
export default node;
