import React, { useContext, useState } from 'react'
import { fetchQuery } from 'react-relay'
import styled from 'styled-components'
import { Context, ContextType } from '../Form/Form'
import { graphql } from 'babel-plugin-relay/macro'
import environment from '../../relay/environment'
import { ImageComponent, Upload } from '../Form/Thumbnail'
import Section from './Section'
import { Input } from './Generic'
import { SectionLabel } from '../Form/Metadata/Section'

import { DEFAULT_IMAGE_WIDTH } from '../../util/meta'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100% !important;
  padding: 0 10px;

  .rmwc-circular-progress ~ img {
    width: 34px !important;
  }

  img {
    width: auto !important;
  }

  label {
    margin-bottom: 8px;
  }
`

const uploadUrlQuery = graphql`
  query ImageUploadUrlQuery($extension: String!, $mime_type: String!, $suffix: String) {
    uploadUrl(extension: $extension, mime_type: $mime_type, suffix: $suffix) {
      url
      thumbnails {
        small
      }
    }
  }
`

export const getSignedUrl = (file: File, callback: <T extends object>(signedUrl: T) => string) => {
  const { name, type: mime_type } = file
  const variables = { extension: name.split('.').pop(), mime_type }

  return fetchQuery(environment, uploadUrlQuery, variables).toPromise().then((data: any) => {
    callback({
      signedUrl: data?.uploadUrl?.url,
      imageUrl: data?.uploadUrl?.thumbnails?.small
    })
    return fetch(data?.uploadUrl?.url, {
      method: 'PUT',
      body: file
    })
  })
}

type Props = {
  name: string
  node?: Element
  cb?: (value: string[] | null) => void
  source?: string
}

const Image: React.FC<Props> = ({ name, node, cb, source }) => {
  const { model, errors, onChange } = useContext(Context) as ContextType
  const [ localErrors, setLocalErrors ] = useState<string []>([])
  const [ src, setSrc ] = useState<string | null>(model[`content[${name}][src]`] || source || '')
  const templateUrl = (node && node.getAttribute('url')) ? node.getAttribute('url') : true
  const templateSrc = (node && node.getAttribute('src')) ? node.getAttribute('src') : null

  const onFinish= (e: { imageUrl: string | null }) => {
    cb ? cb([e?.imageUrl!]) : onChange(`content[${name}][src]`, e?.imageUrl)
    setSrc(e?.imageUrl)
  }

  const onReset = () => {
    if (cb) {
      setSrc('')
      cb(null)
    } else {
      onChange(`content[${name}][src]`, '')
      setSrc('')
    }
  }

  const errorHandler = (err?: string) => {
    setLocalErrors((err) ? [err] : [])
  }

  const previewOrUploadRenderer = () => {
    if (!templateSrc && src) {
      return <ImageComponent src={src} reset={onReset} />
    } else {
      return (
        <Upload
          minDimensions={DEFAULT_IMAGE_WIDTH}
          onFinish={onFinish}
          getSignedUrl={getSignedUrl}
          setError={errorHandler}
          setIsUploadingOnThumbnail={() => {}}
        />
      )
    }
  }

  if (templateUrl && templateSrc) {
    return <></>
  }

  return (
    <Section name={name}>
      <Container>
        {!templateSrc &&<SectionLabel name='Image' errors={localErrors.concat(errors.for(`content[${name}][src]`))}/>}
        {previewOrUploadRenderer()}
        {!templateUrl && <Input name={name} attr='url'/>}
      </Container>
    </Section>
  )
}

export default Image
