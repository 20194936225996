import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import placeholder from '../../assets/png/image-placeholder.png'

export enum StatusColor {
  DRAFT = 'mediumSlateBlue',
  SCHEDULED = 'selectiveYellow',
  PUBLISHED = 'lightSeaGreen',
  '%future added value' = 'whiteSmoke',
}

const rowStyle = css<{ index: number }>`
  display: table-row;
  background-color: ${props => props.index % 2 !== 0 ? props.theme.colors.grays.whiteSmoke : 'white'};
  text-decoration: none;
  color: black;

  & > * {
    display: table-cell;
    vertical-align: middle;
    padding: 0 8px;
  }
`

export const LinkRow = styled(Link)<{ index: number }>`
  ${props => rowStyle}
  cursor: pointer;
`

export const Row = styled.div<{ index: number }>`
  ${props => rowStyle}
`

export const HeaderRow = styled.div`
  display: table-row;
  text-transform: uppercase;
  font-size: ${props => props.theme.fontSizes[2]}px;
  font-weight: 600;
  color: ${props => props.theme.colors.grays.darkGrayishBlue};
  height: 24px;

  div {
    display: table-cell;
    padding: 0 8px;
    box-sizing: border-box;
  }

  div:nth-child(1) {
    width: 18px;
  }

  div:nth-child(2) {
    width: 76px;
  }

  div:nth-child(3) {
    width: 429px;
  }

  div:nth-child(4) {
    width: 254px;
  }

  div:nth-child(5) {
    width: 343px;
  }
`

export const Status = styled.div<{ color: StatusColor }>`
  width: 10px;
  background: content-box ${props => props.theme.colors.primary[props.color]};
  padding-left: 0;
`

export const Thumbnail = styled.img`
  width: 60px;
  height: 60px;
  padding-top: 10px;
  padding-bottom: 10px;
  background: content-box url(${placeholder});
  border: 0;
`

export const Subhead = styled.div`
  font-size: ${props => props.theme.fontSizes[2]}px;
  color: ${props => props.theme.colors.grays.aluminium};
`

export const User = styled.div`
  line-height: ${props => props.theme.fontSizes[5]}px;

  div {
    margin-right: ${props => props.theme.space[1]}px;
  }
`
