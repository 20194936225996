import React, { useContext, useEffect } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { createFragmentContainer } from 'react-relay'
import Form, { Title, Editor, HeroPicker, Context, ContextType } from './Form'
import { Main, MainFields, Aside } from './Form/Containers'
import Controls from './Form/Controls'
import { Textarea, TextSection } from './Form/Metadata'
import DetailRenderer from './DetailRenderer'
import Thumbnail from './Form/Thumbnail'
import Preview from './Preview/Preview'
import { ArticleDetailQuery as Query } from './__generated__/ArticleDetailQuery.graphql'
import { ArticleDetail_draft as Fragment } from './__generated__/ArticleDetail_draft.graphql'
import BylineSelect from './Form/Metadata/BylineSelect'
import styled from 'styled-components/macro'
import PublicationDate from './Form/PublicationDate'
import TagFieldRenderer from './Form/TagField/TagFieldRenderer'

const mutation = graphql`
  mutation ArticleDetailMutation($id: ID!, $input: ArticleInput!) {
    articleUpdate(draftId: $id, input: $input) {
      draft {
        ...ArticleDetail_draft
      }
    }
  }
`

const query = graphql`
  query ArticleDetailQuery($id: ID!) {
    node(id: $id) {
      ...on Article {
        latestDraft {
          ...ArticleDetail_draft
        }
      }
      ...ArticleDetail_draft
    }
  }
`

const StyledTitle = styled(Title)`
  display: block;
  max-width: 1024px;
  margin: auto;
`

const ArticleDetailContent = (props: { draft: Fragment }) => {
  const {
    draft,
    draft: { draftable, scheduledFor }
  } = props
  const { onChange } = useContext(Context) as ContextType
  const latestTemplateDraft = draftable.selectableTemplates[0].versions[0].id

  // eslint-disable-next-line
  useEffect(() => onChange('templateVersionId', latestTemplateDraft), [latestTemplateDraft])

  return (
    <>
      <Preview article={draftable} />
      <Main>
        <MainFields>
          <HeroPicker name='videoFile' article={draftable} />
          <StyledTitle name='headline' placeholder='Headline' />
          <Editor name='body'/>
        </MainFields>
        <Controls draft={draft} />
      </Main>
      <Aside>
        <TextSection name='slug' placeholder='Add a slug' />
        <BylineSelect name='authorIds' label='Bylines' required={false} draftable={draftable} />
        <PublicationDate name='publishedAt' label='Publication Date' required={false} draftable={draftable} scheduledFor={scheduledFor} />
        <Textarea name='description' placeholder='Add a description' required={true} />
        <Thumbnail name='thumbnail' article={draftable} page={null} />
        <TagFieldRenderer taggable={draftable} />
      </Aside>
    </>
  )
}

const ArticleDetail = createFragmentContainer(
  (props: { draft: Fragment }) => {
    return (
      <Form<Fragment['draftable']> id={props.draft.id} mutation={mutation} node={props.draft}>
        <ArticleDetailContent draft={props.draft} />
      </Form>
    )
  },
  {
    draft: graphql`
      fragment ArticleDetail_draft on Draft {
        id
        scheduledFor
        ...Controls_draft
        canUpdate
        draftable {
          selectableTemplates {
            versions {
              id
              version
            }
          }
          ...on Article {
            id
            body
            description
            headline
            slug
            thumbnailAttribution
            ...Thumbnail_article
            ...Preview_article
            ...HeroPicker_article
            ...BylineSelect_draftable
            ...PublicationDate_draftable
            ...TagFieldRenderer_taggable
          }
        }
        errors {
          field
          messages
        }
        warnings {
          field
          messages
        }
      }
    `
  }
)

export default DetailRenderer<Query>(ArticleDetail, query)
