import React, { Dispatch, SetStateAction, useCallback, useRef, useState } from 'react'
import debounce from 'lodash/debounce'
import findIndex from 'lodash/findIndex'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Box, Dialog, Grid, makeStyles, TextField } from '@material-ui/core'
import { FormikContextType, FormikValues, useFormikContext } from 'formik'
import { fetchQuery } from 'react-relay'
import environment from '../../../relay/environment'
import { graphql } from 'babel-plugin-relay/macro'
import { AddTopStoryQueryResponse as Response } from './__generated__/AddTopStoryQuery.graphql'
import { publicationDate, Story, StoryList } from './TopStoryEdit'
import { Theme } from '../../../theme'
import { DEBOUNCE_PERIOD } from '../../../util/meta'
import ModalHeader from '../../GenericFormComponents/ModalHeader'
import { StoryId } from './types'

const query = graphql`
  query AddTopStoryQuery($query: String!) {
    organization {
      # max_age is in seconds, 259200 = 3 days
      media(query: $query, max_age: 259200, sort: { attribute: "public_at", direction: DESC }) {
        edges {
          node {
            id
            title
            public_at
            thumbnails(aspect_ratio: SQUARE) {
              small
            }
          }
        }
      }
    }
  }
`

type Options = NonNullable<NonNullable<Response['organization']['media']>['edges']>
export type Props = {
  showModal: boolean
  onClose: () => void
  addStoryHandler: (arg: any) => void
  storyList: StoryList
  storyToReplace?: StoryId
}

type RenderInputProps = {
  params: any
  setLoading: Dispatch<SetStateAction<boolean>>
  setInputValue: Dispatch<SetStateAction<string>>
  onInputChangeHandler: (arg: string) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '5px'
  },
  thumbnail: {
    height: '100%',
    width: '10%',
    marginRight: '15px',
    '& img': {
      width: '50px',
      height: '50px'
    }
  },
  title: {
    width: '80%',
    height: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '& p': {
      fontSize: '14px'
    },
    '& span': {
      color: theme.palette.text.lightPrimary,
      fontSize: '12px'
    }
  }
}))

/**
 * Filter the search result list in order to ignore stories which have already been added
 */
const filterOptions = (options: Options, storyList: Props['storyList']) => {
  const storyIdArray = storyList!.map((el: Story) => el!.id)
  return options!.filter((el: Options[0]) => !storyIdArray.includes(el!.node!.id))
}

/**
 * MUI Autocomplete native method; renders the search input field
 */
const renderInput = ({ params, setLoading, setInputValue, onInputChangeHandler }: RenderInputProps) => (
  <TextField
    {...params}
    label='Search a story'
    variant='outlined'
    autoFocus={true}
    onChange={(event) => {
      event.persist()
      setLoading(true)
      setInputValue(event.target.value)
      onInputChangeHandler(event.target.value)
    }}
    fullWidth
  />
)

const Row: React.FC<{ option: Options[0] }> = ({ option }) => {
  const classes = useStyles()

  return (
    <Grid container direction='row' spacing={2} alignItems='center' className={classes.container}>
      <Grid item className={classes.thumbnail}>
        <img src={option?.node?.thumbnails?.small!} alt='' />
      </Grid>
      <Grid item className={classes.title}>
        <p>{option?.node?.title}</p>
        <span>{publicationDate(option?.node?.public_at as string)}</span>
      </Grid>
    </Grid>
  )
}

const AddTopStory: React.FC<Props> = ({ showModal, onClose, addStoryHandler, storyList = [], storyToReplace }) => {
  const { setValues, values }: FormikContextType<FormikValues> = useFormikContext()
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  /**
   * Appends a chosen story to the list and sets the Formik values ({ story: string[id], order: number })
   */
  const onChangeHandler = (newValue: Options[0]) => {
    if(newValue?.node) {
      updateFormFields(newValue.node)
      updateParentState(newValue.node)
    }

    onClose()
  }

  /**
   * Transform the array and update the Formik form
   */
  function updateFormFields(node: Story) {
    const arr = values.input.stories.slice()

    if (storyToReplace) {
      const indexToReplace = findIndex(arr, (obj: { story: string }) => obj.story === storyToReplace.id)
      arr.splice(indexToReplace, 1, { order: arr[indexToReplace].order, story: node.id })

      return void setValues({ ...values, input: { stories: arr } })
    }

    return void setValues({ ...values, input: { stories: [...arr, { order: arr.length + 1, story: node.id }] } })
  }

  /**
   * Transform the array and update the TopStoryEdit state
   */
  function updateParentState(node: Story) {
    const arr: Array<any> = storyList!.slice()

    if (storyToReplace) {
      const indexToReplace = findIndex(arr, (obj: { id: string }) => obj.id === storyToReplace.id)
      arr.splice(indexToReplace, 1, { ...node, order: arr[indexToReplace].order })

      return void addStoryHandler(arr)
    }

    return void addStoryHandler([...arr, { ...node, order: arr.length + 1 }])
  }

  /**
   * Fetches the search results
   */
  const getOptions = async (value: string) => {
    if (value) {
      setOpen(true)
      const response = (await fetchQuery(environment, query, { query: value }).toPromise()) as Response
      setOptions(response?.organization?.media?.edges as any)
      setLoading(false)
    } else {
      setOptions([])
      setLoading(false)
      setOpen(false)
    }
  }

  const debouncedRef = useRef((value: string) => getOptions(value)).current
  const onInputChangeHandler = useCallback(
    debounce((value: string) => debouncedRef(value), DEBOUNCE_PERIOD),
    []
  )

  const onCloseHandler = () => {
    setOpen(false)
    setInputValue('')
  }

  return (
    <Dialog maxWidth='sm' fullWidth onClose={onClose} open={showModal}>
      <ModalHeader title={'Choose article'} />
      <Box p={3} pt={1}>
        <Autocomplete
          options={options}
          popupIcon={''}
          noOptionsText='No articles found'
          filterOptions={() => filterOptions(options, storyList)}
          getOptionLabel={(option) => option!.node!.title!}
          open={open}
          onClose={onCloseHandler}
          autoComplete
          loading={loading}
          inputValue={inputValue}
          getOptionSelected={(option, value) => option!.node!.title! === value!.node!.title!}
          onChange={(event, newValue) => onChangeHandler(newValue)}
          renderInput={(params) => renderInput({ params, setLoading, setInputValue, onInputChangeHandler })}
          renderOption={(option: Options[0]) => <Row option={option} />}
        />
      </Box>
    </Dialog>
  )
}

export default AddTopStory
