import React from 'react'
import styled from 'styled-components'

const AlertMessages = styled.ul`
  box-sizing: border-box;
  position: absolute;
  top: -10px;
  left: ${props => props.theme.space[6]}px;
  background-color: ${props => props.theme.colors.primary.nero};
  padding: 8px;
  margin: ${props => props.theme.space[0]};
  list-style: none;
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji;
  font-size: ${props => props.theme.fontSizes[1]}px;
  font-weight: ${props => props.theme.fontWeights[3]};
  font-style: italic;
  color: ${props => props.theme.colors.grays.solitude};
  line-height: 1.5;
  visibility: hidden;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(206, 207, 209, 0.45);
  width: 135px;
  z-index: 5;

  & > li:first-letter {
    text-transform: capitalize;
  }

  &::before {
    content: '';
    position: absolute;
    top: 10px;
    left: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid ${props => props.theme.colors.primary.nero};
  }
`
AlertMessages.displayName = 'AlertMessages'

export const Icon = styled.i<{ className: string, warning?: boolean }>`
  color: ${props => props.warning ? '#ff7e00' : props.theme.colors.secondary.hollywoodCerise};
  vertical-align: bottom;
  font-size: 18px;
  position: ${props => props.warning ? 'absolute' : 'relative'};
  left: 0;
  z-index: ${props => props.warning ? '1' : '2'};

  &:hover + ul {
      visibility: visible;
  }
`

const AlertContainer = styled.div`
  display: inline-block;
  position: relative;
`

type AlertIconProps = {
  errors: readonly string[]
  warnings?: readonly string[]
}

const Messages: React.FC<{ messages: readonly string[] }> = ({ messages }) => (
  <AlertMessages>{ messages.map((c, i) => <li key={i}>{ c }</li>) }</AlertMessages>
)

export const ErrorIcon: React.FC<{ errors: readonly string[] }> = ({ errors }) => (
  <React.Fragment>
    {(errors.length > 0) &&
      <>
        <Icon className='material-icons'>error</Icon>
        <Messages messages={errors}/>
      </>
    }
  </React.Fragment>
)

const WarningIcon: React.FC<{ warnings: readonly string[] }> = ({ warnings }) => (
  <React.Fragment>
    {(warnings.length > 0) &&
      <>
        <Icon className='material-icons' warning={true}>error</Icon>
        <Messages messages={warnings}/>
      </>
    }
  </React.Fragment>
)

const Alert: React.FC<AlertIconProps> = ({ errors, warnings = [] }) => (
  <AlertContainer>
    <ErrorIcon errors={errors}/>
    <WarningIcon warnings={warnings}/>
  </AlertContainer>
)

export default Alert
