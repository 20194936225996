import React, { useState } from 'react'
import styled from 'styled-components'
import ToggleButton from '../Metadata/ToggleButton'

const AsideFieldsContainer = styled.div<{ open: boolean }>`
  position: relative;
  background-color: ${props => props.theme.colors.primary.whiteSmoke};
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  transition: width .3s ease-in-out
  width: ${props => props.open ? '352px' : '48px'};
  overflow: visible;
`
AsideFieldsContainer.displayName = 'AsideFieldsContainer'

const AsideFields = styled.div<{ open: boolean }>`
  padding: 50px 24px;
  transition: opacity .15s ease-out;
  opacity: ${props => props.open ? 1 : 0};
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
`
AsideFields.displayName = 'AsideFields'

const StyledToggleButton = styled(ToggleButton) <{ open: boolean, hovering: boolean }>`
  right: inherit;
  left: -10px;
  transform: rotate(90deg);
  background-color: ${props => props.theme.colors.grays.zircon};
  transition: opacity .10s ease-in-out, background-color: .10s ease-in-out;
  opacity: ${props => !props.open || props.hovering ? 1 : 0};
  position: absolute;

  &:hover {
    background-color: ${props => props.theme.colors.primary.mediumSlateBlue};
    i {
      color: white;
    }
  }

  i {
    color: ${props => props.theme.colors.primary.blackRussian};
  }
`
StyledToggleButton.displayName = 'AsideToggleButton'

const Aside: React.FC = ({ children }) => {
  const [hovering, setHovering] = useState(false)
  const [open, setOpen] = useState(true)

  return (
    <AsideFieldsContainer onMouseOver={() => setHovering(true)} onMouseOut={() => setHovering(false)} open={open}>
      <StyledToggleButton onClick={() => setOpen(!open)} open={open} hovering={hovering} />
      <AsideFields open={open}>
        {children}
      </AsideFields>
    </AsideFieldsContainer>
  )
}

export default Aside
