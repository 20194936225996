import React from 'react'
import styled from 'styled-components'

const StyledLabel = styled.label`
  cursor: pointer;
  display: block;
  position: relative;
  padding: 0 8px 0 24px;
  margin: 8px 0 20px 0 !important;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`
const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  &:checked ~ span {
    background-color: ${props => props.theme.colors.primary.mediumSlateBlue};
  }
  &:checked ~ span:before {
    display: none
  }
  &:checked ~ span:after {
    display: block;
    top: 2px;
    left: 2px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${props => props.theme.colors.primary.mediumSlateBlue};
    border: 2px solid #ffffff;
    box-sizing: border-box;
  }
`
const Radio = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  border-radius: 50%;
  &:before {
    content: '';
    position: absolute;
    display: block;
    border: 2px solid ${props => props.theme.colors.grays.mischka};
    width: 16px;
    height: 16px;
    border-radius: 50%;
    box-sizing: border-box;
  }
  &:after {
    content: '';
    position: absolute;
    display: none;
  }
`

type Props = {
  title: string
  checked: boolean
  onChange: () => void
  disabled?: boolean
}

const RadioButton: React.FC<Props> = ({ title, checked, onChange, disabled }) => {
  return (
    <React.Fragment>
      <StyledLabel>
        {title}
        <StyledInput type="radio" checked={checked} name={title} onChange={onChange} disabled={disabled}/>
        <Radio></Radio>
      </StyledLabel>
    </React.Fragment>
  )
}

export default RadioButton
