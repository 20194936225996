import React, { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components'

export const Tooltip = styled.div<{ text: string }>`
  position: absolute;
  display: none;
  width: 56px;
  height: 24px;
  border-radius: 2px;
  background-color: ${props =>  props.theme.colors.primary.nero};
  color: white;
  right: 41px;
  top: 2px;
  font-size: ${props => props.theme.fontSizes[1]}px;
  z-index: 2;
  &::before {
    color: #f7f8f9;
    content: '${({text}) => text}';
    font-size: 12px;
    height: 24px;
    line-height: 24px;
    width: 24px;
  }
`

export const ButtonContainer = styled.div<{isOpen: boolean}>`
  text-align: center;
  display: block;
  position: relative;
  user-select: none;
  &:hover{
    div {
      display: block
    }
    cursor: pointer;
    color: ${props => (props.isOpen) ? props.theme.colors.grays.brightGray : props.theme.colors.primary.mediumSlateBlue};
  }
`
ButtonContainer.displayName = 'ButtonContainer'

const DeviceIcon = styled.i<{ isActive: boolean }>`
  padding: 8px 0;
  font-size: ${props => props.theme.fontSizes[3]}px;
  color: ${props => (props.isActive) 
    ? props.theme.colors.primary.mediumSlateBlue + ' !important' 
    : props.theme.colors.primary.nero
  };
`

type PreviewButtonProps = {
  deviceType: string,
  deviceIcon: string,
  setCurrentPreview: Dispatch<SetStateAction<string | null>>,
  isActive: boolean
}

const PreviewButton: React.FC<PreviewButtonProps> = ({ deviceType, deviceIcon, setCurrentPreview, isActive }) => { 
  return(
    <ButtonContainer onClick={() => setCurrentPreview(deviceType)} isOpen={isActive}>
      <DeviceIcon className="material-icons" isActive={isActive}>{deviceIcon}</DeviceIcon>
      <Tooltip text={deviceType}/>
    </ButtonContainer>
  )
}

export default PreviewButton