import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: inherit;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const DragMessage: React.FC = () => {
  return (
    <Container>
      <p>Drag and drop an image or video here to upload</p>
    </Container>
  )
}

export default DragMessage
