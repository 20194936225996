import React from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { createFragmentContainer } from 'react-relay'
import Form from './Form'
import { Main, MainFields, Aside } from './Form/Containers'
import Controls from './Form/Controls'
import { TemplateSelect, Textarea } from './Form/Metadata'
import Thumbnail from './Form/Thumbnail'
import TextInput from './TextInput'
import DetailRenderer from './DetailRenderer'
import { PageDetailQuery as Query } from './__generated__/PageDetailQuery.graphql'
import { PageDetail_draft as Fragment } from './__generated__/PageDetail_draft.graphql'
import ComponentMap from './ComponentMap'

const mutation = graphql`
  mutation PageDetailMutation($id: ID!, $input: PageInput!) {
    pageUpdate(draftId: $id, input: $input) {
      draft {
        ...PageDetail_draft
      }
    }
  }
`

const query = graphql`
  query PageDetailQuery($id: ID!) {
    node(id: $id) {
      ...on Page {
        latestDraft {
          ...PageDetail_draft
        }
      }
      ...PageDetail_draft
    }
  }
`

const PageDetail = createFragmentContainer(
  (props: { draft: Fragment }) => {
    const { draft, draft: { id, draftable } } = props

    return (
      <Form<Fragment["draftable"]> id={id} mutation={mutation} node={draft}>
        <Main>
          <MainFields>
            { draftable.template && draftable.template.body &&
              <ComponentMap key={draftable.template.name} template={draftable.template.body}/>
            }
          </MainFields>
          <Controls draft={draft}/>
        </Main>
        <Aside>
          <TemplateSelect name='templateVersionId' label='Template Selection' draftable={draftable}/>
          <Textarea name='description' placeholder='Add a description'/>
          <Thumbnail name='thumbnail' page={draftable} article={null}/>
          <TextInput name='name' placeholder='Add a name'/>
          <TextInput name='slug' placeholder='Add a slug'/>
        </Aside>
      </Form>
    )
  },
  {
    draft: graphql`
      fragment PageDetail_draft on Draft {
        id
        ...Controls_draft
        canUpdate
        draftable {
          ...TemplateSelect_draftable
          template {
            name
            body
          }
          ...on Page {
            name
            description
            ...Thumbnail_page
            slug
            content {
              source
              value
            }
          }
        }
        errors {
          field
          messages
        }
        warnings {
          field
          messages
        }
      }
    `
  }
)

export default DetailRenderer<Query>(PageDetail, query)
