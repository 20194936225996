import React, { useEffect } from 'react'
import styled from 'styled-components'

const QueryWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 100px;
  flex-wrap: wrap;
`

const QueryDiv = styled.div`
  border: solid 1px black;
  box-shadow: box-shadow: -5px 9px 29px 0px rgba(194,186,194,1);
  border-radius: 4px;
  margin-bottom: 30px;

  div {
    height: 100% !important;
    max-height: 200px !important;
  }

  table {
    height: 100% !important;

    th {
      background-color: white;
    }
  }
`

const QueryLabel = styled.div`
  label {
    font-weight: bold;
    margin: 10px 0;
  }
`

const QueryContainer = styled.div`
  overflow: auto;
  height: 100%;
`

const commonQueries = {
  dimensions: 'ga:pagePathLevel2',
  metrics: 'ga:pageviews',
  sort: '-ga:pageviews',
  'start-date': 'yesterday',
  'end-date': 'today',
  'include-empty-rows': false,
  'max-results': 10,
}

const dataChart = () => (
  new window.gapi.analytics.googleCharts.DataChart({
    query: {
      ...commonQueries
    },
    chart: {
      container: 'chart-container1',
      type: 'TABLE',
      options: {
        height: '100px',
        width: '100%'
      }
    }
  })
)

const dataChart2 = () => (
  new window.gapi.analytics.googleCharts.DataChart({
    query: {
      ...commonQueries,
      'start-date': '7daysAgo',
      'end-date': 'today',
    },
    chart: {
      container: 'chart-container2',
      type: 'TABLE',
      options: {
        height: '100px',
        width: '100%'
      }
    }
  })
)

const dataChart3 = () => (
  new window.gapi.analytics.googleCharts.DataChart({
    query: {
      ...commonQueries,
      metrics: 'ga:pageviews',
      dimensions: 'ga:sourceMedium'
    },
    chart: {
      container: 'chart-container3',
      type: 'TABLE',
      options: {
        height: '100px',
        width: '100%'
      }
    }
  })
)

const ready = (dashboardToken: string) => {
  window.gapi.analytics.auth.authorize({
    'serverAuth': {
      'access_token': dashboardToken
    }
  })

  var viewSelector = new window.gapi.analytics.ViewSelector({
    container: 'view-selector-container'
  })

  viewSelector.execute();

  viewSelector.on('change', function (ids: any) {
    dataChart().set({ query: { ids: ids } }).execute();
    dataChart2().set({ query: { ids: ids } }).execute();
    dataChart3().set({ query: { ids: ids } }).execute();
  })
}

const Dashboard: React.FC<any> = ({ dashboardToken }) => {
  useEffect(() => { window.gapi?.load('analytics', () => ready(dashboardToken)) }, [dashboardToken])

  return (
    <QueryContainer data-testid="dashboard">
      <QueryWrapper>
        <QueryLabel> <label> Top 10 pages in the last 24 hours </label>
          <QueryDiv data-testid="chart-container1" id="chart-container1"></QueryDiv>
        </QueryLabel>
        <QueryLabel> <label> Top 10 pages in the last 7 days </label>
          <QueryDiv data-testid="chart-container2" id="chart-container2"></QueryDiv>
        </QueryLabel>
        <QueryLabel> <label> Top 10 source/medium pageviews in last 24 hours </label>
          <QueryDiv data-testid="chart-container3" id="chart-container3"></QueryDiv>
        </QueryLabel>
      </QueryWrapper>
      <div data-testid="view-selector-container" id="view-selector-container"></div>
    </QueryContainer>
  )
}

export default Dashboard
