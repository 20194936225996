/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type HeroPicker_article = {
    readonly organization: {
        readonly id: string;
    };
    readonly heroImageUrl: string | null;
    readonly videoFile: {
        readonly id: string;
        readonly videoUrls: ReadonlyArray<{
            readonly url: string | null;
            readonly mime: string | null;
        }>;
    } | null;
    readonly " $refType": "HeroPicker_article";
};
export type HeroPicker_article$data = HeroPicker_article;
export type HeroPicker_article$key = {
    readonly " $data"?: HeroPicker_article$data;
    readonly " $fragmentRefs": FragmentRefs<"HeroPicker_article">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeroPicker_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Organization",
      "kind": "LinkedField",
      "name": "organization",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "heroImageUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "VideoFile",
      "kind": "LinkedField",
      "name": "videoFile",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Url",
          "kind": "LinkedField",
          "name": "videoUrls",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "url",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "mime",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
})();
(node as any).hash = '7e006ebad8ea340b1a67669b0f86b62e';
export default node;
