import React, { ReactNode, useState } from 'react'
import { commitMutation, GraphQLTaggedNode } from 'react-relay'
import styled, { css } from 'styled-components'
import { Subject } from 'rxjs'
import environment from '../../relay/environment'
import Errors from '../../graphql/errors'
import useStuntman, { ChangeHandler } from '../../hooks/useStuntman'
import { ErrorBanner } from '../NetworkErrors'

const StyledForm = styled.form`
  position: relative;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr auto;
  width: 100%;
  overflow: auto;

  & > * {
    min-height: 100%;
  }
`

const ReadonlyTag = styled.div`
  position: absolute;
  color: white;
  background-color: ${props => props.theme.colors.primary.seafoam};
  padding: 6px 18px;
  text-transform: uppercase;
  top: ${props => props.theme.space[5]}px;
  left: ${props => props.theme.space[11]}px;
  font-size: ${props => props.theme.fontSizes[2]}px;
  font-weight: ${props => props.theme.fontWeights[2]};
  z-index: 2;

  &::after {
    content: 'read only';
  }
`
ReadonlyTag.displayName = 'ReadonlyTag'

export enum FormEvent {
  saveStart,
  saveSuccess
}

interface Base {
  children: ReactNode
  node: any
}

type ContainerProps = Base & {
  context: InternalContextType,
  updates: any
}

export type ContextType = InternalContextType & {
  disabled?: boolean,
  draft: Base['node'],
  errors: Errors,
  publishable: boolean,
  setPublishable: (e: boolean) => void,
  updates: any,
  warnings: Errors
}

export type InputProps = {
  name: string,
  fill?: boolean,
  placeholder?: string,
  displayName?: string
}

export type InternalContextType = {
  autoSave: boolean,
  events: Subject<FormEvent>,
  model: any,
  onChange: ChangeHandler,
  saveMutation: GraphQLTaggedNode
}

type Props<T> = Base & {
  autoSave?: boolean,
  id: string,
  mutation: GraphQLTaggedNode,
}

export const inputStyles = css<InputProps>`
  flex-grow: ${props => props.fill ? 1 : 0};
`

export const Context = React.createContext({})

const FormContainer = (props: ContainerProps) => {
  const { children, node, updates } = props
  const [publishable, setPublishable] = useState<boolean>(true)
  const isUpdatingDisabled = (node.hasOwnProperty('canUpdate')) ? !node.canUpdate : false
  const errors = new Errors((node.hasOwnProperty('errors')) ? node.errors : [])
  const warnings = new Errors((node.hasOwnProperty('warnings')) ? node.warnings : [])

  return (
    <Context.Provider value={{ ...props.context, updates, draft: node, disabled: isUpdatingDisabled, publishable, setPublishable: (e: boolean) => setPublishable(e), errors, warnings }}>
      { isUpdatingDisabled && <ReadonlyTag/> }
      <StyledForm>
        { children }
      </StyledForm>
    </Context.Provider>
  )
}

export default function Form<T>(props: Props<T>) {
  const events = new Subject<FormEvent>()
  const [error, setError] = useState<string>('')
  const { autoSave = true, children, id, mutation, node } = props

  const onIdle = () => {
    events.next(FormEvent.saveStart)
    commitMutation(environment, {
      mutation,
      variables: { id, input: stuntman.updates },
      onCompleted: () => {
        setError('')
        events.next(FormEvent.saveSuccess)
      },
      onError: () => {
        setError('Unable to save your changes. Please backup your work and try again later.')
      }
    })
  }

  const [stuntman, model, onChange] = useStuntman<T>(node.draftable ? node.draftable : node, autoSave ? onIdle : () => {})

  return (
    <FormContainer updates={stuntman.updates} node={node} context={{ autoSave, events, model, onChange, saveMutation: mutation }}>
      <ErrorBanner message={error} onDismiss={() => setError('')}/>
      { children }
    </FormContainer>
  )
}
