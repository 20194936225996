import React, { useState, useContext, useEffect, useCallback, useMemo } from 'react'
import { Context, ContextType, InputProps } from '../Form'
import styled, { css } from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { createFragmentContainer } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import { HeroPicker_article as Fragment } from './__generated__/HeroPicker_article.graphql'
import ImageUpload from './ImageUpload'
import VideoUpload from './VideoUpload'
import UploadProgress from './UploadProgress'
import HeroPickerControl, { ButtonWrapper } from './HeroPickerControl'
import Error from './Error'
import DragMessage from './DragMessage'
import { UploadResources } from './UploadResources'

import META from '../../../util/meta'

const Wrapper = styled.div<{ hideButtons: boolean }>`
  ${(props) =>
    !props.hideButtons &&
    css`
  &:hover {
    ${ButtonWrapper} {
      z-index: 1;
    }
  }
`}
  width: 696px;
  height: 392px;
  margin-left: auto;
  margin-right: auto;
  outline: none;
  border: dashed 1px ${(props) => props.theme.colors.grays.athensGray};
  background-color: ${(props) => props.theme.colors.grays.aliceBlue};
  font-size: ${(props) => props.theme.fontSizes[3]}px;
  line-height: 1.5;
  color: ${(props) => props.theme.colors.grays.stormGray};
  border-radius: 2px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
Wrapper.displayName = 'Wrapper'

export const signedUrlQuery = graphql`
  query HeroPickerQuery($extension: String!, $mimeType: String!, $final: Boolean) {
    organization {
      mediaUploadUrl(extension: $extension, mimeType: $mimeType, final: $final)
    }
  }
`

const ACCEPTED_FILE_TYPES = 'image/jpeg, image/jpg, image/png, video/mp4, application/mxf, .mxf'

const variantUrlFor = (article: Fragment, mime: string): string | null =>
  article?.videoFile?.videoUrls?.find((u) => u.mime?.startsWith(mime))?.url || null

const videoUrlFor = (article: Fragment): string | null => variantUrlFor(article, 'video/mp4')

const previewImageFor = (article: Fragment): string | null => (article.videoFile ? variantUrlFor(article, 'image/') : article.heroImageUrl)

const HeroPicker = createFragmentContainer(
  (props: InputProps & { article: Fragment }) => {
    const { name, article } = props
    const { disabled, onChange, errors, setPublishable } = useContext(Context) as ContextType
    const [error, setError] = useState<string>('')
    const [isUploading, setIsUploading] = useState<boolean>(false)
    const [uploadPct, setUploadPct] = useState<number>(0.0)
    const [preview, setPreview] = useState<string | null>(previewImageFor(article))
    const uploaderParams = { setIsUploading, setUploadPct, setPreview, onChange, setError, signedUrlQuery }
    const onDrop = useCallback(
      (acceptedFiles) => {
        const mediaType = acceptedFiles[0]['type'].split('/')[0]
        if (mediaType === 'image') {
          new ImageUpload({ ...uploaderParams, file: acceptedFiles[0], minDimensions: META.HERO_IMAGE_MIN_DIMENSIONS }).upload(
            UploadResources.IMAGE
          )
        } else if (mediaType === 'video' || mediaType === 'application' || mediaType === '') {
          new VideoUpload({
            ...uploaderParams,
            file: acceptedFiles[0],
            minDimensions: META.HERO_VIDEO_MIN_DIMENSIONS,
            organizationId: article.organization.id
          }).upload(UploadResources.VIDEO)
        }
      },
      [uploaderParams, article.organization.id]
    )
    const isDisabled = Boolean(disabled || isUploading)
    const { getRootProps, isDragActive, getInputProps } = useDropzone({
      onDrop,
      multiple: false,
      disabled: isDisabled,
      accept: ACCEPTED_FILE_TYPES
    })

    // videoFile.videoUrls are signed and thus technically change even when the image has not _actually_ changed.
    // eslint complains that article is not a dependency but we _really_ only want this hook to
    // re-compute when a different videoFile is attached to the article. we need the entire article
    // to be passed to previewImageFor because we look through the various videoFile urls to find an
    // image.
    // eslint-disable-next-line
    useEffect(() => setPreview(previewImageFor(article)), [article.videoFile?.id, article.heroImageUrl])
    useEffect(() => (isDisabled ? setPublishable(false) : setPublishable(true)), [isDisabled, setPublishable])
    useEffect(() => {
      const videoFileError = errors.for(name)[0]
      setError(videoFileError ? `Video ${videoFileError}` : '')
    }, [errors, name])

    // eslint complaints because of the same reason as above, we need new videoUrl only if a different videoFile is attached to the article.
    // eslint-disable-next-line
    const videoUrl = useMemo(() => videoUrlFor(article), [article.videoFile?.id])

    return (
      <Wrapper hideButtons={isDisabled} {...getRootProps({ onClick: (event) => event.stopPropagation() })}>
        {error && <Error error={error} />}
        <UploadProgress pct={uploadPct} preview={preview} isUploading={isUploading} />
        {isDragActive && <DragMessage />}
        <HeroPickerControl
          dropZoneInputProps={getInputProps}
          imageUrl={preview}
          videoUrl={videoUrl}
          hasVideo={Boolean(article?.videoFile)}
        />
      </Wrapper>
    )
  },
  {
    article: graphql`
      fragment HeroPicker_article on Article {
        organization {
          id
        }
        heroImageUrl
        videoFile {
          id
          videoUrls {
            url
            mime
          }
        }
      }
    `
  }
)

export default HeroPicker
