import React, { useContext } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { createFragmentContainer } from 'react-relay'
import SingleSelect from './SingleSelect'
import { ValueType } from 'react-select'
import { Context, ContextType } from '../Form'
import Section, { SectionProps } from './Section'
import { TemplatableType } from '../../../graphql-types'
import { TemplateTypeSelect_template as Fragment } from './__generated__/TemplateTypeSelect_template.graphql'

type TemplatableTypeValue = {
  label: string
  value: string
}

const TemplateTypeSelect = createFragmentContainer (
  (props: SectionProps & { template: Fragment }) => {
    const { model, onChange, errors, disabled } = useContext(Context) as ContextType
    const { template: { templatableType }, name } = props

    const options = Object.keys(TemplatableType).reduce((m: TemplatableTypeValue[], k: string) => {
      return [...m, { label: k, value: (TemplatableType as any)[k] }]
    }, [])
    const value = options.filter((c: TemplatableTypeValue) => c.value === (model[name] || templatableType))[0]
    const currentTemplatableType = (value && value.label) || ''

    return (
      <Section {...props} label={'Template Type'} preview={currentTemplatableType} errors={errors.for(name)}>
        <SingleSelect {...props}
          placeholder='Select A Template Type'
          required={true}
          options={options}
          preview={currentTemplatableType}
          value={value}
          onChange={(e: ValueType<{}, false>) => onChange(name, (e as TemplatableTypeValue).value)}
          isDisabled={disabled}
        />
      </Section>
    )
  },
  {
    template: graphql`
      fragment TemplateTypeSelect_template on Draftable {
        ...on Template {
          templatableType
        }
      }
    `
  }
)

TemplateTypeSelect.displayName = 'TemplateTypeSelect'
export default TemplateTypeSelect
