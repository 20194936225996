import styled, { css } from 'styled-components'

const HeadingStyles = css`
  font-size: ${props => props.theme.fontSizes[3]}px;
  line-height: 19px;
  padding: 0 10px 0 0px;
  margin: 0;
`

const Row = styled.div<{ expanded?: boolean, cursor?: string, visibleButtons?: boolean }>`
  padding: 14px 40px;
  border-bottom: 1px solid #F1F1F1;
  background-color: ${props => props.expanded ? '#F9F9F9' : 'white'};
  display: flex;
  align-items: center;
  cursor: ${props => props.cursor ? props.cursor : 'unset'};

  button {
    visibility: ${props => props.visibleButtons ? 'visible' : 'hidden'};
  }

  &:hover {
    button {
      visibility: visible;
    }
  }

  h2 {
    ${HeadingStyles}
    width: 150px;
    user-select: none;
    font-weight: 600;
    text-transform: capitalize;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  h3 {
    ${HeadingStyles}
    font-weight: 400;
  }

  label {
    padding: 0;
    margin-right: 24px;

    div {
      width: auto;
    }
  }

  input {
    font-size: 16px;
    line-height: 19px;
    border-radius: 2px;
    border: 1px solid #C4C4C4;
    outline: none;
    min-width: 214px;
    height: 100%;
    padding: 7px 8px 7px 8px;
    box-sizing: border-box;
  }
`

export default Row
