import React, { FC, useState } from 'react'
import { Box, Button, Card, IconButton, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { FormikContextType, useFormikContext } from 'formik'
import { graphql } from 'babel-plugin-relay/macro'
import * as Yup from 'yup'
import alticeLogo from '../../../images/alticeLogo.png'
import Schema from '../../Settings/Users/Schema'
import { Logo, Wrapper } from '../Signin'
import Form, { OnCompletedOptions as FormProps } from '../../Settings/Form'
import { useSnackbar } from 'notistack'
import { CreatePasswordMutationResponse as MutationResponse } from './__generated__/CreatePasswordMutation.graphql'

const mutation = graphql`
  mutation CreatePasswordMutation($id: ID!, $input: UserInput!) {
    userUpdate(id: $id, input: $input) {
      user {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

const useStyles = makeStyles(() => ({
  cardContainer: {
    padding: 80
  }
}))

const initialValues = (id: string) => ({
  id: id,
  input: {
    password: ''
  }
})

const validationSchema = Yup.object().shape({
  id: Yup.string().max(255).required(),
  input: Yup.object().shape({
    password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password must be at least 8 characters')
  })
})

interface OnCompleteProps extends FormProps {
  values: MutationResponse
}

type AdornmentProps = {
  showPassword: boolean
  setShowPassword: (arg: boolean) => void
}

const Adornment: FC<AdornmentProps> = ({ showPassword, setShowPassword }) => (
  <InputAdornment position='end'>
    <IconButton
      aria-label='toggle password visibility'
      onClick={() => setShowPassword(!showPassword)}
      onMouseDown={() => setShowPassword(!showPassword)}
    >
      {showPassword ? <Visibility /> : <VisibilityOff />}
    </IconButton>
  </InputAdornment>
)

export const CreatePasswordForm: FC<{ label: string }> = ({ label }) => {
  const { values, errors, touched, handleBlur, handleChange }: FormikContextType<Schema> = useFormikContext()
  const [showPassword, setShowPassword] = useState(false)

  return (
    <>
      <Box height={55} width={350}>
        <TextField
          fullWidth
          type={showPassword ? 'text' : 'password'}
          InputProps={{ endAdornment: <Adornment showPassword={showPassword} setShowPassword={setShowPassword} /> }}
          value={values.input.password || []}
          variant='outlined'
          label={label}
          id={`input.password`}
          name={`input.password`}
          error={touched?.input?.password && Boolean(errors?.input?.password)}
          helperText={touched?.input?.password && errors?.input?.password}
          onBlur={handleBlur}
          onChange={handleChange}
        />
      </Box>
      <Box textAlign='right' mt={3}>
        <Button variant='contained' color='secondary' type='submit'>
          Continue
        </Button>
      </Box>
    </>
  )
}

export const CreatePasswordWrapper: FC<{ title: string }> = ({ children, title }) => {
  const classes = useStyles()

  return (
    <Wrapper>
      <Card className={classes.cardContainer}>
        <Logo>
          <img alt={'Altice Logo'} src={alticeLogo} />
        </Logo>
        <Typography variant='h4'>{title}</Typography>
        {children}
      </Card>
    </Wrapper>
  )
}

const CreatePassword: FC<{ id: string }> = ({ id }) => {
  const { enqueueSnackbar } = useSnackbar()

  const onComplete = ({ values: { userUpdate }, setErrors, resetForm }: OnCompleteProps) => {
    if (!userUpdate?.errors.length) {
      resetForm()
      enqueueSnackbar(`Password successfully created`, { variant: 'success' })
      window.location.href = '/'
    } else {
      setErrors({ input: { password: userUpdate.errors[0].messages.join(',') } })
    }
  }

  return (
    <CreatePasswordWrapper title='You’ve been added to your team’s CMS'>
      <Form validationSchema={validationSchema} initialValues={initialValues(id!)} mutation={mutation} onComplete={onComplete}>
        <Box mt={3} mb={1}>
          <CreatePasswordForm label='Create a password to continue' />
        </Box>
      </Form>
    </CreatePasswordWrapper>
  )
}

export default CreatePassword
