import React from 'react'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { graphql } from 'babel-plugin-relay/macro'
import { FormikValues } from 'formik'
import { Box } from '@material-ui/core'
import { ResetPasswordMutationResponse } from './__generated__/ResetPasswordMutation.graphql'
import { CreatePasswordForm, CreatePasswordWrapper as Wrapper } from '../CreatePassword/CreatePassword'
import Form, { formatErrors, handleNonFieldErrors, OnCompletedOptions as FormProps } from '../../Settings/Form'

const mutation = graphql`
  mutation ResetPasswordMutation($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      errors {
        field
        messages
      }
    }
  }
`

const initialValues = (token: string) => ({
  token,
  input: {
    password: ''
  }
})

const validationSchema = Yup.object().shape({
  input: Yup.object().shape({
    password: Yup.string().min(8, 'Password must be at least 8 characters').required('Password must be at least 8 characters')
  })
})

interface onCompleteProps extends FormProps {
  values: ResetPasswordMutationResponse
}

const formatValues = (values: FormikValues) => ({ password: values.input.password, token: values.token })

const ResetPassword: React.FC<{ passwordResetToken: string }> = ({ passwordResetToken }) => {
  const { enqueueSnackbar } = useSnackbar()

  const onComplete = ({ values: { resetPassword }, setErrors, resetForm, formValues }: onCompleteProps) => {
    if (!resetPassword?.errors.length) {
      resetForm()
      enqueueSnackbar('New password successfully created.', { variant: 'success' })
      window.location.href = '/'
    } else {
      handleNonFieldErrors(resetPassword.errors, formValues!.input, enqueueSnackbar)
      setErrors({ input: formatErrors(resetPassword.errors) })
    }
  }

  return (
    <Wrapper title='Password reset'>
      <Form
        formatValues={(values) => formatValues(values)}
        validationSchema={validationSchema}
        initialValues={initialValues(passwordResetToken)}
        mutation={mutation}
        onComplete={onComplete}
      >
        <Box mt={3} mb={1}>
          <CreatePasswordForm label='Enter a new password to continue' />
        </Box>
      </Form>
    </Wrapper>
  )
}

export default ResetPassword
