import React from 'react'
import styled from 'styled-components'

const PaddingWrapper = styled.div`
  padding: 20px 0;
  height: 100%;
  box-sizing: border-box;
`

const Wrapper = styled.div`
  box-shadow: -51px 51px 49px 0 rgba(0, 0, 0, 0.06);
  background-color: ${props => props.theme.colors.grays.athensGray};
  padding: 40px 41px 40px 41px;
  border-radius: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  box-sizing: border-box;
`

const Camera = styled.div`
  width: 12px;
  height: 14px;
  background-color: ${props => props.theme.colors.grays.whiteSmoke};
  border-radius: 50%;
  position: relative;
  margin-bottom: 28px;

  &:after {
    content: '';
    background-color: #fffafa;
    width: 6px;
    height: 7px;
    border-radius: 50%;
    position: absolute;
    left: 3px;
    top: 3px;
  }
`

const Screen = styled.div`
  width: 768px;
  height: 100%;
`

const Content = styled.div`
  padding: 20px;
  background-color: #ffffff;
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
`

const ContentHeader = styled.div`
  width: 100%;
  background-color: #000000;
  height: 64px;
`

const Button = styled.div`
  height: 45px;
  width: 41px;
  box-sizing: border-box;
  border-radius: 50%;
  border: solid 2px #f5f5f5;
  position: relative;
  top: 20px;
`

type Props = {
  html: string
}

const TabletPreview: React.FC<Props> = ({html}) => {
  return (
    <PaddingWrapper>
      <Wrapper>
        <Camera/>
        <ContentHeader/> 
        <Screen>
          <Content dangerouslySetInnerHTML={{__html: html}}/>
        </Screen>
        <Button/>
      </Wrapper>
    </PaddingWrapper>
  )
}

export default TabletPreview
