import { FormikValues } from 'formik'

/**
 * Format Form fields to 'backend' style.
 */
export const formatFields = <T extends FormikValues>(values: T) => {
  const fieldPairs = Object.entries(values)

  return fieldPairs.reduce((formattedFields, pair) => {
    const [currentKey, currentValue] = pair

    if (typeof currentValue !== 'object') {
      return { ...formattedFields, ...preservePair({ currentKey, currentValue }) }
    }

    if (currentKey === 'tags') {
      return { ...formattedFields, ...flattenNestedValues({ currentValue }) }
    }

    return { ...formattedFields, ...flattenAuthorValues({ currentValue }) }
  }, {})
}

const preservePair = ({ currentKey, currentValue }: { currentKey: string; currentValue: string }) => {
  return { [currentKey]: currentValue }
}

const flattenNestedValues = ({ currentValue }: { currentValue: {} }) => {
  const nestedPairs = currentValue && Object.entries(currentValue)

  return nestedPairs.reduce((innerFormattedFields: {}, innerPair: [string, unknown]) => {
    const [innerKey, innerValue] = innerPair

    return { ...innerFormattedFields, [createTagName('tags', innerKey)]: (innerValue as string[])[0] }
  }, {})
}

const flattenAuthorValues = ({ currentValue }: { currentValue: {} }) => {
  const nestedPairs = Object.entries(currentValue)

  return nestedPairs.reduce((innerFormattedFields, innerPair) => {
    const [innerKey, innerValue] = innerPair
    if (innerKey !== 'tags') {
      return { ...innerFormattedFields, [createAuthorName(innerKey)]: (innerValue as string[])[0] }
    }

    return { ...innerFormattedFields, ...flattenAuthorTagValues(innerValue) }
  }, {})
}

const flattenAuthorTagValues = (currentValue: any) => {
  const nestedPairs = currentValue && Object.entries(currentValue)

  return (
    nestedPairs &&
    nestedPairs.reduce((innerFormattedFields, innerPair) => {
      const [innerKey, innerValue] = innerPair

      return { ...innerFormattedFields, [createTagName('author.tags', innerKey)]: (innerValue as string[])[0] }
    }, {})
  )
}

const createTagName = (staticString: string, dynamicString: string) => {
  return `${staticString}[${dynamicString}]`
}

const createAuthorName = (dynamicString: string) => {
  return `author.${dynamicString}`
}
