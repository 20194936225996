import React, { FC, useEffect, useState } from 'react'
import deepEqual from 'deep-equal'
import { Formik, FormikValues } from 'formik'
import { FormikErrors } from 'formik/dist/types'
import { ObjectSchema } from 'yup'
import { useSnackbar } from 'notistack'
import { capitalize } from '@material-ui/core'
import { commitMutation, GraphQLTaggedNode } from 'react-relay'
import NavigationPrompt from 'react-router-navigation-prompt'
import environment from '../../relay/environment'
import useBeforeUnload from '../../hooks/useBeforeUnload'
import ConfirmModal from '../Modal'

export type OnCompletedOptions = {
  setErrors: (errors: FormikErrors<FormikValues>) => void
  resetForm: () => void
  formValues?: FormikValues
}

interface OnCompletedProps extends OnCompletedOptions {
  values: any
}

export type FormProps = {
  validationSchema: ObjectSchema
  initialValues: {}
  mutation: GraphQLTaggedNode
  onComplete?: (response: OnCompletedProps) => void
  formatValues?: (values: FormikValues) => FormikValues
}

export const formatErrors = (backendErrors: any) =>
  backendErrors.length &&
  backendErrors.reduce(
    (acc: any, val: any) => ({
      ...acc,
      [val.field]: capitalize(val.messages.join('; '))
    }),
    {}
  )

/**
 * Determine if there is an error occurred on the field which is not presented on the Form but exists on backend.
 */
export const isNonFieldError = <T extends { backendErrors: {}; formFields: {} }>({ backendErrors={}, formFields }: T): boolean =>
  Boolean(
    Object.keys(backendErrors).length &&
      Object.keys(formFields).length &&
      !Object.keys(formFields).some((f) => Object.keys(backendErrors).includes(f))
  )

export const handleNonFieldErrors = <T extends any>(backendErrors: T, formFields: T, enqueueSnackbar: any) => {
  if (isNonFieldError({ backendErrors: formatErrors(backendErrors), formFields: formFields })) {
    enqueueSnackbar('Unable to save your changes. Please backup your work and try again later.', { variant: 'error' })
  }
}

const NavigationModal: FC<{ preventNavigation: boolean }> = ({ preventNavigation }) => (
  <NavigationPrompt when={preventNavigation}>
    {({ onConfirm, onCancel }) => (
      <ConfirmModal
        buttonText='Leave'
        title='Confirmation'
        message='Your work has not been saved. Are you sure you want to leave?'
        onClick={onConfirm}
        open={preventNavigation}
        onClose={onCancel}
        maxWidth='sm'
      />
    )}
  </NavigationPrompt>
)

const Form: FC<FormProps> = ({ formatValues = (values) => values, validationSchema, initialValues, children, mutation, onComplete }) => {
  const [preventNavigation, setPreventNavigation] = useState(false)
  const [isFormDataSaved, setIsFormDataSaved] = useState(false)
  const [formData, setFormData] = useState(initialValues)
  const { enqueueSnackbar } = useSnackbar()

  useBeforeUnload(preventNavigation)

  useEffect(() => {
    if (isFormDataSaved) {
      setPreventNavigation(false)
      return
    }
    deepEqual(initialValues, formData, { strict: true }) ? setPreventNavigation(false) : setPreventNavigation(true)
  }, [formData, initialValues, isFormDataSaved])

  const onSubmit = ({ values, setErrors, resetForm }: OnCompletedProps) => {
    commitMutation(environment, {
      mutation,
      variables: formatValues(values),
      onCompleted: (response, error) => {
        if (error) {
          enqueueSnackbar('Unable to save your changes. Please backup your work and try again later.', { variant: 'error' })
          return
        }
        const errors = (Object.values(response)[0] as any)?.errors
        errors && errors.length === 0 && setIsFormDataSaved(true)
        onComplete && onComplete({ values: response, setErrors, resetForm, formValues: values })
      },
      onError: () => {
        enqueueSnackbar('Unable to save your changes. Please backup your work and try again later.', { variant: 'error' })
      }
    })
  }

  return (
    <Formik
      validate={(e) => {
        setIsFormDataSaved(false)
        setFormData(e)
      }}
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm, setSubmitting, setErrors }) => {
        onSubmit({ values, setErrors, resetForm })
        setSubmitting(false)
      }}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit} style={{ height: '100%' }}>
          <NavigationModal preventNavigation={preventNavigation} />
          {children}
        </form>
      )}
    </Formik>
  )
}

export default Form
