import React, { ReactElement } from 'react'
import styled from 'styled-components'
import ReactSelect, { components, OptionProps, StylesConfig } from 'react-select'
import { ErrorMessage } from './Input'
const { DropdownIndicator, IndicatorsContainer, Option, Placeholder, ValueContainer } = components

const Label = styled.label`
  font-weight: ${props => props.theme.fontWeights[1]};
  font-size: ${props => props.theme.fontSizes[3]}px;
  color: ${props => props.theme.colors.primary.blackRussian};
  padding-bottom: ${props => props.theme.space[2]}px !important;
  display: block;
`

const CustomStyles: StylesConfig<{}, boolean> = {
  container: (provided, state) => {
    return {
      ...provided,
      outline: 'none',
    }
  },
  control: (provided, state) => ({
    ...provided,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#c4c4c4',
    borderRadius: '2px',
    boxShadow: 'none',
    backgroundColor: state.selectProps.menuIsOpen ? '#6F50F4' : 'white',
    '&:hover': {
      borderColor: '#c4c4c4'
    }
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    'i': {
      color: state.selectProps.menuIsOpen ? 'white' : '#151515'
    }
  }),
  menu: (provided, state) => ({
    ...provided,
    marginTop: '0px',
    borderRadius: '2px',
    padding: '8px'
  }),
  multiValue: (provided, state) => ({
    ...provided,
    background: 'white',
    margin: '0',
    padding: '0 3px'
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    margin: '0',
    paddingLeft: '0px',
    padding: '3px 0',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '19px',
    color: '#4E5155'
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: state.selectProps.menuIsOpen ? 'white' : '#8E8F91'
  }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    justifyContent: 'space-between',
    background: 'white !important',
    color: '#4E5155',
    fontSize: '16px',
    lineHeight: '19px',
    padding: '8px 7px',
    '&:hover': {
      background: '#F9F9F9 !important'
    },
    'i': {
      fontSize: '16px',
      lineHeight: '19px',
    }
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    '>div:not(:first-of-type)': {
      display: state.selectProps.menuIsOpen ? 'none' : 'flex',
      padding: '0 3px'
    }
  })
};

const CustomIndicatorsContainer = (props: any) => (
  <IndicatorsContainer {...props}>
    <DropdownIndicator {...props}>
      <i className="material-icons">{props.selectProps.menuIsOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</i>
    </DropdownIndicator>
  </IndicatorsContainer>
)

const CustomOption = (props: OptionProps<{}, boolean>) => (
  <Option {...props}>
    <span>{props.children}</span>
    {props.isSelected && <i className="material-icons">check</i>}
  </Option>
)

const CustomValueContainer = (props: any) => {
  const noValueSelected = (!props.selectProps.value || props.selectProps.value.length === 0) ? true : false
  const showPlaceholder = props.selectProps.menuIsOpen || (!props.selectProps.menuIsOpen && noValueSelected)
  return(
    <ValueContainer {...props}>
      {showPlaceholder && <Placeholder {...props} isFocused={props.isFocused}>{props.selectProps.placeholder}</Placeholder>}
      {props.children.map((child: ReactElement) => child && child.type !== Placeholder ? child : null)}
    </ValueContainer>
)}

type OptionType = {
  label: string
  value: string | string
}

export type Props = {
  error?: string
  handleChange: (e: { name: string, value: string | string [] }) => void
  label: string
  name: string
  isMulti: boolean
  options: OptionType[]
  placeholder: string
  value: OptionType | OptionType[] | undefined | null
}

const SelectConfig = ({ error, handleChange, name, isMulti, options, placeholder, value }: Props) => ({
  backspaceRemovesValue: true,
  closeMenuOnSelect: true,
  components: {
    IndicatorsContainer: CustomIndicatorsContainer,
    Option: CustomOption,
    ValueContainer: CustomValueContainer
  },
  error,
  onChange: (selection: any) => {
    if (selection) {
      if (selection.length) {
        const value = selection.reduce((a: any, b: any) => { return a = [...a, b.value] }, []) || []
        handleChange({ name, value})
      } else {
        handleChange({ name, value: selection.value })
      }
    }
  },
  hideSelectedOptions: false,
  name,
  isClearable: false,
  isSearchable: false,
  isMulti,
  options,
  placeholder,
  styles: CustomStyles,
  value
})

const Select: React.FC<Props> = (props) => (
  <div>
    <Label htmlFor={props.label}>{props.label}</Label>
    <ReactSelect {...SelectConfig(props)} inputId={props.label}/>
    {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
  </div>
)

export default Select
