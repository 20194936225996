import React, { useEffect, useContext, useRef } from 'react'
import { fetchQuery } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import environment from '../../relay/environment'
import EditorJS, { EditorConfig } from '@editorjs/editorjs'
import { Context, ContextType, Field } from '../Form'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import Quote from '@editorjs/quote'
import Embed from '@editorjs/embed'
import ImageTool from '@editorjs/image'
import styled, { css, CSSObject } from 'styled-components'
import Twitter from './Editor.js/Twitter'
import SpotIm from './Editor.js/SpotIm'
import TagBoard from './Editor.js/Tagboard'
import VideoEmbed from './Editor.js/VideoEmbed'
import UnderlineTool from '../../vendor/Underline'

const embedParams: CSSObject = {
  'margin-left': 'auto',
  'margin-right': 'auto',
  'min-width': '360px',
  'max-width': '568px',
}

const EditorBlock = styled.div<{ disabled: boolean }>`
  max-width: 1024px;
  margin: auto;

  .ce-block__content {
    max-width: 98%;
    margin: unset;
  }

  .ce-toolbar__content {
    max-width: 100%;
  }

  .ce-toolbar__plus {
    transform: translate3d(-45px, calc(23px - 50%), 0px);
  }

  .image-tool__image-picture {
    ${embedParams}
  }

  .image-tool__caption {
    ${embedParams}
  }

  .embed-tool {
    ${embedParams}
  }

  .ce-paragraph {
    padding-right: 16px;
  }

  .cdx-quote {
    width: 60%;
  }

  .twitter-tweet {
    ${embedParams}
  }

  ${props => props.disabled && css`
    .ce-toolbar {
      display: none;
    }

    .ce-inline-toolbar {
      display: none;
    }

    .codex-editor__redactor {
      padding-bottom: 0 !important;
      margin-bottom: 300px
    }

  `}
`

const uploadUrlQuery = graphql`
  query EditorUploadUrlQuery($extension: String!, $mime_type: String!, $suffix: String) {
    uploadUrl(extension: $extension, mime_type: $mime_type, suffix: $suffix) {
      url
      thumbnails {
        large
      }
    }
  }
`

 const getSignedUrl = (file: File) => {
  const { name, type: mime_type } = file

  let variables = {
    extension: name.split('.').pop(),
    mime_type
  }

  return fetchQuery(environment, uploadUrlQuery, variables).toPromise()
    .then((data: any) => {
      return fetch(data?.uploadUrl?.url, {
        method: 'PUT',
        body: file
      }).then((response) => {
        return {
          success: 1,
          file: {
            url: data?.uploadUrl?.thumbnails?.large,
          }
        }
      })
    })
}

const getSignedUrlFromLink = (url: string) => {
  return fetch(url)
    .then(response => response.blob())
    .then(blob => new File([blob], url))
    .then(file => getSignedUrl(file))
}

const editorConfiguration: EditorConfig = {
  holder: 'editor',
  placeholder: 'Start writing here...',
  tools: {
    header: Header,
    list: List,
    quote: Quote,
    spotim: {
      class: SpotIm
    },
    tagboard: {
      class: TagBoard
    },
    video: {
      class: VideoEmbed
    },
    twitter: {
      class: Twitter
    },
    underlineTool: {
      class: UnderlineTool,
      shortcut: 'CMD+U'
    },
    embed: {
      class: Embed,
      config: {
        services: {
          youtube: true,
          vimeo: true,
          cheddar: {
            regex: /https?:\/\/cheddar.com\/media\/([^/?&]*)/,
            embedUrl: 'https://cheddar.com/media/<%= remote_id %>/player?autoplay=false',
            html: '<iframe width=100% height="315" frameborder="0" allowfullscreen></iframe>',
            height: 315,
            width: 560
          },
          facebook: {
            regex: /https?:\/\/w{0,3}\.facebook\.com.*\/(\d+)/,
            embedUrl: 'https://www.facebook.com/video/embed?video_id=<%= remote_id %>',
            html: '<iframe width=100% height="315" frameborder="0" allowfullscreen></iframe>',
            height: 315,
            width: 552,
          },
          ugc_carousel: {
            regex: /https?:\/\/ugc.curds.io\/carousel\/(\d+)/,
            embedUrl: 'https://ugc.curds.io/carousel/<%= remote_id %>',
            html: '<iframe width=100% height="818" scrolling="no" frameborder="0" allowfullscreen></iframe>',
          },
          ugc_form: {
            regex: /https?:\/\/ugc.curds.io\/formviewer\/display\/(\d+)/,
            embedUrl: 'https://ugc.curds.io/formviewer/display/<%= remote_id %>',
            html: '<iframe width=100% height="1100" scrolling="no" frameborder="0" allowfullscreen></iframe>',
          },
          adx_live_stream: {
            regex: /https?:\/\/adx.news12.com.*=(\w*)/,
            embedUrl: 'https://adx.news12.com/livevideo_jw8/livevideo_iframe.html?region=<%= remote_id %>',
            html: '<iframe width=100% height="315" scrolling="no" frameborder="0" allowfullscreen></iframe>',
            height: 315,
            width: 560
          }
        }
      }
    },
    image: {
      class: ImageTool,
      config: {
        uploader: {
          uploadByFile(file: File) {
            return getSignedUrl(file)
          },
          uploadByUrl(url: string) {
            return getSignedUrlFromLink(url)
          }
        }
      }
    }
  }
}

const TwitterScriptHandler = () => {
  const script = document.createElement('script')

  return {
    initialize() {
      return new Promise(function(resolve) {
        script.src = 'https://platform.twitter.com/widgets.js'
        document.body.appendChild(script)
        script.setAttribute('data-testid', 'twitter-script')
        script.onload = () => resolve()
      })
    },
    cleanup() {
      document.body.removeChild(script)
    }
  }
}

const disableEdit = (disabled: boolean | undefined) => {
  if (disabled){
    let contents = Array.from(document.querySelectorAll('[contenteditable]'))
    contents.forEach((el) => { el.setAttribute("contenteditable", 'false') })
  }
}

const Editor: React.FC<{ name: string }> = (props) => {
  const { name } = props
  let staticContext = useRef(useContext(Context))
  let editor = useRef<EditorJS>()
  const { errors, disabled } = useContext(Context) as ContextType

  useEffect(() => {
    const { model, onChange } = staticContext.current as ContextType
    const twitter = TwitterScriptHandler()

    const handleChange = () => {
      editor.current?.save().then((data: any) => {
        model.body = JSON.stringify(data)
        onChange(name, JSON.stringify(data))
      })
    }

    twitter.initialize().then(() => {
      editor.current = new EditorJS({
        data: JSON.parse(model[name]),
        onChange: () => handleChange(),
        onReady: () => disableEdit(disabled),
        ...editorConfiguration
      })
    })

    return function cleanup() {
      editor.current?.destroy?.()
      twitter.cleanup()
    }
  }, [name, disabled])

  return (
    <Field name={name} errors={errors.for(name)}>
      <EditorBlock
        data-testid='editor'
        disabled={disabled!}
        id='editor'
      />
    </Field>
  )
}

export default Editor
