/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Roles = "ADMIN" | "CMS" | "EDITOR" | "WRITER" | "%future added value";
export type UserEdit_user = {
    readonly id: string;
    readonly role: Roles | null;
    readonly " $refType": "UserEdit_user";
};
export type UserEdit_user$data = UserEdit_user;
export type UserEdit_user$key = {
    readonly " $data"?: UserEdit_user$data;
    readonly " $fragmentRefs": FragmentRefs<"UserEdit_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserEdit_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'c8c41ed6754ce46beab298692b711495';
export default node;
