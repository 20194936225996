import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormikContextType, FormikValues, useFormikContext } from 'formik'
import { useNavigatorOnline } from '../../hooks/useNavigatorOnline'
import { Box, Button, makeStyles } from '@material-ui/core'
import { Theme } from '../../theme'
import WarningModal, { selectWarning, WARNING_MODAL_TYPE } from '../TopStories/WarningModal'

const useStyles = makeStyles((theme: Theme) => ({
  footer: {
    marginTop: 1,
    padding: 1,
    height: 60,
    position: 'fixed',
    backgroundColor: theme.palette.background.primary,
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 1
  },
  button: {
    marginRight: 10
  }
}))

export type ControlsProps = {
  redirectTo: string
  isAdmin: boolean
  numberOfStories?: number
}

const Controls: FC<ControlsProps> = ({ redirectTo, isAdmin, numberOfStories }) => {
  const { isSubmitting, values, submitForm }: FormikContextType<FormikValues> = useFormikContext()
  const { isOnline } = useNavigatorOnline()
  // A state machine for determining the values to be injected into the warning modal
  const [selectWarningModal, setSelectWarningModal] = useState<string>('')

  const classes = useStyles()
  const history = useHistory()

  const handleCancel = (isAdmin: boolean) => {
    if (isAdmin) {
      history.push(`/${redirectTo}`)
    } else {
      history.push('/articles')
    }
  }

  const handleSubmit = () => {
    const selectedWarning = selectWarning(numberOfStories!, values.input.max, values.input.min)
    if (selectedWarning) {
      setSelectWarningModal(selectedWarning)
      return
    }
    submitForm()
  }

  // This object encapsulates the unique differences between what each warning modal needs to show.
  // Use the "WARNING_MODAL_TYPE" enum to determine which values should be accessed.
  const selectWarningProps: { [key: string]: any } = {
    [WARNING_MODAL_TYPE.OVER_MAXIMUM]: {
      message: 'You entered a value for "Max" that is less than the current number of stories in the list. The last stories will be lost if you continue.'
    },
    [WARNING_MODAL_TYPE.UNDER_MINIMUM]: {
      message: 'You entered a value for "Min" that is greater than the current number of stories in the list.'
    },
    // Handles the case when the modal shouldn't show yet. An empty object is used here so that the spread operator
    //  can be used for all use cases without error.
    '': {}
  }

  const warningModalProps = {
    // Props common to each type of warning modal
    onClose: () => setSelectWarningModal(''),
    showModal: !!selectWarningModal,
    // Props unique to each type of warning modal
    ...selectWarningProps[selectWarningModal]
  }

  return (
    <div className={classes.footer}>
      <Box mr={8} pt={1} textAlign='right'>
        <Button className={classes.button} variant='contained' type='reset' onClick={() => handleCancel(isAdmin)}>
          Cancel
        </Button>
        <Button variant='contained' color='secondary' onClick={handleSubmit} disabled={isSubmitting || !isOnline}>
          Save
        </Button>
      </Box>
      <WarningModal {...warningModalProps} />
    </div>
  )
}

export default Controls
