import React, { FC } from 'react'
import { FormikContextType, FormikValues, useFormikContext } from 'formik'
import ConfirmModal from '../Modal'

// Types of warning modals
export enum WARNING_MODAL_TYPE {
  OVER_MAXIMUM = 'OVER_MAXIMUM',
  UNDER_MINIMUM = 'UNDER_MINIMUM',
}

/**
 * Determines the type of modal to show. Returns an empty string/falsy value if no warning is necessary.
 */
export const selectWarning = (numberOfStories: number, maxStories: number, minStories: number): WARNING_MODAL_TYPE | '' => {
  if(numberOfStories > maxStories) {
    return WARNING_MODAL_TYPE.OVER_MAXIMUM
  }

  if(numberOfStories < minStories) {
    return WARNING_MODAL_TYPE.UNDER_MINIMUM
  }

  // Implementation note: In Typescript, we can utilize an empty string as a "falsy" value without having to tell `useState` to
  //  expect multiple types from this function. It can just implicitly expect a string when storing values from this state machine.
  return ''
}

export type WarningModalProps = {
  message?: string
  onClose: any
  showModal: boolean
}

const WarningModal: FC<WarningModalProps> = ({ message = '', onClose, showModal }) => {
  const { submitForm }: FormikContextType<FormikValues> = useFormikContext()

  const onClick = () => {
    submitForm()
    onClose()
  }

  return (
    <ConfirmModal
      buttonText='Proceed'
      title='Warning'
      message={message}
      onClick={onClick}
      open={Boolean(showModal)}
      onClose={onClose}
      maxWidth='sm'
    />
  )
}

export default WarningModal
