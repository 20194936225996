import React, { FC, MouseEvent } from 'react'
import { IconButton, makeStyles, TableCell, TableRow } from '@material-ui/core'
import { Draggable } from 'react-beautiful-dnd'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import { ActionButtons } from './Buttons'
import { publicationDate } from './TopStoryEdit'
import { Theme } from '../../../theme'
import { StoryId } from './types'

type RowProps = StoryId & {
  id: string
  index: number
  handleDelete: ({ id }: StoryId) => void
  handleReplace: ({ id }: StoryId) => void
  publicAt: string
  title: string
  thumbnail: string
  story: any
}

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    display: 'table',
    width: '100%'
  },
  icon: {
    width: '5%'
  },
  thumbnail: {
    width: '10%',
    '& img': {
      width: '80px',
      height: '80px'
    }
  },
  title: {
    width: '68%',
    fontWeight: theme.typography.fontWeightRegular,
    '& p': {
      paddingBottom: '10px'
    },
    '& span': {
      color: theme.palette.text.lightPrimary
    }
  }
}))

const Row: FC<RowProps> = ({ handleDelete, handleReplace, id, index, publicAt, story, title, thumbnail }) => {
  const classes = useStyles()

  const onDelete = (event: MouseEvent) => void handleDelete({ id: event.currentTarget.id! })

  const onReplace = (event: MouseEvent) => void handleReplace({ id: event.currentTarget.id! })

  return (
    <Draggable draggableId={id} index={index}>
      {(provided: any) => (
        <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} {...story}>
          <TableRow hover component='div' className={classes.table}>
            <TableCell className={classes.icon} component='div'>
              <IconButton title='Reorder' style={{ cursor: 'move' }}>
                <DragIndicatorIcon />
              </IconButton>
            </TableCell>
            <TableCell className={classes.thumbnail} component='div'>
              <img src={thumbnail} alt={''} />
            </TableCell>
            <TableCell className={classes.title} component='div'>
              <p>{title}</p>
              <span>{publicationDate(publicAt)}</span>
            </TableCell>
            <ActionButtons id={id} onDelete={onDelete} onReplace={onReplace} />
          </TableRow>
        </div>
      )}
    </Draggable>
  )
}

export default Row
