/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Roles = "ADMIN" | "CMS" | "EDITOR" | "WRITER" | "%future added value";
export type TopStoryEdit_user = {
    readonly role: Roles | null;
    readonly " $refType": "TopStoryEdit_user";
};
export type TopStoryEdit_user$data = TopStoryEdit_user;
export type TopStoryEdit_user$key = {
    readonly " $data"?: TopStoryEdit_user$data;
    readonly " $fragmentRefs": FragmentRefs<"TopStoryEdit_user">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TopStoryEdit_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
(node as any).hash = 'a041a427b605b4d77632156d52675c1e';
export default node;
