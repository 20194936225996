import React, { FC, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { commitMutation } from 'react-relay'
import { IconButton, TableCell } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import environment from '../../../relay/environment'
import { AuthorIndex_query as Response } from './__generated__/AuthorIndex_query.graphql'
import { AuthorDeleteMutation, AuthorDeleteMutationResponse } from './__generated__/AuthorDeleteMutation.graphql'
import ConfirmModal from '../../Modal'
import { ClickableRow } from '../DataList'

type Author = NonNullable<NonNullable<NonNullable<NonNullable<NonNullable<Response['organization']['authors']>>['edges']>[0]>['node']>

export type AuthorProps = {
  author: Author
  id: string
  updateAuthorPage: () => void
}

const deleteMutation = graphql`
  mutation AuthorDeleteMutation($id: ID!) {
    authorDelete(id: $id) {
      author {
        byline
        isDeleted
        id
      }
      errors {
        field
        messages
      }
    }
  }
`

const Author: FC<AuthorProps> = ({ author, id, updateAuthorPage }) => {
  const [showModal, setShowModal] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const deleteAuthor = () => {
    commitMutation<AuthorDeleteMutation>(environment, {
      mutation: deleteMutation,
      variables: { id },
      onCompleted: (resp: AuthorDeleteMutationResponse) => {
        if (!resp.authorDelete?.errors?.length) {
          updateAuthorPage()
          enqueueSnackbar(`"${resp?.authorDelete?.author?.byline}" deleted`, { variant: 'success' })
        } else {
          enqueueSnackbar(`${resp?.authorDelete?.errors[0].messages[0]}`, { variant: 'error' })
        }
        setShowModal(false)
      },
      onError: () => {
        enqueueSnackbar(`Cannot delete author`, { variant: 'error' })
        setShowModal(false)
      }
    })
  }

  return (
    <>
      <ClickableRow clickHandler={() => history.push(`/authors/${author.id}`)}>
        <TableCell>{author.byline}</TableCell>
        <TableCell align='right'>
          <IconButton
            onClick={(e) => {
              e.stopPropagation()
              setShowModal(true)
            }}
            title='Delete'
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </ClickableRow>
      <ConfirmModal
        buttonText='Delete'
        title='Delete Author'
        message={`Are you sure you want to delete "${author.byline}"?`}
        onClick={deleteAuthor}
        open={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  )
}

export default Author
