/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TemplateDetail_draft = {
    readonly id: string;
    readonly canUpdate: boolean;
    readonly draftable: {
        readonly body?: string | null;
        readonly css?: string | null;
        readonly description?: string | null;
        readonly name?: string;
        readonly " $fragmentRefs": FragmentRefs<"TemplateTypeSelect_template">;
    };
    readonly errors: ReadonlyArray<{
        readonly field: string;
        readonly messages: ReadonlyArray<string>;
    }>;
    readonly warnings: ReadonlyArray<{
        readonly field: string;
        readonly messages: ReadonlyArray<string>;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"Controls_draft">;
    readonly " $refType": "TemplateDetail_draft";
};
export type TemplateDetail_draft$data = TemplateDetail_draft;
export type TemplateDetail_draft$key = {
    readonly " $data"?: TemplateDetail_draft$data;
    readonly " $fragmentRefs": FragmentRefs<"TemplateDetail_draft">;
};



const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "field",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "messages",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TemplateDetail_draft",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "draftable",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TemplateTypeSelect_template"
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "body",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "css",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "type": "Template",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Error",
      "kind": "LinkedField",
      "name": "errors",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Error",
      "kind": "LinkedField",
      "name": "warnings",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Controls_draft"
    }
  ],
  "type": "Draft",
  "abstractKey": null
};
})();
(node as any).hash = '32571a336e5fa8601d92e1f69896d118';
export default node;
