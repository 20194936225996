import React, { useState, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import useClickOutside from '../../hooks/useClickOutside'
import { ControlsPublishDraftMutation } from './__generated__/ControlsPublishDraftMutation.graphql'
import { publishMutation } from './Controls'
import { MutationButton, ButtonActions } from '../Button'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import CheckIcon from '@material-ui/icons/Check'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'

const useStyles = makeStyles({
  button: {
    border: 'solid 1px #4a4a4a',
    color: '#4a4a4a',
    transition: 'none',
    '&:hover': {
      borderColor: '#4a4a4a',
      backgroundColor: '#5637db',
      color: 'white'
     },
  },
  buttonGroup: {
    marginRight: '10px',
    '& button:not(:last-of-type)': {
      '& i': {
        display: 'none'
      }
    },
  },
  icon: {
    color: '#5637db'
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '10px 0'
  },
  menuList: {
    padding: '8px'
  },
  typography: {
    textTransform: 'capitalize'
  }
});

const Dropdown: React.FC<{ draftId: string }> = ({ draftId }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [open, setOpen] = useState(false)
  const options = [ButtonActions.Modify, ButtonActions.Republish]
  const selectedOption = options[selectedIndex]
  const anchorRef = useRef(null)
  const containerRef = useRef(null)
  const classes = useStyles()
  useClickOutside(containerRef, () => setOpen(false))

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index)
    setOpen(false)
  }

  const mutationButtonProps = {
    buttonType: selectedOption,
    draftType: 'Article',
    mutation: publishMutation,
    variables: {
      id: draftId,
      minorEdit: selectedOption === 'republish' ? false : true
    }
  }

  return (
    <Grid container direction='column' alignItems='center' ref={containerRef}>
      <Grid item xs={12}>
        <ButtonGroup variant='contained' color='primary' ref={anchorRef} className={classes.buttonGroup}>
          <MutationButton<ControlsPublishDraftMutation> {...mutationButtonProps}/>
          <Button className={classes.button} disableRipple variant='outlined' size='small' onClick={() => setOpen((prevOpen) => !prevOpen)}>
            <KeyboardArrowUp/>
          </Button>
        </ButtonGroup>
        <Popper open={open} placement='top-end' anchorEl={anchorRef.current} transition style={{ zIndex: 2 }}>
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: 'center bottom' }}>
              <Paper >
                <MenuList className={classes.menuList}>
                  <MenuItem className={classes.menuItem} onClick={() => handleMenuItemClick(0)}>
                    <Grid container>
                      <Grid item xs={2}><CheckIcon className={classes.icon} style={{ opacity: selectedOption === 'modify' ? 1 : 0 }}/></Grid>
                      <Grid item xs={10}>
                        <Typography component='h5'>Modify</Typography>
                        <Typography variant='body2'>Don't update timestamp</Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                  <Divider/>
                  <MenuItem className={classes.menuItem} onClick={() => handleMenuItemClick(1)}>
                    <Grid container>
                      <Grid item xs={2}><CheckIcon className={classes.icon} style={{ opacity: selectedOption === 'republish' ? 1 : 0 }}/></Grid>
                      <Grid item xs={10}>
                        <Typography component='h5'>Republish</Typography>
                        <Typography variant='body2'>Update the timestamp</Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                </MenuList>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  )
}

export default Dropdown
