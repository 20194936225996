/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type ImageUploadUrlQueryVariables = {
    extension: string;
    mime_type: string;
    suffix?: string | null;
};
export type ImageUploadUrlQueryResponse = {
    readonly uploadUrl: {
        readonly url: string;
        readonly thumbnails: {
            readonly small: string | null;
        };
    };
};
export type ImageUploadUrlQuery = {
    readonly response: ImageUploadUrlQueryResponse;
    readonly variables: ImageUploadUrlQueryVariables;
};



/*
query ImageUploadUrlQuery(
  $extension: String!
  $mime_type: String!
  $suffix: String
) {
  uploadUrl(extension: $extension, mime_type: $mime_type, suffix: $suffix) {
    url
    thumbnails {
      small
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "extension"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "mime_type"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "suffix"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "extension",
        "variableName": "extension"
      },
      {
        "kind": "Variable",
        "name": "mime_type",
        "variableName": "mime_type"
      },
      {
        "kind": "Variable",
        "name": "suffix",
        "variableName": "suffix"
      }
    ],
    "concreteType": "UploadUrl",
    "kind": "LinkedField",
    "name": "uploadUrl",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Thumbnail",
        "kind": "LinkedField",
        "name": "thumbnails",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "small",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ImageUploadUrlQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ImageUploadUrlQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "53afe6840b565960c3f6f98d4e42513a",
    "id": null,
    "metadata": {},
    "name": "ImageUploadUrlQuery",
    "operationKind": "query",
    "text": "query ImageUploadUrlQuery(\n  $extension: String!\n  $mime_type: String!\n  $suffix: String\n) {\n  uploadUrl(extension: $extension, mime_type: $mime_type, suffix: $suffix) {\n    url\n    thumbnails {\n      small\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '3d21dca20a76b07f2148f11c75a16855';
export default node;
