import React, { useRef, useState, useEffect, useCallback, ReactElement } from 'react'
import styled from 'styled-components'
import { DropzoneState } from 'react-dropzone/typings/react-dropzone'
import Search from './Search'
import ListRenderer from './ListRenderer'
import { graphql } from 'babel-plugin-relay/macro'

const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 30px 30px 10px 30px;
`

const Text = styled.div`
  margin-bottom: auto;
  margin-top: ${props => props.theme.space[2]}px;
  text-align: center;
`

const MediaIcon = styled.div`
  width: 34px;
  height: 28px;
  color: ${props => props.theme.colors.grays.grayChateau};
  text-align: center;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 80px;
  border: solid 1px ${props => props.theme.colors.grays.zircon};
  background-color: ${props => props.theme.colors.grays.solitude};
  font-weight: ${props => props.theme.fontWeights[3]};
  color: ${props => props.theme.colors.primary.mediumSlateBlue};
  cursor: pointer;
  user-select: none;

  &:hover {
    border-color: ${props => props.theme.colors.primary.mediumSlateBlue};
    background-color: ${props => props.theme.colors.primary.mediumSlateBlue};
    color: ${props => props.theme.colors.grays.aliceBlue};
  }
`

const ButtonContainer = styled.div`
  display: flex;
  min-width: 254px;
  justify-content: space-between;
`

const SearchWrapper = styled.div<{ src: string | null }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  background-color: ${props => (props.src) ? 'rgba(255, 255, 255, 0.9)' : 'rgba(255, 255, 255, 1)' };
  z-index: 2;
  position: absolute;
`

const VideoPlayer = styled.video`
  position: absolute;
  height: 100%;
  outline: none;
  width: 100%;
`

const searchVideoQuery = graphql`
query HeroPickerControlQuery($searchTerm: String!) {
  organization {
    videoFiles(query: $searchTerm, first: 15) {
      edges {
        node {
          id
          createdAt
          duration
          name
          videoUrls {
            mime
            url
          }
          thumbnails(aspect_ratio: HD) {
            small
          }
      }
      }
    }
  }
}
`

const Info: React.FC<{ icon: string }> = ({ icon, children }) => (
  <Content>
    <MediaIcon className='material-icons'>{icon}</MediaIcon>
    <Text>{children}</Text>
  </Content>
)

const Unsupported = () => (
  <Info icon='broken_image'>
    Uploaded video is not supported for playback. Check back when transcoding is complete.
  </Info>
)

const Instructions = () => (
  <Info icon='perm_media'>
    Add a video or image to your story
  </Info>
)

const IconButton: React.FC<{ icon: string, text: string, onClick?: () => void }> = (props) => {
  const { icon, text, onClick } = props

  return (
    <ButtonWrapper onClick={onClick}>
      <i className='material-icons'>{ icon }</i>
      <div>{ text.toUpperCase() }</div>
    </ButtonWrapper>
  )
}

type ButtonsProps = {
  dropZoneInputProps: DropzoneState['getInputProps']
  setDisplayVideoSearch: (value: boolean) => void
}

const Buttons: React.FC<ButtonsProps> = (props) => {
  const { dropZoneInputProps, setDisplayVideoSearch } = props
  const inputRef = useRef<HTMLInputElement>(null)

  const openFileUploadPrompt = () => {
    inputRef?.current?.click()
  }

  return (
    <ButtonContainer>
      <IconButton icon='search' text='search' onClick={() => setDisplayVideoSearch(true)}/>
      <input {...dropZoneInputProps()} ref={inputRef}/>
      <IconButton icon='add' text='upload' onClick={openFileUploadPrompt}/>
    </ButtonContainer>
  )
}

export type HeroPickerControlType = {
  dropZoneInputProps: DropzoneState['getInputProps']
  imageUrl: string | null
  videoUrl: string | null
  hasVideo: boolean
}

const getContent = (image: string | null, video: string | null, hasVideo: boolean): ReactElement | undefined => {
  if (video) {
    return (
      <VideoPlayer
        src={video!}
        controls={true}
        poster={image || undefined}
        placeholder={'Video Loading'}
      />
    )
  } else if (image) {
    return <ThumbnailImage src={image!} alt='' />
  } else if (hasVideo) {
    return <Unsupported />
  }
}

const renderListComponent = (searchTerm: string) => (
  <ListRenderer query={searchVideoQuery} searchTerm={searchTerm}/>
)

const HeroPickerControl: React.FC<HeroPickerControlType> = (props) => {
  const { dropZoneInputProps, imageUrl, videoUrl, hasVideo } = props
  const [ displayVideoSearch, setDisplayVideoSearch ] = useState<boolean>(false)
  const dismissSearch = useCallback(() => setDisplayVideoSearch(false), [])
  useEffect(dismissSearch, [imageUrl])

  return (
    <Container>
      {getContent(imageUrl, videoUrl, hasVideo) || <Instructions />}
      {displayVideoSearch
        ? <SearchWrapper src={imageUrl}>
          <Search onDismiss={dismissSearch} renderListComponent={renderListComponent}/>
        </SearchWrapper>
        : <Buttons
          dropZoneInputProps={dropZoneInputProps}
          setDisplayVideoSearch={setDisplayVideoSearch}
        />
      }
    </Container>
  )
}

export default HeroPickerControl
