import React, { FC } from 'react'
import { Box, Button, Dialog, Divider, makeStyles, Typography, DialogProps } from '@material-ui/core'
import clsx from 'clsx'
import { Theme } from '../theme'

type ModalProps = {
  onClose: () => void
  open: boolean
  onClick: () => void
  message: string
  title: string
  buttonText: string
  maxWidth?: DialogProps['maxWidth']
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  button: {
    marginLeft: theme.spacing(2),
    color: theme.palette.text.light,
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  }
}))

const Modal: FC<ModalProps> = ({ onClose, open, onClick, message, title, buttonText, maxWidth }) => {
  const classes = useStyles()

  return (
    <Dialog maxWidth={maxWidth || 'xs'} fullWidth onClose={onClose} open={open}>
      <div className={clsx(classes.root)}>
        <Box p={2} pl={0}>
          <Typography align='left' gutterBottom variant='h3'>
            {title}
          </Typography>
        </Box>
        <Typography align='left' gutterBottom>
          {message}
        </Typography>
      </div>
      <Divider />
      <Box p={2} display='flex' alignItems='center'>
        <Box flexGrow={1} />
        <Button onClick={onClose}>Cancel</Button>
        <Button variant='contained' className={classes.button} onClick={onClick}>
          {buttonText}
        </Button>
      </Box>
    </Dialog>
  )
}

export default Modal
