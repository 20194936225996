import network from './network'

const {
  Environment,
  RecordSource,
  Store,
} = require('relay-runtime')

export default new Environment({
  network: network,
  store: new Store(new RecordSource()),
})

