/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type TopStoryListInput = {
    max?: number | null;
    min?: number | null;
    name?: string | null;
    stories?: Array<TopStoryListStoryInput> | null;
    tags?: Array<TagInput> | null;
};
export type TopStoryListStoryInput = {
    story: string;
    order: number;
};
export type TagInput = {
    source: unknown;
    value: Array<string>;
};
export type TopStoryEditMutationVariables = {
    id: string;
    input: TopStoryListInput;
};
export type TopStoryEditMutationResponse = {
    readonly topStoryListUpdate: {
        readonly topStoryList: {
            readonly id: string;
            readonly name: string;
            readonly stories: ReadonlyArray<{
                readonly story: string;
                readonly order: number;
            }>;
        } | null;
        readonly errors: ReadonlyArray<{
            readonly field: string;
            readonly messages: ReadonlyArray<string>;
        }>;
    } | null;
};
export type TopStoryEditMutation = {
    readonly response: TopStoryEditMutationResponse;
    readonly variables: TopStoryEditMutationVariables;
};



/*
mutation TopStoryEditMutation(
  $id: ID!
  $input: TopStoryListInput!
) {
  topStoryListUpdate(id: $id, input: $input) {
    topStoryList {
      id
      name
      stories {
        story
        order
      }
    }
    errors {
      field
      messages
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TopStoryListUpdateMutationPayload",
    "kind": "LinkedField",
    "name": "topStoryListUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TopStoryList",
        "kind": "LinkedField",
        "name": "topStoryList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "TopStoryListStory",
            "kind": "LinkedField",
            "name": "stories",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "story",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "order",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "messages",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TopStoryEditMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TopStoryEditMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "05f84899bca2285f6133f5e663034ce2",
    "id": null,
    "metadata": {},
    "name": "TopStoryEditMutation",
    "operationKind": "mutation",
    "text": "mutation TopStoryEditMutation(\n  $id: ID!\n  $input: TopStoryListInput!\n) {\n  topStoryListUpdate(id: $id, input: $input) {\n    topStoryList {\n      id\n      name\n      stories {\n        story\n        order\n      }\n    }\n    errors {\n      field\n      messages\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'ce9ede31d5fe3e55231cb9f38905ed48';
export default node;
