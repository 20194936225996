/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type AuthorEditQueryVariables = {
    id: string;
};
export type AuthorEditQueryResponse = {
    readonly node: {
        readonly " $fragmentRefs": FragmentRefs<"AuthorEdit_node">;
    } | null;
};
export type AuthorEditQuery = {
    readonly response: AuthorEditQueryResponse;
    readonly variables: AuthorEditQueryVariables;
};



/*
query AuthorEditQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ...AuthorEdit_node
    id
  }
}

fragment AuthorEdit_node on Author {
  __typename
  id
  byline
  slug
  active
  tags {
    id
    name
    fixedOptions
    value
    type
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthorEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AuthorEdit_node"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuthorEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "byline",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "active",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Tag",
                "kind": "LinkedField",
                "name": "tags",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fixedOptions",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "type",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Author",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cc9d2e11cd541ae541a3d64d8c912aa8",
    "id": null,
    "metadata": {},
    "name": "AuthorEditQuery",
    "operationKind": "query",
    "text": "query AuthorEditQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...AuthorEdit_node\n    id\n  }\n}\n\nfragment AuthorEdit_node on Author {\n  __typename\n  id\n  byline\n  slug\n  active\n  tags {\n    id\n    name\n    fixedOptions\n    value\n    type\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c46bf1d9ab0cd48084d41739533c2a25';
export default node;
