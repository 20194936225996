import React from 'react'
import TextInput from '../TextInput'
import Section from './Section'

export const Input = (props: { name: string, attr: string }) => {
  const { name, attr } = props

  return (
    <TextInput
      placeholder={`Add a ${attr}`}
      name={`content[${name}][${attr}]`}
      displayName={`${attr}`}
    />
  )
}

const Generic = (props: { node: Element, label: string, attrs: string[] }) => {
  const { node, label, attrs } = props
  const name = node.getAttribute('name') || ''

  return (
    <Section name={`${label} (${name})`}>
      {attrs.filter(attr => !node.getAttribute(attr)).map((attr, i) => (
        <Input key={i} name={name} attr={attr} />
      ))}
    </Section>
  )
}

export default Generic
