/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Platform = "ANDROID" | "CMS" | "CRUNCH" | "DIRECTV" | "IOS" | "LOOP" | "ROKU" | "SAMSUNGTV" | "TVOS" | "WEB" | "%future added value";
export type CheddarAuthenticatorCreateDeviceTokenMutationVariables = {
    platform: Platform;
    version: string;
    token?: string | null;
};
export type CheddarAuthenticatorCreateDeviceTokenMutationResponse = {
    readonly associate: {
        readonly id: string;
        readonly token: string;
        readonly " $fragmentRefs": FragmentRefs<"Layout_device">;
    } | null;
};
export type CheddarAuthenticatorCreateDeviceTokenMutation = {
    readonly response: CheddarAuthenticatorCreateDeviceTokenMutationResponse;
    readonly variables: CheddarAuthenticatorCreateDeviceTokenMutationVariables;
};



/*
mutation CheddarAuthenticatorCreateDeviceTokenMutation(
  $platform: Platform!
  $version: String!
  $token: String
) {
  associate(platform: $platform, version: $version, token: $token) {
    id
    token
    ...Layout_device
  }
}

fragment Account_user on User {
  id
  avatar_url
  first_name
  last_name
}

fragment Layout_device on DeviceToken {
  ...Navigation_device
  user {
    role
    id
    ...Account_user
  }
}

fragment Navigation_device on DeviceToken {
  user {
    role
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "platform"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "token"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "version"
},
v3 = [
  {
    "kind": "Variable",
    "name": "platform",
    "variableName": "platform"
  },
  {
    "kind": "Variable",
    "name": "token",
    "variableName": "token"
  },
  {
    "kind": "Variable",
    "name": "version",
    "variableName": "version"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CheddarAuthenticatorCreateDeviceTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "DeviceToken",
        "kind": "LinkedField",
        "name": "associate",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Layout_device"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CheddarAuthenticatorCreateDeviceTokenMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "DeviceToken",
        "kind": "LinkedField",
        "name": "associate",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "role",
                "storageKey": null
              },
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "avatar_url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "first_name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "last_name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ba96343d3fa76cb412a2ed729c73973f",
    "id": null,
    "metadata": {},
    "name": "CheddarAuthenticatorCreateDeviceTokenMutation",
    "operationKind": "mutation",
    "text": "mutation CheddarAuthenticatorCreateDeviceTokenMutation(\n  $platform: Platform!\n  $version: String!\n  $token: String\n) {\n  associate(platform: $platform, version: $version, token: $token) {\n    id\n    token\n    ...Layout_device\n  }\n}\n\nfragment Account_user on User {\n  id\n  avatar_url\n  first_name\n  last_name\n}\n\nfragment Layout_device on DeviceToken {\n  ...Navigation_device\n  user {\n    role\n    id\n    ...Account_user\n  }\n}\n\nfragment Navigation_device on DeviceToken {\n  user {\n    role\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '237a4064abec034119d4cbd6cdd8d2b3';
export default node;
