import React, { useContext, useState, useEffect } from 'react'
import { fetchQuery, createFragmentContainer } from 'react-relay'
import styled from 'styled-components'
import DropzoneS3Uploader from 'react-dropzone-s3-uploader'
import { CircularProgress } from '@rmwc/circular-progress'
import '@rmwc/circular-progress/circular-progress.css'
import { Context, ContextType } from './Form'
import Section, { SectionProps } from './Metadata/Section'
import TextInput from '../TextInput'
import { graphql } from 'babel-plugin-relay/macro'
import environment from '../../relay/environment'
import { ThumbnailQueryResponse as Response } from './__generated__/ThumbnailQuery.graphql'
import { Thumbnail_article as ArticleFragment } from './__generated__/Thumbnail_article.graphql'
import { Thumbnail_page as PageFragment } from './__generated__/Thumbnail_page.graphql'
import ImageUpload from './HeroPicker/ImageUpload'

import { Dimensions } from '../../util/types/Dimensions'
import { AVATAR_MIN_DIMENSIONS, DEFAULT_IMAGE_WIDTH } from '../../util/meta'

export const UploadContainer = styled.div`
  width: 100%;
  height: 144px;
  border-radius: 2px;
  border: dashed 0.5px ${props => props.theme.colors.grays.athensGray};
  background-color: ${props => props.theme.colors.secondary.magnolia};
  margin-bottom: ${props => props.theme.space[3]}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:focus {
    outline: none;
  }
`

const UploadIcon = styled.i`
  color: ${props => props.theme.colors.primary.mediumSlateBlue};
  font-size: ${props => props.theme.fontSizes[6]}px;
  margin-bottom: ${props => props.theme.space[1]}px;
`

const UploadText = styled.div`
  font-size: ${props => props.theme.fontSizes[1]}px;
  color: ${props => props.theme.colors.grays.midGray};
`

const HighlightedText = styled.span`
  text-decoration: underline;
  color: ${props => props.theme.colors.primary.mediumSlateBlue};
  cursor: pointer;
`

const ImageWrapper = styled.div`
  text-align: right;
`
ImageWrapper.displayName = 'ImageWrapper'

const ImageContainer = styled.div`
  height: 144px;
`

const StyledImage = styled.img`
  width: 100%;
  max-height: 100%;
`
StyledImage.displayName = 'StyledImage'

const WrapperContainer = styled.div`
  margin-bottom: ${props => props.theme.space[2]}px;
`

const ReplaceImageButton = styled.span`
  color: ${props => props.theme.colors.primary.lightSlateBlue};
  font-size: ${props => props.theme.fontSizes[1]}px;
  cursor: pointer;
`
ReplaceImageButton.displayName = 'ReplaceImageButton'

const StyledDropzoneS3Uploader = styled(DropzoneS3Uploader)`
  width: 100% !important;
  border: none !important;
  border-radius: unset !important;
  height: auto !important;
  cursor: unset !important;
  overflow: initial !important;
`

export const StyledImagePreview = styled.img`
  width: 34px;
  height: 34px;
  position: absolute;
  border-radius: 17px;
  object-fit: cover;
  object-postion: center;
  opacity: ${props => props.src ? 1 : 0};
  transition: opacity 0.3s ease-in-out;
`

const signedUrlQuery = graphql`
  query ThumbnailQuery($extension: String!, $mimeType: String!) {
    organization {
      mediaUploadUrl(extension: $extension, mimeType: $mimeType, final: true)
    }
  }
`

const getSignedUrl = async(file: File, callback: <T extends object>(signedUrl: T) => string) => {
  const vars = {
    extension: file.name.split('.').pop(),
    mimeType: file.type,
  }
  const data = await fetchQuery(environment, signedUrlQuery, vars).toPromise() as Response
  if (data.organization.mediaUploadUrl !== null) {
    callback({ signedUrl: data.organization.mediaUploadUrl })
  }
}

const dropzoneS3Options = {
  accept: 'image/jpeg, image/jpg, image/png',
  multiple: false,
  s3Url: ''
}

type ProgressProps = {
  uploading: boolean,
  pct: number,
  preview: string | null
}

const UploadProgress: React.FC<ProgressProps> = ({ uploading, pct, preview }) => {
  return (
    <UploadContainer>
      {uploading ? (
        <>
          <CircularProgress size='xlarge' progress={pct}/>
          <StyledImagePreview alt='upload preview' src={preview || undefined}/>
        </>
      ) : (
        <>
          <UploadIcon className='material-icons'>cloud_upload</UploadIcon>
          <UploadText>Drag and drop or <HighlightedText>browse</HighlightedText> files to upload</UploadText>
        </>
      )}
    </UploadContainer>
  )
}

type UploadProps = {
  setIsUploadingOnThumbnail: () => void
  setError: (error?: string) => void
  getSignedUrl: (file: File, callback: <T extends object>(signedUrl: T) => string) => void
  onFinish: (e: { signedUrl: string, imageUrl: null}) => void
  minDimensions?: Dimensions
}

export const Upload: React.FC<UploadProps> = ({setIsUploadingOnThumbnail, setError, getSignedUrl, onFinish, minDimensions = AVATAR_MIN_DIMENSIONS}) => {
  const [ uploading, setUploading ] = useState<boolean>(false)
  const [ uploadPct, setUploadPct ] = useState<number>(0.0)
  const [ preview, setPreview ] = useState<string | null>(null)

  return (
    <StyledDropzoneS3Uploader
      upload={{
        getSignedUrl,
        uploadRequestHeaders: {},
        preprocess: async (file: File, next: (file: File) => void) => {
          if (await ImageUpload.validateImage(file, setError, minDimensions)) {
            const reader = new FileReader()
            reader.onload = () => setPreview(reader.result as string)
            reader.readAsDataURL(file)

            setUploadPct(0.0)
            setUploading(true)
            setIsUploadingOnThumbnail()
            setError()
            next(file)
          }
        }
      }}
      onProgress={(pct: number) => setUploadPct(pct / 100.0)}
      onFinish={onFinish}
      onError={() => {
        setError('There was an error uploading the image')
        setIsUploadingOnThumbnail()
      }}
      {...dropzoneS3Options}
    >
      <UploadProgress uploading={uploading} pct={uploadPct} preview={preview}/>
    </StyledDropzoneS3Uploader>
  )
}

interface ImageProps {
  src: string
  reset: () => void
}

export const ImageComponent: React.FC<ImageProps> = (props) => {
  const {src, reset} = props
  const { disabled } = useContext(Context) as ContextType

  return (
    <ImageWrapper>
      <ImageContainer>
        <StyledImage src={src} alt=''/>
      </ImageContainer>
      { !disabled && <ReplaceImageButton onClick={reset}>replace image</ReplaceImageButton> }
    </ImageWrapper>
  )
}

type Props = SectionProps & {
  article?: ArticleFragment | null
  page?: PageFragment | null
}

const Thumbnail = createFragmentContainer(
  (props: Props) => {
    const { name, article, page } = props
    const obj = article || page
    const { errors, onChange } = useContext(Context) as ContextType
    const [ value, setValue ] = useState<string>((obj && obj.thumbnails && obj.thumbnails.small) || '')
    const [ isUploading, setIsUploading ] = useState<boolean>(false)
    const [ localErrors, setLocalErrors ] = useState(([] as string[]))

    const reset = () => {
      setValue('')
    }

    const errorHandler = (err?: string) => {
      setLocalErrors((err) ? [err] : [])
    }

    const onFinish = (e: { signedUrl: string }) => {
      onChange('thumbnailUrl', e.signedUrl.replace(/\?.*$/, ''))
      setIsUploading(false)
    }

    useEffect(() => {
      if (obj && obj.thumbnails !== null && obj.thumbnails.small !== null && !isUploading) {
        setValue(obj.thumbnails.small)
      }
    }, [obj, isUploading])

    return (
      <Section {...props} preview='' errors={localErrors.concat(errors.for(name))}>
        <WrapperContainer>
          {value
            ? (<ImageComponent src={value} reset={reset}/>)
            : (<Upload 
                  minDimensions={DEFAULT_IMAGE_WIDTH}
                  getSignedUrl={getSignedUrl} 
                  onFinish={onFinish} 
                  setError={errorHandler} 
                  setIsUploadingOnThumbnail={() => setIsUploading(true)}/>)}
        </WrapperContainer>
        <TextInput name='thumbnailAttribution' displayName='Image Caption' placeholder='Add a source' required={false}/>
      </Section>
    )
  },
  {
    article: graphql`
      fragment Thumbnail_article on Article {
        thumbnailUrl
        thumbnails {
          small
        }
      }
    `,
    page: graphql`
      fragment Thumbnail_page on Page {
        thumbnailUrl
        thumbnails {
          small
        }
      }
    `
  }
)

export default Thumbnail
