import { Dimensions } from './types/Dimensions'

// Default parameters
export const DEFAULT_IMAGE_WIDTH: Dimensions = { width: 1920 }

// Set minimum dimension requirements for avatars
export const AVATAR_MIN_DIMENSIONS: Dimensions = { square: 250 }

// Set minimum dimension requirements for image uploads
export const HERO_IMAGE_MIN_DIMENSIONS: Dimensions = DEFAULT_IMAGE_WIDTH

// Set minimum dimension requirements for video uploads
export const HERO_VIDEO_MIN_DIMENSIONS: Dimensions = { width: 720 }

const META = {
  AVATAR_MIN_DIMENSIONS,
  HERO_IMAGE_MIN_DIMENSIONS,
  HERO_VIDEO_MIN_DIMENSIONS
}

export default META

export const DEBOUNCE_PERIOD = 500
