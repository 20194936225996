/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Roles = "ADMIN" | "CMS" | "EDITOR" | "WRITER" | "%future added value";
export type Layout_device = {
    readonly user: {
        readonly role: Roles | null;
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"Account_user">;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"Navigation_device">;
    readonly " $refType": "Layout_device";
};
export type Layout_device$data = Layout_device;
export type Layout_device$key = {
    readonly " $data"?: Layout_device$data;
    readonly " $fragmentRefs": FragmentRefs<"Layout_device">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Layout_device",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Account_user"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Navigation_device"
    }
  ],
  "type": "DeviceToken",
  "abstractKey": null
};
(node as any).hash = '669b3dda16431e59b6a237b888506db0';
export default node;
