import { API, ToolConfig } from '@editorjs/editorjs'
import _ from 'lodash'
import { server, headers } from '../../../relay/network'
import { VideoFileEdge as VideoFile, Url } from '../../../graphql-types'
import { formatDate, secondsToMinutes } from '../HeroPicker/ListRenderer'
import './video-embed-style.css'
import icon from '../../../assets/png/video-player-icon.png'

export type VideoEmbedData = {
  caption?: string
  isLive?: boolean
  unsignedUrl?: string
  videoFileId?: string
}

interface Constructor {
  data?: VideoEmbedData
  api: API
  config?: ToolConfig
}

const buttonIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-upload" viewBox="0 0 16 16">  <path d="M7.27 1.047a1 1 0 0 1 1.46 0l6.345 6.77c.6.638.146 1.683-.73 1.683H1.656C.78 9.5.326 8.455.926 7.816L7.27 1.047zM.5 11.5a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-13a1 1 0 0 1-1-1v-1z"/></svg>`

const videoListQuery = `
query GetListOfVideos($query: String!, $first: Int){
  organization{
    videoFiles(query: $query, first: $first) {
      edges {
        node {
          id
          createdAt
          duration
          name
          videoUrls {
            mime
            unsignedUrl
            url
          }
          isLive
          thumbnails(aspect_ratio: HD) {
            small
          }
        }
      }
    }
  }
}
`

const videoFileQuery = `
query GetVideoFileInfo($id: ID!){
  node(id: $id) {
    ...on VideoFile {
      isLive
      videoUrls {
        mime
        unsignedUrl
        url
      }
      thumbnails(aspect_ratio: HD) {
        small
      }
    }
  }
}
`

export default class VideoEmbed {
  private videoEmbedData: VideoEmbedData
  private container: HTMLElement

  constructor(params: Constructor) {
    this.videoEmbedData = params.data!
    this.container = document.createElement('div')
    this.container.classList.add('cdx-block')
  }

  /**
   * EditorJS native method, renders a 'video' icon in the toolbox along with other plugins
   */
  static get toolbox() {
    return {
      icon: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"><image width="16" height="16" href='${icon}'/></svg>`,
      title: 'Video'
    }
  }

  /**
   * Handles the event when user clicks outside of search result list - the list should be removed from the DOM
   */
  private handleOutsideClick(e: MouseEvent) {
    const container = document.getElementById('video-list')
    if (container && !container.contains(e.target as Node)) {
      document.getElementById('video-list')!.remove()
      const input = document.getElementById('search-input') as HTMLInputElement
      input.value = ''
    }
  }

  /**
   * Fetches VideoFiles based on search term
   */
  private getVideos(value: string) {
    return fetch(`${server}/graphql`, {
      method: 'POST',
      body: JSON.stringify({
        query: videoListQuery,
        variables: {
          query: value,
          first: 15
        }
      }),
      headers: headers()
    })
      .then((response) => response.json())
      .then((response) => {
        return response.data.organization.videoFiles.edges
      })
  }

  /**
   * Fetches a single VideoFile based on id
   */
  private getVideoFile(id: string) {
    return fetch(`${server}/graphql`, {
      method: 'POST',
      body: JSON.stringify({
        query: videoFileQuery,
        variables: {
          id: id
        }
      }),
      headers: headers()
    }).then((response) => response.json())
  }

  private clearSearchResults() {
    this.container.innerHTML = ''
  }

  private debounced = _.debounce(async (value) => {
    if (!value.length && this.container.children[2]) {
      this.container.removeChild(this.container.children[2])
    } else {
      const videoFiles = await this.getVideos(value)
      this.renderSearchResults(videoFiles)
    }
    value.length && (await this.getVideos(value))
  }, 500)

  private generateSearchInput(): HTMLInputElement {
    const input = document.createElement('input')
    input.focus()
    input.id = 'search-input'
    input.classList.add('input-field')
    input.placeholder = 'Search for content'
    input.classList.add('search-input')
    input.addEventListener('input', (e) => this.debounced((e.currentTarget as HTMLInputElement).value))
    return input
  }

  private generateSearchLabel(): HTMLHeadingElement {
    const label = document.createElement('h3')
    label.innerHTML = 'Video Embed'
    label.classList.add('label')
    return label
  }

  /**
   * Creates captions input under the video player
   * Populates it with preexisting captions if they exist
   */
  private generateCaptions(): HTMLDivElement {
    const caption = document.createElement('div')
    caption.contentEditable = 'true'
    caption.classList.add('cdx-input')
    caption.setAttribute('placeholder', 'Enter a caption')
    caption.innerHTML = this?.videoEmbedData?.caption || ''
    return caption
  }

  /**
   * Creates a video player node with its attributes
   */
  private generateVideoPlayer(videoSrc: string, posterSrc: string): HTMLVideoElement {
    const videoPlayer = document.createElement('video')
    videoPlayer.classList.add('video-player')
    videoPlayer.setAttribute('controls', 'true')
    videoPlayer.setAttribute('poster', posterSrc)
    videoPlayer.setAttribute('preload', 'none')
    videoPlayer.setAttribute('src', videoSrc)
    videoPlayer.setAttribute('type', 'video/mp4')
    return videoPlayer
  }

  /**
   * Renders search input with label 'Video Embed' above it
   */
  private renderSearchInput() {
    this.container.appendChild(this.generateSearchLabel())
    this.container.appendChild(this.generateSearchInput())
  }

  /**
   * Renders a video player with picked video in it and captions in the bottom
   */
  private async renderVideoPlayer(id: string) {
    const result = (await this.getVideoFile(id)) as { data: VideoFile }
    const wrapper = document.createElement('div')
    wrapper.classList.add('wrapper')

    if (result.data?.node?.isLive) {
      const src = this.getThumbnailUrl(result.data)
      const image = this.generateThumbnailElement(src)
      image.classList.remove('image')
      wrapper.appendChild(image)
    } else {
      const imageSrc = this.getThumbnailUrl(result.data)
      const videoSrc = this.getSrc(result.data, 'video/mp4')
      const video = this.generateVideoPlayer(videoSrc, imageSrc)
      wrapper.appendChild(video)
    }
    this.container.appendChild(wrapper)
    this.container.appendChild(this.generateCaptions())
  }

  /**
   * Parses the URL from the response according to mime
   */
  private getSrc(item: VideoFile, mime: string, unsigned = false): string {
    const urlGroup = item?.node?.videoUrls.find((videoUrl: Url) => videoUrl?.mime?.startsWith(mime))
    if (unsigned) {
      return urlGroup?.unsignedUrl || ''
    } else {
      return urlGroup?.url || ''
    }
  }

  /**
   * Creates a list of search results
   */
  private generateVideoList(data: VideoFile[]): HTMLDivElement {
    const videoList = document.createElement('div')
    const table = document.createElement('table')
    videoList.appendChild(table)
    videoList.id = 'video-list'
    videoList.classList.add('search-results-container')
    table.appendChild(this.generateTableHeader())
    table.appendChild(this.generateTableBody(data))
    return videoList
  }

  /**
   * Shows 'No search results' message
   */
  private generateNoResultsMessage(): HTMLDivElement {
    const message = document.createElement('div')
    message.classList.add('no-results')
    message.innerHTML = 'No search results'
    return message
  }

  private getDuration(item: VideoFile) {
    if (item.node?.duration) {
      return secondsToMinutes(item.node.duration)
    } else {
      return 'UNKNOWN'
    }
  }

  private getDate(item: VideoFile) {
    if (item.node?.createdAt) {
      return formatDate(item.node.createdAt)
    } else {
      return 'UNKNOWN'
    }
  }

  private getName(item: VideoFile) {
    if (item.node?.name) {
      return item.node.name
    } else {
      return 'UNKNOWN'
    }
  }

  private getThumbnailUrl(item: VideoFile): string {
    if (item.node?.thumbnails?.small) {
      return item.node.thumbnails.small
    } else {
      return this.getSrc(item, 'image/jpeg')
    }
  }

  /**
   * Generates table body with rows for each video that is returned in the search result
   */
  private generateTableBody(videoFiles: VideoFile[]): HTMLTableSectionElement {
    document.body.addEventListener('mouseup', this.handleOutsideClick)
    const tableBody = document.createElement('tbody')
    if (videoFiles.length) {
      videoFiles.forEach((item: VideoFile) => this.generateTableRow(item, tableBody))
    } else {
      tableBody.appendChild(this.generateNoResultsMessage())
    }
    return tableBody
  }

  private generateTableHeader(): HTMLTableSectionElement {
    const header = document.createElement('thead')
    const headCells = ['', 'NAME', 'DURATION', 'DATE']
    headCells.forEach((el: string) => {
      let cell = document.createElement('th')
      cell.innerHTML = el
      header.appendChild(cell)
    })
    return header
  }

  private generateThumbnailElement(src: string): HTMLImageElement {
    const image = document.createElement('img')
    image.setAttribute('onerror', 'this.style.display="none"')
    image.setAttribute('src', src)
    image.classList.add('image')
    return image
  }

  /**
   * Generates table row HTML for each video
   * Displays thumbnail, name, duration and date
   */
  private generateTableRow(item: VideoFile, tableBody: HTMLElement) {
    const row = document.createElement('tr')
    row.addEventListener('click', () => this.listItemClickHandler(item))

    const imageCell = document.createElement('td')
    const imageSrc = this.getThumbnailUrl(item)
    imageCell.appendChild(this.generateThumbnailElement(imageSrc))
    row.appendChild(imageCell)

    const textCells = [this.getName(item), this.getDuration(item), this.getDate(item)]
    textCells.forEach((el: string) => {
      const cell = document.createElement('td')
      cell.innerText = el
      row.appendChild(cell)
    })

    tableBody.appendChild(row)
  }

  /**
   * Handles a click event on each search result item
   * Responsible for rendering the video player after a search result is clicked
   */
  private listItemClickHandler(item: VideoFile) {
    this.clearSearchResults()
    this.renderVideoPlayer(item?.node?.id!)
    this.videoEmbedData.isLive = item.node?.isLive!
    this.videoEmbedData.videoFileId = item.node?.id!
    this.videoEmbedData.unsignedUrl = this.getSrc(item, item.node?.isLive ? 'application/x-mpegURL' : 'video/mp4', true)
  }

  /**
   * Creates a document fragment and HTML to display search results
   */
  private renderSearchResults(data: VideoFile[]) {
    const resultsFragment = document.createDocumentFragment()
    resultsFragment.appendChild(this.generateVideoList(data))
    if (this.container.children[2]) {
      this.container.removeChild(this.container.children[2])
    }
    this.container.appendChild(resultsFragment)
  }

  /**
   * EditorJS's native method, returns a button in the settings menu (right upper corner of block element)
   */
  private renderSettings() {
    const wrapper = document.createElement('div')
    const button = document.createElement('div')
    button.classList.add('cdx-settings-button')
    // property 'id' is added only for test purposes
    button.id = 'button'
    button.innerHTML = buttonIcon
    wrapper.appendChild(button)
    button.addEventListener('click', (e) => {
      e.preventDefault()
      this.clearSearchResults()
      this.renderSearchInput()
    })
    return wrapper
  }

  render(): HTMLElement {
    if (this.videoEmbedData.videoFileId) {
      //existing event listeners need to be removed when search input is not displayed
      document.body.removeEventListener('mouseup', this.handleOutsideClick)
      this.clearSearchResults()
      this.renderVideoPlayer(this.videoEmbedData.videoFileId)
    } else {
      this.renderSearchInput()
    }
    return this.container
  }

  save(content: any): VideoEmbedData {
    const caption = content.querySelector('[contenteditable]')?.textContent || ''
    return { ...this.videoEmbedData, caption: caption.trim() }
  }
}
