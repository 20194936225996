/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type MetatagType = "AVATAR" | "KEYWORDS" | "MULTI_SELECT" | "SINGLE_SELECT" | "TEXT" | "THUMBNAIL" | "%future added value";
export type TopStoryListInput = {
    max?: number | null;
    min?: number | null;
    name?: string | null;
    stories?: Array<TopStoryListStoryInput> | null;
    tags?: Array<TagInput> | null;
};
export type TopStoryListStoryInput = {
    story: string;
    order: number;
};
export type TagInput = {
    source: unknown;
    value: Array<string>;
};
export type TopStoryListEditMutationVariables = {
    id: string;
    input: TopStoryListInput;
};
export type TopStoryListEditMutationResponse = {
    readonly topStoryListUpdate: {
        readonly topStoryList: {
            readonly id: string;
            readonly name: string;
            readonly isDeleted: boolean;
            readonly tags: ReadonlyArray<{
                readonly id: string;
                readonly name: string;
                readonly fixedOptions: ReadonlyArray<string> | null;
                readonly value: ReadonlyArray<string> | null;
                readonly type: MetatagType;
            }>;
        } | null;
        readonly errors: ReadonlyArray<{
            readonly field: string;
            readonly messages: ReadonlyArray<string>;
        }>;
    } | null;
};
export type TopStoryListEditMutation = {
    readonly response: TopStoryListEditMutationResponse;
    readonly variables: TopStoryListEditMutationVariables;
};



/*
mutation TopStoryListEditMutation(
  $id: ID!
  $input: TopStoryListInput!
) {
  topStoryListUpdate(id: $id, input: $input) {
    topStoryList {
      id
      name
      isDeleted
      tags {
        id
        name
        fixedOptions
        value
        type
      }
    }
    errors {
      field
      messages
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TopStoryListUpdateMutationPayload",
    "kind": "LinkedField",
    "name": "topStoryListUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TopStoryList",
        "kind": "LinkedField",
        "name": "topStoryList",
        "plural": false,
        "selections": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "isDeleted",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Tag",
            "kind": "LinkedField",
            "name": "tags",
            "plural": true,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fixedOptions",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "value",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "messages",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TopStoryListEditMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TopStoryListEditMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "29a95cd52ef76919e5d2951acd65b4ac",
    "id": null,
    "metadata": {},
    "name": "TopStoryListEditMutation",
    "operationKind": "mutation",
    "text": "mutation TopStoryListEditMutation(\n  $id: ID!\n  $input: TopStoryListInput!\n) {\n  topStoryListUpdate(id: $id, input: $input) {\n    topStoryList {\n      id\n      name\n      isDeleted\n      tags {\n        id\n        name\n        fixedOptions\n        value\n        type\n      }\n    }\n    errors {\n      field\n      messages\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'f8c88bf961e0d68665873d3ee7d5b78d';
export default node;
