/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TopStoryEditQueryVariables = {
    id: string;
};
export type TopStoryEditQueryResponse = {
    readonly node: {
        readonly " $fragmentRefs": FragmentRefs<"TopStoryEdit_node">;
    } | null;
    readonly user: {
        readonly " $fragmentRefs": FragmentRefs<"TopStoryEdit_user">;
    } | null;
};
export type TopStoryEditQuery = {
    readonly response: TopStoryEditQueryResponse;
    readonly variables: TopStoryEditQueryVariables;
};



/*
query TopStoryEditQuery(
  $id: ID!
) {
  node(id: $id) {
    __typename
    ...TopStoryEdit_node
    id
  }
  user {
    ...TopStoryEdit_user
    id
  }
}

fragment TopStoryEdit_node on TopStoryList {
  __typename
  stories {
    order
    story
  }
  orderedStories {
    __typename
    id
    public_at
    title
    thumbnails(aspect_ratio: SQUARE) {
      small
    }
  }
  id
  name
  max
  min
}

fragment TopStoryEdit_user on User {
  role
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TopStoryEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TopStoryEdit_node"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TopStoryEdit_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TopStoryEditQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TopStoryListStory",
                "kind": "LinkedField",
                "name": "stories",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "order",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "story",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "orderedStories",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "public_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "aspect_ratio",
                        "value": "SQUARE"
                      }
                    ],
                    "concreteType": "Thumbnail",
                    "kind": "LinkedField",
                    "name": "thumbnails",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "small",
                        "storageKey": null
                      }
                    ],
                    "storageKey": "thumbnails(aspect_ratio:\"SQUARE\")"
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "max",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "min",
                "storageKey": null
              }
            ],
            "type": "TopStoryList",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "role",
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3f0212e274af3b3c11f511ed679882ee",
    "id": null,
    "metadata": {},
    "name": "TopStoryEditQuery",
    "operationKind": "query",
    "text": "query TopStoryEditQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...TopStoryEdit_node\n    id\n  }\n  user {\n    ...TopStoryEdit_user\n    id\n  }\n}\n\nfragment TopStoryEdit_node on TopStoryList {\n  __typename\n  stories {\n    order\n    story\n  }\n  orderedStories {\n    __typename\n    id\n    public_at\n    title\n    thumbnails(aspect_ratio: SQUARE) {\n      small\n    }\n  }\n  id\n  name\n  max\n  min\n}\n\nfragment TopStoryEdit_user on User {\n  role\n}\n"
  }
};
})();
(node as any).hash = '3d18a2110f6b63b4a3ffaf05f097fa39';
export default node;
