import React from 'react'
import styled from 'styled-components'

const InputContainer = styled.div`
  position: relative;
  width: 167px;
`

const Icon = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
`

const StyledInput = styled.input`
  outline: none;
  font-size: 12px;
  color: #67686a;
  padding: 9px 35px 9px 8px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(238, 238, 238, 0.32);
  border: solid 1px rgba(151, 151, 151, 0.1);
`

const CustomInput: React.FC<{ value: string }> = ({ value }) => (
  <InputContainer>
    <StyledInput value={value} disabled={true} />
    <Icon role="img" aria-label="calendar" aria-hidden="true">
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14">
        <path fill="#6F50F4" fillRule="nonzero" d="M11.345 1.26h-.63V0h-1.26v1.26H3.152V0H1.89v1.26h-.63C.567 1.26 0 1.829 0 2.522v10.085c0 .693.567 1.26 1.26 1.26h10.085c.694 0 1.261-.567 1.261-1.26V2.521c0-.693-.567-1.26-1.26-1.26zm0 11.346H1.261V4.412h10.084v8.194z"/>
      </svg>
    </Icon>
  </InputContainer>
)

export default CustomInput
