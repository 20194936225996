import React, { FC } from 'react'
import { FormikProps, FormikValues, useFormikContext } from 'formik'
import { useNavigatorOnline } from '../../hooks/useNavigatorOnline'
import { Box, Button, makeStyles } from '@material-ui/core'
import { Theme } from '../../theme'

interface ButtonsProps {
  onClose: () => void
  buttonText: string
}

const useStyles = makeStyles((theme: Theme) => ({
  confirmButton: {
    marginLeft: theme.spacing(2)
  }
}))

const Buttons: FC<ButtonsProps> = ({ onClose, buttonText }) => {
  const { isSubmitting }: FormikProps<FormikValues> = useFormikContext()
  const { isOnline } = useNavigatorOnline()
  const classes = useStyles()

  return (
    <Box p={2} display='flex' alignItems='center'>
      <Box flexGrow={1} />
      {onClose && <Button onClick={onClose}>Cancel</Button>}
      <Button variant='contained' type='submit' disabled={isSubmitting || !isOnline} color='secondary' className={classes.confirmButton}>
        {buttonText}
      </Button>
    </Box>
  )
}

export default Buttons
