/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type AuthorInput = {
    active?: boolean | null;
    byline?: string | null;
    slug?: string | null;
    tags?: Array<TagInput> | null;
};
export type TagInput = {
    source: unknown;
    value: Array<string>;
};
export type AuthorCreateMutationVariables = {
    organizationId: string;
    input: AuthorInput;
};
export type AuthorCreateMutationResponse = {
    readonly authorCreate: {
        readonly author: {
            readonly active: boolean;
            readonly byline: string;
        } | null;
        readonly errors: ReadonlyArray<{
            readonly field: string;
            readonly messages: ReadonlyArray<string>;
        }>;
    } | null;
};
export type AuthorCreateMutation = {
    readonly response: AuthorCreateMutationResponse;
    readonly variables: AuthorCreateMutationVariables;
};



/*
mutation AuthorCreateMutation(
  $organizationId: ID!
  $input: AuthorInput!
) {
  authorCreate(organizationId: $organizationId, input: $input) {
    author {
      active
      byline
      id
    }
    errors {
      field
      messages
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  },
  {
    "kind": "Variable",
    "name": "organizationId",
    "variableName": "organizationId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "active",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "byline",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Error",
  "kind": "LinkedField",
  "name": "errors",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "field",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "messages",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuthorCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AuthorCreateMutationPayload",
        "kind": "LinkedField",
        "name": "authorCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Author",
            "kind": "LinkedField",
            "name": "author",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AuthorCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AuthorCreateMutationPayload",
        "kind": "LinkedField",
        "name": "authorCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Author",
            "kind": "LinkedField",
            "name": "author",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e623b45893f247ab2ea4528289082d0f",
    "id": null,
    "metadata": {},
    "name": "AuthorCreateMutation",
    "operationKind": "mutation",
    "text": "mutation AuthorCreateMutation(\n  $organizationId: ID!\n  $input: AuthorInput!\n) {\n  authorCreate(organizationId: $organizationId, input: $input) {\n    author {\n      active\n      byline\n      id\n    }\n    errors {\n      field\n      messages\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '3c0b09371e22e929568a4d3d46773213';
export default node;
