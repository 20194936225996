/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type BylineSelectQueryVariables = {
    query: string;
};
export type BylineSelectQueryResponse = {
    readonly organization: {
        readonly assignableAuthors: ReadonlyArray<{
            readonly id: string;
            readonly byline: string;
        }> | null;
    };
};
export type BylineSelectQuery = {
    readonly response: BylineSelectQueryResponse;
    readonly variables: BylineSelectQueryVariables;
};



/*
query BylineSelectQuery(
  $query: String!
) {
  organization {
    assignableAuthors(query: $query) {
      id
      byline
    }
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "query",
      "variableName": "query"
    }
  ],
  "concreteType": "Author",
  "kind": "LinkedField",
  "name": "assignableAuthors",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "byline",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BylineSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BylineSelectQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organization",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "694886858524aa3d2dcf794e283be542",
    "id": null,
    "metadata": {},
    "name": "BylineSelectQuery",
    "operationKind": "query",
    "text": "query BylineSelectQuery(\n  $query: String!\n) {\n  organization {\n    assignableAuthors(query: $query) {\n      id\n      byline\n    }\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = '6b42c5838e614a2411b8f8be631b4b55';
export default node;
