import React, { FC } from 'react'
import { Box, Card, IconButton, makeStyles, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete'
import clsx from 'clsx'
import PerfectScrollbar from 'react-perfect-scrollbar'

type ListProps = {
  header?: JSX.Element
}

type ActionButtonsProps = {
  onEdit: () => void
  onDelete: () => void
}

const useStyles = makeStyles(() => ({
  root: {
    margin: 64,
    marginBottom: 10,
    marginTop: 0
  },
  row: {
    height: 63,
    cursor: 'pointer'
  }
}))

export const ActionButtons: FC<ActionButtonsProps> = ({ onEdit, onDelete }) => {

  const clickHandler = (e: React.SyntheticEvent, callback: () => void) => {
    e.stopPropagation()
    callback()
  }

  return (
    <TableCell align='right'>
      <IconButton onClick={(e) => clickHandler(e, onEdit)} title='Edit'>
        <CreateIcon />
      </IconButton>
      <IconButton onClick={(e) => clickHandler(e, onDelete)} title='Delete'>
        <DeleteIcon />
      </IconButton>
    </TableCell>
  )
}

export const ClickableRow: FC<{ clickHandler: () => void }> = ({ children, clickHandler }) => {
  const classes = useStyles()

  return (
    <TableRow hover className={clsx(classes.row)} onClick={clickHandler}>
      {children}
    </TableRow>
  )
}

export const List: FC<ListProps> = ({ children, header }) => {
  const classes = useStyles()

  return (
    <Card className={clsx(classes.root)}>
      <PerfectScrollbar>
        <Box minWidth={700}>
          <Table>
            {header && (
              <TableHead>
                <TableRow>{header}</TableRow>
              </TableHead>
            )}
            <TableBody>{children}</TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  )
}
