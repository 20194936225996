import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useNavigatorOnline } from '../hooks/useNavigatorOnline'

const ErrorWrapper = styled.div`
  background-color: ${props => props.theme.colors.primary.redOrange};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  min-height: auto !important;
  transition: height 0.2s;
`

const ErrorContainer = styled.div<{isOpen: boolean}>`
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.isOpen ? '40px' : '0px' };
  transition: height 0.2s;
`

const ErrorMessage = styled.div`
  flex-grow: 1;
  text-align: center;
`

const CloseButton = styled.button`
  color: #ffffff;
  background: transparent;
  padding: 0;
  cursor: pointer;
  border: none;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  right: 20px;
`

const NetworkErrors: React.FC = () => {
  const [errorMessage, setErrorMessage] = useState('')
  const { isOnline } = useNavigatorOnline()

  useEffect(() => {
    (!isOnline) ? setErrorMessage('Network Connection Lost') : setErrorMessage('')
  }, [isOnline])

  return <ErrorBanner message={errorMessage} onDismiss={() => setErrorMessage('')} />
}

export const ErrorBanner: React.FC<{ message: string, onDismiss: () => void }> = ({ message, onDismiss }) => (
  <ErrorWrapper>
    <ErrorContainer isOpen={Boolean(message)}>
      {message &&
      (<React.Fragment>
        <ErrorMessage>{message}</ErrorMessage>
        <CloseButton onClick={onDismiss}>&#x2715;</CloseButton>
      </React.Fragment>)}
    </ErrorContainer>
  </ErrorWrapper>
)

export default NetworkErrors
