/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type MetatagScopeType = "ARTICLE" | "AUTHOR" | "PAGE" | "TEMPLATE" | "TOP_STORY_LIST" | "%future added value";
export type MetatagType = "AVATAR" | "KEYWORDS" | "MULTI_SELECT" | "SINGLE_SELECT" | "TEXT" | "THUMBNAIL" | "%future added value";
export type MetatagInput = {
    name?: string | null;
    fixedOptions?: Array<string> | null;
    maxValues?: number | null;
    minValues?: number | null;
    scopeType?: Array<MetatagScopeType> | null;
    type?: MetatagType | null;
    validator?: string | null;
};
export type MetatagUpdateMutationVariables = {
    id: string;
    input: MetatagInput;
};
export type MetatagUpdateMutationResponse = {
    readonly metatagUpdate: {
        readonly metatag: {
            readonly fixedOptions: ReadonlyArray<string> | null;
            readonly id: string;
            readonly maxValues: number | null;
            readonly minValues: number | null;
            readonly name: string;
            readonly scopeType: ReadonlyArray<MetatagScopeType> | null;
            readonly type: MetatagType;
            readonly validator: string | null;
        } | null;
        readonly errors: ReadonlyArray<{
            readonly messages: ReadonlyArray<string>;
            readonly field: string;
        }>;
    } | null;
};
export type MetatagUpdateMutation = {
    readonly response: MetatagUpdateMutationResponse;
    readonly variables: MetatagUpdateMutationVariables;
};



/*
mutation MetatagUpdateMutation(
  $id: ID!
  $input: MetatagInput!
) {
  metatagUpdate(id: $id, input: $input) {
    metatag {
      fixedOptions
      id
      maxValues
      minValues
      name
      scopeType
      type
      validator
    }
    errors {
      messages
      field
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "MetatagUpdateMutationPayload",
    "kind": "LinkedField",
    "name": "metatagUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Metatag",
        "kind": "LinkedField",
        "name": "metatag",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fixedOptions",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "maxValues",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "minValues",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "scopeType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "validator",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "messages",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MetatagUpdateMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MetatagUpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "391de890094babc0557f6a73c9fe60c7",
    "id": null,
    "metadata": {},
    "name": "MetatagUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation MetatagUpdateMutation(\n  $id: ID!\n  $input: MetatagInput!\n) {\n  metatagUpdate(id: $id, input: $input) {\n    metatag {\n      fixedOptions\n      id\n      maxValues\n      minValues\n      name\n      scopeType\n      type\n      validator\n    }\n    errors {\n      messages\n      field\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = '6df675799fb277c4e1cdd4e60495d7ff';
export default node;
