import React from 'react'
import styled from 'styled-components'
import { components, OptionProps, OptionTypeBase } from 'react-select'

const StyledMultiValueLabelContainer = styled.div`
  display: flex;
  padding-left: 16px;
`

const LetterIconContainer = styled.div`
  background: #c7c7c7;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LetterIcon = styled.span`
  font-size: ${props => props.theme.fontSizes[1]}px;
  color: #ffffff;
  text-transform: capitalize;
`

const MultiValueLabel = (props: OptionProps<OptionTypeBase, true>) => (
  <StyledMultiValueLabelContainer>
    <LetterIconContainer><LetterIcon>{props.data.label[0]}</LetterIcon></LetterIconContainer>
    <components.MultiValueLabel {...props}/>
  </StyledMultiValueLabelContainer>
)

export default MultiValueLabel
