/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type KeywordsInputContainerQueryVariables = {
    count: number;
    query?: string | null;
    exclude?: Array<string | null> | null;
};
export type KeywordsInputContainerQueryResponse = {
    readonly suggestedTags: ReadonlyArray<string | null> | null;
};
export type KeywordsInputContainerQuery = {
    readonly response: KeywordsInputContainerQueryResponse;
    readonly variables: KeywordsInputContainerQueryVariables;
};



/*
query KeywordsInputContainerQuery(
  $count: Int!
  $query: String
  $exclude: [String]
) {
  suggestedTags(count: $count, query: $query, exclude: $exclude)
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "exclude"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "query"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "count",
        "variableName": "count"
      },
      {
        "kind": "Variable",
        "name": "exclude",
        "variableName": "exclude"
      },
      {
        "kind": "Variable",
        "name": "query",
        "variableName": "query"
      }
    ],
    "kind": "ScalarField",
    "name": "suggestedTags",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "KeywordsInputContainerQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "KeywordsInputContainerQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "d6e7970ebf2bdb26d2a443d2f72635b2",
    "id": null,
    "metadata": {},
    "name": "KeywordsInputContainerQuery",
    "operationKind": "query",
    "text": "query KeywordsInputContainerQuery(\n  $count: Int!\n  $query: String\n  $exclude: [String]\n) {\n  suggestedTags(count: $count, query: $query, exclude: $exclude)\n}\n"
  }
};
})();
(node as any).hash = 'ee1c57eaa26fc94f01f3e45024297858';
export default node;
