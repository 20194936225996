import _ from 'lodash'
import { createMuiTheme, responsiveFontSizes, colors } from '@material-ui/core'
import { Theme as MuiTheme } from '@material-ui/core/styles/createMuiTheme'
import { Shadows as MuiShadows } from '@material-ui/core/styles/shadows'
import { Palette as MuiPalette, TypeBackground as MuiTypeBackground, TypeText as MuiTypeText} from '@material-ui/core/styles/createPalette'
import { softShadows } from './theme/shadows'
import typography from './theme/typography'
import { DefaultTheme } from 'styled-components'

interface TypeBackground extends MuiTypeBackground {
  dark: string
  white: string
  hover: string
  primary: string
}

interface TypeText extends MuiTypeText {
  light: string
  lightPrimary: string
  lightSecondary: string
}

interface Palette extends MuiPalette {
  background: TypeBackground
  text: TypeText
}

export interface Theme extends MuiTheme {
  name: string
  palette: Palette
  colors: any
  borders: any
  space: any
  fontSizes: any
  fontWeights: any
  shadows: any
  radii: any
}

interface ThemeOptions {
  name?: string
  typography?: Record<string, any>
  overrides?: Record<string, any>
  palette?: Record<string, any>
  shadows?: MuiShadows
}

const baseOptions: ThemeOptions = {
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    },
    MuiTableCell: {
      root: {
        padding: 7,
        width: 70,
        "&:first-child": {
          paddingLeft: 16
        }
      }
    }
  }
}

const themesOptions: ThemeOptions = {
  overrides: {
    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: colors.blueGrey[600]
        }
      }
    }
  },
  palette: {
    type: 'light',
    action: {
      active: colors.blueGrey[600]
    },
    background: {
      default: colors.common.white,
      dark: '#282C34',
      paper: colors.common.white,
      primary: '#fafbfc',
      hover: 'rgba(154,147,147,0.12)'
    },
    primary: {
      main: colors.indigo[600]
    },
    secondary: {
      main: '#5850EC',
      light: '#8a85ff'
    },
    error: {
      contrastText: '#ffffff',
      main: '#d32f2f'
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
      light: '#e6e5e8',
      lightPrimary: '#606063',
      lightSecondary: '#adb0bb',
}
  },
  shadows: softShadows
}

export const styledComponentsTheme: DefaultTheme = {
  colors: {
    primary: {
      nero: '#151515',
      blackRussian: '#25272b',
      zircon: '#e7e8ea',
      whiteSmoke: '#f6f6f6',
      purpleHeart: '#5637db',
      mediumSlateBlue: '#6f50f4',
      lightSlateBlue: '#8565fa',
      lightSeaGreen: '#1bd7bb',
      riptide: '#95e1d3',
      seafoam: '#5fe6c8',
      selectiveYellow: '#ffca62',
      redOrange: '#f52727'
    },
    grays: {
      abbey: '#4E5155',
      solitude: '#f4f5f7',
      grayChateau: '#a8a9ab',
      zircon: '#e7e8ea',
      aluminium: '#8e8f91',
      athensGray: '#dbdcde',
      stormGray: '#757678',
      iron: '#cecfd1',
      midGray: '#68696b',
      ghost: '#c1c2c4',
      brightGray: '#5c5d5f',
      mischka: '#b4b5b7',
      tuna: '#4f5052',
      darkGrayishBlue: '#45474B',
      nightRider: '#2f2f2f',
      aliceBlue: '#fafbfc',
      whiteSmoke: '#f4f4f4'
    },
    secondary: {
      irisBlue: '#0dbed8',
      darkSeaGreen: '#16c9cb',
      hollywoodCerise: '#e7079d',
      pinkLace: '#f7cee0',
      lightCyan: '#abf2e2',
      magnolia: '#f4f1fe'
    }
  },
  borders: {
    mediumSlateBlueBorder: '1px solid #6f50f4',
  },
  space: [0, 4, 8, 16, 24, 32, 40, 48, 56, 64, 128, 160, 256, 512],
  fontSizes: [10, 12, 14, 16, 20, 24, 28, 32, 40, 48],
  fontWeights: [100, 300, 400, 500, 700],
  shadows: ['0 2px 4px 0 #FBFBFB', '0 2px 4px 0 #FAFAFA', '0 2px 2px 0 rgba(180,181,183,0.16)'],
  radii: [2]
}

export const theme = responsiveFontSizes(createMuiTheme(
  _.merge(
    {},
    baseOptions,
    themesOptions
  )
)) as Theme
