import React, { FC } from 'react'
import moment from 'moment'
import { TableCell } from '@material-ui/core'
import { Roles } from '../__generated__/Layout_device.graphql'
import { ActionButtons, ClickableRow } from '../Settings/DataList'
import { useHistory } from 'react-router-dom'

export type TopStoryProps = {
  name?: string
  editedBy: string
  updatedAt: string
  role: Roles
  id?: string
  handleDelete: ({ id, name }: { id: string; name: string }) => void
}

const TopStory: FC<TopStoryProps> = ({ name, editedBy, updatedAt, role, id, handleDelete }) => {
  /* library hooks */
  const history = useHistory()

  /* callbacks */
  const onDelete = () => {
    handleDelete({ id: id!, name: name! })
  }

  return (
    <ClickableRow clickHandler={() => history.push(`/topstories/${id}`)}>
      <TableCell style={{ fontWeight: 500 }}>{name}</TableCell>
      <TableCell>{editedBy}</TableCell>
      <TableCell>{moment(updatedAt).format('DD/MM, LT')}</TableCell>
      {role === 'ADMIN' ? <ActionButtons onEdit={() => history.push(`/topstories/${id}`)} onDelete={onDelete} /> : <TableCell />}
    </ClickableRow>
  )
}

export default TopStory
