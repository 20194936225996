/* tslint:disable */
/* eslint-disable */

import { ConcreteRequest } from "relay-runtime";
export type TopStoriesDeleteMutationVariables = {
    id: string;
};
export type TopStoriesDeleteMutationResponse = {
    readonly topStoryListDelete: {
        readonly topStoryList: {
            readonly id: string;
            readonly name: string;
        };
        readonly errors: ReadonlyArray<{
            readonly field: string;
            readonly messages: ReadonlyArray<string>;
        }>;
    } | null;
};
export type TopStoriesDeleteMutation = {
    readonly response: TopStoriesDeleteMutationResponse;
    readonly variables: TopStoriesDeleteMutationVariables;
};



/*
mutation TopStoriesDeleteMutation(
  $id: ID!
) {
  topStoryListDelete(id: $id) {
    topStoryList {
      id
      name
    }
    errors {
      field
      messages
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      }
    ],
    "concreteType": "TopStoryListDeleteMutationPayload",
    "kind": "LinkedField",
    "name": "topStoryListDelete",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "TopStoryList",
        "kind": "LinkedField",
        "name": "topStoryList",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Error",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "field",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "messages",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TopStoriesDeleteMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TopStoriesDeleteMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8d7a7376cb29cf042d37c94a980b69b6",
    "id": null,
    "metadata": {},
    "name": "TopStoriesDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation TopStoriesDeleteMutation(\n  $id: ID!\n) {\n  topStoryListDelete(id: $id) {\n    topStoryList {\n      id\n      name\n    }\n    errors {\n      field\n      messages\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c793521c058a22fd30054d00d78712c8';
export default node;
