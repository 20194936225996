import React, { useState } from 'react'
import styled from 'styled-components'

const StyledInput = styled.input<{ error?: string }>`
  font-size: ${props => props.theme.fontSizes[3]}px;
  font-weight: normal;
  line-height: ${props => props.theme.space[4]}px;
  color: ${props => props.theme.colors.grays.abbey};
  padding: 8px 32px 8px 8px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.error ? '#ec39b1' : '#c4c4c4'};
  border-radius: 2px;
  width: 100%;
  outline: none;

  ::placeholder {
    color: ${props => props.theme.colors.grays.aluminium};
  }
`

const Label = styled.label`
  padding-bottom: ${props => props.theme.space[5]}px;
  display: block;
  text-transform: capitalize;
`

const Container = styled.div`
  display: flex;
  max-width: 400px;
  position: relative;
`

export const Name = styled.span`
  font-weight: ${props => props.theme.fontWeights[1]};
  font-size: ${props => props.theme.fontSizes[3]}px;
  color: ${props => props.theme.colors.primary.blackRussian};
  padding-bottom: ${props => props.theme.space[2]}px;
  display: block;
`

const ErrorIcon = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 12px;

  i {
    color: #EC39B1;
    font-size: 16px;
  }
`

export const ErrorMessage = styled.p`
  font-weight: normal;
  font-size: 10px;
  line-height: 20px;
  color: #EC39B1;
  margin: 0;
  padding: 0;

  &:first-letter {
    text-transform: uppercase;
  }
`

type Props = {
  error?: string
  label?: string
  name: string
  onChange: (e: { name: string, value: string | number }) => void
  placeholder?: string
  type?: string
  val: string | number
}

const Input: React.FC<Props> = ({ error, label, name, onChange, placeholder, val, type = 'text' }) => {
  const [inputValue, setInputValue] = useState(val)

  const handleChange = ({ target : { name, value }}: React.ChangeEvent<HTMLInputElement>) => {
    onChange({ name, value: type === 'text' ? value : parseInt(value) })
    setInputValue(value)
  }

  return (
    <Label>
      {label && <Name>{label}</Name>}
      <Container>
        <StyledInput
          error={error}
          name={name}
          onChange={handleChange}
          placeholder={placeholder || `Enter ${label || name}`}
          type={type}
          value={inputValue}
        />
        {error && <ErrorIcon><i className='material-icons'>error</i></ErrorIcon>}
      </Container>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Label>
  )
}

export default Input
