/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ArticleDetail_draft = {
    readonly id: string;
    readonly scheduledFor: string | null;
    readonly canUpdate: boolean;
    readonly draftable: {
        readonly selectableTemplates: ReadonlyArray<{
            readonly versions: ReadonlyArray<{
                readonly id: string;
                readonly version: number;
            }>;
        }>;
        readonly id?: string;
        readonly body?: string | null;
        readonly description?: string | null;
        readonly headline?: string | null;
        readonly slug?: string;
        readonly thumbnailAttribution?: string | null;
        readonly " $fragmentRefs": FragmentRefs<"Thumbnail_article" | "Preview_article" | "HeroPicker_article" | "BylineSelect_draftable" | "PublicationDate_draftable" | "TagFieldRenderer_taggable">;
    };
    readonly errors: ReadonlyArray<{
        readonly field: string;
        readonly messages: ReadonlyArray<string>;
    }>;
    readonly warnings: ReadonlyArray<{
        readonly field: string;
        readonly messages: ReadonlyArray<string>;
    }>;
    readonly " $fragmentRefs": FragmentRefs<"Controls_draft">;
    readonly " $refType": "ArticleDetail_draft";
};
export type ArticleDetail_draft$data = ArticleDetail_draft;
export type ArticleDetail_draft$key = {
    readonly " $data"?: ArticleDetail_draft$data;
    readonly " $fragmentRefs": FragmentRefs<"ArticleDetail_draft">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "field",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "messages",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArticleDetail_draft",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "scheduledFor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canUpdate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "draftable",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Template",
          "kind": "LinkedField",
          "name": "selectableTemplates",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Draft",
              "kind": "LinkedField",
              "name": "versions",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "version",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "body",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "headline",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "thumbnailAttribution",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Thumbnail_article"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Preview_article"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "HeroPicker_article"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "BylineSelect_draftable"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "PublicationDate_draftable"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TagFieldRenderer_taggable"
            }
          ],
          "type": "Article",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Error",
      "kind": "LinkedField",
      "name": "errors",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Error",
      "kind": "LinkedField",
      "name": "warnings",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "Controls_draft"
    }
  ],
  "type": "Draft",
  "abstractKey": null
};
})();
(node as any).hash = '20e1115743fe15191101e80c257006dc';
export default node;
