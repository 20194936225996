import React, { FC, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { createPaginationContainer, RelayPaginationProp } from 'react-relay'
import { Box, makeStyles, TableCell } from '@material-ui/core'
import { AuthorIndexQuery as Query } from './__generated__/AuthorIndexQuery.graphql'
import { AuthorIndex_query as Response } from './__generated__/AuthorIndex_query.graphql'
import { Wrapper } from '../Livestream'
import { PaginatedTable, PaginatedWrapper } from '../../Index'
import { AddButton as Button } from '../Metatags/Controls'
import { Theme } from '../../../theme'
import { List } from '../DataList'
import AuthorCreateModal from './AuthorCreate'
import Header from '../Header'
import ListItem from './Author'
import ToggleButton from '../ToggleButton'


export const PER_PAGE = 10

const query = graphql`
  query AuthorIndexQuery (
    $count: Int!,
    $cursor: String,
    $active: Boolean,
    $query: String
  ) {
    ...AuthorIndex_query @arguments(count: $count, cursor: $cursor, active: $active, query: $query)
  }
`

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: 64,
    marginBottom: 10,
    marginTop: 0,
  },
  avatar: {
    height: 42,
    width: 42,
    marginRight: theme.spacing(1)
  },
  toggleContainer: {
    marginRight: 64,
    fontWeight: theme.typography.fontWeightRegular
  }
}))

const AuthorTableHeader: FC = () => (
  <>
    <TableCell>Name</TableCell>
    <TableCell align='right'>Actions</TableCell>
  </>
)

const AuthorIndex = createPaginationContainer(
  (props: { query: Response, relay: RelayPaginationProp }) => {
    const {query: { organization: { id, authors }}, relay } = props
    const [active, setActive] = useState(true)
    const [showModal, setShowModal] = useState(false)
    const [updateList, setUpdateList] = useState(false)
    const classes = useStyles()

    const updateAuthorPage = () => setUpdateList((s) => !s)

    return (
      <Wrapper>
        <PaginatedWrapper
          updateList={updateList}
          search={true}
          rowCount={authors?.edges?.length}
          relay={relay}
          perPage={PER_PAGE}
          title='Authors'
          isLegacyStyle={false}
          HeaderComponent={() => <Header title='Authors'><Button handleClick={() => setShowModal(true)} text='Create'/></Header>}
          isActive={active}>
          <Box textAlign='right' className={classes.toggleContainer}>
            <ToggleButton
              active={active}
              handleChange={(event) => setActive(event.target.checked)}
              name='input.active'
              title='Show active authors'
              titlePosition='start'
            />
          </Box>
          <List header={<AuthorTableHeader/>}>
            {authors?.edges?.map(author => (
              <ListItem key={author?.node?.id!} author={author?.node!} id={author?.node?.id!} updateAuthorPage={updateAuthorPage}/>))}
          </List>
        </PaginatedWrapper>
        <AuthorCreateModal open={showModal} onClose={() => setShowModal(false)} id={id} updateAuthorPage={updateAuthorPage}/>
      </Wrapper>
    )
  },
  {
    query: graphql`
      fragment AuthorIndex_query on Query
      @argumentDefinitions(
        count: { type: "Int" }
        cursor: { type: "String" }
        active: { type: "Boolean" }
        query: { type: "String" }
      ) {
        organization {
          id
          authors(
            first: $count,
            after: $cursor,
            active: $active,
            query: $query
          ) @connection(key: "Authors_authors") {
            edges {
              node {
                ...on Author {
                  id
                  byline
                  active
                  slug
                }
              }
            }
          }
        }
      }
    `
  },
  {
    query,
    getVariables(props, { count, cursor }, { active, query }) {
      return {
        count,
        cursor,
        active,
        query
      }
    }
  }
)

export default () => <PaginatedTable<Query> query={query} Component={AuthorIndex} variables={{ count: PER_PAGE, active: true }}/>
