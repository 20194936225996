import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql } from 'babel-plugin-relay/macro'
import { commitMutation } from 'react-relay'
import environment from '../../../relay/environment'
import {
  ListDeleteMetatagMutation,
  ListDeleteMetatagMutationResponse
} from './__generated__/ListDeleteMetatagMutation.graphql'
import {
  ListUpdateMetatagOptionsMutation,
  ListUpdateMetatagOptionsMutationResponse,
} from './__generated__/ListUpdateMetatagOptionsMutation.graphql'
import { MetatagType } from '../Metatags'
import Button from './Button'
import Confirm from '../../Confirm'
import AddOption from './Controls'
import Row from './Row'
import ModifyMetatag from './Metatag'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #F1F1F1;

  >${Row} {
    border-bottom: none;
  }
`

const Label = styled.span`
  background: #E1E1E1;
  border-radius: 2px;
  color: ${props => props.theme.colors.grays.abbey};
  font-size: 13px;
  line-height: ${props => props.theme.space[3]}px;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 2px 11px;
  width: 53px;
  box-sizing: border-box;
  justify-content: center;
  user-select: none;
`

const Container = styled.div<{ visible: boolean, maxHeight: number }>`
  width: 100%;
  overflow: hidden;
  max-height: ${props => props.visible ? `${props.maxHeight}px` : '0px'};
  transition: 0.2s ease-in-out;

  ${Row}:last-child {
    padding: 14px 40px 20px 40px;
    border-bottom: none;
  }
`

const ContainerButtons = styled.div<{ isCaretVisible: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
  user-select: none;

  div:last-of-type {
    &:hover {
      color: black;
    }
  }

  div:not(:last-of-type) {
    margin-right: 8px;
  }

  button:last-of-type {
    visibility: ${props => props.isCaretVisible ? 'visible' : 'hidden'};
  }
`

const RemoveFixedOptionButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;
`

const TYPES: { [key: string] : string } = {
  AVATAR: 'Avatar',
  KEYWORDS: 'Keywords',
  MULTI_SELECT: 'Fixed',
  SINGLE_SELECT: 'Fixed',
  TEXT: 'Text',
  THUMBNAIL: 'Thumbnail'
}

const updateMetatagOptionsMutation = graphql`
  mutation ListUpdateMetatagOptionsMutation($id: ID!, $input: MetatagInput!) {
    metatagUpdate(id: $id, input: $input) {
      errors { field messages }
      metatag {
        id
        fixedOptions
      }
    }
  }
`

const deleteMutation = graphql`
  mutation ListDeleteMetatagMutation($id: ID!) {
    metatagDelete(id: $id) {
      metatag {
        isDeleted
        id
      }
    }
  }
`

type ListItemsProps = {
  id: string,
  options: MetatagType['fixedOptions']
  onUpdate: (fixedOptions: MetatagType['fixedOptions']) => void
  visible: boolean
}

const ListItems: React.FC<ListItemsProps> = ({ options, visible, id, onUpdate }) => {
  const [open, setOpen] = useState(false)
  const [unwantedOption, setUnwantedOption] = useState('')

  const updateMetatagOptions = (newOptions: string[]) => {
    commitMutation<ListUpdateMetatagOptionsMutation>(environment, {
      mutation: updateMetatagOptionsMutation,
      variables: { id: id, input: { fixedOptions: newOptions }},
      onCompleted: (resp:ListUpdateMetatagOptionsMutationResponse) => {
        if (!resp?.metatagUpdate?.errors.length) {
          onUpdate(newOptions)
        }
      }
    })
  }

  const handleAddOption = (newOption: string) => updateMetatagOptions(options ? [...options, newOption] : [newOption])
  const handleRemoveOption = () => {
    updateMetatagOptions(options ? [...options.filter(o => o !== unwantedOption)] : [])
    setUnwantedOption('')
    setOpen(false)
  }

  return (
    <Container visible={visible} maxHeight={(options && options.length) ? (58 * options.length + 68) : 68}>
      { options && options.map((option, i) => (
        <Row key={i}>
          <h3>{option}</h3>
          { options.length > 1 &&
            <RemoveFixedOptionButtonContainer>
              <Button icon='delete' onClick={() => {
                setUnwantedOption(option)
                setOpen(true)
              }}/>
            </RemoveFixedOptionButtonContainer>
          }
        </Row>
      ))}
      <Confirm
        open={open}
        close={() => setOpen(false)}
        onClick={handleRemoveOption}
        message={`Are you sure you want to remove the option '${unwantedOption}'?`}
      />
      <AddOption onAdd={handleAddOption}/>
    </Container>
  )
}

type ButtonGroupProps = {
  caret: boolean
  expanded: boolean
  handleClick: () => void
  onDelete: () => void
  onModify: () => void
}

const ButtonGroup: React.FC<ButtonGroupProps> = ({ caret, expanded, handleClick, onDelete, onModify }) => (
  <ContainerButtons isCaretVisible={caret}>
    <Button icon='mode' onClick={onModify}/>
    <Button icon='delete' onClick={onDelete}/>
    <Button icon={expanded ? 'keyboard_arrow_up' : 'keyboard_arrow_down'} onClick={handleClick}/>
  </ContainerButtons>
)

export type Props = {
  metatag: MetatagType
  onDelete: (id: string) => void
  onModify: (metatag: MetatagType) => void
}

const List: React.FC<Props> = ({ metatag, onDelete, onModify }) => {
  const [isConfirmVisible, setIsConfirmVisible] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [fixedOptions, setFixedOptions] = useState(metatag.fixedOptions)
  const { id, ...metatagData } = metatag
  const TagType = TYPES[metatag['type']]
  const cursor = TagType === 'Fixed' ? 'pointer' : 'unset'

  const deleteMetatag = () => {
    setIsConfirmVisible(false)
    commitMutation<ListDeleteMetatagMutation>(environment, {
      mutation: deleteMutation,
      variables: { id },
      onCompleted: (resp:ListDeleteMetatagMutationResponse) => resp?.metatagDelete?.metatag?.isDeleted && onDelete(id)
    })
  }

  const updateFixedOptions = (newFixedOptions: MetatagType['fixedOptions']) => {
    setFixedOptions(newFixedOptions)
    onModify({ ...metatag, fixedOptions: newFixedOptions})
  }

  const updateMetatag = (metatagUpdates: MetatagType) => {
    setFixedOptions(metatagUpdates.fixedOptions)
    onModify(metatagUpdates)
  }

  return (
    <Wrapper>
      <Row expanded={expanded} onClick={() => TagType === 'Fixed' && setExpanded(!expanded)} cursor={cursor}>
        <h2>{metatag.name}</h2>
        <Label>{TagType}</Label>
        <ButtonGroup
          caret={TagType === 'Fixed'}
          expanded={expanded}
          handleClick={() => setExpanded(!expanded)}
          onDelete={() => setIsConfirmVisible(true)}
          onModify={() => setIsModalVisible(true)}
        />
      </Row>
      <Confirm
        open={isConfirmVisible}
        close={() => setIsConfirmVisible(false)}
        onClick={deleteMetatag}
        message={`Are you sure you want to delete the tag '${metatag.name}'?`}
      />
      {TagType === 'Fixed' &&
        <ListItems
          id={id}
          options={fixedOptions ? fixedOptions : []}
          visible={expanded}
          onUpdate={updateFixedOptions}/>
      }
      <ModifyMetatag
        edit={true}
        handleClose={() => setIsModalVisible(false)}
        id={id}
        metatag={({...metatagData, fixedOptions} as any)}
        open={isModalVisible}
        updateMetatags={updateMetatag}
      />
    </Wrapper>
  )
}

export default List
