import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;

  p {
    color: ${props => props.theme.colors.primary.mediumSlateBlue};
    font-weight: 600;
    margin: 0;
    font-size: ${props => props.theme.fontSizes[2]}px;
    line-height: 17px;
    padding-top: ${props => props.theme.space[3]}px;
  }

  span {
    margin-left: ${props => props.theme.space[2]}px;
  }
`

const SaveMessage: React.FC = () => {
  return (
    <Container>
      <p>Your changes have been saved <span role='img' aria-label='thumbs up'>👍</span></p>
    </Container>
  )
}

export default SaveMessage
