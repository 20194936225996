import AsyncSelect from 'react-select/async'
import styled from 'styled-components'

const StyledAsyncSelect = styled(AsyncSelect)`
  .async-select__control {
    border: none;
    box-shadow: none;

    &--is-disabled {
      background-color: transparent;

      input[type="text"] {
        opacity: 0.5 !important;
      }
    }
  }
  .async-select__value-container {
    padding: 2px 0;
  }
	.async-select__multi-value  {
    width: 100%;
    height: 33px;
    margin: 2px 0;
    justify-content: space-between;
    align-items: center;
    background-color: ${props => props.theme.colors.primary.mediumSlateBlue};
    :nth-last-child(2){
      margin-bottom: 20px;
    }
  }
  .async-select__multi-value__label {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 188px;
    white-space: nowrap;
    color: ${props => props.theme.colors.grays.solitude};
    font-size: ${props => props.theme.fontSizes[1]};
  }
  .async-select__multi-value__remove {
    color: ${props => props.theme.colors.grays.solitude};
    height: 100%;
    &:hover {
      background-color: ${props => props.theme.colors.secondary.hollywoodCerise};
      color: ${props => props.theme.colors.grays.solitude};
    }
  }
  .async-select__menu {
    position: relative;
    border-radius: 0px;
    box-shadow: 0 2px 4px 0 rgba(238, 238, 238, 0.32);
    border: solid 1px rgba(151, 151, 151, 0.1);
  }
  .async-select__menu-list {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .async-select__menu-notice {
    height: 33px;
    font-size: ${props => props.theme.fontSizes[1]}px;
    color: ${props => props.theme.colors.grays.tuna};
    font-weight: ${props => props.theme.fontWeights[1]};
  }
  .async-select__input {
    width: 100%;
    height: 100%;
    > input {
      font-weight: ${props => props.theme.fontWeights[1]};
      box-sizing: border-box !important;
      width: 100% !important;
      font-size: ${props => props.theme.fontSizes[1]}px !important;
      color: ${props => props.theme.colors.grays.tuna} !important;
      height: 100%;
    }
  }
  .async-select__placeholder {
    display: none;
  }
  .async-select__option {
    background: #ffffff;
    font-size: ${props => props.theme.fontSizes[1]}px;
    color: ${props => props.theme.colors.grays.tuna};
    font-weight: ${props => props.theme.fontWeights[1]};
    height: 33px;
    display: flex;
    align-items: center;
    padding: 0;
    &:hover {
      background-color: ${props => props.theme.colors.grays.solitude};
      cursor: pointer;
    }
  }
`

export default StyledAsyncSelect
