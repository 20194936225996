/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TemplateSelect_draftable = {
    readonly selectableTemplates: ReadonlyArray<{
        readonly name: string;
        readonly versions: ReadonlyArray<{
            readonly id: string;
            readonly version: number;
        }>;
        readonly latestDraft: {
            readonly id: string;
        };
    }>;
    readonly template: {
        readonly name: string;
    } | null;
    readonly templateVersionId: string | null;
    readonly " $refType": "TemplateSelect_draftable";
};
export type TemplateSelect_draftable$data = TemplateSelect_draftable;
export type TemplateSelect_draftable$key = {
    readonly " $data"?: TemplateSelect_draftable$data;
    readonly " $fragmentRefs": FragmentRefs<"TemplateSelect_draftable">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TemplateSelect_draftable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Template",
      "kind": "LinkedField",
      "name": "selectableTemplates",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Draft",
          "kind": "LinkedField",
          "name": "versions",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "version",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Draft",
          "kind": "LinkedField",
          "name": "latestDraft",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Template",
      "kind": "LinkedField",
      "name": "template",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templateVersionId",
      "storageKey": null
    }
  ],
  "type": "Draftable",
  "abstractKey": "__isDraftable"
};
})();
(node as any).hash = 'db1762bd095913ecc098d903d7317831';
export default node;
