import React from 'react'
import clsx from 'clsx'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { graphql } from 'babel-plugin-relay/macro'
import { FormikContextType, useFormikContext } from 'formik'
import { Box, makeStyles, TextField } from '@material-ui/core'
import { RequestPasswordResetMutationVariables } from './__generated__/RequestPasswordResetMutation.graphql'
import Form, { OnCompletedOptions as FormProps } from '../../Settings/Form'
import alticeLogo from '../../../images/alticeLogo.png'
import { Logo, Title } from '../Signin'
import Buttons from '../../GenericFormComponents/ModalButtons'

const mutation = graphql`
  mutation RequestPasswordResetMutation($email: String!) {
    requestPasswordReset(email: $email)
  }
`

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required('Email is required')
})

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '41px',
    '& form': {
      width: '100%',
      '& >.MuiBox-root': {
        padding: '16px 0'
      }
    }
  }
}))

const Input = () => {
  const { values, errors, touched, handleBlur, handleChange }: FormikContextType<RequestPasswordResetMutationVariables> = useFormikContext()

  return (
    <Box height={55}>
      <TextField
        fullWidth
        id='email'
        label='Email'
        name='email'
        error={touched?.email && Boolean(errors?.email)}
        helperText={touched?.email && errors?.email}
        onBlur={handleBlur}
        onChange={handleChange}
        value={values.email}
        variant='outlined'
      />
    </Box>
  )
}

const RequestPasswordReset: React.FC<{ onCancel: () => void }> = ({ onCancel }) => {
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()

  const onComplete = ({ resetForm }: FormProps) => {
    resetForm()
    enqueueSnackbar('Password reset instructions sent.', { variant: 'success' })
  }

  return (
    <Box className={clsx(classes.wrapper)}>
      <Logo>
        <img alt={'Altice Logo'} src={alticeLogo} />
      </Logo>
      <Title>Password Reset</Title>
      <Form mutation={mutation} onComplete={onComplete} validationSchema={validationSchema} initialValues={{ email: '' }}>
        <Input />
        <Buttons onClose={onCancel} buttonText='Submit' />
      </Form>
    </Box>
  )
}

export default RequestPasswordReset
