import React, { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import { graphql } from 'babel-plugin-relay/macro'
import { Box, Divider, Dialog, TextField, capitalize } from '@material-ui/core'
import { FormikContextType, FormikValues, useFormikContext } from 'formik'
import { UserInviteMutationResponse } from './__generated__/UserInviteMutation.graphql'
import Form, { formatErrors, handleNonFieldErrors, OnCompletedOptions as FormProps } from '../Form'
import RoleSelect from './RoleSelect'
import StaticFields from './StaticFields'
import ToggleButton from '../ToggleButton'
import ModalHeader from '../../GenericFormComponents/ModalHeader'
import Buttons from '../../GenericFormComponents/ModalButtons'

const mutation = graphql`
  mutation UserInviteMutation($organizationId: ID!, $input: UserInviteInput!) {
    userInvite(organizationId: $organizationId, input: $input) {
      user {
        email
      }
      errors {
        field
        messages
      }
    }
  }
`

interface Schema {
  organizationId: string
  input: {
    email: string
    first_name: string
    last_name: string
    role: string
    is_author: boolean
    slug: string
  }
}

interface onCompleteProps extends FormProps {
  values: UserInviteMutationResponse
}

const validationSchema = Yup.object().shape({
  organizationId: Yup.string().max(255),
  input: Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
    first_name: Yup.string().max(255).required('First name is required'),
    last_name: Yup.string().max(255).required('Last name is required'),
    role: Yup.string().max(255).required('Role is required'),
    is_author: Yup.boolean()
  })
})

const initialValues = (id: string): Schema => ({
  organizationId: id,
  input: {
    email: '',
    first_name: '',
    last_name: '',
    role: 'CMS',
    is_author: false,
    slug: ''
  }
})

const formatValues = (values: FormikValues) => {
  if (!values.input.is_author) {
    const {
      input: { slug, ...fields }
    } = values
    return { ...values, input: { ...fields } }
  } else {
    return values
  }
}

const SlugField = () => {
  const {
    errors,
    handleBlur,
    touched,
    setFieldValue,
    values: {
      input: { first_name, last_name, slug }
    }
  }: FormikContextType<Schema> = useFormikContext()
  const [error, showError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const isFormikError = touched?.input && touched?.input?.slug && errors?.input && errors?.input?.slug
  const isBackendError = errors.input && (errors.input as any)['author.slug']
  const helperText = errors.input && (errors.input as any)['author.slug'] ? (errors.input as any)['author.slug'] : errors?.input?.slug

  useEffect(() => {
    if (!slug && error && first_name.trim().length > 0 && last_name.trim().length > 0) {
      const fullName = `${capitalize(first_name)} ${capitalize(last_name)}`
      const formattedSlug = fullName
        .toLocaleLowerCase('en-US')
        .replace(/[']/g, '')
        .replace(/_/g, ' ')
        .replace(/&/g, ' and ')
        .trim()
        .replace(/[^a-zA-Z0-9 -]/, '')
        .replace(/\s/g, '-')
      setFieldValue('input.slug', formattedSlug)
    }
  })

  useEffect(() => {
    showError(true)
    setErrorMessage('Already exists. Please choose another.')
  }, [])

  return (
    <Box mb={4} height={55}>
      <TextField
        fullWidth
        variant='outlined'
        type='text'
        id='input.slug'
        label='Slug'
        name='input.slug'
        onFocus={handleBlur}
        onBlur={handleBlur}
        onChange={(e) => {
          setFieldValue(e.target.name, e.target.value)
          showError(false)
          setErrorMessage('')
        }}
        value={slug}
        error={Boolean(isFormikError || isBackendError || error)}
        helperText={<>{capitalize(helperText ? helperText : errorMessage)}</>}
      />
    </Box>
  )
}

const Fields: FC<{ showSlugField: boolean }> = ({ showSlugField }) => {
  const [isAuthor, setIsAuthor] = useState(false)
  const { setFieldValue }: FormikContextType<Schema> = useFormikContext()

  return (
    <>
      <ModalHeader title={'Invite User'} />
      <Box p={3} pb={0}>
        <StaticFields />
        <Box mt={1} mb={3}>
          <RoleSelect isAdminOnOwnPage={false} />
        </Box>
        <Box mt={1} mb={2}>
          <ToggleButton
            active={isAuthor}
            handleChange={(e) => {
              setIsAuthor((s) => !s)
              setFieldValue(e.target.name, e.target.checked)
            }}
            name='input.is_author'
            title='Author'
            titlePosition='top'
          />
          {showSlugField && isAuthor && <SlugField />}
        </Box>
      </Box>
      <Divider />
    </>
  )
}

export type Props = {
  id: string
  updateAuthorPage: () => void
  onClose: () => void
  open: boolean
}

const UserInvite: FC<Props> = ({ onClose, open, id, updateAuthorPage }) => {
  const [showSlugField, setShowSlugField] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const handleModalClose = () => {
    onClose()
    setShowSlugField(false)
  }

  const onComplete = ({ values: { userInvite }, setErrors, resetForm, formValues }: onCompleteProps) => {
    if (!userInvite?.errors.length) {
      handleModalClose()
      updateAuthorPage()
      resetForm()
      enqueueSnackbar(`Invitation successfully sent to "${userInvite?.user?.email}"`, { variant: 'success' })
    } else {
      if (userInvite.errors.find((err) => err.field === 'author.slug')) {
        setShowSlugField(true)
      } else {
        handleNonFieldErrors(userInvite.errors, formValues!.input, enqueueSnackbar)
      }
      setErrors({ input: formatErrors(userInvite.errors) })
    }
  }

  return (
    <Dialog maxWidth='xs' fullWidth onClose={handleModalClose} disableBackdropClick disableEscapeKeyDown open={open}>
      <Form
        formatValues={(values) => formatValues(values)}
        mutation={mutation}
        onComplete={onComplete}
        validationSchema={validationSchema}
        initialValues={initialValues(id)}
      >
        <Fields showSlugField={showSlugField} />
        <Buttons onClose={handleModalClose} buttonText={'Send Invite'} />
      </Form>
    </Dialog>
  )
}

export default UserInvite
