import React, { useContext } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import environment from '../../../relay/environment'
import { createFragmentContainer, fetchQuery } from 'react-relay'
import { BylineSelect_draftable as Fragment } from './__generated__/BylineSelect_draftable.graphql'
import { BylineSelectQueryResponse as Response } from './__generated__/BylineSelectQuery.graphql'
import { debounce } from 'lodash'
import Section, { SectionProps } from './Section'
import { Context, ContextType } from '../Form'
import { ChangeHandler } from '../../../hooks/useStuntman'
import StyledAsyncSelect from './AsyncSelect/StyledAsyncSelect'
import Input from './AsyncSelect/Input'
import Option from './AsyncSelect/Option'
import MultiValueLabel from './AsyncSelect/MultiValueLabel'
import { SelectOptionType as OptionType } from './SelectOptionType'

const assignableAuthorsQuery = graphql`
  query BylineSelectQuery($query: String!) {
    organization {
      assignableAuthors(query: $query) {
        id
        byline
      }
    }
  }
`

const getAssignableAuthors = (inputValue: string, cb: (options: OptionType[]) => void) => {
  const vars = { query: inputValue }
  if (inputValue.length > 1) {
    fetchQuery(environment, assignableAuthorsQuery, vars).toPromise().then((data) => {
      const response = data as Response
      if (response.organization.assignableAuthors) {
        cb(createOptions(response.organization.assignableAuthors))
      }
    })
  }
}

const getPreview = (authors: Fragment['authors']) => {
  if (authors) {
    const authorsString = authors.map((author) => author.byline).join(' ')
    return (authorsString.length > 30) ? `${authorsString.substring(0, 30)}...` : authorsString
  }
}

const createOptions = (authors: Fragment['authors']) => {
  return authors.map(a => ({ value: a.id, label: a.byline }))
}

const handleChange = (value: OptionType[], onChange: ChangeHandler) => {
  if(value !== null) {
    const authorIds = value.map((e: OptionType) => e.value)
    onChange('authorIds', authorIds)
  } else {
    onChange('authorIds', [])
  }
}

type Props = SectionProps & {
  draftable: Fragment,
  name: string
}

const BylineSelect = createFragmentContainer (
  (props: Props) => {
    const { onChange, errors, disabled } = useContext(Context) as ContextType
    const { name, draftable: { authors} } = props
    const options = createOptions(authors)

    return (
      <Section {...props} preview={getPreview(authors)} errors={errors.for(name)}>
        <StyledAsyncSelect {...props}
          isMulti
          isClearable={false}
          openMenuOnClick={false}
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          defaultValue={options}
          classNamePrefix="async-select"
          placeholder="Search for author"
          loadOptions={debounce(getAssignableAuthors, 1000)}
          onChange={(value: OptionType[]) => handleChange(value, onChange)}
          components={{Option, Input, MultiValueLabel, IndicatorsContainer: () => null}}
          filterOption={(currentOption: OptionType) => !options.some((option: OptionType) => option.label === currentOption.label)}
          isDisabled={disabled}
        />
      </Section>
    )
  }, {
    draftable: graphql`
      fragment BylineSelect_draftable on Article {
        authors {
          id
          byline
        }
      }
    `
  }
)

export default BylineSelect
