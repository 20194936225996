import React from 'react'
import styled from 'styled-components/macro'
import randomColor from 'randomcolor'
import {
  DraftableCell_draftable as Fragment,
} from './Index/__generated__/DraftableCell_draftable.graphql'

type NoUndefinedField<T> = {
  [P in keyof T]-?: Exclude<T[P], undefined>;
};

export type User = NoUndefinedField<NonNullable<Partial<NonNullable<Fragment['latestDraft']['administrators']>[0]['user']>>>

type Props = {
  size: number,
  float?: 'left' | 'right'
  user: User
}

const initials = (user: User): string => {
  return [ user.first_name, user.last_name ].map((n) => n?.slice(0, 1)?.toUpperCase()).join('')
}

const Container = styled.div<{ size: number, float?: 'left' | 'right' }>`
  width: ${props => props.theme.space[props.size + 4]}px;
  height: ${props => props.theme.space[props.size + 4]}px;
  border-radius: ${props => props.theme.space[props.size + 4] / 2}px;
  line-height: ${props => props.theme.space[props.size + 4]}px;
  font-size: ${props => props.theme.fontSizes[props.size]}px;
  font-weight: ${props => props.theme.fontWeights[3]};
  text-align: center;
  overflow: hidden;
  float: ${props => props.float || 'none'};

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  div {
    border-radius: ${props => props.theme.space[props.size + 4] / 2}px;
  }
`

const Initials = styled.div<{ seed: string }>`
  background-color: ${props => randomColor({ luminosity: 'dark', seed: props.seed })};
  color: white;
  width: 100%;
  height: 100%;
`

const Avatar: React.FC<Props> = ({ user, ...rest }) => (
  <Container {...rest} >
    {user.avatar_url ? (
      <img src={user.avatar_url} alt={`${user.first_name} ${user.last_name}`}/>
    ) : (
      <Initials seed={user.id}>{ initials(user) }</Initials>
    )}
  </Container>
)

export default Avatar
