import React, { ComponentType } from 'react'
import { QueryRenderer, GraphQLTaggedNode } from 'react-relay'
import { RouteComponentProps } from 'react-router-dom'
import { MutationParameters } from 'relay-runtime'
import environment from '../relay/environment'
import Loader from './Loader'
import ErrorRenderer from './ErrorRenderer'

type QueryParams = {
  id: string
}

interface NodeQuery extends MutationParameters {
  response: NodeQueryResponse
}

type NodeQueryResponse = {
  node: any
}

export default function DetailRenderer<QT extends NodeQuery>(Component: ComponentType<{ draft: any }>, query: GraphQLTaggedNode) {
  const Renderer: React.FC<RouteComponentProps<QueryParams>> = ({ match }) => (
    <QueryRenderer<QT>
      environment={environment}
      query={query}
      variables={match.params}
      render={({ error, props }) => {
        if(error){
          return <ErrorRenderer message={error.message}/>
        }

        const draft = props?.node?.latestDraft || props?.node
        return draft ? (<Component draft={draft} />) : <Loader />
      }}
    />
  )

  return Renderer
}

