import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { fetchQuery } from 'react-relay'
import environment from '../../../relay/environment'
import { graphql } from 'babel-plugin-relay/macro'
import Input from './KeywordsInput'
import Dropdown from './AutoComplete'

const StyledInputContainer = styled.div`
  display: inline-block;
  padding 9px 13px;
`

const tagQuery = graphql`
  query KeywordsInputContainerQuery($count: Int!, $query: String, $exclude: [String]) {
    suggestedTags(count: $count, query: $query, exclude: $exclude)
  }
`

export const KeyObject = { UP: 38, DOWN: 40, BACKSPACE: 8, ENTER: 13 }

type InputContainerProps = {
  disabled: boolean | undefined
  newInput: React.RefObject<HTMLInputElement> | null
  addWord: (arg: string) => void
  removeWord: () => void
  updateHeight: () => void
}

const getSuggestions = (query: string, setSuggestions: React.Dispatch<React.SetStateAction<string[] | never[]>>) => {
  query = query.trim()
  const variables = { count: 5, query }
  if (query !== "") {
    fetchQuery(environment, tagQuery, variables).toPromise().then((data: any) => {
      setSuggestions(data.suggestedTags)
    })
  }
}

const handleKeyDown = (e: any, dropdown: any) => {
  if (dropdown.current) {
    let eventHandler = Object.keys(dropdown.current).filter((element) => element.includes('__reactEventHandlers'))[0]
    dropdown.current[eventHandler].onKeyDown(e)
  }
}

const updateInput = (newValue: string, newInput: any) => {
  if (newInput.current) {
    let eventHandler = Object.keys(newInput.current).filter((element) => element.includes('__reactEventHandlers'))[0]
    newInput.current[eventHandler].onChange({ currentTarget: { value: newValue, scrolling: true } })
  }
}

const KeywordsInputContainer: React.FC<InputContainerProps> = ({ disabled, newInput, addWord, removeWord, updateHeight }) => {
  const [suggestions, setSuggestions] = useState<string[]>([])
  const DropdownList = useRef<HTMLUListElement>(null)

  return (
    <StyledInputContainer data-testid="input-container" onKeyDown={(e) => handleKeyDown(e, DropdownList)}>
      {!disabled && <Input getSuggestions={(query: string) => getSuggestions(query, setSuggestions)} newInput={newInput} updateHeight={updateHeight} addWord={addWord} removeWord={removeWord} setSuggestions={setSuggestions} />}
      {!disabled && suggestions?.length > 0 && <Dropdown suggestions={suggestions} setCurrentInputValue={(str: string) => updateInput(str, newInput)} DropdownList={DropdownList} addWord={addWord} setSuggestions={setSuggestions}/>}
    </StyledInputContainer>
  )
}

export default KeywordsInputContainer
