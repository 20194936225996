export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Converts node IDs to scalar IDs in input arguments */
  ConvertedID: any;
  /** date converted into iso8601 */
  DateTime: any;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  JSON: any;
};

export type AdvertiseInput = {
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  message?: Maybe<Scalars['String']>;
  recaptcha: Scalars['String'];
};

/** Different client applications for users (e.g. Web, Android) */
export type App = Node & {
  __typename?: 'App';
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  name: Scalars['String'];
};

/** Autogenerated return type of AppCreateMutation */
export type AppCreateMutationPayload = {
  __typename?: 'AppCreateMutationPayload';
  app: App;
  errors: Array<Error>;
};

/** Autogenerated return type of AppDeleteMutation */
export type AppDeleteMutationPayload = {
  __typename?: 'AppDeleteMutationPayload';
  app: App;
};

export type AppInput = {
  name: Scalars['String'];
};

/** Autogenerated return type of AppUpdateMutation */
export type AppUpdateMutationPayload = {
  __typename?: 'AppUpdateMutationPayload';
  app: App;
  errors: Array<Error>;
};

/** Represents all information for an article */
export type Article = Draftable & Node & Renderable & Shareable & Slugable & {
  __typename?: 'Article';
  _score?: Maybe<Scalars['Float']>;
  amp: Scalars['String'];
  authors: Array<Author>;
  body?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Category>>;
  comscore: Scalars['String'];
  createdAt: Scalars['String'];
  css?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  description?: Maybe<Scalars['String']>;
  drafts: Array<Draft>;
  formattedAuthors: Scalars['String'];
  fullHtml: Scalars['String'];
  headline?: Maybe<Scalars['String']>;
  heroImageUrl?: Maybe<Scalars['String']>;
  hero_video?: Maybe<HeroVideo>;
  html: Scalars['String'];
  id: Scalars['ID'];
  isTemplatable: Scalars['Boolean'];
  is_saved?: Maybe<Scalars['Boolean']>;
  lastPublishedAt?: Maybe<Scalars['String']>;
  latestDraft: Draft;
  legacyTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  markdownBody?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organization: Organization;
  originalPublishedAt: Scalars['String'];
  parsed_html?: Maybe<Scalars['String']>;
  public_at?: Maybe<Scalars['DateTime']>;
  public_url?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  related_media?: Maybe<SlugableConnection>;
  selectableTemplates: Array<Template>;
  slug: Scalars['String'];
  tags: Array<Tag>;
  template?: Maybe<Template>;
  templateVersionId?: Maybe<Scalars['ID']>;
  thumbnailAttribution?: Maybe<Scalars['String']>;
  thumbnailChecksum?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  thumbnail_attribution?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  thumbnails?: Maybe<Thumbnail>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  updated_at: Scalars['DateTime'];
  version?: Maybe<Scalars['Int']>;
  video?: Maybe<Video>;
  videoFile?: Maybe<VideoFile>;
  /** @deprecated use videoFile { id } instead */
  videoFileId?: Maybe<Scalars['ID']>;
};


/** Represents all information for an article */
export type ArticleRelated_MediaArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Represents all information for an article */
export type ArticleThumbnailsArgs = {
  aspect_ratio?: Maybe<AspectRatio>;
};

/** The connection type for Article. */
export type ArticleConnection = {
  __typename?: 'ArticleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ArticleEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Article>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of ArticleCreateMutation */
export type ArticleCreateMutationPayload = {
  __typename?: 'ArticleCreateMutationPayload';
  draft: Draft;
};

/** Autogenerated return type of ArticleDeleteMutation */
export type ArticleDeleteMutationPayload = {
  __typename?: 'ArticleDeleteMutationPayload';
  article: Article;
  errors: Array<Error>;
};

/** An edge in a connection. */
export type ArticleEdge = {
  __typename?: 'ArticleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Article>;
};

export type ArticleInput = {
  headline?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  thumbnailAttribution?: Maybe<Scalars['String']>;
  thumbnailChecksum?: Maybe<Scalars['String']>;
  videoId?: Maybe<Scalars['ConvertedID']>;
  authorIds?: Maybe<Array<Scalars['ConvertedID']>>;
  templateVersionId?: Maybe<Scalars['ConvertedID']>;
  tags?: Maybe<Array<TagInput>>;
  heroImageUrl?: Maybe<Scalars['String']>;
  videoFileId?: Maybe<Scalars['ConvertedID']>;
  publishedAt?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of ArticleUpdateMutation */
export type ArticleUpdateMutationPayload = {
  __typename?: 'ArticleUpdateMutationPayload';
  draft: Draft;
  draftable: Draftable;
  errors: Array<Error>;
  warnings: Array<Error>;
};

/** The aspect ratio of an image or video (16:9, 4:3, etc) */
export enum AspectRatio {
  /** Original */
  Original = 'ORIGINAL',
  /** 16:9 */
  Hd = 'HD',
  /** 4:3 */
  Sd = 'SD',
  /** 3:2 */
  Film = 'FILM',
  /** 1:1 */
  Square = 'SQUARE'
}

/** An asset that can be used for videos */
export type Asset = Node & {
  __typename?: 'Asset';
  checksum?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  mime_type?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  thumbnails?: Maybe<Thumbnail>;
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['DateTime'];
};


/** An asset that can be used for videos */
export type AssetThumbnailsArgs = {
  aspect_ratio?: Maybe<AspectRatio>;
};

/** The connection type for Asset. */
export type AssetConnection = {
  __typename?: 'AssetConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AssetEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Asset>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AssetEdge = {
  __typename?: 'AssetEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Asset>;
};

export type AssetInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  mime_type?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  url?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  checksum?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AuthOauthAccessTokenMutation */
export type AuthOauthAccessTokenMutationPayload = {
  __typename?: 'AuthOauthAccessTokenMutationPayload';
  device: DeviceToken;
  user: User;
};

/** An Author profile */
export type Author = Node & {
  __typename?: 'Author';
  active: Scalars['Boolean'];
  articles?: Maybe<SlugableConnection>;
  /** @deprecated Use articles */
  authored_articles?: Maybe<SlugableConnection>;
  avatarUrl: Scalars['String'];
  /** @deprecated Use avatarUrl */
  avatar_url: Scalars['String'];
  bio?: Maybe<Scalars['String']>;
  byline: Scalars['String'];
  email: Scalars['String'];
  /** @deprecated Use byline */
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  /** @deprecated Use byline */
  last_name?: Maybe<Scalars['String']>;
  linkedin?: Maybe<Scalars['String']>;
  organization: Organization;
  slug: Scalars['String'];
  tags: Array<Tag>;
  twitter?: Maybe<Scalars['String']>;
};


/** An Author profile */
export type AuthorArticlesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** An Author profile */
export type AuthorAuthored_ArticlesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Author. */
export type AuthorConnection = {
  __typename?: 'AuthorConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AuthorEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Author>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of AuthorCreateMutation */
export type AuthorCreateMutationPayload = {
  __typename?: 'AuthorCreateMutationPayload';
  author?: Maybe<Author>;
  errors: Array<Error>;
};

/** Autogenerated return type of AuthorDeleteMutation */
export type AuthorDeleteMutationPayload = {
  __typename?: 'AuthorDeleteMutationPayload';
  author: Author;
  errors: Array<Error>;
};

/** An edge in a connection. */
export type AuthorEdge = {
  __typename?: 'AuthorEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Author>;
};

export type AuthorInput = {
  active?: Maybe<Scalars['Boolean']>;
  byline?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<TagInput>>;
};

/** Autogenerated return type of AuthorUpdateMutation */
export type AuthorUpdateMutationPayload = {
  __typename?: 'AuthorUpdateMutationPayload';
  author?: Maybe<Author>;
  errors: Array<Error>;
};

export type Authorization = Node & {
  __typename?: 'Authorization';
  /** @deprecated The `db_id` field is being eliminated and instead `id` arguments will convert node IDs to database IDs transparently */
  db_id?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** @deprecated The `node_id` field is being eliminated */
  node_id?: Maybe<Scalars['ID']>;
  short_code: Scalars['String'];
};

/** Returns avatar image */
export type Avatar = {
  __typename?: 'Avatar';
  image?: Maybe<Scalars['String']>;
};

export type Card = Node & {
  __typename?: 'Card';
  brand?: Maybe<Scalars['String']>;
  exp_month: Scalars['String'];
  exp_year: Scalars['String'];
  id: Scalars['ID'];
  last4: Scalars['String'];
};

/** A media in a carousel */
export type CarouselMedia = {
  __typename?: 'CarouselMedia';
  content: Content;
  id: Scalars['ID'];
  media: Slugable;
  position: Scalars['Int'];
};

export type CarouselMediaInput = {
  id?: Maybe<Scalars['ConvertedID']>;
  position?: Maybe<Scalars['Int']>;
  media_id?: Maybe<Scalars['String']>;
  _delete?: Maybe<Scalars['Boolean']>;
};

/** A video in a carousel */
export type CarouselVideo = {
  __typename?: 'CarouselVideo';
  content: Content;
  /** @deprecated The `id` field needs to be a node ID */
  id?: Maybe<Scalars['Int']>;
  position: Scalars['Int'];
  video: Video;
};

export type CarouselVideoInput = {
  id?: Maybe<Scalars['ConvertedID']>;
  position?: Maybe<Scalars['Int']>;
  video_id: Scalars['ConvertedID'];
  _delete?: Maybe<Scalars['Boolean']>;
};

/** Category of media */
export enum Category {
  /** business */
  Business = 'BUSINESS',
  /** science */
  Science = 'SCIENCE',
  /** technology */
  Technology = 'TECHNOLOGY',
  /** politics */
  Politics = 'POLITICS',
  /** sports */
  Sports = 'SPORTS',
  /** culture */
  Culture = 'CULTURE',
  /** bestof */
  Bestof = 'BESTOF'
}

/** See the programming schedule for each channel */
export type ChannelSchedule = Node & {
  __typename?: 'ChannelSchedule';
  channel: Scalars['String'];
  end_time: Scalars['String'];
  id: Scalars['ID'];
  start_time: Scalars['String'];
  title: Scalars['String'];
};

export type Collection = Node & Draftable & {
  __typename?: 'Collection';
  body?: Maybe<Scalars['JSON']>;
  collectables?: Maybe<SlugableConnection>;
  createdAt: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  drafts: Array<Draft>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['String']>;
  isTemplatable: Scalars['Boolean'];
  lastPublishedAt?: Maybe<Scalars['String']>;
  latestDraft: Draft;
  name: Scalars['String'];
  originalPublishedAt: Scalars['String'];
  publishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  selectableTemplates: Array<Template>;
  template?: Maybe<Template>;
  templateVersionId?: Maybe<Scalars['ID']>;
  version?: Maybe<Scalars['Int']>;
};


export type CollectionCollectablesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Collection. */
export type CollectionConnection = {
  __typename?: 'CollectionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CollectionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Collection>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of CollectionCreateMutation */
export type CollectionCreateMutationPayload = {
  __typename?: 'CollectionCreateMutationPayload';
  draft: Draft;
};

/** An edge in a connection. */
export type CollectionEdge = {
  __typename?: 'CollectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Collection>;
};

export type CollectionInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['JSON']>;
};

/** Autogenerated return type of CollectionUpdateMutation */
export type CollectionUpdateMutationPayload = {
  __typename?: 'CollectionUpdateMutationPayload';
  draft: Draft;
  draftable: Draftable;
  errors: Array<Error>;
  warnings: Array<Error>;
};

export type Configuration = Node & {
  __typename?: 'Configuration';
  /** @deprecated The `db_id` field is being eliminated and instead `id` arguments will convert node IDs to database IDs transparently */
  db_id?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  live_url: Scalars['String'];
  /** @deprecated The `node_id` field is being eliminated */
  node_id?: Maybe<Scalars['ID']>;
};

/** A generalized content for a page */
export type Content = Node & {
  __typename?: 'Content';
  body?: Maybe<Scalars['String']>;
  carousel_medias?: Maybe<Array<Maybe<CarouselMedia>>>;
  /** @deprecated Deprecating to CarouselMedia */
  carousel_videos?: Maybe<Array<Maybe<CarouselVideo>>>;
  created_at: Scalars['DateTime'];
  css?: Maybe<Scalars['String']>;
  cta_action?: Maybe<Scalars['String']>;
  cta_text?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['DateTime']>;
  expires_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  max_count?: Maybe<Scalars['Int']>;
  min_count?: Maybe<Scalars['Int']>;
  /** @deprecated The `node_id` field is being eliminated */
  node_id?: Maybe<Scalars['ID']>;
  position: Scalars['Int'];
  slug: Scalars['String'];
  tagline?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type ContentInput = {
  id?: Maybe<Scalars['ConvertedID']>;
  css?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
  cta_text?: Maybe<Scalars['String']>;
  cta_action?: Maybe<Scalars['String']>;
  expires_at?: Maybe<Scalars['String']>;
  min_count?: Maybe<Scalars['Int']>;
  max_count?: Maybe<Scalars['Int']>;
  carousel_videos_attributes?: Maybe<Array<Maybe<CarouselVideoInput>>>;
  carousel_medias_attributes?: Maybe<Array<Maybe<CarouselMediaInput>>>;
  body?: Maybe<Scalars['String']>;
};



/** Autogenerated return type of DeviceLogoutMutation */
export type DeviceLogoutMutationPayload = {
  __typename?: 'DeviceLogoutMutationPayload';
  device: DeviceToken;
};

/** Each device that registers with the API gets a unique device record with a token that is used for all future requests. */
export type DeviceToken = {
  __typename?: 'DeviceToken';
  id: Scalars['ID'];
  is_preview_active: Scalars['Boolean'];
  /** @deprecated Use `is_preview_active` instead */
  is_trialing: Scalars['Boolean'];
  platform: Platform;
  preview_duration: Scalars['Int'];
  preview_expires_at: Scalars['DateTime'];
  provider_auth?: Maybe<ProviderAuthentication>;
  token: Scalars['String'];
  /** @deprecated Use `preview_expires_at` instead */
  trial_expires_at: Scalars['DateTime'];
  user?: Maybe<User>;
  variants?: Maybe<Array<Maybe<Variant>>>;
  version: Scalars['String'];
};

/** A URL that, when visited, will cause the browser to download rather than displaying the file */
export type Download = {
  __typename?: 'Download';
  description: Scalars['String'];
  url: Scalars['String'];
};

/** Metadata surrounding a draftable object */
export type Draft = Node & Validatable & Permissible & {
  __typename?: 'Draft';
  administrators: Array<DraftAdministrator>;
  canDelete: Scalars['Boolean'];
  canEdit: Scalars['Boolean'];
  canPublish: Scalars['Boolean'];
  canUpdate: Scalars['Boolean'];
  createdAt: Scalars['ISO8601DateTime'];
  draftable: Draftable;
  errors: Array<Error>;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  isScheduled: Scalars['Boolean'];
  publicationStatus: PublicationStatus;
  publishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  scheduledFor?: Maybe<Scalars['ISO8601DateTime']>;
  updatedAt: Scalars['ISO8601DateTime'];
  version: Scalars['Int'];
  warnings: Array<Error>;
};

/** represents the user associated with a draft */
export type DraftAdministrator = {
  __typename?: 'DraftAdministrator';
  privilege?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type DraftAdministratorInput = {
  user_id: Scalars['ConvertedID'];
  privilege: Scalars['String'];
  _destroy?: Maybe<Scalars['Boolean']>;
};

/** Autogenerated return type of DraftDeleteMutation */
export type DraftDeleteMutationPayload = {
  __typename?: 'DraftDeleteMutationPayload';
  draft: Draft;
};

/** Autogenerated return type of DraftEditMutation */
export type DraftEditMutationPayload = {
  __typename?: 'DraftEditMutationPayload';
  draft: Draft;
  editableDraft?: Maybe<Draft>;
};

export type DraftInput = {
  administrators_attributes: Array<DraftAdministratorInput>;
};

/** Autogenerated return type of DraftPublishMutation */
export type DraftPublishMutationPayload = {
  __typename?: 'DraftPublishMutationPayload';
  draft: Draft;
  draftable: Draftable;
  errors: Array<Error>;
};

/** Any type that can be drafted */
export type Draftable = {
  createdAt: Scalars['String'];
  drafts: Array<Draft>;
  isTemplatable: Scalars['Boolean'];
  lastPublishedAt?: Maybe<Scalars['String']>;
  latestDraft: Draft;
  name: Scalars['String'];
  originalPublishedAt: Scalars['String'];
  publishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  selectableTemplates: Array<Template>;
  template?: Maybe<Template>;
  templateVersionId?: Maybe<Scalars['ID']>;
  version?: Maybe<Scalars['Int']>;
};

/** Filters five_or_less, five_to_fifteen, fifteen_or_less */
export enum DurationFilter {
  /** five_or_less */
  FiveOrLess = 'FIVE_OR_LESS',
  /** five_to_fifteen */
  FiveToFifteen = 'FIVE_TO_FIFTEEN',
  /** fifteen_or_less */
  FifteenOrLess = 'FIFTEEN_OR_LESS'
}

/** Captures error messages */
export type Error = {
  __typename?: 'Error';
  field: Scalars['String'];
  messages: Array<Scalars['String']>;
};

/** An export format for partners and their templates. */
export type ExportFormat = Node & {
  __typename?: 'ExportFormat';
  created_at: Scalars['DateTime'];
  /** @deprecated The `db_id` field is being eliminated and instead `id` arguments will convert node IDs to database IDs transparently */
  db_id?: Maybe<Scalars['Int']>;
  export_url?: Maybe<Scalars['String']>;
  feedTemplate?: Maybe<FeedTemplate>;
  id: Scalars['ID'];
  legacyCollections?: Maybe<LegacyCollectionConnection>;
  name?: Maybe<Scalars['String']>;
  /** @deprecated The `node_id` field is being eliminated */
  node_id?: Maybe<Scalars['ID']>;
  partner?: Maybe<Partner>;
  /** @deprecated Use feedTemplate instead */
  template?: Maybe<FeedTemplate>;
  updated_at: Scalars['DateTime'];
  videos?: Maybe<VideoConnection>;
};


/** An export format for partners and their templates. */
export type ExportFormatLegacyCollectionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** An export format for partners and their templates. */
export type ExportFormatVideosArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ExportFormatInput = {
  partner_id?: Maybe<Scalars['ID']>;
  template_id?: Maybe<Scalars['ID']>;
  feed_template_id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

/** A feature flag */
export type FeatureFlag = {
  __typename?: 'FeatureFlag';
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
};

export type FeedInput = {
  partner_id?: Maybe<Scalars['ID']>;
  template_id?: Maybe<Scalars['ID']>;
  legacyCollection_id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** A partner's template for generating feeds */
export type FeedTemplate = Node & {
  __typename?: 'FeedTemplate';
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  exportFormats: Array<ExportFormat>;
  id: Scalars['ID'];
  mimeType: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

/** Autogenerated return type of FeedTemplateCreateMutation */
export type FeedTemplateCreateMutationPayload = {
  __typename?: 'FeedTemplateCreateMutationPayload';
  errors: Array<Error>;
  feedTemplate?: Maybe<FeedTemplate>;
};

export type FeedTemplateInput = {
  name?: Maybe<Scalars['String']>;
  mimeType?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  footer?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of FeedTemplateUpdateMutation */
export type FeedTemplateUpdateMutationPayload = {
  __typename?: 'FeedTemplateUpdateMutationPayload';
  errors: Array<Error>;
  feedTemplate: FeedTemplate;
};

/** Represents a media (image, video, audio) format */
export type Format = {
  __typename?: 'Format';
  extension: Scalars['String'];
  mime: Scalars['String'];
};

/** Represent only information from a Video needed for Hero display */
export type HeroVideo = {
  __typename?: 'HeroVideo';
  captions?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Int']>;
  isLive?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  transcript?: Maybe<Scalars['String']>;
  video_urls?: Maybe<Array<Maybe<Url>>>;
  width?: Maybe<Scalars['Int']>;
};



/** Represents all available information for a legacy article */
export type LegacyArticle = Node & Slugable & {
  __typename?: 'LegacyArticle';
  _score?: Maybe<Scalars['Float']>;
  authors: Array<Author>;
  body: Scalars['String'];
  categories?: Maybe<Array<Category>>;
  description: Scalars['String'];
  formattedAuthors: Scalars['String'];
  hero_video?: Maybe<HeroVideo>;
  id: Scalars['ID'];
  is_saved?: Maybe<Scalars['Boolean']>;
  latest_draft: LegacyArticle;
  legacyTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  parsed_html: Scalars['String'];
  public_at?: Maybe<Scalars['DateTime']>;
  public_url?: Maybe<Scalars['String']>;
  published_at?: Maybe<Scalars['DateTime']>;
  related_media?: Maybe<SlugableConnection>;
  slug: Scalars['String'];
  tags: Array<Maybe<Scalars['String']>>;
  template?: Maybe<Template>;
  thumbnail_attribution: Scalars['String'];
  thumbnail_checksum: Scalars['String'];
  thumbnail_url: Scalars['String'];
  thumbnails?: Maybe<Thumbnail>;
  title: Scalars['String'];
  updated_at: Scalars['DateTime'];
  video?: Maybe<Video>;
};


/** Represents all available information for a legacy article */
export type LegacyArticleRelated_MediaArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Represents all available information for a legacy article */
export type LegacyArticleThumbnailsArgs = {
  aspect_ratio?: Maybe<AspectRatio>;
};

/** A legacy collection of videos with specific parameters (i.e. tags, age, show) */
export type LegacyCollection = Node & {
  __typename?: 'LegacyCollection';
  age?: Maybe<Scalars['Int']>;
  can_delete?: Maybe<Scalars['Boolean']>;
  categories?: Maybe<Array<Maybe<Category>>>;
  clip?: Maybe<Scalars['Boolean']>;
  duration_filter?: Maybe<DurationFilter>;
  excluded_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  free?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  included_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  media_format?: Maybe<Scalars['String']>;
  order_attribute?: Maybe<SortAttribute>;
  order_direction?: Maybe<SortDirection>;
  show_ids?: Maybe<Array<Maybe<Scalars['ConvertedID']>>>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  videos?: Maybe<VideoConnection>;
};


/** A legacy collection of videos with specific parameters (i.e. tags, age, show) */
export type LegacyCollectionVideosArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for LegacyCollection. */
export type LegacyCollectionConnection = {
  __typename?: 'LegacyCollectionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LegacyCollectionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LegacyCollection>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type LegacyCollectionEdge = {
  __typename?: 'LegacyCollectionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<LegacyCollection>;
};

export type LegacyCollectionInput = {
  title?: Maybe<Scalars['String']>;
  order_attribute?: Maybe<SortAttribute>;
  order_direction?: Maybe<SortDirection>;
  age?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  clip?: Maybe<Scalars['Boolean']>;
  free?: Maybe<Scalars['Boolean']>;
  included_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  excluded_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Category>>>;
  duration_filter?: Maybe<DurationFilter>;
  show_ids?: Maybe<Array<Maybe<Scalars['ConvertedID']>>>;
};

/** A feed of multiple videos (RSS, etc) for periodic polling by an authenticated partner */
export type LegacyFeed = Node & {
  __typename?: 'LegacyFeed';
  created_at: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  export_url?: Maybe<Scalars['String']>;
  feedTemplate?: Maybe<FeedTemplate>;
  id: Scalars['ID'];
  legacyCollection?: Maybe<LegacyCollection>;
  partner?: Maybe<Partner>;
  slug: Scalars['String'];
  /** @deprecated Use feedTemplate instead */
  template?: Maybe<FeedTemplate>;
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['DateTime'];
};

/** A Page content */
export type LegacyPage = Node & {
  __typename?: 'LegacyPage';
  contents?: Maybe<Array<Maybe<Content>>>;
  css?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  path?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at: Scalars['DateTime'];
};

export type LegacyPageInput = {
  css?: Maybe<Scalars['String']>;
  contents_attributes?: Maybe<Array<Maybe<ContentInput>>>;
};

/** A channel that streams a live news stream */
export type LiveChannel = Node & {
  __typename?: 'LiveChannel';
  description: Scalars['String'];
  description_default: Scalars['String'];
  description_override?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_currently_overriden: Scalars['Boolean'];
  name: Scalars['String'];
  override_duration?: Maybe<Scalars['Int']>;
  override_expires_at?: Maybe<Scalars['DateTime']>;
  override_time_left?: Maybe<Scalars['Int']>;
  publish_to_apple_news: Scalars['Boolean'];
  url: Scalars['String'];
};

export type LiveChannelInput = {
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  description_default?: Maybe<Scalars['String']>;
  description_override?: Maybe<Scalars['String']>;
  override_duration?: Maybe<Scalars['Int']>;
  publish_to_apple_news?: Maybe<Scalars['Boolean']>;
};

/** live type */
export type LiveSettings = {
  __typename?: 'LiveSettings';
  free: Scalars['Boolean'];
};

export type LiveSettingsInput = {
  free: Scalars['Boolean'];
};

/** A channel that streams a live news stream */
export type LiveStream = Node & {
  __typename?: 'LiveStream';
  id: Scalars['ID'];
  liveChannel: LiveChannel;
  partner: Partner;
  signedUrl: Scalars['String'];
  slug: Scalars['String'];
};

/** Autogenerated return type of LiveStreamCreateMutation */
export type LiveStreamCreateMutationPayload = {
  __typename?: 'LiveStreamCreateMutationPayload';
  errors: Array<Error>;
  liveStream?: Maybe<LiveStream>;
};

export type LiveStreamInput = {
  liveChannelId: Scalars['ConvertedID'];
  partnerId: Scalars['ConvertedID'];
  slug: Scalars['String'];
};

/** A type of media (video, article, etc) */
export enum MediaType {
  /** article */
  Article = 'ARTICLE',
  /** video */
  Video = 'VIDEO'
}

/** Represents all information for a metatag */
export type Metatag = Node & {
  __typename?: 'Metatag';
  fixedOptions?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  maxValues?: Maybe<Scalars['Int']>;
  minValues?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  organization: Organization;
  scopeType?: Maybe<Array<MetatagScopeType>>;
  type: MetatagType;
  validator?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of MetatagCreateMutation */
export type MetatagCreateMutationPayload = {
  __typename?: 'MetatagCreateMutationPayload';
  errors: Array<Error>;
  metatag?: Maybe<Metatag>;
};

/** Autogenerated return type of MetatagDeleteMutation */
export type MetatagDeleteMutationPayload = {
  __typename?: 'MetatagDeleteMutationPayload';
  errors: Array<Error>;
  metatag: Metatag;
};

export type MetatagInput = {
  name?: Maybe<Scalars['String']>;
  fixedOptions?: Maybe<Array<Scalars['String']>>;
  maxValues?: Maybe<Scalars['Int']>;
  minValues?: Maybe<Scalars['Int']>;
  scopeType?: Maybe<Array<MetatagScopeType>>;
  type?: Maybe<MetatagType>;
  validator?: Maybe<Scalars['String']>;
};

/** Things that can be Metatag'ed */
export enum MetatagScopeType {
  /** article */
  Article = 'ARTICLE',
  /** author */
  Author = 'AUTHOR',
  /** page */
  Page = 'PAGE',
  /** template */
  Template = 'TEMPLATE',
  /** top_story_list */
  TopStoryList = 'TOP_STORY_LIST'
}

/** Category of metatag */
export enum MetatagType {
  /** single_select */
  SingleSelect = 'SINGLE_SELECT',
  /** multi_select */
  MultiSelect = 'MULTI_SELECT',
  /** keywords */
  Keywords = 'KEYWORDS',
  /** thumbnail */
  Thumbnail = 'THUMBNAIL',
  /** avatar */
  Avatar = 'AVATAR',
  /** text */
  Text = 'TEXT'
}

/** Autogenerated return type of MetatagUpdateMutation */
export type MetatagUpdateMutationPayload = {
  __typename?: 'MetatagUpdateMutationPayload';
  errors: Array<Error>;
  metatag?: Maybe<Metatag>;
};

/** This is the entry point for all mutations */
export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Associate collection with ExportFormat
   * @deprecated Never implemented client side
   */
  addCollectionExportFormat?: Maybe<ExportFormat>;
  /** Send email to advertise@cheddar with contact information */
  advertise?: Maybe<Scalars['Boolean']>;
  /** Creates an app */
  appCreate?: Maybe<AppCreateMutationPayload>;
  /** Deletes an app */
  appDelete?: Maybe<AppDeleteMutationPayload>;
  /** Updates an app */
  appUpdate?: Maybe<AppUpdateMutationPayload>;
  /** Create an article */
  articleCreate?: Maybe<ArticleCreateMutationPayload>;
  /** Delete an article */
  articleDelete?: Maybe<ArticleDeleteMutationPayload>;
  /** Updates an article */
  articleUpdate?: Maybe<ArticleUpdateMutationPayload>;
  /** Authenticates by creating a new device record and generating an auth token */
  associate?: Maybe<DeviceToken>;
  /** Creates an author */
  authorCreate?: Maybe<AuthorCreateMutationPayload>;
  /** Deletes an author */
  authorDelete?: Maybe<AuthorDeleteMutationPayload>;
  /** Updates an author */
  authorUpdate?: Maybe<AuthorUpdateMutationPayload>;
  /** Create a collection */
  collectionCreate?: Maybe<CollectionCreateMutationPayload>;
  /** Updates a collection */
  collectionUpdate?: Maybe<CollectionUpdateMutationPayload>;
  /**
   * Confirms a users email address
   * @deprecated Legacy functionality. Now invites are sent through Loop
   */
  confirmEmail?: Maybe<Scalars['Boolean']>;
  /** Creates an asset and returns the asset object if successful */
  createAsset?: Maybe<Asset>;
  /** Creates auth associated with device */
  createAuth?: Maybe<Authorization>;
  /** Associate partner with a feed template */
  createExportFormat?: Maybe<ExportFormat>;
  /**
   * Associate a collection, template, and partner together into a legacy_feed
   * @deprecated Being phased out - stay tuned!
   */
  createFeed?: Maybe<LegacyFeed>;
  /** Creates LegacyCollection */
  createLegacyCollection?: Maybe<LegacyCollection>;
  /** Creates a LiveChannel */
  createLiveChannel?: Maybe<LiveChannel>;
  /** Creates a Partner */
  createPartner?: Maybe<Partner>;
  /** Creates a new promotion of a video */
  createPromotion?: Maybe<Promotion>;
  /** Creates a Show */
  createShow?: Maybe<Show>;
  /** Creates subscription for a user */
  createSubscription?: Maybe<Subscription>;
  /** Creates Video */
  createVideo?: Maybe<Video>;
  /** Creates a WhitelistedDomain */
  createWhitelistedDomain?: Maybe<WhitelistedDomain>;
  /** Dissociate partner from a feed template */
  deleteExportFormat?: Maybe<ExportFormat>;
  /**
   * Revoke access to a legacy_feed of content for a particular partner
   * @deprecated Being phased out - stay tuned!
   */
  deleteFeed?: Maybe<Scalars['Boolean']>;
  /** Delete LegacyCollection returns whether deletion is successful */
  deleteLegacyCollection?: Maybe<Scalars['Boolean']>;
  /** Delete an existing LiveChannel */
  deleteLiveChannel?: Maybe<Scalars['Boolean']>;
  /** Deletes a Partner */
  deletePartner?: Maybe<Scalars['Boolean']>;
  /** Delete Show returns whether deletion is successful */
  deleteShow?: Maybe<Scalars['Boolean']>;
  /** Delete Video returns whether deletion is successful */
  deleteVideo?: Maybe<Scalars['Boolean']>;
  /** Deletes a WhitelistedDomain */
  deleteWhitelistedDomain?: Maybe<Scalars['Boolean']>;
  /** Logs out a user from the device */
  deviceLogout?: Maybe<DeviceLogoutMutationPayload>;
  /** Check or update a device's provider auth */
  deviceProviderAuthHasAccess?: Maybe<Scalars['Boolean']>;
  /** Log out a device's provider auth */
  deviceProviderAuthLogout?: Maybe<Scalars['Boolean']>;
  /** Deletes a draft */
  draftDelete?: Maybe<DraftDeleteMutationPayload>;
  /** Acquire editing permission on the current draft */
  draftEdit?: Maybe<DraftEditMutationPayload>;
  /** Publishes a draft */
  draftPublish?: Maybe<DraftPublishMutationPayload>;
  /** Updates a draft to reflect a change in administrators */
  draftUpdate?: Maybe<Draft>;
  /** Creates a new RSS, JSON, etc. feed template */
  feedTemplateCreate?: Maybe<FeedTemplateCreateMutationPayload>;
  /** Updates an existing feed template */
  feedTemplateUpdate?: Maybe<FeedTemplateUpdateMutationPayload>;
  /** Creates a LiveStream */
  liveStreamCreate?: Maybe<LiveStreamCreateMutationPayload>;
  /** Associates a user to his/her current device token using email address and password */
  login?: Maybe<User>;
  /**
   * Logs out a user from the device
   * @deprecated Deprecated in favor of deviceLogout
   */
  logout?: Maybe<Scalars['Boolean']>;
  /** Creates a metatag */
  metatagCreate?: Maybe<MetatagCreateMutationPayload>;
  /** Deletes a metatag */
  metatagDelete?: Maybe<MetatagDeleteMutationPayload>;
  /** Updates a metatag */
  metatagUpdate?: Maybe<MetatagUpdateMutationPayload>;
  /** Validates Oauth Token and creates a new device token associated with the user */
  oAuthToken?: Maybe<AuthOauthAccessTokenMutationPayload>;
  /** Associates a user to his/her current device token using a valid OAuth token */
  oauth?: Maybe<User>;
  /** Creates an organization */
  organizationCreate?: Maybe<OrganizationCreateMutationPayload>;
  /** Updates an organization */
  organizationUpdate?: Maybe<OrganizationUpdateMutationPayload>;
  /** Creates a page */
  pageCreate?: Maybe<PageCreateMutationPayload>;
  /** Updates a page */
  pageUpdate?: Maybe<PageUpdateMutationPayload>;
  /** Updates play count */
  played?: Maybe<Scalars['Int']>;
  /**
   * Creates a new user record. A newly created user will have a trial subscription period that lasts 30 days
   * @deprecated No longer used. Users invited in Loop
   */
  register?: Maybe<User>;
  /**
   * Delete collection from ExportFormat
   * @deprecated Never implemented client side
   */
  removeCollectionExportFormat?: Maybe<ExportFormat>;
  /** Request a user's password to be reset and validate a reset token */
  requestPasswordReset?: Maybe<Scalars['Boolean']>;
  /**
   * Request Email confirmation to be resent
   * @deprecated Use new invite system
   */
  resendConfirmationEmail?: Maybe<Scalars['Boolean']>;
  /** Request a user's password to be reset and validate a reset token */
  resetPassword?: Maybe<UserResetPasswordMutationPayload>;
  /** Marks a video as saved for the current user */
  saveVideo?: Maybe<Video>;
  /** Send transaction to validate/save */
  sendTransaction?: Maybe<Transaction>;
  /** Publishes a message to nsqd with an email address and list name */
  subscribe?: Maybe<Scalars['Boolean']>;
  /** Creates a template */
  templateCreate?: Maybe<TemplateCreateMutationPayload>;
  /** Updates a template */
  templateUpdate?: Maybe<TemplateUpdateMutationPayload>;
  /** Creates a list of top stories */
  topStoryListCreate?: Maybe<TopStoryListCreateMutationPayload>;
  /** Deletes a list of top stories */
  topStoryListDelete?: Maybe<TopStoryListDeleteMutationPayload>;
  /** Updates a list of top stories */
  topStoryListUpdate?: Maybe<TopStoryListUpdateMutationPayload>;
  /** Marks a video as not saved for the current user */
  unsaveVideo?: Maybe<Video>;
  /** Updates an asset */
  updateAsset?: Maybe<Asset>;
  /** Update device version of a Device */
  updateDeviceVersion?: Maybe<DeviceToken>;
  /** Favorites or Unfavorites a Show for a User */
  updateFavoriteShow?: Maybe<Show>;
  /** Updates LegacyCollection */
  updateLegacyCollection?: Maybe<LegacyCollection>;
  /** Updates an existing LiveChannel */
  updateLiveChannel?: Maybe<LiveChannel>;
  /** Updates Page */
  updatePage?: Maybe<LegacyPage>;
  /** Updates a Partner */
  updatePartner?: Maybe<Partner>;
  /** Updates a users payment info */
  updatePayment?: Maybe<Scalars['Boolean']>;
  /** Update an existing promotion of a video (change expiration date, level, etc) */
  updatePromotion?: Maybe<Promotion>;
  /** Updates a Show */
  updateShow?: Maybe<Show>;
  /** Updates a subscription for a user */
  updateSubscription?: Maybe<Subscription>;
  /** Updates Video */
  updateVideo?: Maybe<Video>;
  /** Updates a WhitelistedDomain */
  updateWhitelistedDomain?: Maybe<WhitelistedDomain>;
  /** Creates an author from user information or makes author inactive */
  userAuthorToggle?: Maybe<UserAuthorToggleMutationPayload>;
  /** Soft-deletes a user */
  userDelete?: Maybe<UserDeleteMutationPayload>;
  /** Invite a user to join Loop */
  userInvite?: Maybe<UserInviteMutationPayload>;
  /** Marks media as saved for the current user */
  userSaveMedia?: Maybe<Slugable>;
  /** Marks media as not saved for the current user */
  userUnsaveMedia?: Maybe<Slugable>;
  /** Updates a user */
  userUpdate?: Maybe<UserUpdateMutationPayload>;
  /** Creates a VideoFile */
  videoFileCreate?: Maybe<VideoFileCreateMutationPayload>;
};


/** This is the entry point for all mutations */
export type MutationAddCollectionExportFormatArgs = {
  id: Scalars['ID'];
  legacyCollection_id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationAdvertiseArgs = {
  input: AdvertiseInput;
};


/** This is the entry point for all mutations */
export type MutationAppCreateArgs = {
  input: AppInput;
};


/** This is the entry point for all mutations */
export type MutationAppDeleteArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationAppUpdateArgs = {
  id: Scalars['ID'];
  input: AppInput;
};


/** This is the entry point for all mutations */
export type MutationArticleCreateArgs = {
  organizationId?: Maybe<Scalars['ID']>;
};


/** This is the entry point for all mutations */
export type MutationArticleDeleteArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationArticleUpdateArgs = {
  draftId: Scalars['ID'];
  input: ArticleInput;
};


/** This is the entry point for all mutations */
export type MutationAssociateArgs = {
  platform: Platform;
  version: Scalars['String'];
  token?: Maybe<Scalars['String']>;
};


/** This is the entry point for all mutations */
export type MutationAuthorCreateArgs = {
  input: AuthorInput;
  organizationId: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationAuthorDeleteArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationAuthorUpdateArgs = {
  id: Scalars['ID'];
  input: AuthorInput;
};


/** This is the entry point for all mutations */
export type MutationCollectionCreateArgs = {
  organizationId?: Maybe<Scalars['ID']>;
};


/** This is the entry point for all mutations */
export type MutationCollectionUpdateArgs = {
  draftId: Scalars['ID'];
  input: CollectionInput;
};


/** This is the entry point for all mutations */
export type MutationConfirmEmailArgs = {
  token: Scalars['String'];
};


/** This is the entry point for all mutations */
export type MutationCreateAssetArgs = {
  input: AssetInput;
};


/** This is the entry point for all mutations */
export type MutationCreateExportFormatArgs = {
  input: ExportFormatInput;
};


/** This is the entry point for all mutations */
export type MutationCreateFeedArgs = {
  input: FeedInput;
};


/** This is the entry point for all mutations */
export type MutationCreateLegacyCollectionArgs = {
  input: LegacyCollectionInput;
};


/** This is the entry point for all mutations */
export type MutationCreateLiveChannelArgs = {
  input: LiveChannelInput;
};


/** This is the entry point for all mutations */
export type MutationCreatePartnerArgs = {
  input: PartnerInput;
  organizationId?: Maybe<Scalars['ID']>;
};


/** This is the entry point for all mutations */
export type MutationCreatePromotionArgs = {
  input: PromotionInput;
};


/** This is the entry point for all mutations */
export type MutationCreateShowArgs = {
  input: ShowInput;
  organizationId?: Maybe<Scalars['ID']>;
};


/** This is the entry point for all mutations */
export type MutationCreateSubscriptionArgs = {
  input?: Maybe<SubscriptionInput>;
};


/** This is the entry point for all mutations */
export type MutationCreateVideoArgs = {
  input: VideoInput;
  organizationId?: Maybe<Scalars['ID']>;
};


/** This is the entry point for all mutations */
export type MutationCreateWhitelistedDomainArgs = {
  input: WhitelistedDomainInput;
};


/** This is the entry point for all mutations */
export type MutationDeleteExportFormatArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationDeleteFeedArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationDeleteLegacyCollectionArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationDeleteLiveChannelArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationDeletePartnerArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationDeleteShowArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationDeleteVideoArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationDeleteWhitelistedDomainArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationDraftDeleteArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationDraftEditArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationDraftPublishArgs = {
  id: Scalars['ID'];
  minorEdit?: Maybe<Scalars['Boolean']>;
};


/** This is the entry point for all mutations */
export type MutationDraftUpdateArgs = {
  id: Scalars['ID'];
  input: DraftInput;
};


/** This is the entry point for all mutations */
export type MutationFeedTemplateCreateArgs = {
  input: FeedTemplateInput;
};


/** This is the entry point for all mutations */
export type MutationFeedTemplateUpdateArgs = {
  id: Scalars['ID'];
  input: FeedTemplateInput;
};


/** This is the entry point for all mutations */
export type MutationLiveStreamCreateArgs = {
  input: LiveStreamInput;
};


/** This is the entry point for all mutations */
export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


/** This is the entry point for all mutations */
export type MutationMetatagCreateArgs = {
  input: MetatagInput;
  organizationId: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationMetatagDeleteArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationMetatagUpdateArgs = {
  id: Scalars['ID'];
  input: MetatagInput;
};


/** This is the entry point for all mutations */
export type MutationOAuthTokenArgs = {
  accessToken: Scalars['String'];
  platform: Platform;
  version: Scalars['String'];
};


/** This is the entry point for all mutations */
export type MutationOauthArgs = {
  code: Scalars['String'];
  redirect_uri: Scalars['String'];
};


/** This is the entry point for all mutations */
export type MutationOrganizationCreateArgs = {
  input: OrganizationInput;
};


/** This is the entry point for all mutations */
export type MutationOrganizationUpdateArgs = {
  id: Scalars['ID'];
  input: OrganizationInput;
};


/** This is the entry point for all mutations */
export type MutationPageCreateArgs = {
  organizationId?: Maybe<Scalars['ID']>;
};


/** This is the entry point for all mutations */
export type MutationPageUpdateArgs = {
  draftId: Scalars['ID'];
  input: PageInput;
};


/** This is the entry point for all mutations */
export type MutationPlayedArgs = {
  slug: Scalars['String'];
};


/** This is the entry point for all mutations */
export type MutationRegisterArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  marketing_optin?: Maybe<Scalars['Boolean']>;
};


/** This is the entry point for all mutations */
export type MutationRemoveCollectionExportFormatArgs = {
  id: Scalars['ID'];
  legacyCollection_id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationRequestPasswordResetArgs = {
  email: Scalars['String'];
};


/** This is the entry point for all mutations */
export type MutationResetPasswordArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
};


/** This is the entry point for all mutations */
export type MutationSaveVideoArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationSendTransactionArgs = {
  transaction_id: Scalars['String'];
  transaction_type?: Maybe<Scalars['String']>;
  source: SubscriptionSource;
};


/** This is the entry point for all mutations */
export type MutationSubscribeArgs = {
  email_address: Scalars['String'];
  newsletters: Array<Maybe<SubscribableNewsletters>>;
};


/** This is the entry point for all mutations */
export type MutationTemplateCreateArgs = {
  organizationId?: Maybe<Scalars['ID']>;
};


/** This is the entry point for all mutations */
export type MutationTemplateUpdateArgs = {
  draftId: Scalars['ID'];
  input: TemplateInput;
};


/** This is the entry point for all mutations */
export type MutationTopStoryListCreateArgs = {
  input: TopStoryListInput;
  organizationId: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationTopStoryListDeleteArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationTopStoryListUpdateArgs = {
  id: Scalars['ID'];
  input: TopStoryListInput;
};


/** This is the entry point for all mutations */
export type MutationUnsaveVideoArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationUpdateAssetArgs = {
  id: Scalars['ID'];
  input: AssetInput;
};


/** This is the entry point for all mutations */
export type MutationUpdateDeviceVersionArgs = {
  version: Scalars['String'];
};


/** This is the entry point for all mutations */
export type MutationUpdateFavoriteShowArgs = {
  id: Scalars['ID'];
  set_favorite: Scalars['Boolean'];
};


/** This is the entry point for all mutations */
export type MutationUpdateLegacyCollectionArgs = {
  id: Scalars['ID'];
  input: LegacyCollectionInput;
};


/** This is the entry point for all mutations */
export type MutationUpdateLiveChannelArgs = {
  id: Scalars['ID'];
  input: LiveChannelInput;
};


/** This is the entry point for all mutations */
export type MutationUpdatePageArgs = {
  id: Scalars['ID'];
  input?: Maybe<LegacyPageInput>;
};


/** This is the entry point for all mutations */
export type MutationUpdatePartnerArgs = {
  id: Scalars['ID'];
  input: PartnerInput;
};


/** This is the entry point for all mutations */
export type MutationUpdatePaymentArgs = {
  input: PaymentInput;
  subscription_id: Scalars['ConvertedID'];
};


/** This is the entry point for all mutations */
export type MutationUpdatePromotionArgs = {
  id: Scalars['ID'];
  input: PromotionInput;
};


/** This is the entry point for all mutations */
export type MutationUpdateShowArgs = {
  id: Scalars['ID'];
  input: ShowInput;
};


/** This is the entry point for all mutations */
export type MutationUpdateSubscriptionArgs = {
  input: SubscriptionInput;
};


/** This is the entry point for all mutations */
export type MutationUpdateVideoArgs = {
  id: Scalars['ID'];
  input: VideoInput;
};


/** This is the entry point for all mutations */
export type MutationUpdateWhitelistedDomainArgs = {
  id: Scalars['ID'];
  input: WhitelistedDomainInput;
};


/** This is the entry point for all mutations */
export type MutationUserAuthorToggleArgs = {
  id: Scalars['ID'];
  isAuthor: Scalars['Boolean'];
  slug?: Maybe<Scalars['String']>;
};


/** This is the entry point for all mutations */
export type MutationUserDeleteArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationUserInviteArgs = {
  input: UserInviteInput;
  organizationId: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationUserSaveMediaArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationUserUnsaveMediaArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all mutations */
export type MutationUserUpdateArgs = {
  id: Scalars['ID'];
  input: UserInput;
};


/** This is the entry point for all mutations */
export type MutationVideoFileCreateArgs = {
  organizationId?: Maybe<Scalars['ID']>;
  input: VideoFileInput;
};

/** Our newsletter fetched from the newsletter service */
export type NewsletterService = {
  __typename?: 'NewsletterService';
  body: Scalars['String'];
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID'];
};

/** Top level of hierarchy to group content (e.g. News12, Cheddar, i24, etc...) */
export type Organization = Node & {
  __typename?: 'Organization';
  articles: ArticleConnection;
  assignableAuthors?: Maybe<Array<Author>>;
  author?: Maybe<Author>;
  authors?: Maybe<AuthorConnection>;
  collections: CollectionConnection;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  media?: Maybe<SlugableInterfaceWithHitCountConnection>;
  mediaUploadUrl?: Maybe<Scalars['String']>;
  metatags?: Maybe<Array<Metatag>>;
  name: Scalars['String'];
  pages: PageConnection;
  parent?: Maybe<Organization>;
  providerAuthentication: ProviderAuthentication;
  providers: Array<Provider>;
  settings: OrganizationSettings;
  slug: Scalars['String'];
  slugable?: Maybe<Slugable>;
  templates: TemplateConnection;
  topStoryLists?: Maybe<TopStoryListConnection>;
  trendingMedia?: Maybe<SlugableConnection>;
  users?: Maybe<UserConnection>;
  videoFiles?: Maybe<VideoFileConnection>;
  weather: Weather;
};


/** Top level of hierarchy to group content (e.g. News12, Cheddar, i24, etc...) */
export type OrganizationArticlesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
};


/** Top level of hierarchy to group content (e.g. News12, Cheddar, i24, etc...) */
export type OrganizationAssignableAuthorsArgs = {
  query?: Maybe<Scalars['String']>;
};


/** Top level of hierarchy to group content (e.g. News12, Cheddar, i24, etc...) */
export type OrganizationAuthorArgs = {
  slug: Scalars['String'];
};


/** Top level of hierarchy to group content (e.g. News12, Cheddar, i24, etc...) */
export type OrganizationAuthorsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  active?: Maybe<Scalars['Boolean']>;
  query?: Maybe<Scalars['String']>;
};


/** Top level of hierarchy to group content (e.g. News12, Cheddar, i24, etc...) */
export type OrganizationCollectionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
};


/** Top level of hierarchy to group content (e.g. News12, Cheddar, i24, etc...) */
export type OrganizationMediaArgs = {
  categories?: Maybe<Array<Maybe<Category>>>;
  include_private?: Maybe<Scalars['Boolean']>;
  include_unpublished?: Maybe<Scalars['Boolean']>;
  isolated?: Maybe<Scalars['Boolean']>;
  max_age?: Maybe<Scalars['Int']>;
  mediaType?: Maybe<MediaType>;
  query?: Maybe<Scalars['String']>;
  recency_days?: Maybe<Scalars['Int']>;
  recency_weight?: Maybe<Scalars['Float']>;
  sort?: Maybe<SortInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Top level of hierarchy to group content (e.g. News12, Cheddar, i24, etc...) */
export type OrganizationMediaUploadUrlArgs = {
  extension: Scalars['String'];
  final?: Maybe<Scalars['Boolean']>;
  mimeType: Scalars['String'];
  suffix?: Maybe<Scalars['String']>;
};


/** Top level of hierarchy to group content (e.g. News12, Cheddar, i24, etc...) */
export type OrganizationPagesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
};


/** Top level of hierarchy to group content (e.g. News12, Cheddar, i24, etc...) */
export type OrganizationSlugableArgs = {
  slug: Scalars['String'];
};


/** Top level of hierarchy to group content (e.g. News12, Cheddar, i24, etc...) */
export type OrganizationTemplatesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  type?: Maybe<TemplatableType>;
};


/** Top level of hierarchy to group content (e.g. News12, Cheddar, i24, etc...) */
export type OrganizationTopStoryListsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
};


/** Top level of hierarchy to group content (e.g. News12, Cheddar, i24, etc...) */
export type OrganizationTrendingMediaArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  hours?: Maybe<Scalars['Int']>;
};


/** Top level of hierarchy to group content (e.g. News12, Cheddar, i24, etc...) */
export type OrganizationUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
};


/** Top level of hierarchy to group content (e.g. News12, Cheddar, i24, etc...) */
export type OrganizationVideoFilesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
};


/** Top level of hierarchy to group content (e.g. News12, Cheddar, i24, etc...) */
export type OrganizationWeatherArgs = {
  zip: Scalars['String'];
};

/** Autogenerated return type of OrganizationCreateMutation */
export type OrganizationCreateMutationPayload = {
  __typename?: 'OrganizationCreateMutationPayload';
  errors: Array<Error>;
  organization: Organization;
};

export type OrganizationInput = {
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ConvertedID']>;
  settings?: Maybe<OrganizationSettingsInput>;
  slug?: Maybe<Scalars['String']>;
};

/** GraphQL Endpoint for Organization Settings */
export type OrganizationSettings = {
  __typename?: 'OrganizationSettings';
  live?: Maybe<LiveSettings>;
  synacor?: Maybe<SynacorSettings>;
  usesOauth?: Maybe<Scalars['Boolean']>;
};

export type OrganizationSettingsInput = {
  synacor?: Maybe<SynacorSettingsInput>;
  live?: Maybe<LiveSettingsInput>;
};

/** Autogenerated return type of OrganizationUpdateMutation */
export type OrganizationUpdateMutationPayload = {
  __typename?: 'OrganizationUpdateMutationPayload';
  errors: Array<Error>;
  organization: Organization;
};

/** A Page */
export type Page = Draftable & Node & Renderable & Shareable & Slugable & {
  __typename?: 'Page';
  _score?: Maybe<Scalars['Float']>;
  body?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Category>>;
  content: Array<PageContent>;
  createdAt: Scalars['String'];
  css?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  drafts: Array<Draft>;
  fullHtml: Scalars['String'];
  hero_video?: Maybe<HeroVideo>;
  id: Scalars['ID'];
  isTemplatable: Scalars['Boolean'];
  is_saved?: Maybe<Scalars['Boolean']>;
  lastPublishedAt?: Maybe<Scalars['String']>;
  latestDraft: Draft;
  legacyTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  originalPublishedAt: Scalars['String'];
  parsed_html?: Maybe<Scalars['String']>;
  public_at?: Maybe<Scalars['DateTime']>;
  public_url?: Maybe<Scalars['String']>;
  publishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  related_media?: Maybe<SlugableConnection>;
  selectableTemplates: Array<Template>;
  slug: Scalars['String'];
  template?: Maybe<Template>;
  templateVersionId?: Maybe<Scalars['ID']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  thumbnail_attribution?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  thumbnails?: Maybe<Thumbnail>;
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
  version?: Maybe<Scalars['Int']>;
};


/** A Page */
export type PageRelated_MediaArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A Page */
export type PageThumbnailsArgs = {
  aspect_ratio?: Maybe<AspectRatio>;
};

/** The connection type for Page. */
export type PageConnection = {
  __typename?: 'PageConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PageEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Page>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** A piece of content within a Draftable Page */
export type PageContent = {
  __typename?: 'PageContent';
  source: Scalars['String'];
  value: Scalars['String'];
};

export type PageContentInput = {
  source: Scalars['String'];
  value: Scalars['String'];
};

/** Autogenerated return type of PageCreateMutation */
export type PageCreateMutationPayload = {
  __typename?: 'PageCreateMutationPayload';
  draft: Draft;
  errors: Scalars['String'];
};

/** An edge in a connection. */
export type PageEdge = {
  __typename?: 'PageEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Page>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type PageInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  templateVersionId?: Maybe<Scalars['ConvertedID']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  content?: Maybe<Array<PageContentInput>>;
};

/** Autogenerated return type of PageUpdateMutation */
export type PageUpdateMutationPayload = {
  __typename?: 'PageUpdateMutationPayload';
  draft: Draft;
  draftable: Draftable;
  errors: Array<Error>;
  warnings: Array<Error>;
};

/** A partner with access to a livestream and/or RSS feeds */
export type Partner = Node & {
  __typename?: 'Partner';
  adtag?: Maybe<Scalars['String']>;
  created_at: Scalars['DateTime'];
  custom_rss?: Maybe<Scalars['String']>;
  /** @deprecated The `db_id` field is being eliminated and instead `id` arguments will convert node IDs to database IDs transparently */
  db_id?: Maybe<Scalars['Int']>;
  domains?: Maybe<Array<Maybe<Scalars['String']>>>;
  export_formats?: Maybe<Array<Maybe<ExportFormat>>>;
  feeds?: Maybe<Array<Maybe<LegacyFeed>>>;
  full_stream: Scalars['Boolean'];
  id: Scalars['ID'];
  key: Scalars['String'];
  liveStreams?: Maybe<Array<Maybe<LiveStream>>>;
  name?: Maybe<Scalars['String']>;
  /** @deprecated The `node_id` field is being eliminated */
  node_id?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  slug: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type PartnerInput = {
  adtag?: Maybe<Scalars['String']>;
  full_stream?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  custom_rss?: Maybe<Scalars['String']>;
};

export type PaymentInput = {
  source?: Maybe<SubscriptionSource>;
  /** An identifier for this Subscription to be used when interacting with external APIs */
  payment_source_id?: Maybe<Scalars['String']>;
  /** An identifier pointing to a payment token (stripe only) */
  payment_token?: Maybe<Scalars['String']>;
};

/** Allows you to ask questions about what operations the current user can perform */
export type Permissible = {
  canDelete: Scalars['Boolean'];
  canEdit: Scalars['Boolean'];
  canPublish: Scalars['Boolean'];
  canUpdate: Scalars['Boolean'];
};

/** Subscription plan (monthly, yearly, etc) */
export enum Plan {
  /** Monthly */
  Monthly = 'MONTHLY',
  /** Yearly */
  Yearly = 'YEARLY',
  /** Open */
  Open = 'OPEN'
}

/** The platform a user subscribed on, is viewing from, etc. */
export enum Platform {
  /** iOS */
  Ios = 'IOS',
  /** tvOS */
  Tvos = 'TVOS',
  /** Web */
  Web = 'WEB',
  /** Android */
  Android = 'ANDROID',
  /** Roku */
  Roku = 'ROKU',
  /** Directv */
  Directv = 'DIRECTV',
  /** Samsungtv */
  Samsungtv = 'SAMSUNGTV',
  /** Cms */
  Cms = 'CMS',
  /** Loop */
  Loop = 'LOOP',
  /** Crunch */
  Crunch = 'CRUNCH'
}

/** An intent by someone with the promoter role to promote content */
export type Promotion = Node & {
  __typename?: 'Promotion';
  created_at: Scalars['DateTime'];
  /** @deprecated The `db_id` field is being eliminated and instead `id` arguments will convert node IDs to database IDs transparently */
  db_id?: Maybe<Scalars['Int']>;
  expires_at: Scalars['DateTime'];
  id: Scalars['ID'];
  level: PromotionLevel;
  /** @deprecated The `node_id` field is being eliminated */
  node_id?: Maybe<Scalars['ID']>;
  tagline: Scalars['String'];
  updated_at: Scalars['DateTime'];
  video: Video;
};

/** The connection type for Promotion. */
export type PromotionConnection = {
  __typename?: 'PromotionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PromotionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Promotion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PromotionEdge = {
  __typename?: 'PromotionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Promotion>;
};

export type PromotionInput = {
  expires_at?: Maybe<Scalars['String']>;
  level?: Maybe<PromotionLevel>;
  video_id?: Maybe<Scalars['ID']>;
  tagline?: Maybe<Scalars['String']>;
};

/** At what level a video is being promoted (level represents how heavily it will be pushed) */
export enum PromotionLevel {
  /** low */
  Low = 'LOW',
  /** medium */
  Medium = 'MEDIUM',
  /** high */
  High = 'HIGH'
}

export type Provider = {
  __typename?: 'Provider';
  logo: Scalars['String'];
  name: Scalars['String'];
};

/** Authentication record from remote tv provider. */
export type ProviderAuthentication = Node & {
  __typename?: 'ProviderAuthentication';
  /** @deprecated What is the deal with this all-caps field? */
  ID: Scalars['ID'];
  expires_at?: Maybe<Scalars['DateTime']>;
  has_access?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  liveChannels: Array<LiveChannel>;
  provider?: Maybe<Scalars['String']>;
  providers: Array<Provider>;
};

export type ProviderLogo = {
  __typename?: 'ProviderLogo';
  logo: Scalars['String'];
  name: Scalars['String'];
};

/** Indicates the publication status of a Draftable */
export enum PublicationStatus {
  /** Draft */
  Draft = 'DRAFT',
  /** Published */
  Published = 'PUBLISHED',
  /** Scheduled */
  Scheduled = 'SCHEDULED'
}

/** This is the entry point for all queries */
export type Query = {
  __typename?: 'Query';
  all_newsletters: Array<Maybe<Scalars['String']>>;
  apps?: Maybe<Array<Maybe<App>>>;
  /** @deprecated Use Organization as entry point to graph */
  articles: ArticleConnection;
  /** check for existing checksum or return nil */
  asset?: Maybe<Asset>;
  assets?: Maybe<AssetConnection>;
  /** @deprecated Use Organization as entry point to graph */
  assignableAuthors?: Maybe<Array<Author>>;
  /** @deprecated Use Organization as entry point to graph */
  author?: Maybe<Author>;
  /** See the programming schedule for each channel */
  channel_schedules?: Maybe<Array<Maybe<ChannelSchedule>>>;
  /**
   * Check on the validity and properties of a device token
   * @deprecated Deprecated in favor of query { device }
   */
  checkDevice?: Maybe<DeviceToken>;
  /** Retrieve the current list of service configurations */
  configuration?: Maybe<Configuration>;
  /** Return data about the current device */
  device?: Maybe<DeviceToken>;
  /** Return data about the current device or create a device */
  deviceSafe: DeviceToken;
  embed?: Maybe<Scalars['String']>;
  /** Fetch a ExportFormat by slug */
  exportFormat?: Maybe<ExportFormat>;
  exportFormats?: Maybe<Array<Maybe<ExportFormat>>>;
  /** Fetch a feature flag by name */
  featureFlag?: Maybe<FeatureFlag>;
  feedTemplates: Array<FeedTemplate>;
  /** @deprecated Use Organization#mediaUploadUrl instead */
  getSignedPost?: Maybe<Scalars['String']>;
  /** Get the attributes for the legacy collection */
  legacyCollection?: Maybe<LegacyCollection>;
  legacyCollections?: Maybe<Array<Maybe<LegacyCollection>>>;
  legacyPage?: Maybe<LegacyPage>;
  legacyPages: Array<LegacyPage>;
  liveChannels?: Maybe<Array<Maybe<LiveChannel>>>;
  /** @deprecated Use Organization as entry point to graph */
  media?: Maybe<SlugableConnection>;
  /** @deprecated Use Organization as entry point to graph */
  mediaWithHitCount?: Maybe<SlugableInterfaceWithHitCountConnection>;
  newsletter_service?: Maybe<NewsletterService>;
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** An entry point into the content of an organization */
  organization: Organization;
  organizations?: Maybe<Array<Maybe<Organization>>>;
  page?: Maybe<Page>;
  /** @deprecated Use Organization as entry point to graph */
  pages: Array<Page>;
  /** Manage partner keys, feeds, etc */
  partners?: Maybe<Array<Maybe<Partner>>>;
  promotions?: Maybe<PromotionConnection>;
  providerLogos?: Maybe<Array<Maybe<ProviderLogo>>>;
  renderable?: Maybe<Renderable>;
  /** Retrieve user settings */
  settings?: Maybe<Settings>;
  show?: Maybe<Show>;
  shows?: Maybe<Array<Maybe<Show>>>;
  /** @deprecated Use Organization as entry point to graph */
  slugable?: Maybe<Slugable>;
  suggestedTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated Use Organization as entry point to graph */
  templates: Array<Template>;
  /** @deprecated Use Organization as entry point to graph */
  trending_media?: Maybe<SlugableConnection>;
  uploadUrl: UploadUrl;
  /** Get information about a single user (or the currently logged in user if no id is provided) */
  user?: Maybe<User>;
  userCheck?: Maybe<UserCheck>;
  userValidateInvite: UserValidateInvite;
  /** @deprecated Use Organization as entry point to graph */
  users?: Maybe<UserConnection>;
  video?: Maybe<Video>;
  videoFiles: VideoFileConnection;
  videos?: Maybe<VideoConnection>;
  videosWithHitCount?: Maybe<VideoTypeWithHitCountConnection>;
  /** Fetch a whitelistedDomain by domain */
  whitelistedDomain?: Maybe<WhitelistedDomain>;
  whitelistedDomains?: Maybe<WhitelistedDomainConnection>;
};


/** This is the entry point for all queries */
export type QueryArticlesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** This is the entry point for all queries */
export type QueryAssetArgs = {
  checksum: Scalars['String'];
};


/** This is the entry point for all queries */
export type QueryAssetsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
};


/** This is the entry point for all queries */
export type QueryAssignableAuthorsArgs = {
  query?: Maybe<Scalars['String']>;
};


/** This is the entry point for all queries */
export type QueryAuthorArgs = {
  slug: Scalars['String'];
};


/** This is the entry point for all queries */
export type QueryChannel_SchedulesArgs = {
  date?: Maybe<Scalars['String']>;
};


/** This is the entry point for all queries */
export type QueryDeviceSafeArgs = {
  platform: Platform;
  version: Scalars['String'];
};


/** This is the entry point for all queries */
export type QueryEmbedArgs = {
  url: Scalars['String'];
};


/** This is the entry point for all queries */
export type QueryExportFormatArgs = {
  id?: Maybe<Scalars['String']>;
  node_id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};


/** This is the entry point for all queries */
export type QueryFeatureFlagArgs = {
  name: Scalars['String'];
};


/** This is the entry point for all queries */
export type QueryGetSignedPostArgs = {
  extension: Scalars['String'];
  mime_type: Scalars['String'];
  suffix?: Maybe<Scalars['String']>;
};


/** This is the entry point for all queries */
export type QueryLegacyCollectionArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all queries */
export type QueryLegacyPageArgs = {
  slug: Scalars['String'];
};


/** This is the entry point for all queries */
export type QueryMediaArgs = {
  categories?: Maybe<Array<Maybe<Category>>>;
  include_private?: Maybe<Scalars['Boolean']>;
  include_unpublished?: Maybe<Scalars['Boolean']>;
  isolated?: Maybe<Scalars['Boolean']>;
  max_age?: Maybe<Scalars['Int']>;
  mediaType?: Maybe<MediaType>;
  query?: Maybe<Scalars['String']>;
  recency_days?: Maybe<Scalars['Int']>;
  recency_weight?: Maybe<Scalars['Float']>;
  sort?: Maybe<SortInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** This is the entry point for all queries */
export type QueryMediaWithHitCountArgs = {
  categories?: Maybe<Array<Maybe<Category>>>;
  include_private?: Maybe<Scalars['Boolean']>;
  include_unpublished?: Maybe<Scalars['Boolean']>;
  isolated?: Maybe<Scalars['Boolean']>;
  max_age?: Maybe<Scalars['Int']>;
  mediaType?: Maybe<MediaType>;
  query?: Maybe<Scalars['String']>;
  recency_days?: Maybe<Scalars['Int']>;
  recency_weight?: Maybe<Scalars['Float']>;
  sort?: Maybe<SortInput>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** This is the entry point for all queries */
export type QueryNewsletter_ServiceArgs = {
  newsletter: ViewableNewsletters;
};


/** This is the entry point for all queries */
export type QueryNodeArgs = {
  id: Scalars['ID'];
};


/** This is the entry point for all queries */
export type QueryPageArgs = {
  slug: Scalars['String'];
};


/** This is the entry point for all queries */
export type QueryPromotionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** This is the entry point for all queries */
export type QueryRenderableArgs = {
  slug: Scalars['String'];
};


/** This is the entry point for all queries */
export type QueryShowArgs = {
  slug: Scalars['String'];
};


/** This is the entry point for all queries */
export type QuerySlugableArgs = {
  slug: Scalars['String'];
};


/** This is the entry point for all queries */
export type QuerySuggestedTagsArgs = {
  query?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
  exclude?: Maybe<Array<Maybe<Scalars['String']>>>;
  taggable?: Maybe<Taggable>;
};


/** This is the entry point for all queries */
export type QueryTemplatesArgs = {
  type?: Maybe<Scalars['String']>;
};


/** This is the entry point for all queries */
export type QueryTrending_MediaArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  hours?: Maybe<Scalars['Int']>;
};


/** This is the entry point for all queries */
export type QueryUploadUrlArgs = {
  extension: Scalars['String'];
  mime_type: Scalars['String'];
  suffix?: Maybe<Scalars['String']>;
};


/** This is the entry point for all queries */
export type QueryUserArgs = {
  id?: Maybe<Scalars['Int']>;
};


/** This is the entry point for all queries */
export type QueryUserCheckArgs = {
  email: Scalars['String'];
};


/** This is the entry point for all queries */
export type QueryUserValidateInviteArgs = {
  confirmationToken: Scalars['String'];
};


/** This is the entry point for all queries */
export type QueryUsersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
};


/** This is the entry point for all queries */
export type QueryVideoArgs = {
  id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  node_id?: Maybe<Scalars['ID']>;
};


/** This is the entry point for all queries */
export type QueryVideoFilesArgs = {
  query?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** This is the entry point for all queries */
export type QueryVideosArgs = {
  query?: Maybe<Scalars['String']>;
  include_unpublished?: Maybe<Scalars['Boolean']>;
  include_private?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<SortInput>;
  clip?: Maybe<Scalars['Boolean']>;
  free?: Maybe<Scalars['Boolean']>;
  has_transcript?: Maybe<Scalars['Boolean']>;
  isolated?: Maybe<Scalars['Boolean']>;
  max_age?: Maybe<Scalars['Int']>;
  max_duration?: Maybe<Scalars['Float']>;
  min_duration?: Maybe<Scalars['Float']>;
  categories?: Maybe<Array<Maybe<Category>>>;
  recency_weight?: Maybe<Scalars['Float']>;
  recency_days?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** This is the entry point for all queries */
export type QueryVideosWithHitCountArgs = {
  query?: Maybe<Scalars['String']>;
  include_unpublished?: Maybe<Scalars['Boolean']>;
  include_private?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  sort?: Maybe<SortInput>;
  clip?: Maybe<Scalars['Boolean']>;
  free?: Maybe<Scalars['Boolean']>;
  has_transcript?: Maybe<Scalars['Boolean']>;
  isolated?: Maybe<Scalars['Boolean']>;
  max_age?: Maybe<Scalars['Int']>;
  max_duration?: Maybe<Scalars['Float']>;
  min_duration?: Maybe<Scalars['Float']>;
  categories?: Maybe<Array<Maybe<Category>>>;
  recency_weight?: Maybe<Scalars['Float']>;
  recency_days?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** This is the entry point for all queries */
export type QueryWhitelistedDomainArgs = {
  domain: Scalars['String'];
};


/** This is the entry point for all queries */
export type QueryWhitelistedDomainsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
};

/** Any type that can be rendered into a markup language through the use of templates */
export type Renderable = {
  css?: Maybe<Scalars['String']>;
  fullHtml: Scalars['String'];
};

/** Role tier options for a user */
export enum Roles {
  /** cms */
  Cms = 'CMS',
  /** writer */
  Writer = 'WRITER',
  /** editor */
  Editor = 'EDITOR',
  /** admin */
  Admin = 'ADMIN'
}

export type Settings = {
  __typename?: 'Settings';
  cbn_url: Scalars['String'];
  free_cheddar_url: Scalars['String'];
  livestream_url: Scalars['String'];
};

/** Any type that implements Sharable can be shared on social media, etc. */
export type Shareable = {
  description?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

/** A show */
export type Show = Node & {
  __typename?: 'Show';
  background_color: Scalars['String'];
  block_count: Scalars['Int'];
  browseable: Scalars['Boolean'];
  comscore: Scalars['String'];
  days: Array<Maybe<Scalars['Int']>>;
  /** @deprecated The `db_id` field is being eliminated and instead `id` arguments will convert node IDs to database IDs transparently */
  db_id?: Maybe<Scalars['Int']>;
  deletable: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  end: Scalars['Int'];
  foreground_color: Scalars['String'];
  has_segments: Scalars['Boolean'];
  id: Scalars['ID'];
  indexed: Scalars['Boolean'];
  is_favorite?: Maybe<Scalars['Boolean']>;
  isolated: Scalars['Boolean'];
  media?: Maybe<SlugableConnection>;
  /** @deprecated The `node_id` field is being eliminated */
  node_id?: Maybe<Scalars['ID']>;
  original: Scalars['Boolean'];
  slug: Scalars['String'];
  sponsor?: Maybe<Scalars['String']>;
  start: Scalars['Int'];
  subtitle?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  thumbnails?: Maybe<Thumbnail>;
  title: Scalars['String'];
  tms_id?: Maybe<Scalars['String']>;
  unpublished_count: Scalars['Int'];
  unpublished_videos?: Maybe<VideoConnection>;
  videos?: Maybe<VideoConnection>;
};


/** A show */
export type ShowMediaArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A show */
export type ShowThumbnailsArgs = {
  aspect_ratio?: Maybe<AspectRatio>;
};


/** A show */
export type ShowUnpublished_VideosArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A show */
export type ShowVideosArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ShowInput = {
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  days?: Maybe<Array<Maybe<Scalars['Int']>>>;
  start?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  has_segments?: Maybe<Scalars['Boolean']>;
  indexed?: Maybe<Scalars['Boolean']>;
  browseable?: Maybe<Scalars['Boolean']>;
  original?: Maybe<Scalars['Boolean']>;
  background_color?: Maybe<Scalars['String']>;
  foreground_color?: Maybe<Scalars['String']>;
  sponsor?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  tms_id?: Maybe<Scalars['String']>;
};

/** Any type that implements Slugable can be presented in carousels, or on detail pages */
export type Slugable = {
  _score?: Maybe<Scalars['Float']>;
  body?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Category>>;
  description?: Maybe<Scalars['String']>;
  hero_video?: Maybe<HeroVideo>;
  id: Scalars['ID'];
  is_saved?: Maybe<Scalars['Boolean']>;
  legacyTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  parsed_html?: Maybe<Scalars['String']>;
  public_at?: Maybe<Scalars['DateTime']>;
  public_url?: Maybe<Scalars['String']>;
  related_media?: Maybe<SlugableConnection>;
  slug: Scalars['String'];
  template?: Maybe<Template>;
  thumbnail_attribution?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  thumbnails?: Maybe<Thumbnail>;
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['DateTime'];
};


/** Any type that implements Slugable can be presented in carousels, or on detail pages */
export type SlugableRelated_MediaArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Any type that implements Slugable can be presented in carousels, or on detail pages */
export type SlugableThumbnailsArgs = {
  aspect_ratio?: Maybe<AspectRatio>;
};

/** The connection type for Slugable. */
export type SlugableConnection = {
  __typename?: 'SlugableConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SlugableEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Slugable>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SlugableEdge = {
  __typename?: 'SlugableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Slugable>;
};

/** The connection type for Slugable. */
export type SlugableInterfaceWithHitCountConnection = {
  __typename?: 'SlugableInterfaceWithHitCountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SlugableEdge>>>;
  hitCount?: Maybe<Scalars['Int']>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Order attribute */
export enum SortAttribute {
  /** created at */
  CreatedAt = 'CREATED_AT',
  /** published at */
  PublishedAt = 'PUBLISHED_AT',
  /** title */
  Title = 'TITLE',
  /** duration */
  Duration = 'DURATION',
  /** aired at */
  AiredAt = 'AIRED_AT'
}

/** Direction of a sort request */
export enum SortDirection {
  /** ascending */
  Asc = 'ASC',
  /** descending */
  Desc = 'DESC'
}

/** Represents a user's preference for sorting of a response */
export type SortInput = {
  attribute: Scalars['String'];
  direction: SortDirection;
};

/** Newsletters to be subscribed to */
export enum SubscribableNewsletters {
  /** need2know */
  Need2Know = 'NEED2KNOW',
  /** cheddar */
  Cheddar = 'CHEDDAR',
  /** cannabiz */
  Cannabiz = 'CANNABIZ',
  /** need2knowreferral */
  Need2Knowreferral = 'NEED2KNOWREFERRAL'
}

/** A subscription */
export type Subscription = {
  __typename?: 'Subscription';
  active?: Maybe<Scalars['Boolean']>;
  expires_at?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  /** @deprecated Deprecated in favor of payment_source_id */
  original_transaction_id?: Maybe<Scalars['String']>;
  payment_source_id?: Maybe<Scalars['String']>;
  plan?: Maybe<Plan>;
  source?: Maybe<SubscriptionSource>;
  transactions?: Maybe<TransactionConnection>;
  user?: Maybe<User>;
};


/** A subscription */
export type SubscriptionTransactionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Subscription. */
export type SubscriptionConnection = {
  __typename?: 'SubscriptionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SubscriptionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Subscription>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SubscriptionEdge = {
  __typename?: 'SubscriptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Subscription>;
};

export type SubscriptionInput = {
  id?: Maybe<Scalars['ConvertedID']>;
  source?: Maybe<SubscriptionSource>;
  original_transaction_id?: Maybe<Scalars['String']>;
  /** An identifier for this Subscription to be used when interacting with external APIs */
  payment_source_id?: Maybe<Scalars['String']>;
  /** An identifier pointing to a payment token (stripe only) */
  payment_source?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  plan?: Maybe<Plan>;
};

/** Source for subscription */
export enum SubscriptionSource {
  /** app_store */
  AppStore = 'APP_STORE',
  /** play_store */
  PlayStore = 'PLAY_STORE',
  /** roku */
  Roku = 'ROKU',
  /** stripe */
  Stripe = 'STRIPE',
  /** internal */
  Internal = 'INTERNAL'
}

/** GraphQL Endpoint for Organization Settings */
export type SynacorSettings = {
  __typename?: 'SynacorSettings';
  key?: Maybe<Scalars['String']>;
  pickerId?: Maybe<Scalars['String']>;
  pickerKey?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
};

export type SynacorSettingsInput = {
  key?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  pickerKey?: Maybe<Scalars['String']>;
  pickerId?: Maybe<Scalars['String']>;
};

/** A tag that represents metadata or a strict categorization of a Taggable */
export type Tag = {
  __typename?: 'Tag';
  avatar?: Maybe<Avatar>;
  fixedOptions?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  name: Scalars['String'];
  organization: Organization;
  scope_type: Array<Scalars['String']>;
  thumbnails?: Maybe<Thumbnail>;
  type: MetatagType;
  value?: Maybe<Array<Scalars['String']>>;
};

export type TagInput = {
  source: Scalars['ConvertedID'];
  value: Array<Scalars['String']>;
};

/** Taggable Objects */
export enum Taggable {
  /** video */
  Video = 'VIDEO'
}

/** Types of resources that can be templated */
export enum TemplatableType {
  /** page */
  Page = 'PAGE',
  /** article */
  Article = 'ARTICLE'
}

/** A XML-like tree to organize and present dynamic content */
export type Template = Node & Draftable & {
  __typename?: 'Template';
  body?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  css?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  drafts: Array<Draft>;
  id: Scalars['ID'];
  isTemplatable: Scalars['Boolean'];
  lastPublishedAt?: Maybe<Scalars['String']>;
  latestDraft: Draft;
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  originalPublishedAt: Scalars['String'];
  publishedAt?: Maybe<Scalars['ISO8601DateTime']>;
  selectableTemplates: Array<Template>;
  templatableType: TemplatableType;
  template?: Maybe<Template>;
  templateVersionId?: Maybe<Scalars['ID']>;
  updatedAt?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Int']>;
  versions: Array<Draft>;
};

/** The connection type for Template. */
export type TemplateConnection = {
  __typename?: 'TemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TemplateEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Template>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of TemplateCreateMutation */
export type TemplateCreateMutationPayload = {
  __typename?: 'TemplateCreateMutationPayload';
  draft: Draft;
};

/** An edge in a connection. */
export type TemplateEdge = {
  __typename?: 'TemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Template>;
};

export type TemplateInput = {
  body?: Maybe<Scalars['String']>;
  css?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  templatableType?: Maybe<TemplatableType>;
};

/** Autogenerated return type of TemplateUpdateMutation */
export type TemplateUpdateMutationPayload = {
  __typename?: 'TemplateUpdateMutationPayload';
  draft: Draft;
  draftable: Draftable;
  errors: Array<Error>;
  warnings: Array<Error>;
};

/** Represents all available information for a video */
export type Thumbnail = {
  __typename?: 'Thumbnail';
  large?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  small?: Maybe<Scalars['String']>;
};

/** A collection of stories */
export type TopStoryList = Node & {
  __typename?: 'TopStoryList';
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  max: Scalars['Int'];
  min: Scalars['Int'];
  name: Scalars['String'];
  orderedStories?: Maybe<Array<Slugable>>;
  stories: Array<TopStoryListStory>;
  tags: Array<Tag>;
  updatedAt: Scalars['ISO8601DateTime'];
  user: User;
};

/** The connection type for TopStoryList. */
export type TopStoryListConnection = {
  __typename?: 'TopStoryListConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TopStoryListEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TopStoryList>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of TopStoryListCreateMutation */
export type TopStoryListCreateMutationPayload = {
  __typename?: 'TopStoryListCreateMutationPayload';
  errors: Array<Error>;
  topStoryList?: Maybe<TopStoryList>;
};

/** Autogenerated return type of TopStoryListDeleteMutation */
export type TopStoryListDeleteMutationPayload = {
  __typename?: 'TopStoryListDeleteMutationPayload';
  errors: Array<Error>;
  topStoryList: TopStoryList;
};

/** An edge in a connection. */
export type TopStoryListEdge = {
  __typename?: 'TopStoryListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TopStoryList>;
};

export type TopStoryListInput = {
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  stories?: Maybe<Array<TopStoryListStoryInput>>;
  tags?: Maybe<Array<TagInput>>;
};

/** A story in a top story list */
export type TopStoryListStory = {
  __typename?: 'TopStoryListStory';
  order: Scalars['Int'];
  story: Scalars['ID'];
};

export type TopStoryListStoryInput = {
  story: Scalars['ID'];
  order: Scalars['Int'];
};

/** Autogenerated return type of TopStoryListUpdateMutation */
export type TopStoryListUpdateMutationPayload = {
  __typename?: 'TopStoryListUpdateMutationPayload';
  errors: Array<Error>;
  topStoryList?: Maybe<TopStoryList>;
};

export type Transaction = Node & {
  __typename?: 'Transaction';
  created_at: Scalars['DateTime'];
  id: Scalars['ID'];
  source: SubscriptionSource;
  subscription?: Maybe<Subscription>;
  transaction_id: Scalars['String'];
  transaction_type?: Maybe<Scalars['String']>;
};

/** The connection type for Transaction. */
export type TransactionConnection = {
  __typename?: 'TransactionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TransactionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Transaction>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type TransactionEdge = {
  __typename?: 'TransactionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Transaction>;
};

/** a signed url and final locations for the asset, once it is uploaded */
export type UploadUrl = {
  __typename?: 'UploadUrl';
  thumbnails: Thumbnail;
  url: Scalars['String'];
};

/** a url and a mime_type */
export type Url = {
  __typename?: 'Url';
  mime?: Maybe<Scalars['String']>;
  unsignedUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** A user profile */
export type User = Node & {
  __typename?: 'User';
  author?: Maybe<Author>;
  avatar_url: Scalars['String'];
  confirmedAt?: Maybe<Scalars['ISO8601DateTime']>;
  /** @deprecated use confirmedAt */
  confirmed_at?: Maybe<Scalars['DateTime']>;
  created_at: Scalars['DateTime'];
  /** @deprecated Is this even used? */
  dashboardToken?: Maybe<Scalars['String']>;
  /** @deprecated The `db_id` field is being eliminated and instead `id` arguments will convert node IDs to database IDs transparently */
  db_id?: Maybe<Scalars['Int']>;
  /** @deprecated Field no longer supported */
  default_card?: Maybe<Card>;
  deletedAt?: Maybe<Scalars['ISO8601DateTime']>;
  email: Scalars['String'];
  /** @deprecated Deprecated in favor of trial_expires_at. Refer to the expires_at field on each node within the Subscription connection */
  expires_at: Scalars['DateTime'];
  firstName?: Maybe<Scalars['String']>;
  /** @deprecated Use firstName */
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  /** @deprecated Use lastName */
  last_name?: Maybe<Scalars['String']>;
  /** @deprecated The `node_id` field is being eliminated */
  node_id?: Maybe<Scalars['ID']>;
  platform: Scalars['String'];
  role?: Maybe<Roles>;
  /** @deprecated Field no longer supported */
  savedMedia?: Maybe<SlugableConnection>;
  /** @deprecated Field no longer supported */
  savedVideos?: Maybe<VideoConnection>;
  /** @deprecated Field no longer supported */
  subscriptions?: Maybe<SubscriptionConnection>;
  thumbnails?: Maybe<Thumbnail>;
  /** @deprecated Field no longer supported */
  trial_expires_at: Scalars['DateTime'];
  updated_at: Scalars['DateTime'];
};


/** A user profile */
export type UserSavedMediaArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A user profile */
export type UserSavedVideosArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A user profile */
export type UserSubscriptionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** A user profile */
export type UserThumbnailsArgs = {
  aspect_ratio?: Maybe<AspectRatio>;
};

/** Autogenerated return type of UserAuthorToggleMutation */
export type UserAuthorToggleMutationPayload = {
  __typename?: 'UserAuthorToggleMutationPayload';
  errors: Array<Error>;
  user?: Maybe<User>;
};

/** Checker for user/password presence */
export type UserCheck = Node & {
  __typename?: 'UserCheck';
  /** @deprecated The `db_id` field is being eliminated and instead `id` arguments will convert node IDs to database IDs transparently */
  db_id?: Maybe<Scalars['Int']>;
  exists?: Maybe<Scalars['Boolean']>;
  has_password?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  /** @deprecated The `node_id` field is being eliminated */
  node_id?: Maybe<Scalars['ID']>;
};

/** The connection type for User. */
export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<User>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of UserDeleteMutation */
export type UserDeleteMutationPayload = {
  __typename?: 'UserDeleteMutationPayload';
  errors: Array<Error>;
  user: User;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<User>;
};

export type UserInput = {
  author?: Maybe<AuthorInput>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<Roles>;
};

export type UserInviteInput = {
  email: Scalars['String'];
  first_name: Scalars['String'];
  is_author: Scalars['Boolean'];
  last_name: Scalars['String'];
  role: Roles;
  slug?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of UserInviteMutation */
export type UserInviteMutationPayload = {
  __typename?: 'UserInviteMutationPayload';
  errors: Array<Error>;
  user?: Maybe<User>;
};

/** Autogenerated return type of UserResetPasswordMutation */
export type UserResetPasswordMutationPayload = {
  __typename?: 'UserResetPasswordMutationPayload';
  errors: Array<Error>;
  user?: Maybe<User>;
};

/** Autogenerated return type of UserUpdateMutation */
export type UserUpdateMutationPayload = {
  __typename?: 'UserUpdateMutationPayload';
  errors: Array<Error>;
  user?: Maybe<User>;
};

export type UserValidateInvite = {
  __typename?: 'UserValidateInvite';
  error?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  user?: Maybe<User>;
};

/** Any type that can be validated and have errors and/or warnings returned */
export type Validatable = {
  errors: Array<Error>;
  warnings: Array<Error>;
};

/** An A/B test variant */
export type Variant = {
  __typename?: 'Variant';
  name: Scalars['String'];
  value: Scalars['String'];
};

/** Represents audit of any object */
export type Version = Node & {
  __typename?: 'Version';
  actor?: Maybe<User>;
  created_at: Scalars['DateTime'];
  /** @deprecated The `db_id` field is being eliminated and instead `id` arguments will convert node IDs to database IDs transparently */
  db_id?: Maybe<Scalars['Int']>;
  event?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  item_id?: Maybe<Scalars['Int']>;
  item_type?: Maybe<Scalars['String']>;
  /** @deprecated The `node_id` field is being eliminated */
  node_id?: Maybe<Scalars['ID']>;
  object?: Maybe<Scalars['String']>;
  object_changes?: Maybe<Scalars['String']>;
  whodunnit?: Maybe<Scalars['String']>;
};

/** Represents all available information for a video */
export type Video = Node & Shareable & Slugable & {
  __typename?: 'Video';
  _score?: Maybe<Scalars['Float']>;
  aired_at: Scalars['DateTime'];
  block?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  captions?: Maybe<Scalars['String']>;
  categories?: Maybe<Array<Category>>;
  checksum?: Maybe<Scalars['String']>;
  clip?: Maybe<Scalars['Boolean']>;
  comscore: Scalars['String'];
  content_id: Scalars['String'];
  created_at: Scalars['DateTime'];
  cuePoints?: Maybe<Scalars['String']>;
  /** @deprecated The `db_id` field is being eliminated and instead `id` arguments will convert node IDs to database IDs transparently */
  db_id?: Maybe<Scalars['Int']>;
  deletable?: Maybe<Scalars['Boolean']>;
  deleted: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  downloads?: Maybe<Array<Maybe<Download>>>;
  /** @deprecated The `duration` field is now accessible via the associated `VideoFile` object instead */
  duration?: Maybe<Scalars['Float']>;
  formats?: Maybe<Array<Maybe<Format>>>;
  free?: Maybe<Scalars['Boolean']>;
  /** @deprecated The `height` field is now accessible via the associated `VideoFile` object instead */
  height?: Maybe<Scalars['Int']>;
  hero_video?: Maybe<HeroVideo>;
  id: Scalars['ID'];
  imgix_thumbnail_url?: Maybe<Scalars['String']>;
  indexed?: Maybe<Scalars['Boolean']>;
  is_saved?: Maybe<Scalars['Boolean']>;
  last_exported_at?: Maybe<Scalars['DateTime']>;
  legacyTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  marked_thumbnail_url?: Maybe<Scalars['String']>;
  /** @deprecated The `media_url` field is being eliminated and instead `video_urls` should be used instead */
  media_url: Scalars['String'];
  organization?: Maybe<Organization>;
  /** @deprecated Use the `filename` attribute on the associated `VideoFile` instead */
  original_file_name?: Maybe<Scalars['String']>;
  parsed_html?: Maybe<Scalars['String']>;
  play_count?: Maybe<Scalars['Int']>;
  promotions?: Maybe<PromotionConnection>;
  public_at: Scalars['DateTime'];
  public_url?: Maybe<Scalars['String']>;
  published_at?: Maybe<Scalars['DateTime']>;
  related_media?: Maybe<SlugableConnection>;
  related_videos?: Maybe<VideoConnection>;
  show?: Maybe<Show>;
  signed_cea708?: Maybe<Scalars['String']>;
  signed_url?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  template?: Maybe<Template>;
  thumbnail_attribution?: Maybe<Scalars['String']>;
  thumbnail_checksum?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  thumbnails?: Maybe<Thumbnail>;
  title?: Maybe<Scalars['String']>;
  tms_id?: Maybe<Scalars['String']>;
  transcodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  transcoding_status?: Maybe<Scalars['String']>;
  transcript?: Maybe<Scalars['String']>;
  transcription_status?: Maybe<Scalars['String']>;
  tweet_text?: Maybe<Scalars['String']>;
  updated_at: Scalars['DateTime'];
  /** @deprecated The `url` field is now accessible via the associated `VideoFile` object instead */
  url?: Maybe<Scalars['String']>;
  versions?: Maybe<Array<Maybe<Version>>>;
  videoFile?: Maybe<VideoFile>;
  video_urls?: Maybe<Array<Maybe<Url>>>;
  /** @deprecated The `width` field is now accessible via the associated `VideoFile` object instead */
  width?: Maybe<Scalars['Int']>;
};


/** Represents all available information for a video */
export type VideoPromotionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Represents all available information for a video */
export type VideoRelated_MediaArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Represents all available information for a video */
export type VideoRelated_VideosArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


/** Represents all available information for a video */
export type VideoThumbnailsArgs = {
  aspect_ratio?: Maybe<AspectRatio>;
};

/** The connection type for Video. */
export type VideoConnection = {
  __typename?: 'VideoConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VideoEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Video>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type VideoEdge = {
  __typename?: 'VideoEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Video>;
};

/** Captures metadata for a video file mezzanine */
export type VideoFile = Node & {
  __typename?: 'VideoFile';
  createdAt: Scalars['ISO8601DateTime'];
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  filename?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isLive: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  thumbnails?: Maybe<Thumbnail>;
  videoUrls: Array<Url>;
  width?: Maybe<Scalars['Int']>;
};


/** Captures metadata for a video file mezzanine */
export type VideoFileThumbnailsArgs = {
  aspect_ratio?: Maybe<AspectRatio>;
};

/** The connection type for VideoFile. */
export type VideoFileConnection = {
  __typename?: 'VideoFileConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VideoFileEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<VideoFile>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of VideoFileCreateMutation */
export type VideoFileCreateMutationPayload = {
  __typename?: 'VideoFileCreateMutationPayload';
  errors: Array<Error>;
  videoFile: VideoFile;
};

/** An edge in a connection. */
export type VideoFileEdge = {
  __typename?: 'VideoFileEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<VideoFile>;
};

export type VideoFileInput = {
  url?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};

export type VideoInput = {
  aired_at?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  caption_file?: Maybe<Array<Maybe<Scalars['String']>>>;
  categories?: Maybe<Array<Maybe<Category>>>;
  checksum?: Maybe<Scalars['String']>;
  clip?: Maybe<Scalars['Boolean']>;
  cuePoints?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  free?: Maybe<Scalars['Boolean']>;
  height?: Maybe<Scalars['Int']>;
  indexed?: Maybe<Scalars['Boolean']>;
  original_file_name?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Boolean']>;
  published_at?: Maybe<Scalars['String']>;
  show_id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
  small_thumbnail_url?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Scalars['String']>;
  thumbnail_attribution?: Maybe<Scalars['String']>;
  thumbnail_checksum?: Maybe<Scalars['String']>;
  thumbnail_url?: Maybe<Scalars['String']>;
  tms_id?: Maybe<Scalars['String']>;
  transcribe?: Maybe<Scalars['Boolean']>;
  transcript?: Maybe<Scalars['String']>;
  tweet_text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  videoFileId?: Maybe<Scalars['ConvertedID']>;
  width?: Maybe<Scalars['Int']>;
};

/** The connection type for Video. */
export type VideoTypeWithHitCountConnection = {
  __typename?: 'VideoTypeWithHitCountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<VideoEdge>>>;
  hitCount?: Maybe<Scalars['Int']>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Newsletters to be viewed */
export enum ViewableNewsletters {
  /** need2know */
  Need2Know = 'NEED2KNOW',
  /** cheddar */
  Cheddar = 'CHEDDAR',
  /** cannabiz */
  Cannabiz = 'CANNABIZ'
}

/** GraphQL Endpoint for the weather API */
export type Weather = {
  __typename?: 'Weather';
  currentConditions: WeatherCurrentConditions;
  forecast: Array<WeatherForecast>;
};


/** GraphQL Endpoint for the weather API */
export type WeatherForecastArgs = {
  type: WeatherForecastType;
  count: Scalars['Int'];
};

/** Weather API fields for current conditions */
export type WeatherCurrentConditions = {
  __typename?: 'WeatherCurrentConditions';
  feels: Scalars['Int'];
  humidity: Scalars['Int'];
  icon: WeatherIconCode;
  phrase: Scalars['String'];
  precipitation?: Maybe<Scalars['Int']>;
  temperature: Scalars['Int'];
};

/** Daypart fields for weather API */
export type WeatherDaypart = {
  __typename?: 'WeatherDaypart';
  conditions: WeatherIconCode;
  humidity: Scalars['Int'];
  narrative: Scalars['String'];
  precipitation: Scalars['Int'];
  uvIndex: Scalars['Int'];
  windDirection: Scalars['String'];
  windSpeed: Scalars['Int'];
};

/** Weather API fields for weather forecast */
export type WeatherForecast = {
  __typename?: 'WeatherForecast';
  conditions: WeatherIconCode;
  day: WeatherDaypart;
  dayOfWeek: DayOfWeek;
  expirationTime: Scalars['DateTime'];
  moonriseTime: Scalars['DateTime'];
  moonsetTime: Scalars['DateTime'];
  night: WeatherDaypart;
  precipitation: Scalars['Int'];
  sunriseTime: Scalars['DateTime'];
  sunsetTime: Scalars['DateTime'];
  temperature: Scalars['Int'];
  temperatureHigh: Scalars['Int'];
  temperatureLow: Scalars['Int'];
};

/** Specify duration of data. Accepts: DAILY or HOURLY */
export enum WeatherForecastType {
  /** Daily */
  Daily = 'DAILY',
  /** Hourly */
  Hourly = 'HOURLY'
}

/** Indicates the icon code for weather data */
export enum WeatherIconCode {
  /** Tornado */
  Tornado = 'TORNADO',
  /** Tropical Storm */
  TropicalStorm = 'TROPICAL_STORM',
  /** Hurricane */
  Hurricane = 'HURRICANE',
  /** Strong Storms */
  StrongStorms = 'STRONG_STORMS',
  /** Thunderstorms */
  Thunderstorms = 'THUNDERSTORMS',
  /** Rain or Snow */
  RainOrSnow = 'RAIN_OR_SNOW',
  /** Rain or Sleet */
  RainOrSleet = 'RAIN_OR_SLEET',
  /** Wintry Mix */
  WintryMix = 'WINTRY_MIX',
  /** Freezing Drizzle */
  FreezingDrizzle = 'FREEZING_DRIZZLE',
  /** Drizzle */
  Drizzle = 'DRIZZLE',
  /** Freezing Rain */
  FreezingRain = 'FREEZING_RAIN',
  /** Showers */
  Showers = 'SHOWERS',
  /** Rain */
  Rain = 'RAIN',
  /** Flurries */
  Flurries = 'FLURRIES',
  /** Snow Showers */
  SnowShowers = 'SNOW_SHOWERS',
  /** Blowing or Drifting Snow */
  BlowingOrDriftingSnow = 'BLOWING_OR_DRIFTING_SNOW',
  /** Snow */
  Snow = 'SNOW',
  /** Hail */
  Hail = 'HAIL',
  /** Sleet */
  Sleet = 'SLEET',
  /** Blowing Dust or Sandstorm */
  BlowingDustOrSandstorm = 'BLOWING_DUST_OR_SANDSTORM',
  /** Foggy */
  Foggy = 'FOGGY',
  /** Haze */
  Haze = 'HAZE',
  /** Smoke */
  Smoke = 'SMOKE',
  /** Breezy */
  Breezy = 'BREEZY',
  /** Windy */
  Windy = 'WINDY',
  /** Frigid or Ice Crystals */
  FrigidOrIceCrystals = 'FRIGID_OR_ICE_CRYSTALS',
  /** Cloudy */
  Cloudy = 'CLOUDY',
  /** Mostly Cloudy Night */
  MostlyCloudyNight = 'MOSTLY_CLOUDY_NIGHT',
  /** Mostly Cloudy */
  MostlyCloudy = 'MOSTLY_CLOUDY',
  /** Partly Cloudy Night */
  PartlyCloudyNight = 'PARTLY_CLOUDY_NIGHT',
  /** Partly Cloudy */
  PartlyCloudy = 'PARTLY_CLOUDY',
  /** Clear */
  Clear = 'CLEAR',
  /** Sunny */
  Sunny = 'SUNNY',
  /** Fair or Mostly Clear */
  FairOrMostlyClear = 'FAIR_OR_MOSTLY_CLEAR',
  /** Fair or Mostly Sunny */
  FairOrMostlySunny = 'FAIR_OR_MOSTLY_SUNNY',
  /** Mixed Rain and Hail */
  MixedRainAndHail = 'MIXED_RAIN_AND_HAIL',
  /** Hot */
  Hot = 'HOT',
  /** Isolated Thunderstorms */
  IsolatedThunderstorms = 'ISOLATED_THUNDERSTORMS',
  /** Scattered Thunderstorms */
  ScatteredThunderstorms = 'SCATTERED_THUNDERSTORMS',
  /** Scattered Showers */
  ScatteredShowers = 'SCATTERED_SHOWERS',
  /** Heavy Rain */
  HeavyRain = 'HEAVY_RAIN',
  /** Scattered Snow Showers */
  ScatteredSnowShowers = 'SCATTERED_SNOW_SHOWERS',
  /** Heavy Snow */
  HeavySnow = 'HEAVY_SNOW',
  /** Blizzard */
  Blizzard = 'BLIZZARD',
  /** Not Available */
  NotAvailable = 'NOT_AVAILABLE',
  /** Scattered Showers Night */
  ScatteredShowersNight = 'SCATTERED_SHOWERS_NIGHT',
  /** Scattered Snow Showers Night */
  ScatteredSnowShowersNight = 'SCATTERED_SNOW_SHOWERS_NIGHT',
  /** Scattered Thunderstorms Night */
  ScatteredThunderstormsNight = 'SCATTERED_THUNDERSTORMS_NIGHT'
}

/** A whitelisted domain for internal subscriptions */
export type WhitelistedDomain = Node & {
  __typename?: 'WhitelistedDomain';
  created_at: Scalars['DateTime'];
  domain: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** The connection type for WhitelistedDomain. */
export type WhitelistedDomainConnection = {
  __typename?: 'WhitelistedDomainConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<WhitelistedDomainEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<WhitelistedDomain>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type WhitelistedDomainEdge = {
  __typename?: 'WhitelistedDomainEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<WhitelistedDomain>;
};

export type WhitelistedDomainInput = {
  domain?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** Contains every day of the week */
export enum DayOfWeek {
  /** Monday */
  Monday = 'Monday',
  /** Tuesday */
  Tuesday = 'Tuesday',
  /** Wednesday */
  Wednesday = 'Wednesday',
  /** Thursday */
  Thursday = 'Thursday',
  /** Friday */
  Friday = 'Friday',
  /** Saturday */
  Saturday = 'Saturday',
  /** Sunday */
  Sunday = 'Sunday'
}
