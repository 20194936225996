import React, { useContext } from 'react'
import { Context, ContextType } from '../Form'
import StyledSelect from './StyledSelect'
import Input from '../Metadata/AsyncSelect/Input'
import Option from '../Metadata/AsyncSelect/Option'
import MultiValueLabel from '../Metadata/AsyncSelect/MultiValueLabel'
import { TagField_taggable as Fragment } from '../__generated__/TagField_taggable.graphql'
import { SelectOptionType as OptionType } from '../Metadata/SelectOptionType'

type Props = {
  tag: Fragment['tags'][number]
}

const createOptions = (options: Props['tag']['fixedOptions'] | Props['tag']['value']) => {
  return options?.map((option: any) => ({ value: option, label: option }))
}

const MultiSelect: React.FC<Props> = ({ tag }) => {
  const options = createOptions(tag.fixedOptions)
  const { onChange, disabled } = useContext(Context) as ContextType

  return (
    <StyledSelect
      isMulti
      placeholder={`Search for ${tag.name}`}
      maxMenuHeight={100}
      isClearable={false}
      openMenuOnClick={true}
      classNamePrefix='select'
      closeMenuOnSelect={false}
      hideSelectedOptions={true}
      backspaceRemovesValue={false}
      defaultValue={createOptions(tag.value)}
      options={options}
      components={{Option, Input, MultiValueLabel, IndicatorsContainer: () => null}}
      noOptionsMessage={() => 'no matches'}
      isDisabled={disabled}
      onChange={(selectedOptions: OptionType[]) => onChange(`tags[${tag.id}]`, selectedOptions ? selectedOptions.map(selectedOption => selectedOption.value) : [])}
    />
  )
}

export default MultiSelect
