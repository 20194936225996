import React, { useContext, useState } from 'react'
import { graphql } from 'babel-plugin-relay/macro'
import { createFragmentContainer } from 'react-relay'
import { Context, ContextType } from '../Form'
import Section, { SectionProps } from './Section'
import { TemplateSelect_draftable as Fragment } from './__generated__/TemplateSelect_draftable.graphql'
import TemplateSelectDropdown from './TemplateDropdown'
import VersionSelectDropdown from './VersionSelectDropdown'

type Props = SectionProps & {
  draftable: Fragment
}

const filter = (model: any, templateVersionId: string | null, options: any, name: string) => {
  let currentVersion = null
  let currentTemplate = options.filter((c: any) => {
    for (let version of c.versions) {
      if (version.id === (model[name] || templateVersionId)) {
        currentVersion = version.version
        return true
      }
    }
    return false
  })

  if (currentTemplate.length > 0) {
    return { possibleCurrentTemplate: currentTemplate[0], version: currentVersion }
  }

  return { possibleCurrentTemplate: null, version: null }
}

const TemplateSelect = createFragmentContainer(
  (props: Props) => {
    const { model, onChange, errors, disabled } = useContext(Context) as ContextType
    const { draftable: { selectableTemplates, template, templateVersionId }, name } = props
    const templateName = (template && template.name) || ''
    const options = selectableTemplates || []
    const { possibleCurrentTemplate, version } = filter(model, templateVersionId, options, name)
    const [currentVersion] = useState<number | null>(version)
    const [selectedTemplate, setSelectedTemplate] = useState<{ versions: [{ id: string, version: number }], name: string, currentVersion?: number } | null>(possibleCurrentTemplate)
    const formattedTemplateName = (currentVersion && templateName) ? templateName + ` v${currentVersion}` : templateName

    return (
      <Section {...props} preview={formattedTemplateName} errors={errors.for(name)}>
        <TemplateSelectDropdown draftable={props.draftable} name={name} setSelectedTemplate={setSelectedTemplate} selectedTemplate={selectedTemplate} onChange={onChange} disabled={disabled} />
        {selectedTemplate && <VersionSelectDropdown name={name} selectedTemplate={selectedTemplate} draftable={props.draftable} onChange={onChange} disabled={disabled} />}
      </Section>
    )
  },
  {
    draftable: graphql`
      fragment TemplateSelect_draftable on Draftable {
        selectableTemplates {
          name
          versions {
            id
            version
          }
          latestDraft {
            id
          }
        }
        template {
          name
        }
        templateVersionId
      }
    `
  }
)

TemplateSelect.displayName = 'TemplateSelect'
export default TemplateSelect
