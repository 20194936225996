import React, { useContext } from 'react'
import { Context, ContextType } from '../Form'
import environment from '../../../relay/environment'
import { QueryRenderer, createFragmentContainer } from 'react-relay'
import { formatDate, ListProps } from '../HeroPicker/ListRenderer'
import styled from 'styled-components/macro'
import { MediaListRendererQuery } from './__generated__/MediaListRendererQuery.graphql'
import { MediaListRenderer_media } from './__generated__/MediaListRenderer_media.graphql'
import { graphql } from 'babel-plugin-relay/macro'

const MediaListWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 46px;
`

const MediaListItem = styled.div`
  display: block;
  cursor: pointer;
`

const MediaListImage = styled.img`
  max-width: 100%;
  height: auto;
`

const MediaListTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #000000;
`

const MediaListMeta = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  color: ${props => props.theme.colors.grays.aluminium};
`

interface MediaListQueryResponseProps extends Pick<MediaListProps, 'dismiss' | 'name'> {
  media: MediaListRenderer_media
}

const MediaList = createFragmentContainer(
  (props: MediaListQueryResponseProps) => {
    const { onChange } = useContext(Context) as ContextType
    const { name, dismiss } = props
    const media = props.media.edges!

    const handleClick = (id?: string) => {
      onChange(name, id)
      dismiss()
    }

    if(media && media.length < 1) {
      return <div>No results</div>
    }

    return (
      <MediaListWrapper>
        {media.map((m, i) => (
          <React.Fragment key={i}>
            {m && m.node &&
              <MediaListItem key={m.node.id} onClick={() => handleClick(m.node!.id)}>
                <MediaListImage src={m.node.thumbnails!.small || ''} alt="" />
                <MediaListTitle>{m.node.title}</MediaListTitle>
                <div>
                  <MediaListMeta>{formatDate(m.node.public_at as string)}</MediaListMeta>
                  <MediaListMeta> - {m.node.__typename}</MediaListMeta>
                </div>
              </MediaListItem>
            }
          </React.Fragment>
        ))}
      </MediaListWrapper>
    )
  },
  {
    media: graphql`
      fragment MediaListRenderer_media on SlugableConnection {
        edges {
          node {
            id
            public_at
            title
            thumbnails {
              small
            }
            __typename
          }
        }
      }
    `
  }
)

interface MediaListProps extends Omit<ListProps, 'query'> {
  dismiss: () => void
  name: string
}

const MediaListRenderer: React.FC<MediaListProps> = ({ searchTerm, dismiss, name }) => (
  <QueryRenderer<MediaListRendererQuery>
    environment={environment}
    query={graphql`
      query MediaListRendererQuery($searchTerm: String!) {
        media(
          query: $searchTerm
          sort: { attribute: "public_at" direction: DESC }
          first: 8
        ) {
          ...MediaListRenderer_media
        }
      }
    `}
    variables={{searchTerm}}
    render={({props}) => {
      if (props && props.media) return <MediaList media={props.media} dismiss={dismiss} name={name}/>
    }}
  />
)

export default MediaListRenderer
