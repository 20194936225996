import React, { useState } from 'react'
import styled from 'styled-components'
import { Dispatch } from './KeywordsInput'
import { KeyObject } from './KeywordsInputContainer'

type DropdownProps = {
  suggestions: string[]
  setCurrentInputValue: (str: string) => void
  DropdownList: React.RefObject<HTMLUListElement> | null
  addWord: (word: string) => void
  setSuggestions: Dispatch<string[]>
}

const SuggestionsList = styled.ul`
  list-style: none;
  border-radius: 6px;
  box-shadow: 2px -1px 10px 2px rgba(0, 0, 0, 0.1);
  z-index: 200 !important;
  padding: 0;
  width: 120px;
`

const SuggestionsListItem = styled.li`
  width: 100%;
  padding: 5px 20px;
  box-sizing: border-box;
  overflow-wrap: break-word;
  font-weight: bold;

  &.greyed-out{
    background-color: #f1f1f1;
  }
  
  :hover{
    cursor: pointer;
  }
`

export const addClass = (element: EventTarget & HTMLElement, className: string) => {
  element.classList.add(className)
  element.setAttribute('data-testid', 'hovering')
}

export const removeClass = (element: EventTarget & HTMLElement, className: string) => {
  element.classList.remove(className)
  element.removeAttribute('data-testid')
}

const hoverTag = (e: React.MouseEvent<HTMLElement, MouseEvent>, hovering: boolean, setCurrentSelection: Dispatch<number | null>, idx: number | null) => {
  setCurrentSelection(idx)
  if (hovering) {
    addClass(e.currentTarget, 'greyed-out')
  } else {
    removeClass(e.currentTarget, 'greyed-out')
  }
}

const scrollList = (newIdx: number, currentSuggestion: number | null, setCurrentSuggestion: Dispatch<number | null>, elementsArray: HTMLCollectionOf<HTMLLIElement>, removing: boolean) => {
  if (removing && currentSuggestion !== null) { removeClass(elementsArray[currentSuggestion], 'greyed-out') }
  addClass(elementsArray[newIdx], 'greyed-out')
  setCurrentSuggestion(newIdx)
}

const handleKeydown = (e: React.KeyboardEvent<HTMLElement>, suggestionElements: string[], currentSuggestion: null | number, setCurrentSuggestion: Dispatch<number | null>, setCurrentInputValue: DropdownProps['setCurrentInputValue']) => {
  let elementsArray = e.currentTarget.getElementsByTagName('li')
  let newIdx
  const { UP, DOWN } = KeyObject

  switch (e.keyCode) {
    case UP:
      newIdx = (currentSuggestion !== null && currentSuggestion > 0) ? currentSuggestion - 1 : suggestionElements.length - 1;
      scrollList(newIdx, currentSuggestion, setCurrentSuggestion, elementsArray, currentSuggestion !== null)
      break
    case DOWN:
      newIdx = (currentSuggestion !== null && currentSuggestion < suggestionElements.length - 1) ? currentSuggestion + 1 : 0;
      scrollList(newIdx, currentSuggestion, setCurrentSuggestion, elementsArray, currentSuggestion !== null)
      break
  }
  if (newIdx !== undefined) {
    setCurrentInputValue(suggestionElements[newIdx])
  }
}

const handleClick = ( addWord: (word: string) => void , setSuggestions: Dispatch<string[]>, word: string, setCurrentInputValue: (str: string) => void) => {
  addWord(word)
  setCurrentInputValue(' ')
  setSuggestions([])
}

const setTagsNull = (e: React.SyntheticEvent<any, Event>, setCurrentSuggestion: Dispatch<number | null>) => {
  const elementsArray = [...e.currentTarget.getElementsByTagName('li')]

  setCurrentSuggestion(null)
  elementsArray.forEach((element) => {
    removeClass(element, 'greyed-out')
  })
}

const AutoComplete: React.FC<DropdownProps> = ({ suggestions, setCurrentInputValue, DropdownList, addWord, setSuggestions }) => {
  const [currentSelection, setCurrentSelection] = useState<number | null>(null)
  return (
    <SuggestionsList
      data-testid="suggestion-list"
      onMouseEnter={(e) => setTagsNull(e, setCurrentSelection)}
      onMouseOut={(e) => setTagsNull(e, setCurrentSelection)}
      onKeyDown={(e) => handleKeydown(e, suggestions, currentSelection, setCurrentSelection, setCurrentInputValue)} ref={DropdownList}>
      {suggestions.map((suggestion: string, idx: number) => {
        return (<SuggestionsListItem key={idx}
          onMouseEnter={(e) => hoverTag(e, true, setCurrentSelection, idx)}
          onMouseOut={(e) => hoverTag(e, false, setCurrentSelection, null)}
          onClick={() => handleClick(addWord, setSuggestions, suggestion, setCurrentInputValue)}
        >
          {suggestion}
        </SuggestionsListItem>
        )
      })}
    </SuggestionsList>
  )
}

export default AutoComplete
