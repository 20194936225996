import { useState, useEffect, useRef } from 'react'
import { fetchQuery } from 'react-relay'
import { graphql } from 'babel-plugin-relay/macro'
import environment from '../relay/environment'
import { useFeatureFlagQueryResponse as Response } from './__generated__/useFeatureFlagQuery.graphql'

const featureFlagQuery = graphql`
  query useFeatureFlagQuery($name: String!) {
    featureFlag(name: $name) {
      name
      enabled
    }
  }
`

export default (list: string[]): string[] | undefined => {
  const [featureFlagList, setFeatureFlagList] = useState<string[]>(list)
  const initialValuesTracking = useRef({ list })

  useEffect(() => {
    Promise.all(
      initialValuesTracking.current.list.map(
        (name) =>
          new Promise((resolve) => {
            fetchQuery(environment, featureFlagQuery, { name })
              .toPromise()
              .then((data) => {
                const { featureFlag } = data as Response
                resolve(featureFlag?.enabled && featureFlag.name)
              })
          })
      )
    ).then((values) => setFeatureFlagList(values as string[]))
  }, [])

  return featureFlagList.filter(Boolean)
}
