import React from 'react'
import { Box, TextField } from '@material-ui/core'
import { FormikContextType, useFormikContext } from 'formik'

type Schema = {
  input: {
    email: string
    first_name: string
    last_name: string
    [key: string]: any
  }
}

const FIELDS = [
  { name: 'first_name', label: 'First Name', type: 'text' },
  { name: 'last_name', label: 'Last Name', type: 'text' },
  { name: 'email', label: 'Email', type: 'email' }
]

const StaticFields = () => {
  const { values, errors, touched, handleBlur, handleChange }: FormikContextType<Schema> = useFormikContext()

  return (
    <>
      {FIELDS.map((field, i) => (
        <Box mt={1} mb={4} height={55} key={i}>
          <TextField
            fullWidth
            variant='outlined'
            type={field.type}
            id={`input.${field.name}`}
            label={field.label}
            name={`input.${field.name}`}
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.input[field.name] || ''}
            error={touched?.input && touched?.input[field.name] && errors?.input && Boolean(errors?.input[field.name])}
            helperText={touched?.input && touched?.input[field.name] && errors?.input && errors?.input[field.name]}
          />
        </Box>
      ))}
    </>
  )
}

export default StaticFields
