import React, { FC } from 'react'
import * as Yup from 'yup'
import { Box, Divider, Dialog } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'
import { graphql } from 'babel-plugin-relay/macro'
import { TopStoriesCreateMutationResponse } from './__generated__/TopStoriesCreateMutation.graphql'
import Form, { formatErrors, handleNonFieldErrors, OnCompletedOptions as FormProps } from '../Settings/Form'
import StaticFields from './StaticFields'
import ModalHeader from '../GenericFormComponents/ModalHeader'
import Buttons from '../GenericFormComponents/ModalButtons'

export interface Props {
  id: string
  onClose: () => void
  open: boolean
}

export interface ValidationSchema {
  organizationId: string
  input: {
    name: string
    min: string
    max: string
  }
}

interface onCompleteProps extends FormProps {
  values: TopStoriesCreateMutationResponse
}

const initialValues = (id: string): ValidationSchema => ({
  organizationId: id,
  input: {
    name: '',
    min: '',
    max: ''
  }
})

const mutation = graphql`
  mutation TopStoriesCreateMutation($organizationId: ID!, $input: TopStoryListInput!) {
    topStoryListCreate(organizationId: $organizationId, input: $input) {
      topStoryList {
        id
        name
      }
      errors {
        field
        messages
      }
    }
  }
`

export const validationSchema = Yup.object().shape({
  organizationId: Yup.string().max(255),
  input: Yup.object().shape({
    name: Yup.string().max(5000).required('Title is required'),
    min: Yup.number().positive('Must be a positive number').required('Minimum value is required'),
    max: Yup.number().positive('Must be a positive number').required('Maximum value is required')
  })
})

const TopStoriesCreate: FC<Props> = ({ onClose, open, id }) => {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const onComplete = ({ values: { topStoryListCreate }, setErrors, resetForm, formValues }: onCompleteProps) => {
    if (!topStoryListCreate?.errors.length) {
      onClose()
      resetForm()
      enqueueSnackbar(`Top Story "${topStoryListCreate?.topStoryList?.name}" successfully created`, { variant: 'success' })
      history.push(`/topstories/${topStoryListCreate?.topStoryList?.id}`)
    } else {
      handleNonFieldErrors(topStoryListCreate.errors, formValues!.input, enqueueSnackbar)
      setErrors({ input: formatErrors(topStoryListCreate.errors) })
    }
  }

  return (
    <Dialog maxWidth='xs' fullWidth onClose={onClose} open={open}>
      <Form mutation={mutation} onComplete={onComplete} validationSchema={validationSchema} initialValues={initialValues(id)}>
        <ModalHeader title={'Create Top Story List'} />
        <Box p={3}>
          <StaticFields />
        </Box>
        <Divider />
        <Buttons onClose={onClose} buttonText={'Create'} />
      </Form>
    </Dialog>
  )
}

export default TopStoriesCreate
