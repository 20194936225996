import React, { SyntheticEvent } from 'react'
import styled, { css } from 'styled-components'

const TextStyle = css`
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`

const StyledButton = styled.button`
  padding: 8px 12px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  user-select: none;
  cursor: pointer;
  background: white;
  color: ${props => props.theme.colors.primary.nero};
  border: 1px solid ${props => props.theme.colors.grays.abbey};
  border-radius: 3px;
  outline: none;
  margin-right: 16px;
  ${TextStyle}

  &:hover {
    background-color: ${props => props.theme.colors.primary.mediumSlateBlue};
    border-color: ${props => props.theme.colors.primary.mediumSlateBlue};
    color: white;
    transition: 0.2s ease-in-out;
  }

  span, i {
    ${TextStyle}
  }

  i {
    margin-right: 5px;
  }
`

const IconButton = styled.button`
  color: black;
  cursor: pointer;
  border: none;
  background: transparent;
  outline: none;

  &:hover {
    color: ${props => props.theme.colors.primary.mediumSlateBlue};
  }
`

type ButtonProps = {
  icon?: string
  onClick: () => void
}

const Button: React.FC<ButtonProps> = ({ children, icon, onClick }) => {

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation()
    onClick()
  }

  return(
    <>
      { icon && <IconButton onClick={handleClick}><i className="material-icons">{icon}</i></IconButton> }
      { (!icon && children) && <StyledButton onClick={handleClick}>{children}</StyledButton> }
    </>
  )
}

export default Button
