import React, { useContext } from 'react'
import styled from 'styled-components'
import Section, { SectionProps } from './Section'
import { Context, ContextType } from '../Form'

const StyledTextarea = styled.textarea`
  padding: 12px;
  width: 256px;
  height: 144px;
  border-radius: 2px;
  box-shadow: 0 2px 4px 0 rgba(238, 238, 238, 0.32);
  border: solid 1px rgba(151, 151, 151, 0.1);
  font-size: ${props => props.theme.fontSizes[1]}px;
  font-weight: ${props => props.theme.fontWeights[1]};
  color: ${props => props.theme.colors.grays.tuna};
  resize: none;
  box-sizing: border-box;

  ::placeholder {
    color: ${props => props.theme.colors.grays.grayChateau};
    font-style: italic;
  }

  &:focus {
    outline: none
  }
`

const Textarea: React.FC<SectionProps> = (props) => {
  const { model, onChange, errors, disabled } = useContext(Context) as ContextType
  const value = model[props.name] || ''

  return (
    <Section {...props} preview={value} errors={errors.for(props.name)}>
      <StyledTextarea
        {...props}
        disabled={disabled}
        value={value}
        onChange={onChange}
      />
    </Section>
  )
}

export default Textarea
