/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type Thumbnail_article = {
    readonly thumbnailUrl: string | null;
    readonly thumbnails: {
        readonly small: string | null;
    } | null;
    readonly " $refType": "Thumbnail_article";
};
export type Thumbnail_article$data = Thumbnail_article;
export type Thumbnail_article$key = {
    readonly " $data"?: Thumbnail_article$data;
    readonly " $fragmentRefs": FragmentRefs<"Thumbnail_article">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Thumbnail_article",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnailUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Thumbnail",
      "kind": "LinkedField",
      "name": "thumbnails",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "small",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Article",
  "abstractKey": null
};
(node as any).hash = 'e5bf723f061115432ea2936a40553841';
export default node;
